import React from "react";
import { Link } from "react-router-dom";
import "./ProfileList.css";
import UserImg from "../../assets/avatar_placeholder.png";
import "../LandingPage/Landing.css";
import { ImCancelCircle } from "react-icons/im";

const UserProfile = (props) => {
  const Hide_Email = () => {
    const split_1 = props.data.email_id.split("@");
    const split_2 = split_1[1];
    return split_2;
  };

  const gender = () => {
    const g = props.data.gender;
    if (g === "F") {
      return "Female";
    } else {
      return "Male";
    }
  };

  const birthData = () => {
    const data = props.data.userDetails.dob;
    const reverseData = data.split("-").reverse();
    const date = new Date(`${reverseData.join("-")}`).toLocaleString("en-IN", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return date;
  };

  return (
    <div className="show-more-data">
      <div className="user-image">
        <Link
          to={`/profile/view/${props.data._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          <img
            src={
              props.data.userDetails.profileImage
                ? props.data.userDetails.profileImage
                : UserImg
            }
            alt={props.data.first_name}
          />
        </Link>
        <div className="cancel-icon" onClick={() => props.cancel()}>
          <ImCancelCircle />
        </div>
      </div>
      <div className="show-more-details">
        <ul>
          <li>
            Name : {props.data.first_name ? props.data.first_name : "Unknown"}
            &nbsp;
            {props.data.last_name ? props.data.last_name : "Unknown"}
          </li>
          <li>Gender : {props.data.gender ? gender() : "NA"} </li>
          <li>
            Phone :{" "}
            {props.data.phone_number
              ? `${props.data.phone_number.toString().slice(0, 6)}****`
              : "NA"}{" "}
          </li>
          <li>
            Family-status :{" "}
            {props.data.userDetails.familyStatus
              ? props.data.userDetails.familyStatus
              : "NA"}{" "}
          </li>
          <li>
            Height :{" "}
            {props.data.userDetails.height
              ? props.data.userDetails.height + "cm"
              : "NA"}{" "}
          </li>
          <li>
            Religion :{" "}
            {props.data.userDetails.religion
              ? props.data.userDetails.religion
              : "NA"}{" "}
          </li>
          <li>
            Degree :{" "}
            {props.data.userDetails.qualification
              ? props.data.userDetails.qualification
              : "NA"}{" "}
          </li>
          <li>
            Family-income :{" "}
            {props.data.userDetails.familyIncome
              ? props.data.userDetails.familyIncome
              : "NA"}{" "}
          </li>
          <li>
            City :{" "}
            {props.data.userDetails.city ? props.data.userDetails.city : "NA"}{" "}
          </li>
        </ul>
        <ul>
          <li>
            Age :{" "}
            {props.data.userDetails.age
              ? `${props.data.userDetails.age} Years`
              : "NA"}
          </li>
          <li>DOB : {props.data.userDetails.dob ? birthData() : "NA"}</li>
          <li>
            Mail-id : {props.data.email_id ? `****@${Hide_Email()}` : "NA"}
          </li>
          <li>
            Family-value :{" "}
            {props.data.userDetails.familyType
              ? props.data.userDetails.familyType
              : "NA"}
          </li>
          <li>
            Marital-status :{" "}
            {props.data.userDetails.marital
              ? props.data.userDetails.marital
              : "NA"}
          </li>
          <li>
            Horoscope :{" "}
            {props.data.userDetails.horoscope
              ? props.data.userDetails.horoscope
              : "NA"}
          </li>
          <li>
            Specialization :{" "}
            {props.data.userDetails.specialization
              ? props.data.userDetails.specialization
              : "NA"}
          </li>
          <li>
            Income :{" "}
            {props.data.userDetails.income
              ? props.data.userDetails.income
              : "NA"}
          </li>
          <li>
            State :{" "}
            {props.data.userDetails.state ? props.data.userDetails.state : "NA"}
          </li>
        </ul>
      </div>
      <div className="view-more-details">
        <Link
          to={`/profile/view/${props.data._id}`}
          title={"More Details"}
          target="_blank"
          rel="noreferrer"
        >
          view more details
        </Link>
      </div>
    </div>
  );
};

export default UserProfile;
