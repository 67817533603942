import React from "react";
import pdf from "@react-pdf/renderer";
import firstPage from "../../../assets/BiodataImages/biodata-format-8.png";
const { Page, Text, Image, Document, View, StyleSheet } = pdf;
const styles = StyleSheet.create({
  Text: {
    marginLeft: "8px",
    fontSize: "12",
    padding: "5 0",
  },

  headerText: {
    marginBottom: "10px",
    color: "#000",
    fontSize: "18",
    letterSpacing: "1.1px",
  },

  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  container_1: {
    width: "225px",
    position: "absolute",
    top: "10%",
    right: "9%",
  },

  container_2: {
    position: "absolute",
    top: "43%",
    left: "10%",
  },

  container_3: {
    position: "absolute",
    top: "68%",
    left: "10%",
  },

  container_4: {
    position: "absolute",
    top: "10%",
    left: "10%",
  },

  container_5: {
    width: "300px",
    height: "210px",
    position: "absolute",
    top: "39%",
    left: "25%",
  },

  profileImage: {
    width: "100%",
    height: "100%",
    borderRadius: "15px",
  },

  container_6: {
    position: "absolute",
    bottom: "10%",
    left: "10%",
  },

  Image_container_1: {
    width: "210px",
    height: "235px",
    position: "absolute",
    top: "10%",
    left: "11%",
  },

  Image_container_2: {
    width: "210px",
    height: "235px",
    position: "absolute",
    top: "46%",
    right: "11%",
  },

  TextContainer_1: {
    marginLeft: "8px",
    fontSize: "12",
    padding: "2 0",
  }
});
const FormatFive = ({
  fields,
  uncleList,
  auntyList,
  mamaList,
  mamiList,
  brotherList,
  sisterList,
  cousinList,
  base64,
}) => {
  const dob = new Date(fields.dob);
  const birthDate = isNaN(dob.getDate()) ? "" : dob.getDate();
  const birthYear = isNaN(dob.getFullYear()) ? "" : dob.getFullYear();
  const birthMonth =
    dob.toLocaleString("default", { month: "long" }) === "Invalid Date"
      ? ""
      : dob.toLocaleString("default", { month: "long" });
  const date_of_birth = `${birthDate} ${birthMonth} ${birthYear}`;
  const checkIfLastIndex = (index, length) => {
    return index === length - 1 ? "" : ", ";
  };
  return (
    <Document>
      {/* PAGE 1 */}
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={firstPage} />
        </View>
        <View style={styles.Image_container_1}>
          <Image src={{ uri: base64 }} style={styles.profileImage} />
        </View>
        <View style={styles.container_1}>
          <Text style={styles.TextContainer_1}>
            Name : {fields.first_name} {fields.last_name}
          </Text>
          <Text style={styles.TextContainer_1}>Location : {fields.current_location}</Text>
          <Text style={styles.TextContainer_1}>Country : {fields.country}</Text>
          <Text style={styles.TextContainer_1}>
            Gender : {fields.gender === "M" ? "Male" : "Female"}
          </Text>
          <Text style={styles.TextContainer_1}>Heigth : {fields.current_location}</Text>
          <Text style={styles.TextContainer_1}>Date of Birth : {date_of_birth}</Text>
          <Text style={styles.TextContainer_1}>Horoscope : {fields.horoscope}</Text>
          <Text style={styles.TextContainer_1}>Religion : {fields.religion}</Text>
          <Text style={styles.TextContainer_1}>Blood Group : {fields.blood_group}</Text>
          <Text style={styles.TextContainer_1}>Marital Status : {fields.marital}</Text>
          <Text style={styles.TextContainer_1}>Language Known : {fields.language}</Text>
          {
            fields.about_us? 
            <Text style={styles.TextContainer_1}>About Myself : {fields.about_us}</Text>
            : null
          }
        </View>
        <View style={styles.Image_container_2}>
          <Image src={{ uri: base64 }} style={styles.profileImage} />
        </View>
        <View style={styles.container_2}>
          <Text style={styles.headerText}>Qualification and Occupation</Text>
          <Text style={styles.Text}>
            Highest qualification : {fields.qualification}
          </Text>
          <Text style={styles.Text}>
            Specalization : {fields.specialization}
          </Text>
          <Text style={styles.Text}>
            Institute Name : {fields.instituteName}
          </Text>
          <Text style={styles.Text}>Company Name : {fields.companyName}</Text>
          <Text style={styles.Text}>Occupation : {fields.designation}</Text>
          <Text style={styles.Text}>Income : {fields.income}</Text>
        </View>
        <View style={styles.container_3}>
          <Text style={styles.headerText}>Contact Details</Text>
          <Text style={styles.Text}>Email : {fields.email_id}</Text>
          <Text style={styles.Text}>Contact : {fields.phone_number}</Text>
          <Text style={styles.Text}>
            Residence Address : {fields.address_1}
          </Text>
        </View>
      </Page>
      {/* PAGE 2 */}
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={firstPage} />
        </View>
        <View style={styles.container_4}>
          <Text style={styles.headerText}>Family Details</Text>
          <Text style={styles.Text}>Grand Father: {fields.dada}</Text>
          <Text style={styles.Text}>Grand Mother: {fields.dadi}</Text>
          <Text style={styles.Text}>Father Name: {fields.fatherName}</Text>
          <Text style={styles.Text}>Father Job: {fields.father_job}</Text>
          <Text style={styles.Text}>Mother Name: {fields.motherName}</Text>
          <Text style={styles.Text}>Mother Job: {fields.mother_job}</Text>
          <Text style={styles.Text}>
            Brother Name:
            {
              // eslint-disable-next-line
              brotherList.map((elem, index) => {
                if (elem.check) {
                  return (
                    <Text key={index} style={styles.familyRightText}>
                      {elem.brother}
                      {checkIfLastIndex(index, brotherList.length)}
                    </Text>
                  );
                }
              })
            }
          </Text>
          <Text style={styles.Text}>
            Sister Name:
            {
              // eslint-disable-next-line
              sisterList.map((elem, index) => {
                if (elem.check) {
                  return (
                    <Text key={index} style={styles.familyRightText}>
                      {elem.sister}
                      {checkIfLastIndex(index, sisterList.length)}
                    </Text>
                  );
                }
              })
            }
          </Text>
          <Text style={styles.Text}>
            Number of Sibilings: {fields.siblings}
          </Text>
        </View>
        <View style={styles.container_5}>
          <Image
            src={{ uri: base64 }}
            alt="User Image"
            style={styles.profileImage}
          />
        </View>
        <View style={styles.container_6}>
          <Text style={styles.Text}>Nana Name: {fields.nana}</Text>
          <Text style={styles.Text}>Nani Name: {fields.nani}</Text>
          <Text style={styles.Text}>
            Uncles Name:
            {
              // eslint-disable-next-line
              uncleList.map((elem, index) => {
                if (elem.check) {
                  return (
                    <Text key={index} style={styles.familyRightText}>
                      {elem.chacha}
                      {checkIfLastIndex(index, uncleList.length)}
                    </Text>
                  );
                }
              })
            }
          </Text>
          <Text style={styles.Text}>
            Aunty Name:
            {
              // eslint-disable-next-line
              auntyList.map((elem, index) => {
                if (elem.check) {
                  return (
                    <Text key={index} style={styles.familyRightText}>
                      {elem.chachi}
                      {checkIfLastIndex(index, auntyList.length)}
                    </Text>
                  );
                }
              })
            }
          </Text>
          <Text style={styles.Text}>
            Mama :
            {
              // eslint-disable-next-line
              mamaList.map((elem, index) => {
                if (elem.check) {
                  return (
                    <Text key={index} style={styles.familyRightText}>
                      {elem.mama}
                      {checkIfLastIndex(index, mamaList.length)}
                    </Text>
                  );
                }
              })
            }
          </Text>
          <Text style={styles.Text}>
            Mami Name:
            {
              // eslint-disable-next-line
              mamiList.map((elem, index) => {
                if (elem.check) {
                  return (
                    <Text key={index} style={styles.familyRightText}>
                      {elem.mami}
                      {checkIfLastIndex(index, mamiList.length)}
                    </Text>
                  );
                }
              })
            }
          </Text>
          <Text style={styles.Text}>
            Cousins Name:
            {
              // eslint-disable-next-line
              cousinList.map((elem, index) => {
                if (elem.check) {
                  return (
                    <Text key={index} style={styles.familyRightText}>
                      {elem.cousins}
                      {checkIfLastIndex(index, cousinList.length)}
                    </Text>
                  );
                }
              })
            }
          </Text>
          <Text style={styles.Text}>Native Place: {fields.nativePlace}</Text>
          <Text style={styles.Text}>Family Status: {fields.familyStatus}</Text>
        </View>
      </Page>
    </Document>
  );
};
export default FormatFive;
