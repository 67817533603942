import React, { useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import { Helmet } from "react-helmet";
import "./CustomerCarePage.css";
const CustomerCarePage = () => {
  const style = {
    fontSize: "12px",
  };

  const [customerCareDetails, setCustomerCareDetails] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleOnChange = (e) => {
    const newVal = { ...customerCareDetails };
    newVal[e.target.name] = e.target.value;
    setCustomerCareDetails(newVal);
  };

  const customerCareSubmit = (e) => {
    e.preventDefault();
    if (
      customerCareDetails.name &&
      customerCareDetails.email &&
      customerCareDetails.message
    ) {
      let completeCustomerCareDetails = {
        name: customerCareDetails.name,
        email: customerCareDetails.email,
        message: customerCareDetails.message,
      };
      setErrorMessage("");
      console.log(completeCustomerCareDetails);
    } else {
      setErrorMessage("Please fill all the details");
    }
  };

  return (
    <div className="body">
      <Helmet>
        <title>Customer-CarePage</title>
        <meta
          name="description"
          content="We ensure that all your questions will be answered within 24 hours, 7 days a week."
        />
      </Helmet>
      <div className="user-welcome">
        <div className="wrapper-heading">
          <h3>matrimonydemo.com welcomes you</h3>
        </div>
      </div>
      <div className="page-heading">
        <div className="wrapper-heading">
          <h3 className="page-heading-text">Customer care</h3>
        </div>
      </div>
      <div className="col-11 col-md-11 col-lg-10 bg-light shadow m-auto my-2 my-md-5 py-3 py-md-4 px-2 px-md-4">
        {errorMessage ? (
          <div className="container bg-danger text-light col-12 m-auto rounded d-flex justify-content-between align-items-center py-2 my-1 my-md-3 contact-box">
            <span>{errorMessage}</span>
            <button
              type="button"
              className="cursor pointer btn-close"
              aria-label="Close"
              onClick={() => setErrorMessage("")}
            ></button>
          </div>
        ) : null}
        {/* contact details container */}
        <div className="d-flex flex-column flex-md-row justify-content-between text-center m-auto w-100">
          <div className="rounded bg-secondary text-light d-flex flex-row flex-md-column justify-content-between align-items-center p-md-4 p-1 my-2 contact-box">
            <span>
              <FiPhoneCall />
            </span>
            <span style={style}>CUSTOMER SUPPORT</span>
            <span style={style}>123-456-7890</span>
          </div>
          <div className="rounded bg-secondary text-light d-flex flex-row flex-md-column justify-content-between align-items-center p-md-4 p-1 my-2 contact-box">
            <span>
              <GoLocation />
            </span>
            <span style={style}>OUR MAIN OFFICE, City Street</span>
            <span style={style}>Country-pincode</span>
          </div>
          <div className="rounded bg-secondary text-light d-flex flex-row flex-md-column justify-content-between align-items-center p-md-4 p-1 my-2 contact-box">
            <span>
              <MdEmail />
            </span>
            <span style={style}>EMAIL ID</span>
            <span style={style}>info@demo.com</span>
          </div>
        </div>
        <hr className="col-10 m-auto my-3 my-md-5" />
        {/* Contact form */}
        <div className="container col-12 col-md-10">
          <h1 className="text-center my-3 contact-head">Contact Us</h1>
          <form className="w-100" onSubmit={(e) => customerCareSubmit(e)}>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder=" Eg. abc"
                value={customerCareDetails.name}
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="name@example.com"
                value={customerCareDetails.email}
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control"
                rows="3"
                name="message"
                placeholder=" Eg. My query is related to.."
                value={customerCareDetails.message}
                onChange={handleOnChange}
                required
              ></textarea>
            </div>
            <button className="btn btn-primary light-button" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerCarePage;
