import React from "react";
import "./AboutPage.css";
import FooterLandingPage from "../LandingPagesMobile/FooterLandingPage";

const AboutPage = () => {
  return (
    <>
      <div className="aboutus-main-container">
        <h1>About Us</h1>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quod sunt
          vitae qui ut, saepe voluptatum commodi voluptas soluta omnis obcaecati
          laudantium reiciendis laborum dignissimos similique officiis est
          adipisci consequatur officia nulla. Voluptates nam sit, at, corrupti
          unde quia iure nihil, et deleniti omnis reiciendis maxime?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
          mollitia natus voluptate, voluptatum aut repellendus temporibus
          voluptas, nobis reiciendis possimus cupiditate autem, quibusdam id
          unde. Quos sed dolorem, cumque magni laborum quis.
        </p>
        <h2>Lorem Ipsum</h2>
        <ul className="about-us-points">
          <li>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugit
            explicabo iusto excepturi qui assumenda, voluptas ex!
          </li>
          <li>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus
            dolor labore aut debitis?
          </li>
          <li>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
            pariatur assumenda obcaecati perferendis, optio harum?
          </li>
        </ul>
      </div>
      <FooterLandingPage />
    </>
  );
};

export default AboutPage;
