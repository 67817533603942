import React, { useState, useEffect, useRef } from "react";
import "./PersonalDetails.css";
import { useNavigate } from "react-router-dom";
import PersonalDetailsData from "./PersonalDetailsData.json";
import axios from "axios";
import server from "../../config/server.json";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toFeet } from "../../utils/ConvertCmToFeet";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import RichTextEditor from "../RichTextEditor/RichTextEditor";

function PersonalPage({ setSteps, Details, next, setNext }) {
  const [error, setError] = useState("");
  const [errorDOB, setErrorDOB] = useState("");
  const { countryList, hScope, userReligion } = PersonalDetailsData;
  const navigation = useNavigate();
  const masterData = useSelector((state) => state.masterData);
  const [close, setClose] = useState(false);
  const [about, setAbout] = useState("");
  const editor = useRef(null);

  const [details, setDetails] = useState({
    age: "",
    dob: "",
    city: "",
    state: "",
    address_1: "",
    landmark: "",
    pincode: "",
    weight: "",
    language: "",
    height: "",
    horoscope: "",
    country: "",
    religion: "",
    marital: "",
  });

  const {
    age,
    dob,
    address_1,
    landmark,
    city,
    state,
    pincode,
    weight,
    language,
    height,
    horoscope,
    country,
    religion,
    marital,
  } = details;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleValidation = () => {
    //countryStateRegex is used to validate the state i.e. should have 49 letters or spaces
    const countryStateRegex = /^([0-9]{5}|[a-zA-Z][a-zA-Z ]{0,49})$/;

    //pincodeRegex is used to validate the pincode i.e it should consist of 6 digits
    const pincodeRegex = /^\d{6}$/;

    const {
      age,
      dob,
      address_1,
      landmark,
      city,
      state,
      pincode,
      country,
      height,
      weight,
      language,
      horoscope,
      religion,
      marital,
    } = details;

    const today = new Date();
    const currentYear = today.getFullYear();
    const date = new Date(dob);
    const dobYear = date.getFullYear();
    const Year = currentYear - dobYear;

    if (!(Year > 18)) {
      setErrorDOB("***you must be 18+");
    } else {
      setErrorDOB("");
    }

    if (age < 18) {
      setError("Enter your valid Age");
    } else if (address_1.length === 0) {
      setError("Enter your valid address1");
    } else if (landmark.length === 0) {
      setError("Enter your valid landmark");
    } else if (countryStateRegex.test(city) === false) {
      setError("Enter your valid city");
    } else if (countryStateRegex.test(state) === false) {
      setError("Enter your valid state");
    } else if (pincodeRegex.test(pincode) === false) {
      setError("*Pin code should be 6 digits");
    } else if (country.length === 0 || country === "none") {
      setError("Select your valid country");
    } else if (height.length === 0 || height === "none") {
      setError("Select your valid height");
    } else if (Number(weight) < 20) {
      setError("Enter your valid weight");
    } else if (language.length === 0) {
      setError("Enter your valid language");
    } else if (horoscope.length === 0 || horoscope === "none") {
      setError("Select your valid horoscope");
    } else if (religion.length === 0 || religion === "none") {
      setError("Select your valid religion");
    } else if (marital.length === 0 || marital === "none") {
      setError("Select your valid marital");
    } else {
      return true;
    }
  };

  const getUserDetails = async () => {
    setDetails({
      age: Details?.age ? Details?.age : age,
      dob: Details?.dob ? Details?.dob : dob,
      address_1: Details?.address_1 ? Details?.address_1 : address_1,
      landmark: Details?.landmark ? Details?.landmark : landmark,
      city: Details?.city ? Details?.city : city,
      state: Details?.state ? Details?.state : state,
      pincode: Details?.pincode ? Details?.pincode : pincode,
      weight: Details?.weight ? Details?.weight : weight,
      language: Details?.language ? Details?.language.toString() : language,
      height: Details?.height ? Details?.height : height,
      horoscope: Details?.horoscope ? Details?.horoscope : horoscope,
      country: Details?.country ? Details?.country : country,
      religion: Details?.religion ? Details?.religion : religion,
      marital: Details?.marital ? Details?.marital : marital,
    });
  };

  useEffect(() => {
    getUserDetails(); // eslint-disable-next-line
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    // languageArr stores the languages in array format by doing string operation
    const langArr = details.language.replaceAll(" ", "").split(",");

    if (handleValidation()) {
      try {
        const userToken = localStorage.getItem("token");
        const userId = JSON.parse(localStorage.getItem("user")).id;
        const postData = {
          userId: userId,
          age: Number(age),
          dob: dob,
          address_1: address_1,
          landmark: landmark,
          city: city,
          state: state,
          pincode: Number(pincode),
          country: country,
          height: height,
          weight: Number(weight),
          language: langArr,
          horoscope: horoscope,
          religion: religion,
          marital: marital,
        };
        const headers = {
          "Content-Type": "application/json",
          token: userToken,
        };

        const response = await axios.post(
          `${server.url.production}${server.api.UPDATE_PERSONAL_DETAILS}`,
          postData,
          { headers: headers }
        );

        if (response.status === 201) {
          if (close) {
            navigation("/");
          }
          if (next) {
            setSteps(4);
            setNext(false);
          }
          toast.success(response.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        } else {
          toast.error("Something went wrong", {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      } catch (e) {
        if (e.response && e.response.status !== 201) {
          setError(e.response.data.message);
        } else {
          toast.error("Something went wrong. Try again later", {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      }
    }
  };

  useEffect(() => {
    // err msg set to empty, when user type on pending inputfield.
    setError("");
  }, [details]);

  return (
    <>
      <div className="body">
        <Helmet>
          <title>Personal Details</title>
          <meta
            name="description"
            content=" details about a person such as their name and address and background."
          />
        </Helmet>
        <section className="personal">
          <div className="wrapper">
            <div className="personal_details">
              <form onSubmit={handleOnSubmit} autoComplete={"off"}>
                {error.length > 0 ? <div className="error">{error}</div> : ""}
                <div className="form-group df">
                  <div className="mobile df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="age">age</label>
                      <input
                        type="number"
                        min={"18"}
                        name="age"
                        id="age"
                        placeholder="Eg: 20"
                        value={age}
                        max={70}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="email df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="dob">date of birth</label>
                      <input
                        type={"date"}
                        name="dob"
                        id="dob"
                        value={dob}
                        onChange={handleOnChange}
                      />
                    </div>
                    {errorDOB ? (
                      <div className="errorMessage">{errorDOB}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-group df">
                  <div className="address_1 df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="address_1">address</label>
                      <input
                        type="text"
                        name="address_1"
                        id="address_1"
                        placeholder=" 00,Building No"
                        value={address_1}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="address_2 df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="landmark">landmark</label>
                      <input
                        type="text"
                        name="landmark"
                        id="landmark"
                        placeholder="Street, Landmark"
                        value={landmark}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group df">
                  <div className="city df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="city">city</label>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="Eg: City Name"
                        value={city}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="state df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="state">state</label>
                      <input
                        type="text"
                        name="state"
                        id="name"
                        placeholder="Eg: State Name"
                        value={state}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group df">
                  <div className="pincode df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="pincode">pincode</label>
                      <input
                        type="number"
                        name="pincode"
                        id="pincode"
                        placeholder="Eg: 400002"
                        value={pincode}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="country df jcb ac">
                    <div className="input-field df jcb ac">
                      <label htmlFor="country">country</label>
                      <select
                        name="country"
                        id="country"
                        value={country}
                        onChange={handleOnChange}
                      >
                        <option value="none">Choose Your Country</option>
                        {countryList.map((elem) => {
                          return (
                            <option value={elem} key={elem}>
                              {elem}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group df">
                  <div className="height df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="height">height</label>
                      <select
                        name="height"
                        id="height"
                        value={height}
                        onChange={handleOnChange}
                      >
                        <optgroup label="Heights" />
                        <option value="none">{OPTION_SELECT_EMPTY}</option>
                        {masterData.height.map((elem) => {
                          return (
                            <option key={elem._id} value={elem.height}>
                              {toFeet(elem.height)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="weight df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="weight">weight in kg</label>
                      <input
                        type="number"
                        name="weight"
                        id="weight"
                        placeholder="Eg: 50"
                        value={weight}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group df">
                  <div className="horoscope df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="horoscope">horoscope</label>
                      <select
                        name="horoscope"
                        id="horoscope"
                        value={horoscope}
                        onChange={handleOnChange}
                      >
                        <option value="none">Choose Your Horoscope</option>
                        <optgroup label="Horoscope">
                          Choose Your Horoscope
                        </optgroup>
                        {hScope.map((elem) => {
                          return (
                            <option key={elem} value={elem}>
                              {elem}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="religion df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="religion">religion</label>
                      <select
                        name="religion"
                        id="religion"
                        value={religion}
                        onChange={handleOnChange}
                      >
                        <option value="none">Choose Your Religion</option>
                        <optgroup label="Religion">Religion</optgroup>
                        {userReligion.map((elem) => {
                          return (
                            <option key={elem} value={elem}>
                              {elem}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group df">
                  <div className="language df fdc">
                    <div className="input-field df jcb ac">
                      <label htmlFor="language">language</label>
                      <input
                        type="text"
                        name="language"
                        id="language"
                        placeholder="Eg: English, Hindi..."
                        value={language}
                        onChange={handleOnChange}
                      />
                    </div>
                    <span className="warning">
                      Use comma to separate the language
                    </span>
                  </div>
                  <div className="material df jcb ac">
                    <div className="input-field df jcb ac">
                      <label htmlFor="marital">marital</label>
                      <select
                        name="marital"
                        id="marital"
                        value={marital}
                        onChange={handleOnChange}
                      >
                        <optgroup label="Marital Status" />
                        <option value="none">Choose Your Status</option>
                        {masterData.marital.map((elem) => {
                          return (
                            <option key={elem._id} value={elem.maritalStatus}>
                              {" "}
                              {elem.maritalStatus}{" "}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="about">
                  <div className="input-field df jcb ac">
                    <label htmlFor="about_us" className="text-box-label">
                      About
                    </label>

                    <div className="text-box">
                      <RichTextEditor
                        name="about_us"
                        id="about_us"
                        ref={editor}
                        value={about}
                        setAbout={setAbout}
                      />
                    </div>
                  </div>
                </div>

                <div className="saveclosebtns">
                  <div className="reset">
                    <button type="reset" onClick={() => setSteps(2)}>
                      Previous
                    </button>
                  </div>
                  <div className="submit df jce">
                    <button type={"submit"} onClick={() => setClose(true)}>
                      Save & Close
                    </button>
                  </div>
                  <div className="submit df jce">
                    <button type="submit" onClick={() => setNext(true)}>
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default PersonalPage;