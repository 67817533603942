const TermsContent =  [
    {
        "heading" : "Eligibility",
        "content" : "You must be at least 18 years of age or over to Register as a member of matrimonydemo.com or use this Site. Membership to the Site is void where prohibited. Your use of this Site represents and warrants that you have the right, authority, and capacity to enter into this Agreement and to abide by all of the terms and conditions of this Agreement. This site is not meant to encourage and/or promote illicit sexual relations or extramarital affairs. If matrimonydemo.com discovers or becomes aware that any member is using this site to promote or engage or indulge in illicit sexual relations or extra marital affairs his/her membership will be terminated forthwith without any refund and without any liability to matrimonydemo.com. matrimonydemo.com 's discretion to terminate shall be final and binding.",
    },
    {
        "heading" : "Term",
        "content" : "This Agreement will remain in full force and effect while you use the Site and/or are a Member of matrimonydemo.com. You may terminate your membership at any time, for any reason by informing matrimonydemo.com in writing to terminate your Membership. In the event you terminate your membership, you will not be entitled to a refund of any unutilized subscription fees. matrimonydemo.com may terminate your access to the Site and/or your membership for any reason which shall be effective upon sending notice of termination to you at the email address you provide in your application for membership or such other email address as you may later provide to matrimonydemo.com. If matrimonydemo.com terminates your membership because of your breaching the Agreement, you will not be entitled to any refund of any unused Subscription fees. Even after this Agreement is terminated, certain provisions will remain in effect including sections 4,5,7,9 -12, inclusive, of this Agreement"
    },
    {
        "heading" : "Non-Commercial Use by Members",
        "content" : "The matrimonydemo.com Site is for the personal use of individual members only, and may not be used in connection with any commercial endeavors. This includes providing links to other websites, whether deemed competitive to matrimonydemo.com or otherwise. Organizations, companies, and/or businesses may not become Members of matrimonydemo.com and should not use the matrimonydemo.com Service or Site for any purpose. Illegal and/or unauthorized uses of the Site, including unauthorized framing of or linking to the Site will be investigated, and appropriate legal action will be taken, including without limitation, civil, criminal, and injunctive redress"
    }
   
];
export default TermsContent;