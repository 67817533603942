import { createSlice } from "@reduxjs/toolkit";
import {
  getBirthTimeData,
  getBloodGroupData,
  getCasteData,
  getDietPreferencesData,
  getEducationDegreeData,
  getFamilyIncomeData,
  getFamilyStatusData,
  getFamilyTypeData,
  getFamilyValuesData,
  getGenderData,
  getHeightData,
  getIncomeData,
  getMartialData,
  getMotherTongueData,
  getOccupationCategoryData,
  getOccupationData,
  getProfileCreatedByData,
  getSpecializationData,
  getWeightData,
  geteducationCompletionYearData,
  getHoroscopeData,
  getLanguageData,
  getCountryData,
  getAgeData,
  getAnnualIncomeData,
} from "./ApiMaster";
import {
  getWithoutFamilyStatusData,
  getWithoutIncomeData,
  getWithoutLoginCasteData,
  getWithoutLoginHeightData,
  getWithoutLoginOccupationCategoryData,
  getWithoutLoginOccupationData,
  getWithoutMaritalStatusData,
  getWithoutProfileCreatedByData,
  getWithoutQualificationData,
} from "./MasteData/MasterDataWithoutLogin";

const masterSlice = createSlice({
  name: "masterData",
  initialState: {
    occupationData: [],
    casteData: [],
    genderData: [],
    familyType: [],
    familyIncome: [],
    birthTime: [],
    familyStatus: [],
    familyValues: [],
    bloodGroup: [],
    profileCreatedBy: [],
    height: [],
    motherTongue: [],
    income: [],
    occupationCategory: [],
    dietPreference: [],
    weight: [],
    qualification: [],
    educationCompleteYear: [],
    specialization: [],
    maritalStatus: [],
    horoscope: [],
    language: [],
    country: [],
    age: [],
    annualIncome: [],

    withoutLogin: {
      height: [],
      occupation: [],
      maritalStatus: [],
      familStatus: [],
      qualification: [],
      income: [],
      profileCreatedBy: [],
      caste: [],
      occupationCategory: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOccupationData.fulfilled, (state, { payload }) => {
        state.occupationData = payload;
      })
      .addCase(getCasteData.fulfilled, (state, { payload }) => {
        state.casteData = payload;
      })
      .addCase(getGenderData.fulfilled, (state, { payload }) => {
        state.genderData = payload;
      })
      .addCase(getFamilyTypeData.fulfilled, (state, { payload }) => {
        state.familyType = payload;
      })
      .addCase(getAnnualIncomeData.fulfilled, (state, { payload }) => {
        state.annualIncome = payload;
      })
      .addCase(getFamilyIncomeData.fulfilled, (state, { payload }) => {
        state.familyIncome = payload;
      })
      .addCase(getBirthTimeData.fulfilled, (state, { payload }) => {
        state.birthTime = payload;
      })
      .addCase(getFamilyStatusData.fulfilled, (state, { payload }) => {
        state.familyStatus = payload;
      })
      .addCase(getFamilyValuesData.fulfilled, (state, { payload }) => {
        state.familyValues = payload;
      })
      .addCase(getBloodGroupData.fulfilled, (state, { payload }) => {
        state.bloodGroup = payload;
      })
      .addCase(getProfileCreatedByData.fulfilled, (state, { payload }) => {
        state.profileCreatedBy = payload;
      })
      .addCase(getHeightData.fulfilled, (state, { payload }) => {
        state.height = payload;
      })
      .addCase(getAgeData.fulfilled, (state, { payload }) => {
        state.age = payload;
      })
      .addCase(getMotherTongueData.fulfilled, (state, { payload }) => {
        state.motherTongue = payload;
      })
      .addCase(getIncomeData.fulfilled, (state, { payload }) => {
        state.income = payload;
      })
      .addCase(getOccupationCategoryData.fulfilled, (state, { payload }) => {
        state.occupationCategory = payload;
      })
      .addCase(getDietPreferencesData.fulfilled, (state, { payload }) => {
        state.dietPreference = payload;
      })
      .addCase(getWeightData.fulfilled, (state, { payload }) => {
        state.weight = payload;
      })
      .addCase(getEducationDegreeData.fulfilled, (state, { payload }) => {
        state.qualification = payload;
      })
      .addCase(geteducationCompletionYearData.fulfilled, (state, { payload }) => {
        state.educationCompleteYear = payload;
      })
      .addCase(getSpecializationData.fulfilled, (state, { payload }) => {
        state.specialization = payload;
      })
      .addCase(getMartialData.fulfilled, (state, { payload }) => {
        state.maritalStatus = payload;
      })
      .addCase(getHoroscopeData.fulfilled, (state, { payload }) => {
        state.horoscope = payload;
      })
      .addCase(getLanguageData.fulfilled, (state, { payload }) => {
        state.language = payload;
      })
      .addCase(getCountryData.fulfilled, (state, { payload }) => {
        state.country = payload;
      })
      .addCase(getWithoutLoginOccupationData.fulfilled, (state, { payload }) => {
        state.withoutLogin.occupation = payload;
      })
      .addCase(getWithoutLoginHeightData.fulfilled, (state, { payload }) => {
        state.withoutLogin.height = payload;
      })
      .addCase(getWithoutMaritalStatusData.fulfilled, (state, { payload }) => {
        state.withoutLogin.maritalStatus = payload;
      })
      .addCase(getWithoutFamilyStatusData.fulfilled, (state, { payload }) => {
        state.withoutLogin.familStatus = payload;
      })
      .addCase(getWithoutQualificationData.fulfilled, (state, { payload }) => {
        state.withoutLogin.qualification = payload;
      })
      .addCase(getWithoutIncomeData.fulfilled, (state, { payload }) => {
        state.withoutLogin.income = payload;
      })
      .addCase(getWithoutProfileCreatedByData.fulfilled, (state, { payload }) => {
        state.withoutLogin.profileCreatedBy = payload;
      })
      .addCase(getWithoutLoginCasteData.fulfilled, (state, { payload }) => {
        state.withoutLogin.caste = payload;
      })
      .addCase(getWithoutLoginOccupationCategoryData.fulfilled, (state, { payload }) => {
        state.withoutLogin.occupationCategory = payload;
      });
  },
});

export default masterSlice;
