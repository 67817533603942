import React from 'react'
import "./FooterLandingPage.css"
import { useNavigate } from 'react-router-dom';

const FooterLandingPage = () => {
  const navigate = useNavigate(); 

  return (
    <div className="footerMobile">
    <div className="footerNavlink">
      <div className="company">
        <ul>
          <li className="Head">Company</li>
          <li onClick={()=>{window.scrollTo(0, 0); navigate("/aboutus-m")}}>About</li>
          <li>Testimonial</li>
          <li>Contact </li>
          <li>FAQs</li>
        </ul>
      </div>
      <div className="privacy">
        <ul>
          <li className="Head">Privacy & You</li>
          <li>Member Login</li>
          <li>Register Free</li>
          <li>Partner Search </li>
          <li>Report Misuse</li>
        </ul>
      </div>
    </div>
    <div className="footerTerms">
      <p onClick={()=>{window.scrollTo(0, 0); navigate("/terms-condition-m")}}>Terms Of User</p>
      <p onClick={()=>{window.scrollTo(0, 0); navigate("/privacy-policy-m")}}>Privacy Policy</p>
    </div>
    <div className="footerCopyright">
      <p>
        © Copyrights Techsierra-Matrimonial.Com 2022-2023. All Rights
        Reserved.
      </p>
    </div>
  </div>
  )
}

export default FooterLandingPage