import React, { useState } from "react";
import { useSelector } from "react-redux";
import BottomSheet from "./BottomSheet";
import { figmaIcons } from "../../assets/FigmaIcons/figmaIcons";
import "./ShowingHalfDetails.css";
import ProfileViewMob from "../../pages/ProfileView/ProfileViewMob";

const ShowingHalfDetails = ({
  data,
  isBottomSheetOpen,
  setIsBottomSheetOpen,
}) => {
  const [showProfileViewMob, setShowProfileViewMob] = useState(false);
  const appStatus = useSelector((state) => state.contactSharing.status);

  const hashedData = (userAsterikData, appStatus, data) => {
    const foundElem = appStatus.find(
      (elem) => elem.request_to === data?._id && elem.approval_status === 1
    );

    if (foundElem) {
      return userAsterikData === data?.phone_number
        ? data?.phone_number?.toString()
        : data?.email_id;
    } else {
      return userAsterikData === data?.phone_number
        ? data?.phone_number?.toString().replace(/\d/g, "*")
        : data?.email_id?.replace(/[a-zA-Z1-9](?=.*@)/g, "*");
    }
  };

  const aboutDetailsArr = [
    {
      title: "Personal Information",
      icons: figmaIcons.charm_person,
      details: [
        { Name: `${data?.first_name} ${data?.last_name}` },
        { Gender: data?.gender === "F" ? "Female" : "Male" },
        { Age: `${data?.userDetails?.age} years` },
        { "Date of Birth": data?.userDetails?.dob },
        { "Marital status": data?.userDetails?.marital },
        { Phone: hashedData(data?.phone_number, appStatus, data) },
        { "Email-id": hashedData(data?.email_id, appStatus, data) },
      ],
    },
    {
      title: "Family",
      icons: figmaIcons.outline_family,
      details: [
        { "Family Status": data?.userDetails?.familyStatus },
        { "Family Income (annual)": data?.userDetails?.familyIncome },
        { "Family-type": data?.userDetails?.familyType },
      ],
    },
    {
      title: "Physical Information",
      icons: figmaIcons.ph_person,
      details: [
        { Height: `${data?.userDetails?.height} ft` },
        { Weight: data?.userDetails?.weight },
      ],
    },
    {
      title: "Culture & Religion",
      icons: figmaIcons.mingcute_star,
      details: [
        { Religion: data?.userDetails?.religion },
        { Horoscope: data?.userDetails?.horoscope },
      ],
    },
    {
      title: "Education & Profession",
      icons: figmaIcons.mdi_education,
      details: [
        { Degree: data?.userDetails?.qualification },
        { Specialization: data?.userDetails?.specialization },
        { Income: data?.userDetails?.income },
      ],
    },
    {
      title: "Location",
      icons: figmaIcons.carbon_location,
      details: [
        { City: data?.userDetails?.city },
        { State: data?.userDetails?.state },
      ],
    },
  ];

  return (
    <div>
      {showProfileViewMob ? (
        <ProfileViewMob
          userData={data}
          userProfilePhoto={data.userDetails.profileImage}
          onBack={() => setShowProfileViewMob(false)}
          setIsBottomSheetOpen={setIsBottomSheetOpen}
        />
      ) : (
        <BottomSheet
          isBottomSheetOpen={isBottomSheetOpen}
          setIsBottomSheetOpen={setIsBottomSheetOpen}
          userId={data?._id}
          setShowProfileViewMob={setShowProfileViewMob}
        >
          <div className="bottomSheetContent">
            {aboutDetailsArr.map((data) => (
              <div className="aboutDetailsWrapper" key={data.title}>
                <div className="aboutTitleBar">
                  <img className="aboutTitleIcon" src={data.icons} alt="" />
                  <p className="aboutTitle">{data.title}:</p>
                </div>
                <div>
                  {data.details.map((detail, i) => {
                    return Object.entries(detail).map(([key, value], i) => {
                      return (
                        <div className="aboutDetails" key={i}>
                          <span>{key}: </span> <span>{value || "N/A"}</span>
                        </div>
                      );
                    });
                  })}
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-center">
              <button
                className="aboutViewDetailsBtn"
                onClick={() => setShowProfileViewMob(true)}
              >
                Full Profile &gt;
              </button>
            </div>
          </div>
        </BottomSheet>
      )}
    </div>
  );
};

export default ShowingHalfDetails;