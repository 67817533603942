import React from "react";
import "./PageNotFound.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function PageNotFound() {
  return (
    <div className="page_not_found">
      <Helmet>
        <title>PageNotFound</title>
        <meta
          name="description"
          content="website content has been removed or moved to another URL"
        />
      </Helmet>
      <div className="wrapper">
        <h3>404</h3>
        <div className="page_not_found_content">
          <h4>sorry page not found</h4>
          <p>the page requested could not be found</p>
          <div className="buttons">
            <Link to={"/home"}>Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
