import React, { useState } from "react";
import "./RegistrationPageMobile.css";
import PersonalDetailsData from "../PersonalDetails/PersonalDetailsData.json";
import cross from "../../assets/ProfileMobileImages/close-model.png";
import Line from "../../assets/Line 70.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import server from "../../config/server.json";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import back from "../../assets/ProfileMobileImages/back.png";
import { toFeet } from "../../utils/ConvertCmToFeet";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";


const RegistrationPageMobile = (props) => {
  const masterData = useSelector((state) => state.masterData.withoutLogin);
  const { userReligion } = PersonalDetailsData;
  const [stepTwo, setStepTwo] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    gender: "",
    created_by: "",
    height: "",
    marital_status: "",
    caste: "",
    work_location: "",
    company_name: "",
    annual_income: "",
    occupation: "",
    specialization: "",
    institute_name: "",
    dob: "",
    current_location: "",
    education: "",
  });

  const {
    first_name,
    last_name,
    email_id,
    // phone_number,
    gender,
    dob,
    current_location,
    created_by,
    height,
    marital_status,
    caste,
    work_location,
    company_name,
    annual_income,
    occupation,
    education,
    institute_name,
  } = details;
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleValidation = () => {
    switch (true) {
      case first_name.length === 0 || first_name === "none":
        setErrorMessage("*Select first name");
        break;
      case last_name.length === 0 || last_name === "none":
        setErrorMessage("*Select last name");
        break;
      case email_id.length === 0 || email_id === "none":
        setErrorMessage("*Select email id");
        break;
      case gender.length === 0 || gender === "none":
        setErrorMessage("*Select gender");
        break;
      case dob.length === 0 || dob === "none":
        setErrorMessage("*Select date of birth");
        break;
      case current_location.length === 0 || current_location === "none":
        setErrorMessage("*Select current location");
        break;
      case created_by.length === 0 || created_by === "none":
        setErrorMessage("*Select profile created by");
        break;
      case height.length === 0 || height === "none":
        setErrorMessage("*Select height");
        break;
      case marital_status.length === 0 || marital_status === "none":
        setErrorMessage("*Select marital status");
        break;
      case caste.length === 0 || caste === "none":
        setErrorMessage("*Select caste");
        break;
      case work_location.length === 0:
        setErrorMessage("*Select work_location");
        break;
      case company_name.length === 0:
        setErrorMessage("*Select company");
        break;
      case annual_income.length === 0 || annual_income === "none":
        setErrorMessage("*Select annual_income");
        break;
      case occupation.length === 0 || occupation === "none":
        setErrorMessage("*Select occupation category");
        break;
      case education.length === 0:
        setErrorMessage("*Select education");
        break;
      case institute_name.length === 0:
        setErrorMessage("*Select institute_name");
        break;
      default:
        return true;
    }
  };
  

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // const match = height.toString().match(/-\s*(\d{3})/);
    // const heightValue = match ? match[1] : "";
    if (handleValidation()) {
    try {
      const body = {
        first_name: first_name,
        last_name: last_name,
        email_id: email_id,
        phone_number: props.data.phone_number,
        gender: gender,
        dob: dob,
        current_location: current_location,
        profileCreatedBy: created_by,
        height: height,
        marital: marital_status,
        religion: caste,
        jobLocation: work_location,
        companyName: company_name,
        income: annual_income,
        occupation: occupation,
        specialization: education,
        instituteName: institute_name
      };
      const response = await axios.post(`${server.url.production}${server.api.REGISTER}`, body );
      if (response.status === 201) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        setStepTwo(true)
        navigate("/search");
        props.setModal(false);
        props.setSteps(3);
        window.dispatchEvent(new Event("token_update"));
      } else {
        toast.error("Something Went Wrong", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
    }
  };

  return (
    <>
      {!stepTwo && <>
        <div className="registration-main">
          <div className="registration">
            <div className="title1">
              <h1>
                Free Registration
                <img src={cross} alt="close" onClick={() => props.cancel()} />
              </h1>
            </div>
            <div className="details">
              <div>
                <label>First Name*</label>
              </div>
              <div>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="eg. Jagan"
                  value={first_name}
                  onChange={handleOnChange} />
              </div>
              <img src={Line} alt="" />
              <div>
                <label>Last Name*</label>
              </div>
              <div>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="eg. Murthy"
                  value={last_name}
                  onChange={handleOnChange} />
              </div>
              <img src={Line} alt="" />
              <div>
                <label>Email-id*</label>
              </div>
              <div>
                <input
                  type="text"
                  id="email_id"
                  name="email_id"
                  placeholder="abc@xyz.com"
                  value={email_id}
                  onChange={handleOnChange} />
              </div>
              <img src={Line} alt="" />
              {/* <div>
                <label>Phone Number*</label>
              </div> */}
              {/* <div>
                <input
                  type="number"
                  id="phone_number"
                  name="phone_number"
                  placeholder="6754345632"
                  value={props}
                  onChange={handleOnChange} />
              </div> */}
              {/* <img src={Line} alt="" /> */}
              <div>
                <label>Gender*</label>
              </div>
              <div className="gender">
                <input
                  type="radio"
                  name="gender"
                  checked={gender === "M"}
                  onChange={handleOnChange}
                  value="M" />
                <label>Male</label>
                <input
                  type="radio"
                  name="gender"
                  value="F"
                  checked={gender === "F"}
                  onChange={handleOnChange} />
                <label>Female</label>
              </div>
              <img src={Line} alt="" />
              <div>
                <label>Date Of Birth*</label>
              </div>
              <div>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={dob}
                  onChange={handleOnChange} />
              </div>
              <img src={Line} alt="" />
              <div>
                <label>Current Location*</label>
              </div>
              <div>
                <input
                  type="text"
                  id="current_location"
                  name="current_location"
                  value={current_location}
                  onChange={handleOnChange} />
              </div>
              <img src={Line} alt="" />
            </div>
          </div>
          <div className="save_button">
            <button type="submit" onClick={() => setStepTwo(true)}>
              Save & Next
            </button>
          </div>
        </div>
      </>
      }
      <>
        {stepTwo &&
          <div className="registration-main">
            <div className="registration">
              <div className="title2">
                <h1>
                  <img src={back} alt="" onClick={() => setStepTwo(false)}/>
                    Free Registration
                  <img src={cross} alt="close" onClick={() => props.cancel()}/>
                </h1>
              </div>
              <div className="details">
                <div>
                  <label>Profile Created By*</label>
                </div>
                <div>
                  <select
                    name="created_by"
                    id="created_by"
                    value={created_by}
                    onChange={handleOnChange}
                  >
                    <option value="none">{OPTION_SELECT_EMPTY}</option>
                    <optgroup label="Profile Created By" />
                    {masterData.profileCreatedBy.map((elem) => (
                      <option key={elem._id} value={elem.value}>
                        {elem.value}{" "}
                      </option>
                    ))}
                  </select>
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Height (in cm)*</label>
                </div>
                <div>
                  <select
                    name="height"
                    id="height"
                    value={height}
                    onChange={handleOnChange}
                  >
                    <option value="none">{OPTION_SELECT_EMPTY}</option>
                    <optgroup label="Heights" />
                    {masterData.height.map((elem) => (
                      <option key={elem._id} value={elem.value}>
                        {" "}
                        {toFeet(elem.value)}{" "}
                      </option>
                    ))}
                  </select>
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Marital Status*</label>
                </div>
                <div>
                  <select
                    name="marital_status"
                    id="marital_status"
                    value={marital_status}
                    onChange={handleOnChange}
                  >
                    <option value="none">{OPTION_SELECT_EMPTY}</option>
                    <optgroup label="Marital Status" />                                   
                   {masterData?.maritalStatus?.map((elem) => 
                      (<option key={elem._id} value={elem.value}>
                        {" "}
                        {elem.value}{" "}
                      </option>
                      ))
                    }
                  </select>
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Caste*</label>
                </div>
                <div>
                  <select
                    name="caste"
                    id="caste"
                    value={caste}
                    onChange={handleOnChange}
                  >
                    <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {userReligion.map((elem) => {
                      return (
                        <option key={elem} value={elem}>
                          {elem}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Work Location*</label>
                </div>
                <div>
                  <input
                    type="text"
                    id="work_location"
                    name="work_location"
                    placeholder="Eg. Mumbai"
                    value={work_location}
                    onChange={handleOnChange} />
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Company Name*</label>
                </div>
                <div>
                  <input
                    type="text"
                    id="company_name"
                    placeholder="Eg. Tech Mahindra"
                    name="company_name"
                    value={company_name}
                    onChange={handleOnChange} />
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Annual Income*</label>
                </div>
                <div>
                  <select
                    name="annual_income"
                    id="annual_income"
                    value={annual_income}
                    onChange={handleOnChange}
                  >
                    <option value="none">{OPTION_SELECT_EMPTY}</option>
                    <optgroup label={"Annual Income"} />
                    {masterData.income.map((elem) => (
                      <option key={elem._id} value={elem.value}>
                        {" "}
                        {elem.value}{" "}
                      </option>
                    ))}
                  </select>
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Occupation Category*</label>
                </div>
                <div>
                  <select
                    name="occupation"
                    id="occupation"
                    value={occupation}
                    onChange={handleOnChange}
                  >
                    <option value="none">{OPTION_SELECT_EMPTY}</option>
                    <optgroup label={"Occupation Name"} />
                    {masterData.occupation.map((elem) => (
                      <option key={elem._id} value={elem.value}>
                        {elem.value}
                      </option>
                    ))}
                  </select>
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Specialization*</label>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Eg. MBA"
                    name="education"
                    id="education"
                    value={education}
                    onChange={handleOnChange} />
                </div>
                <img src={Line} alt="" />
                <div>
                  <label>Institue Name*</label>
                </div>
                <div>
                  <input
                    type="text"
                    id="institute_name"
                    placeholder="Eg. IIT Kharagpur"
                    name="institute_name"
                    value={institute_name}
                    onChange={handleOnChange} />
                </div>
                <img src={Line} alt="" />
              </div>
            </div>
            {errorMessage.length > 0 ? (
              <span className="personal-details-error">{errorMessage}</span>
            ) : (
              ""
            )}
            <div className="save_button">
              <button type="submit" onClick={handleOnSubmit}>
                All Set!
              </button>
            </div>
          </div>}
      </>
    </>
  );
};

export default RegistrationPageMobile;
