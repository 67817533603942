import React from "react";
import { Helmet } from "react-helmet";
import coupleImage from "../../assets/coupleImage.jpg";
import "./Testimonial.css";

function Testimonial() {
  return (
    <section className="testimonial">
      <div className="wrapper">
        <Helmet>
          <title>Testimonial</title>
          <meta
            name="description"
            content="Testimonials are statements made by our members to demonstrate satisfaction of our matrimonial service"
          />
        </Helmet>
        <h3>testimonial</h3>
        <div className="testimonial_images">
          <div className="testimionial1">
            <div className="img_text">
              <img src={coupleImage} alt="coupleImage 1" />
              <h5>Rachel & Ross</h5>
            </div>
            <div className="testimonial_text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
                hic minus perspiciatis dolor sequi illum iure, quas natus unde,
                harum ipsum placeat? Eveniet voluptates amet a assumenda
                perferendis est eius.
              </p>
              <button>read more</button>
            </div>
          </div>
          <div className="testimionial1">
            <div className="img_text">
              <img src={coupleImage} alt="coupleImage 1" />
              <h5>Rachel & Ross</h5>
            </div>
            <div className="testimonial_text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
                hic minus perspiciatis dolor sequi illum iure, quas natus unde,
                harum ipsum placeat? Eveniet voluptates amet a assumenda
                perferendis est eius.
              </p>
              <button>read more</button>
            </div>
          </div>
          <div className="testimionial1">
            <div className="img_text">
              <img src={coupleImage} alt="coupleImage 1" />
              <h5>Rachel & Ross</h5>
            </div>
            <div className="testimonial_text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
                hic minus perspiciatis dolor sequi illum iure, quas natus unde,
                harum ipsum placeat? Eveniet voluptates amet a assumenda
                perferendis est eius.
              </p>
              <button>read more</button>
            </div>
          </div>
          <div className="testimionial1">
            <div className="img_text">
              <img src={coupleImage} alt="coupleImage 1" />
              <h5>Rachel & Ross</h5>
            </div>
            <div className="testimonial_text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem
                hic minus perspiciatis dolor sequi illum iure, quas natus unde,
                harum ipsum placeat? Eveniet voluptates amet a assumenda
                perferendis est eius.
              </p>
              <button>read more</button>
            </div>
          </div>
        </div>
        <div className="bottom_text">
          <span>More success stories @ xyz.com</span>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
