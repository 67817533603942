import React from "react";
import FooterLandingPage from "../LandingPagesMobile/FooterLandingPage";
import "./PrivacyPolicyMobile.css";

const PrivacyPolicyMobile = () => {
  return (
    <>
      <div className="privacy-policy-main-mobile">
        <div>
          <h1>Our commitment to you</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maxime
            similique eveniet dicta obcaecati eos error!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Exercitationem est in consectetur laborum. Dolore, beatae!
          </p>
          <h3>Lorem Ipsum</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Esse nisi
            delectus pariatur earum dignissimos. Beatae saepe mollitia
            architecto voluptates itaque.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatem
            nemo neque porro beatae, deserunt omnis ratione delectus expedita
            vel quidem.
          </p>
          <h3>Lorem Ipsum</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt a
            nam, enim, recusandae quos, quod eius voluptatum iure sed velit
            omnis labore? Eum delectus magnam amet repellat maiores nostrum
            voluptatum ipsa nesciunt aliquid, iusto eaque cum earum natus magni
            modi impedit. Perspiciatis laudantium pariatur ea!
          </p>
        </div>

        <div>
          <h1>Privacy Policy</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis,
            aperiam. Incidunt ipsum ea officiis cupiditate?
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta ipsum
            officiis quod reprehenderit adipisci id?
          </p>
          <h2>1. Lorem Ipsum</h2>
          <h3>a) Lorem Ipsum Dolor</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat
            minima magnam deleniti. Ad, architecto deserunt?
          </p>
          <h3>b) Lorem Ipsum dolor</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum,
            quisquam error. Necessitatibus, nesciunt. Ad sit perspiciatis et,
            porro culpa, rerum voluptatem accusamus deserunt, qui reprehenderit
            tempore est cum nam beatae?
          </p>
          <ul className="privacy-policy-points">
            <li>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Perferendis eum nostrum magni accusantium reiciendis in fugit
              harum, incidunt commodi porro culpa odit tempore saepe asperiores!
            </li>
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Soluta
              eveniet aliquid quibusdam tempore amet id esse tenetur ea adipisci
              pariatur, corrupti numquam in excepturi nihil.
            </li>
          </ul>
        </div>
      </div>
      <FooterLandingPage />
    </>
  );
};

export default PrivacyPolicyMobile;
