import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const AdvanceSearch = (props) => {

    // isActive state is used in advanced dropdown sortby 
    const [isActive, setIsActive] = useState(false);
    
    // searchData is passed as props in advanceSearh page using prop drilling.
    // here searchData is the state json data.
    let searchData = props.searchData;
    // searchResults are the results fetched after the searching the users.
    let searchResults = props.searchResults?props.searchResults:0;
    // sort by is the additional search field which is current responsible for UI render and still not functionable
    const [sortByValue, setSortByValue] = useState(searchData.sortBy.sortList[0].label);

    // This function is used to toggle the sortby field, it takes the sortBy value as props and set the value as sortBy value.
    const toggleSortBy = (value) => {
        setSortByValue(value);
        props.toggleDropdown("","", value);
    }

    return (
        <>
            <div className="search-title">
                <p>Find a partner based on a few simple search criteria.</p>
                 {/*Here we are printing the No. of search results  */}
                <p><strong>Available Profiles</strong> - {searchResults.length} profiles</p>
            </div>
            {/* <div className="w-100 px-5 py-2"> */}
            <div className="search-filers">
            <div className="search-filter-heading"><strong>FILTERS</strong></div>
            <div className="search-filter-content">
                {/* <div className="w-100 d-flex flex-wrap justify-content-between mx-auto my-3"> */}
                <div className="search-filter-btns">
                {/* This map functions is returning the button field for advance search
                    For eg : Family Status, Family Income, Horoscope, etc.*/}
                {
                    Object.keys(searchData).map((field, i) => {
                        // Checking whethere the field is actually exist from advanced search using inputType and top.
                        if (searchData[field].inputType === "checkbox" && searchData[field].top) {
                            return (
                                <button
                                    type="button"
                                    key={i}
                                    // adding class according to the selectedField to show current selected button
                                    className={
                                        field.toString() === props.selectedField
                                            ? "btn_danger_search btn-sm btn-clicked"
                                            : ""
                                    }
                                    onClick={() => props.toggleFiltersButton(field, "advance")}
                                    disabled={props.loading}
                                >
                                    {searchData[field].label} {props.selectedField===field?<MdKeyboardArrowUp/>:<MdKeyboardArrowDown/>}
                                </button>
                            );
                        }
                        // If not exist then directly return null.
                        return null;
                    })}
                </div>
                {/* This div return the group checkbox of the selected field only 
                    For eg : selected field is familyValues then the group of checkbox will be
                    Traditional, Moderate and Liberal.*/}
                <div className="w-100 d-flex flex-wrap mx-auto pt-2">
                {props.selectedField === props.filterArrayName && ( props.filterArrayName && searchData[props.filterArrayName].top )  ?  (
                    <>
                        {props.filterArray.map((selectedField, i) => {
                            return (
                                <div className="form-check mx-1" key={i}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={props.filterArrayName}
                                        name={props.filterArrayName}
                                        value={selectedField.value}
                                        checked={selectedField.checked}
                                        onChange={(e) => props.toggleCheckbox(e.target.value)}
                                        disabled={props.loading}
                                    />
                                    {/* If the value of selectedField === "0 - 0" then show "N/A" in the checkbox label  */}
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault"
                                    >
                                        {selectedField.value === "0 - 0" ? "N/A" : selectedField.value}
                                    </label>
                                </div>
                            );
                        })}
                    </>
                ) : null}
                </div>
            </div>
            {/* Code for sortBy field. We are setting functionality while doing the mouse hover, when we hover the mouse on sortBy field it automatically open the dropdown.
            And it gets automatically closed after removing hover*/}
            <div className="search-sort" onMouseOver={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
                <div className={isActive ? "no-border" : ""}><p>Sort by : <strong>{sortByValue}</strong> </p></div>
                    <ul className="sort-operations">
                        {/* sortBy.sortList is the state object consist of value and label of the all the sortBy filters */}
                        {
                            searchData.sortBy.sortList.map((sort, i) => {
                                return (
                                    <li className={sortByValue === sort.value ? "bold-text" : "" } key={i} onClick={() => {toggleSortBy(sort.value)}}>{sort.label}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </>
    );
};

export default AdvanceSearch;