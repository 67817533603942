import charm_person from "./charm_person.png";
import outline_family from "./icon-park-outline_family.png";
import ph_person from "./ph_person.png";
import mingcute_star from "./mingcute_star-line.png";
import mdi_education from "./mdi_education-outline.png";
import carbon_location from "./carbon_location.png";
import basil_cross from "./basil_cross-solid.png";
import BackIcon from './backIcon.png'
import PdfIcon from './PdfIcon.png'
import MessageIcon from './Message-Icon.png'
import SendIcon from './Sendicon.png'
import HeartIcon from './HeartIcon.png'
import menuIcon from "./Menu.png"
import close from "./Vector (1).png";
import next from "./Next.png";
export const figmaIcons = {
  charm_person,
  outline_family,
  ph_person,
  mingcute_star,
  mdi_education,
  carbon_location,
  basil_cross,
  BackIcon,
  PdfIcon,
  MessageIcon,
  SendIcon,
  HeartIcon,
  menuIcon,
  close,
  next
}