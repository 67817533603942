import React from "react"; // eslint-disable-next-line react-hooks/exhaustive-deps
import firstPage from "../../../assets/BiodataImages/bg2.png";
import secondPage from "../../../assets/BiodataImages/Backgroundimg3.png";
import Family_photo from "../../../assets/BiodataImages/Family_photo.jpg";

import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  Page: {
    backgroundColor: "#bdf4f4",
  },

  Text: {
    fontSize: "13",
    width: "auto",
    padding: "2 0",
  },

  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  personalContent: {
    display: "flex",
    flexDirection: "row",
    margin: "0 40",
  },

  personalContent1: {
    flexBasis: "40%",
    paddingRight: "10",
    top: "30",
    bottom: "20",
    height: 350,
  },

  personalContent2: {
    alignSelf: "center",
    flexBasis: "60%",
    top: "30",
    bottom: "20",
    left: 28,
    height: 350,
  },

  educationContent1: {
    alignSelf: "center",
    flexBasis: "50%",
    top: "10",
    bottom: "20",
    height: 300,
  },

  familyContent1: {
    alignSelf: "center",
    flexBasis: "50%",
    top: "60",
    bottom: "20",
    height: 300,
  },

  familyContent2: {
    alignSelf: "center",
    flexBasis: "50%",
    top: "69",
    bottom: "20",
    height: 300,
  },

  contactContent: {
    top: "20",
    bottom: "20",
    left: "215",
  },

  personalContentText: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: "5px 0 10px 20px",
    fontSize: 12,
  },

  personalContentText1: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    padding: "3px 0 3px 20px",
    fontSize: 12,
  },

  profileName: {
    fontSize: "18px",
    fontWeight: "900",
    textDecoration: "underline",
    textDecorationColor: "#000000",
    left: 15,
    bottom: 5,
  },

  profileName1: {
    fontSize: "18px",
    fontWeight: "900",
    textDecoration: "underline",
    textDecorationColor: "#000000",
    left: 15,
    bottom: 5,
  },

  contentText: {
    width: "40%",
    padding: "2 0",
  },

  userImg: {
    width: "100%",
    top: 30,
    alignItems: "center",
    borderRadius: 30,
    border: 1,
  },

  profileImgContainer2: {
    display: "flex",
    flexDirection: "row",
    margin: "0 40",
  },

  profileImg2: {
    width: "50%",
    top: 50,
    alignItems: "right",
    borderRadius: 20,
    border: 1,
    left: 240,
  },
  profileImage: {
    padding: "10",
    borderRight: "2px solid white",
  },
});

function FormatEight({
  fields,
  uncleList,
  auntyList,
  mamaList,
  mamiList,
  brotherList,
  sisterList,
  cousinList,
  base64,
}) {
  const {
    pob,
    gender,
    blood_group,
    height,
    marital,
    religion,
    language,
    country,
    horoscope,
    qualification,
    specialization,
    instituteName,
    companyName,
    designation,
    income,
    fatherName,
    father_job,
    motherName,
    mother_job,
    dada,
    dadi,
    siblings,
    nana,
    nani,
    nativePlace,
    familyStatus,
    phone_number,
    email_id,
    address_1,
  } = fields;
  const dob = new Date(fields.dob);
  const birthDate = isNaN(dob.getDate()) ? "" : dob.getDate();
  const birthYear = isNaN(dob.getFullYear()) ? "" : dob.getFullYear();
  const birthMonth =
    dob.toLocaleString("default", { month: "long" }) === "Invalid Date"
      ? ""
      : dob.toLocaleString("default", { month: "long" });
  const date_of_birth = `${birthDate} ${birthMonth} ${birthYear}`;

  const checkIfLastIndex = (index, length) => {
    return index === length - 1 ? "" : ", ";
  };

  const profileDetailListArr1 = [
    { label: "Date of birth:", value: date_of_birth },
    { label: "Birth Place:", value: pob },
    { label: "Gender:", value: gender },
    { label: "Blood Group:", value: blood_group },
    { label: "Height:", value: height },
    { label: "Marital Status:", value: marital },
    { label: "Religion:", value: religion },
    { label: "Language Known:", value: language },
    { label: "Country:", value: country },
    { label: "Horoscope:", value: horoscope },
  ];

  const EducationDetailListArr = [
    { label: "Highest Qualification:", value: qualification },
    { label: "Specialization:", value: specialization },
    { label: "Institute Name:", value: instituteName },
    { label: "Company Name:", value: companyName },
    { label: "Occupation:", value: designation },
    { label: "Income:", value: income },
  ];

  const familyDetailListArr1 = [
    { label: "Father:", value: fatherName },
    { label: "Father's Job:", value: father_job },
    { label: "Mother:", value: motherName },
    { label: "Mother's Job:", value: mother_job },
    { label: "Grand Father:", value: dada },
    { label: "Grand Mother:", value: dadi },
    { label: "Number Of Siblings:", value: siblings },
    { label: "Brother Name:", value: brotherList },
    { label: "Sister Name:", value: sisterList },
  ];

  const familyDetailListArr2 = [
    { label: "Nanaji:", value: nana },
    { label: "Naniji:", value: nani },
    { label: "Uncles:", value: uncleList },
    { label: "Aunty:", value: auntyList },
    { label: "Mama:", value: mamaList },
    { label: "Mami:", value: mamiList },
    { label: "Cousins Name:", value: cousinList },
    { label: "Native Place:", value: nativePlace },
    { label: "Family Status:", value: familyStatus },
  ];

  const profileContactDetails = [
    { label: "Conatct Number:", value: phone_number },
    { label: "Email:", value: email_id },
    { label: "Residential Address:", value: address_1 },
  ];

  return (
    <Document>
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={firstPage} />
        </View>
        {/* profile name & img */}
        <View style={styles.personalContent}>
          <View style={styles.personalContent1}>
            <Image
              src={{ uri: base64 }}
              alt={"User Image"}
              style={styles.userImg}
            />
          </View>
          <View style={styles.personalContent2}>
            <Text style={styles.profileName1}>
              {fields.first_name} {fields.last_name}
            </Text>
            {profileDetailListArr1.map((detail, i) => (
              <View style={styles.personalContentText1} key={i}>
                <Text>{detail.label} </Text>
                <Text>{detail.value}</Text>
              </View>
            ))}
            {
            fields.about_us? 
            <View style={styles.personalContentText1}>
            <Text>About Myself: {fields.about_us}</Text>
            </View> : null
          }
          </View>
        </View>
        <View style={styles.personalContent}>
          <View style={styles.educationContent1}>
            <Text style={styles.profileName}>Education & Qualification</Text>
            {EducationDetailListArr.map((detail, i) => (
              <View style={styles.personalContentText} key={i}>
                <Text>{detail.label} </Text>
                <Text>{detail.value}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.personalContent}>
          <View style={styles.contactContent}>
            <Text style={styles.profileName1}>Contact Details</Text>

            {profileContactDetails.map((detail, i) => (
              <View style={styles.personalContentText} key={i}>
                <Text>{detail.label} </Text>
                <Text style={styles.contentText}>{detail.value}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>

      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={secondPage} />
        </View>
        {/* Family Img */}

        {/* Personal Details Container 2 */}

        <View style={styles.personalContent}>
          <View style={styles.familyContent1}>
            <Text style={styles.profileName}>Family Details</Text>

            {familyDetailListArr1.map((detail, i) => {
              return typeof detail.value === "object" ? (
                <View style={styles.personalContentText} key={i}>
                  <Text>{detail.label} </Text>
                  <Text>
                    {detail.value.map((elem, i) => (
                      <Text>
                        {detail.value.map((elem, i) => {
                          return (
                            elem.check && (
                              <Text key={i}>
                                {Object.values(elem)[0]}
                                {checkIfLastIndex(
                                  i,
                                  Object.keys(elem)[0].length
                                )}
                              </Text>
                            )
                          );
                        })}
                      </Text>
                    ))}
                  </Text>
                </View>
              ) : (
                <View style={styles.personalContentText} key={i}>
                  <Text>{detail.label} </Text>
                  <Text>{detail.value}</Text>
                </View>
              );
            })}
          </View>
        </View>

        <View style={styles.profileImgContainer2}>
          <Image
            src={Family_photo}
            alt={"User Image"}
            style={styles.profileImg2}
          />
        </View>

        <View style={styles.personalContent}>
          <View style={styles.familyContent2}>
            {familyDetailListArr2.map((detail, i) => {
              return typeof detail.value === "object" ? (
                <View style={styles.personalContentText} key={i}>
                  <Text>{detail.label} </Text>
                  <Text>
                    {detail.value.map((elem, i) => (
                      <Text>
                        {detail.value.map((elem, i) => {
                          return (
                            elem.check && (
                              <Text key={i}>
                                {Object.values(elem)[0]}
                                {checkIfLastIndex(
                                  i,
                                  Object.keys(elem)[0].length
                                )}
                              </Text>
                            )
                          );
                        })}
                      </Text>
                    ))}
                  </Text>
                </View>
              ) : (
                <View style={styles.personalContentText} key={i}>
                  <Text>{detail.label} </Text>
                  <Text>{detail.value}</Text>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default FormatEight;
