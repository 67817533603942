import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import axios from "axios";
import { toast } from "react-toastify";
import server from "../../config/server.json";
import BackBtn from "../../assets/DashboardIcons/back.png";
import AttentionBtn from "../../assets/DashboardIcons/Attention.png";
import NextBtn from "../../assets/DashboardIcons/Next.png";
import InformationBtn from "../../assets/DashboardIcons/information.png";
import ProfileViewMob from "../ProfileView/ProfileViewMob";
import DefaultImage from "../../assets/avatar_placeholder.png"

const Dashboard = ({ setShowDashboard, setActiveButton, userDetails ,userPreference ,setEditMyDetails}) => {
  const [shortlistedUserId, setShortlistedUserId] = useState([]);
  const [messages, setMessages] = useState(0);
  const [contactRequest, setContactRequest] = useState(0);
  const [latestProfile,setLatestProfile] = useState([])
  const [showProfileView, setShowProfileView] = useState(null);
  const [allShortlistedUsersData,setAllShortlistedUsersData] = useState([]);

  const filteredData = allShortlistedUsersData.filter((profile) =>
  shortlistedUserId?.includes(profile._id)
  );

  const showUserProfile = (user) => {
    setShowProfileView(user);
  };

  const handleBackFromProfileView = () => {
    setShowProfileView(null)
  };

  const handleEditProfile = () =>{
    setShowDashboard(false)
    setEditMyDetails(true)
  }

// Combine both objects into a single object
const combinedData = { ...userDetails, ...userPreference };

// Function to check if a value is considered unfilled (null or "N/A")
const isUnfilledValue = (value) => value === null || value === "N/A";

// Calculate the total number of filled data and unfilled data
let filledDataCount = 0;

Object.values(combinedData).forEach((value) => {
  if (!isUnfilledValue(value)) {
    filledDataCount++;
  }
});

// Calculate the total number of keys in the combined data
const totalDataKeysCount = Object.keys(combinedData).length;

// Calculate the percentage of data filled by the user
const dataFilledPercentage = Math.round((filledDataCount / totalDataKeysCount) * 100);

const fetchShortlistUserId = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const response = await axios.get(
      `${server.url.production}${server.api.FETCH_SHORTLIST_PROFILE}`,
      {
        headers: headers,
      }
    );
    if (response.data) {
      const { requestId } = response.data;
      setShortlistedUserId(requestId);

      // Call fetchAllUserData here after setting the shortlistedUserId state
      fetchAllUserData(requestId);
    }
  } catch (error) {
    toast.error(error.response.data.error, {
      autoClose: 3000,
      position: "bottom-right",
    });
  }
};

  const fetchMessageData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_MESSAGE_DATA}`,
        {
          headers: headers,
        }
      );
      if (response.data) {
        setMessages(response.data);
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const fetchShortlistUserData = async (userId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_USER_DATA}/${userId}`,
        {
          headers: headers,
        }
      );
      return response.data; // Return the data from the API call
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
      return null; // Handle the error and return null for failed API calls
    }
  };
  
  const fetchAllUserData = async (userIds) => {
    const promises = userIds?.slice(0,2)?.map((userId) => fetchShortlistUserData(userId));
    const userResponses = await Promise.all(promises);
    const successfulResponses = userResponses.filter((response) => response !== null);
    setAllShortlistedUsersData(successfulResponses)
  };

  const latestAddedProfile = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_USER_DETAILS}`,
        {
          headers: headers,
        }
      );
      if (response.data) {
        setLatestProfile(response.data);
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };
  
  const today = new Date();
  const lastSevenDays = new Date(today);
  lastSevenDays.setDate(lastSevenDays.getDate() - 7);
  
  const newProfilesAdded = latestProfile.filter((item) => {
    const insertedDate = new Date(item.inserted_at);
    return insertedDate >= lastSevenDays;
  });


  const handleNotificationCount = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_PENDING_REQUESTS_COUNT}`,
        { headers }
      );
      if (response.status === 200) {
        setContactRequest(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchShortlistUserId();
    fetchMessageData();
    handleNotificationCount();
    latestAddedProfile();
    fetchAllUserData(shortlistedUserId)
    // eslint-disable-next-line
  }, []); 

  return (
    <>
      {showProfileView ? (
        <ProfileViewMob onBack={handleBackFromProfileView} userData={showProfileView} userProfilePhoto={showProfileView.userDetails.profileImage} onClose={() => setShowProfileView(null)}/>
      ) : (
        <div className="profile-dashboard">
          <div className="section">
            <button
              className="back-button"
              onClick={() => setShowDashboard(false)}
            >
              <img src={BackBtn} alt="Back-btn" />
            </button>
            <h1 className="dashboard-heading">My Dashboard</h1>
          </div>

          <div className="section">
            <div className="stats">
              <h2>Your Stats</h2>
              <button className="information-button">
                <img src={InformationBtn} alt="information-btn" />
              </button>
            </div>
            <div className="stats-container">
              <div className="shortlisted-profiles-status">
                <h3>Shortlisted Profiles</h3>
                <p>{shortlistedUserId.length}</p>
              </div>
              <div className="messages-status">
                <h3>Messages</h3>
                <p>{messages.length}</p>
              </div>
              <div className="profile-views-status">
                <h3>Last Week's New Profiles</h3>
                <p>{newProfilesAdded.length}</p>
              </div>
              <div className="sharing-requests-status">
                <h3>Contact Sharing Requests</h3>
                <p>{contactRequest}</p>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="profile-status">
              <h2>
                Your Profile Status
                <img src={AttentionBtn} alt="attention-btn" />
              </h2>
              <button className="next-button" onClick={handleEditProfile}>
                <img src={NextBtn} alt="next-btn" />
              </button>
            </div>
            <p>Incomplete: Complete to get the best matches</p>
            <div className="percentage-container">
            <p style={{ width: `${dataFilledPercentage}%` }}>{dataFilledPercentage}%</p>
            </div>
          </div>

          <div className="section">
            <div className="shortlisted-heading">
              <h2>Shortlisted Profiles</h2>
              <button
                className="view-all-button"
                onClick={() => setActiveButton("heart")}
              >
                <span>View All</span>
                <img src={NextBtn} alt="next-btn" />
              </button>
            </div>
            <div className="shortlisted-profiles-container">
            {filteredData?.slice(0,2).map((user) => {
                const { _id, first_name, last_name } = user;
                const { age, profileImage, city, country } = user.userDetails;

                return (
                  <div className="profile-container" key={_id}>
                      <img src={profileImage ? profileImage : DefaultImage} alt="Profile 1" />
                      <p className="profile-user-name">
                        {first_name} {last_name}
                      </p>
                      <p className="user-age-city">
                        {age ? age : "N/A"} | {city || "N/A"},{" "}
                        {country || "N/A"}
                      </p>
                      <button className="view-button" onClick={() => showUserProfile(user)} >View</button>
                    </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
