import axios from "axios";
import React, { useEffect, useState } from "react";
import server from "../../config/server.json";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import defaultImage from "../../assets/avatar_placeholder.png";

function HorizontalSwiper({ profileImage, id }) {
  const [multipleImages, setMultipleImages] = useState([]);

  const getMultipleImages = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_MULTIPLE_IMAGES}/${id}`,
        {
          headers: headers,
        }
      );
      setMultipleImages(response.data);
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getMultipleImages();
    // eslint-disable-next-line
  }, []);

  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      {Array.isArray(multipleImages) && multipleImages.length > 0 ? (
        <>
          <SwiperSlide>
            <img src={profileImage} alt={"first_name"} />{" "}
            {/* First we display the profile images */}
          </SwiperSlide>
          {multipleImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image.profileImages} alt={"first_name"} />{" "}
              {/* This slide will render if the user has multiple images */}
            </SwiperSlide>
          ))}
        </>
      ) : profileImage ? (
        <SwiperSlide>
          <img src={profileImage} alt={"first_name"} />
        </SwiperSlide>
      ) : (
        <SwiperSlide>
          <img src={defaultImage} alt={"first_name"} />
        </SwiperSlide>
      )}
    </Swiper>
  );
}

export default HorizontalSwiper;
