import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import "./Contact.css";
import { toast } from "react-toastify";

function ContactUs() {
  const [isValid, setIsValid] = useState(false);
  const [showErrorMessage, setErrorMessages] = useState("");
  const [field, setField] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
  });

  const { name, email, phone, subject, message } = field;

  const handleValidation = () => {
    const nameRegex = /^[a-zA-Z ]{1,30}$/; // accept alphabets and only space character between words and min 2 chars and max of 30 chars
    const emailRegex = /^([a-zA-Z0-9\_\-])+\@([a-zA-Z])+\.([a-zA-Z]){2,5}$/ // eslint-disable-line
    const phoneRegex = /^[0-9]{10}$/;
    const messageRegex = /^[a-zA-Z0-9\s\,\''\-\?]*$/; // eslint-disable-line
    const subjectRegex = /^[a-zA-Z ]{1,75}$/; // accept alphabets and only space character between words and min 2 chars and max of 75 chars

    if (!(name.match(nameRegex))) {
      setErrorMessages("Name Cannot be Empty ");
    } else if (!(email.match(emailRegex))) {
      setErrorMessages("Email is Not Valid");
    } else if (!(phone.match(phoneRegex))) {
      setErrorMessages("Phone Number is not Valid");
    } else if (!(message.match(messageRegex))) {
      setErrorMessages("Message Cannot be Emmpty")
    } else if (!(subject.match(subjectRegex))) {
      setErrorMessages("Enter a valid subject not exceding 75 characters")
    } else {
      setIsValid(true);
    }
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setField({ ...field, [name]: value });
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      console.log(name, email, phone, subject, message);
      toast.success("Message Feedback Successfully", {
        autoClose: 3000,
        position: "bottom-right",
      });
      setField({ name: "", email: "", phone: "", subject: "", message: "" })
    }
  }

  useEffect(() => {
    setErrorMessages("")
  }, [field])

  return (
    <section className="contact__us">
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Our customer service team will be pleased to assist you anytime you have a query. You can contact our customer service team in one of the following ways."
        />
      </Helmet>
      <div className="page__heading">
        <div className="wrapper">
          <h4>get in touch</h4>
        </div>
      </div>
      <div className="contact__locations">
        <div className="wrapper">
          <div className="address__map">
            <div className="address">
              <h5>address</h5>
              <p><span>address: </span>demo city, demo state, demo country</p>
              <p><span>phone: </span>1234567890</p>
              <p><span>email: </span>info@martimonydemo.com</p>
            </div>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.161416356256!2d72.9637468!3d19.1881509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b91c2aac887f%3A0xf2f100f4a232a457!2sTech%20Sierra!5e0!3m2!1sen!2sin!4v1670845075869!5m2!1sen!2sin"
                title="myFrame"></iframe>
            </div>
          </div>
          <div className="contact__feedback">
            <h5>enquiry/feedback</h5>
            <form onSubmit={handleOnSubmit}>
              <div className="form__group">
                <div>
                  <label htmlFor="name">name <span>*</span></label>
                  <input type="text" name='name' id="name" value={name} onChange={handleOnChange} placeholder='Ex: John' required />
                </div>
                <div>
                  <label htmlFor="email">email <span>*</span></label>
                  <input type="email" name='email' id='email' value={email} onChange={handleOnChange} placeholder='Ex: john123@email.com' required />
                </div>
              </div>
              <div className="form__group">
                <div>
                  <label htmlFor="phone">phone <span>*</span></label>
                  <input type="text" name='phone' id="phone" value={phone} onChange={handleOnChange} placeholder='Ex: 9638527410' required />
                </div>
                <div>
                  <label htmlFor="subject">subject <span>*</span></label>
                  <input type="text" name='subject' id='subject' value={subject} onChange={handleOnChange} placeholder='Enter Subject: ' required />
                </div>
              </div>
              <div>
                <label htmlFor="message">message <span>*</span></label>
                <textarea name="message" rows={4} id="message" value={message} onChange={handleOnChange} placeholder='Enter Your Feedback' required />
              </div>
              <div>
                <button disabled={showErrorMessage.length > 0 ? true : false}
                  onClick={handleValidation}>send message</button>
              </div>
              {showErrorMessage.length > 0 ? (
                <div id="career_form_error">
                  <span>Alert: {showErrorMessage}</span>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
