import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import axios from 'axios';
import server from "../../config/server.json";
// import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import { useNavigate } from 'react-router-dom';
import { RxCross1 } from "react-icons/rx";
import { AiOutlineUser } from "react-icons/ai";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";

const CarrerDetails = ({ userDetails, setCarrerDetails, editCarrerDetails }) => {

    const navigate = useNavigate();
    const masterData = useSelector((state) => state.masterData);
    const [userId, setUserId] = useState(null);
    const [token, setToken] = useState(null);
    const [carrerData, setCarrerData] = useState({
        occupation: "",
        jobLocation: "",
        companyName: "",
        designation: "",
        income: "",
    });

    const { occupation, jobLocation, companyName, designation, income } = carrerData;

    const handleValidation = () => {
        if (occupation === "") {
            toast.error("*** Enter Your Occupation", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (jobLocation === "") {
            toast.error("*** Enter Your Job Location", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (companyName === "") {
            toast.error("*** Enter Your Company Name", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (designation === "") {
            toast.error("*** Enter Your Current Position in Company", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (income === "") {
            toast.error("*** Enter Your a Income", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else {
            return true;
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (handleValidation()) {
            try {
                const headers = {
                    "Content-Type": "application/json",
                    token: token,
                };
                const body = {
                    userId,
                    occupation,
                    jobLocation,
                    companyName,
                    designation,
                    income,
                };
                const response = await axios.post(
                    `${server.url.production}${server.api.UPDATE_CAREER_DETAILS}`,
                    body,
                    { headers }
                );
                if (response.status === 201) {
                    toast.success(response.data.message, {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                    setCarrerDetails(!editCarrerDetails);
                } else {
                    toast.error("Something went wrong.", {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                }

            } catch (error) {
                if (error.data && error.data.status !== 201) {
                    toast.error(error.data.message, {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                } else {
                    toast.error(error.response.data.message, {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                }
            }
        } else {
            toast.error("Something Went Wrong, Please Try Again Later", {
                autoClose: 3000,
                position: "bottom-right",
            });
        }
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setCarrerData({ ...carrerData, [name]: value });
    }

    useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("user"));
        if (loggedUser) {
            setUserId(loggedUser.id);
            setToken(localStorage.getItem("token"));
        } else {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setCarrerData({
            occupation: userDetails?.occupation ? userDetails.occupation : "",
            jobLocation: userDetails?.jobLocation ? userDetails.jobLocation : "",
            companyName: userDetails?.companyName ? userDetails.companyName : "",
            designation: userDetails?.designation ? userDetails.designation : "",
            income: userDetails?.income ? userDetails.income : ""
        });
    }, [userDetails]);

    return (
        <section className="CarrerDetail-data">
            <div className="CarrerDetail-data-info">
                <div className="carrer-info">
                    <span>occupation category</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : occupation
                                ? occupation
                                : "NA"
                        }
                    </span>
                </div>
                <div className="carrer-info">
                    <span>current city</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : jobLocation
                                ? jobLocation
                                : "NA"
                        }
                    </span>
                </div>
                <div className="carrer-info">
                    <span>company name</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : companyName
                                ? companyName
                                : "NA"
                        }
                    </span>
                </div>
                <div className="carrer-info">
                    <span>designation</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : designation
                                ? designation
                                : "NA"
                        }
                    </span>
                </div>
                <div className="carrer-info">
                    <span>annual income</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : income
                                ? income
                                : "NA"
                        }
                    </span>
                </div>
            </div>
            {
                editCarrerDetails &&
                <div className='edit_carrer_details'>
                    <h4>
                        <span><RxCross1 onClick={() => setCarrerDetails(!editCarrerDetails)} />
                        </span><span>edit Carrer details</span>
                    </h4>
                    <h6>
                        <span><AiOutlineUser /></span>
                        <span>Carrer Information</span>
                    </h6>
                    <form onSubmit={handleOnSubmit}>
                        <div className="input-block">
                            <label htmlFor="occupation">occupation category</label>
                            <input type="text" name='occupation' id='occupation' value={occupation} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="jobLocation">current city</label>
                            <input type="text" name='jobLocation' id='jobLocation' value={jobLocation} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="companyName">company name</label>
                            <input type="text" name='companyName' id='companyName' value={companyName} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="designation">designation</label>
                            <input type="text" name='designation' id='designation' value={designation} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="income">income range</label>
                            <select name='income' id='income'
                                value={income}
                                onChange={handleOnChange}
                            >
                                <optgroup label="Income" />
                                <option value="none">{OPTION_SELECT_EMPTY}</option>
                                {
                                    masterData?.income.slice().sort((a, b) => a.value - b.value).map(elem => (
                                        <option key={elem._id} value={elem.value}>{elem.value}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="input-block-btn">
                            <button type='submit'>save</button>
                        </div>
                    </form>
                </div>
            }
        </section>
    )
}

export default CarrerDetails;