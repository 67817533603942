import React from "react"; // eslint-disable-next-line react-hooks/exhaustive-deps
import pdf from "@react-pdf/renderer";
import firstPage from "../../../assets/BiodataImages/bg5_1.png";
import secondPage from "../../../assets/BiodataImages/bg5_2.png";
import Family_photo from "../../../assets/BiodataImages/Family_photo.jpg";

const { Page, Text, Image, Document, View, StyleSheet } = pdf;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  profileImgContainer1: {
    width: "240px",
    height: "260px",
    position: "absolute",
    top: "38px",
    right: "50px",
  },

  profileImgContainer2: {
    width: "400px",
    height: "300px",
    position: "absolute",
    top: "30px",
    right: "17%",
  },

  profileName: {
    padding: "15px 0",
    fontFamily: "Helvetica-Bold",
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "900",
    top: 10,
  },

  profileImg1: {
    width: "200px",
    margin: "0 auto",
    borderRadius: "30px",
  },

  profileImg2: {
    width: "400px",
    height: "300px",
    borderRadius: "20px",
    objectFit: "cover",
  },

  personalDetailsContainer1: {
    width: "284px",
    height: "841px",
    padding: "20px 0 10px 20px",
  },

  personalDetailsContainer2: {
    width: "500px",
    padding: "5px",
    position: "absolute",
    right: "40px",
    left: "50px",
    bottom: "10%",
  },

  personalDetailsHeader1: {
    fontSize: "15px",
    fontFamily: "Helvetica-Bold",
    padding: "20px 0 0 0",
  },

  personalDetailsHeader2: {
    fontSize: "28px",
    fontFamily: "Helvetica-Bold",
    position: "absolute",
    right: "35%",
    top: "50%",
  },

  personalDetails1: {
    padding: "0 0 10px 0",
  },

  personalDetails2: {
    width: "300px",
    padding: "0 0 10px 20px",
    height: "270px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  personalSingleDetail1: {
    padding: "13px 0 5px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  personalSingleDetail2: {
    width: "248px",
    paddingTop: "13px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
  },

  aboutMeConatiner: {
    position: "absolute",
    width: "200px",
    top: "35%",
    right: "50px",
  },

  aboutMeHeader: {
    paddingTop: 30,
    paddingBottom: "10px",
    fontFamily: "Helvetica-Bold",
  },

  aboutMe: {
    fontSize: "13px",
    marginBottom: 5,
    paddingTop: 38,
    letterSpacing: 1,
  },
});

const FormatTwo = ({
  fields,
  uncleList,
  auntyList,
  mamaList,
  mamiList,
  brotherList,
  sisterList,
  cousinList,
  base64,
}) => {
  const {
    first_name,
    last_name,
    pob,
    gender,
    blood_group,
    height,
    marital,
    religion,
    language,
    country,
    horoscope,
    qualification,
    specialization,
    instituteName,
    companyName,
    designation,
    income,
    fatherName,
    father_job,
    motherName,
    mother_job,
    dada,
    dadi,
    siblings,
    nana,
    nani,
    nativePlace,
    familyStatus,
    phone_number,
    email_id,
    address_1,
    about_us
  } = fields;

  const dob = new Date(fields.dob);
  const birthDate = isNaN(dob.getDate()) ? "" : dob.getDate();
  const birthYear = isNaN(dob.getFullYear()) ? "" : dob.getFullYear();
  const birthMonth =
    dob.toLocaleString("default", { month: "long" }) === "Invalid Date"
      ? ""
      : dob.toLocaleString("default", { month: "long" });
  const date_of_birth = `${birthDate} ${birthMonth} ${birthYear}`;
  const checkIfLastIndex = (index, length) => {
    return index === length - 1 ? "" : ", ";
  };

  const profileDetailListArr1 = [
    { title: "Profile Details" },
    [
      { label: "Date of birth", value: date_of_birth },
      { label: "Birth Place", value: pob },
      { label: "Gender", value: gender },
      { label: "Blood Group", value: blood_group },
      { label: "Height", value: height },
      { label: "Marital Status", value: marital },
      { label: "Religion", value: religion },
      { label: "Language Known", value: language },
      { label: "Country", value: country },
      { label: "Horoscope", value: horoscope },
    ],
  ];

  const qualificationListArr1 = [
    { title: "Qualification" },
    [
      { label: "Highest Qualification", value: qualification },
      { label: "Specialization", value: specialization },
      { label: "Institute Name", value: instituteName },
    ],
  ];

  const occupationListArr1 = [
    { title: "Occupation" },
    [
      { label: "Company Name", value: companyName },
      { label: "Occupation", value: designation },
      { label: "Income", value: income },
    ],
  ];

  const contactListDetails = [
    { title: "Contact Details" },
    [
      { label: "Conatct Number", value: phone_number },
      { label: "Email", value: email_id },
      { label: "Residential Address", value: address_1 },
    ],
  ];

  const profileDetailListArr2 = [
    { label: "Father", value: fatherName },
    { label: "Father's Job", value: father_job },
    { label: "Mother", value: motherName },
    { label: "Mother's Job", value: mother_job },
    { label: "Grand Father", value: dada },
    { label: "Grand Mother", value: dadi },
    { label: "Number Of Siblings", value: siblings },
    { label: "Brother Name", value: brotherList },
    { label: "Sister Name", value: sisterList },
    { label: "Nanaji", value: nana },
    { label: "Naniji", value: nani },
    { label: "Uncles", value: uncleList },
    { label: "Aunty", value: auntyList },
    { label: "Mama", value: mamaList },
    { label: "Mami", value: mamiList },
    { label: "Cousins Name", value: cousinList },
    { label: "Native Place", value: nativePlace },
    { label: "Family Status", value: familyStatus },
  ];

  return (
    <Document>
      {/* PAGE 1 */}
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={firstPage} />
        </View>
        {/* profile name & img */}
        <View style={styles.profileImgContainer1}>
          <Image
            src={{ uri: base64 }}
            alt={"User Image"}
            style={styles.profileImg1}
          />
          <Text style={styles.profileName}>
            {first_name} {last_name}
          </Text>
        </View>
        {/* profile about me */}
        <View style={styles.aboutMeConatiner}>
          {/* <Text style={styles.aboutMeHeader}>About Me</Text> */}
          {!about_us?
          <Text style={styles.aboutMe}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book. It has survived not
          only five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </Text>
        :
        <Text style={styles.aboutMe}>{about_us}</Text>}
        </View>
        {/* Personal Details Container 1 */}
        <View style={styles.personalDetailsContainer1}>
          {[
            profileDetailListArr1,
            qualificationListArr1,
            occupationListArr1,
            contactListDetails,
          ].map((arrOfuserDetails, i) => (
            <View key={i}>
              <View style={styles.personalDetailsHeader1}>
                <Text>{arrOfuserDetails[0].title}</Text>
              </View>
              <View style={styles.personalDetails1}>
                {arrOfuserDetails[1].map((detail, i) => (
                  <View style={styles.personalSingleDetail1} key={i}>
                    <Text style={styles.personalLeftText}>
                      {detail.label}:{" "}
                    </Text>
                    <Text style={styles.personalRightText}>{detail.value}</Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      </Page>

      {/* PAGE 2 */}
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={secondPage} />
        </View>
        {/* Family Img */}
        <View style={styles.profileImgContainer2}>
          <Image
            src={Family_photo}
            alt={"User Image"}
            style={styles.profileImg2}
          />
        </View>
        {/* Personal Details Container 2 */}
        <View style={styles.personalDetailsHeader2}>
          <Text>Family Details</Text>
        </View>
        <View style={styles.personalDetailsContainer2}>
          <View style={styles.personalDetails2}>
            {profileDetailListArr2.map((detail, i) => {
              return typeof detail.value === "object" ? (
                <View style={styles.personalSingleDetail2} key={i}>
                  <Text>{detail.label}: </Text>
                  <Text>
                    {detail.value.map((elem, i) => {
                      return (
                        elem.check && (
                          <Text key={i}>
                            {Object.values(elem)[0]}
                            {checkIfLastIndex(i, Object.keys(elem)[0].length)}
                          </Text>
                        )
                      );
                    })}
                  </Text>
                </View>
              ) : (
                <View style={styles.personalSingleDetail2} key={i}>
                  <Text>{detail.label}: </Text>
                  <Text>{detail.value}</Text>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FormatTwo;
