import axios from "axios";
import "./FamilyPage.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import server from "../../../src/config/server.json";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";

const FamilyPage = ({setSteps,userDetails, next, setNext}) => {
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [profileCreatedBy, setProfileCreatedBy] = useState("Select");
  const [familyType, setFamilyType] = useState("Select Family Type");
  const [familyValue, setFamilyValue] = useState("Select");
  const [familyStatus, setFamilyStatus] = useState("Select Family Status");
  const [familyIncome, setFamilyIncome] = useState("0-200000");
  const [familyIncomeRangeFrom, setFamilyIncomeRangeFrom] = useState(0);
  const [familyIncomeRangeTo, setFamilyIncomeRangeTo] = useState(0);
  const [noOfBrotherUnmarried, setNoOfBrotherUnmarried] = useState(0);
  const [noOfBrotherMarried, setNoOfBrotherMarried] = useState(0);
  const [noOfSisterUnmarried, setNoOfSisterUnmarried] = useState(0);
  const [noOfSisterMarried, setNoOfSisterMarried] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const FAMILY_DETAILS_EDIT = server.api.FAMILY_DETAILS_EDIT;
  const navigate = useNavigate();
  const masterData = useSelector(state => state.masterData);
  const [close, setClose] = useState(false);

  const [familyDetails, setFamilyDetails] = useState({
    fatherName: "",
    motherName: "",
    gotra: "",
    mamaSurname: "",
    nativePlace: "",
  });

  const setFamilyIncomeDetails = () => {
    const arr = familyIncome.split(" ").join("");
    let subStr = arr.split(/[-]/);
    setFamilyIncomeRangeFrom(parseInt(subStr[0]));
    setFamilyIncomeRangeTo(parseInt(subStr[1]));
  };

  const handleOnChange = (e) => {
    const newVal = { ...familyDetails };
    newVal[e.target.name] = e.target.value;
    setFamilyDetails(newVal);
  };

  const handleFamilyDetailsSubmit = (e) => {
    e.preventDefault();
    const textRegex = /^[a-zA-Z ]{2,30}$/; // Only Text With Spaces and length will be 30
    if (!(familyDetails.nativePlace.match(textRegex)) || (!(familyDetails.fatherName.match(textRegex))) || (!(familyDetails.gotra.match(textRegex))) || (!(familyDetails.mamaSurname.match(textRegex))) || (!(familyDetails.motherName.match(textRegex)))) {
      return setErrorMessage(" *** required alphabet character [a-zA-Z]");
    }
    // e.preventDefault();
    if (familyStatus === "Select Family Status" || familyType === "Select Family Type" || familyValue === "Select" || profileCreatedBy === "Select") {
      return setErrorMessage(`Please select the value of ${familyStatus === "Select Family Status" ? "Family Status" : ""}, ${familyType === "Select Family Type" ? "Family Type," : ""} ${profileCreatedBy === "Select" ? "Profile Created By," : ""} ${familyValue === "Select" ? "Family Value," : ""} ...!`);
    }
    if (
      userId &&
      familyDetails.fatherName &&
      familyDetails.motherName &&
      familyDetails.gotra &&
      familyDetails.mamaSurname &&
      familyDetails.nativePlace
    ) {
      let completeFamilyDetails = {
        userId: userId,
        profileCreatedBy: profileCreatedBy,
        fatherName: familyDetails.fatherName,
        motherName: familyDetails.motherName,
        noOfBrotherUnmarried: noOfBrotherUnmarried,
        noOfBrotherMarried: noOfBrotherMarried,
        noOfSisterUnmarried: noOfSisterUnmarried,
        noOfSisterMarried: noOfSisterMarried,
        gotra: familyDetails.gotra,
        mamaSurname: familyDetails.mamaSurname,
        familyType: familyType,
        familyValue: familyValue,
        familyStatus: familyStatus,
        familyIncome: familyIncome,
        familyIncomeRangeFrom: familyIncomeRangeFrom,
        familyIncomeRangeTo: familyIncomeRangeTo,
        nativePlace: familyDetails.nativePlace,
      };
      setErrorMessage("");
      postFamilyDetailsRequest(completeFamilyDetails);
    } else {
      setErrorMessage("Please fill all the details");
      toast.error("Please fill all the details", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  let disable = (currentValue) => currentValue === 0;

  useEffect(() => {
    setFamilyDetails({
      fatherName: userDetails.fatherName,
      motherName: userDetails.motherName,
      gotra: userDetails.gotra,
      mamaSurname: userDetails.mamaSurname,
      nativePlace: userDetails.nativePlace,
    });
    setFamilyStatus(userDetails.familyStatus);
    setFamilyValue(userDetails.familyValue);
    setProfileCreatedBy(userDetails.profileCreatedBy);
    setFamilyType(userDetails.familyType);
    setNoOfBrotherUnmarried(userDetails.noOfBrotherUnmarried ? userDetails.noOfBrotherUnmarried : noOfBrotherUnmarried);
    setNoOfBrotherMarried(userDetails.noOfBrotherMarried ? userDetails.noOfBrotherMarried : noOfBrotherMarried);
    setNoOfSisterUnmarried(userDetails.noOfSisterUnmarried ? userDetails.noOfSisterUnmarried : noOfSisterUnmarried);
    setNoOfSisterMarried(userDetails.noOfSisterMarried ? userDetails.noOfSisterMarried : noOfSisterMarried);
    setFamilyIncome(userDetails.familyIncome ? userDetails.familyIncome : familyIncome);
  }, []); //eslint-disable-line

  const postFamilyDetailsRequest = async (completeFamilyDetails) => {
    try {
      const config = {
        "Content-type": "application/json",
        token: token,
      };
      const response = await axios.post(
        `${server.url.production}${FAMILY_DETAILS_EDIT}`,
        completeFamilyDetails,
        { headers: config }
      );
      if (response.status === 201) {
        if (close) {
          navigate('/');
        } 
        if (next) {
          setSteps(5);
          setNext(false);
        }
        toast.success(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error("Something went wrong.", {
          autoClose: 3000,
          position: "bottom-right",
        });
        setErrorMessage("Something went wrong.");
      }
    } catch (e) {
      if (e.response && e.response.status !== 201) {
        toast.error(e.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
        setErrorMessage(e.response.data.message);
      } else {
        toast.error("Something went wrong, please try again later.", {
          autoClose: 3000,
          position: "bottom-right",
        });
        setErrorMessage("Something went wrong, please try again later.");
      }
    }
  };

  useEffect(() => {
    setFamilyIncomeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyIncome]);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setUserId(loggedUser.id);
      setToken(localStorage.getItem("token"));
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <div className="body">
        <Helmet>
          <title>familyDetails</title>
          <meta
            name="description"
            content="A family description helps the other party determine if the other party is socially and economically equal or higher."
          />
        </Helmet>
        <div className="main d-flex flex-column m-auto col-12">
          <div className="family-wrapper">
            <div className="col-12 col-md-10 rounded family-details">
              
              <form onSubmit={(e) => handleFamilyDetailsSubmit(e)}>
                {errorMessage.length > 0 ? (
                <div className="container bg-danger text-light col-11 m-auto rounded d-flex justify-content-between align-items-center p-2">
                  <span>{errorMessage}</span>
                  <button
                    type="button"
                    className="cursor pointer btn-close"
                    aria-label="Close"
                    onClick={() => setErrorMessage("")}
                  ></button>
                </div>
                ) : (
                  ""
                )}
                {/*Profile created Div  */}
                <div className="container py-3">
                  <div className="col-12 col-md-6 d-flex justify-content-between px-md-4 px-0">
                    <h6
                      className="w-50 d-flex align-items-center"
                      htmlFor="createdBy"
                    >
                      Profile created by
                    </h6>
                    <div className="dropdown w-50">
                      <select
                        className="form-select border rounded border-secondary"
                        name="createdBy"
                        onChange={(e) => setProfileCreatedBy(e.target.value)}
                        id="createdBy"
                        value={profileCreatedBy}
                        required
                      >
                        <optgroup label="Profile Created By" />
                        <option>{ OPTION_SELECT_EMPTY }</option>
                        {
                          masterData.profileCreatedBy.map(elem => {
                            return(
                            <option key={elem._id}>{elem.name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                {/* Parent details Div  */}
                <div className="container d-flex flex-column flex-md-row my-3">
                  <div className="w-100 w-md-50 d-flex justify-content-between px-md-4 px-0 my-2 my-md-0">
                    <label
                      htmlFor="fatherName"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Father's Name</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control w-50 border border-secondary text-center"
                      id="fatherName"
                      name="fatherName"
                      placeholder="Eg : abc"
                      value={familyDetails.fatherName}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                  <div className="w-100 w-md-50 d-flex justify-content-between px-md-4 px-0 my-2 my-md-0">
                    <label
                      htmlFor="motherName"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Mother's Name</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control w-50 border border-secondary text-center"
                      id="motherName"
                      name="motherName"
                      placeholder="Eg : xyz"
                      value={familyDetails.motherName}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                </div>
                {/* siblings details Div  */}
                <div className="container d-flex flex-column my-3">
                  {/* Brother details Div */}
                  <div className="col-12 ">
                    <label
                      htmlFor="brother"
                      className="form-label px-md-4 px-0 my-3"
                    >
                      <h6>Brother(s)</h6>
                    </label>
                    <div className="w-100 d-flex flex-column flex-md-row">
                      <div className="col-12 col-md-6 px-md-4 px-0">
                        <div className="d-flex justify-content-between my-2 my-md-0 pl-2">
                          <label
                            htmlFor="noOfBrotherMarried"
                            className="form-label w-50 d-flex m-auto"
                          >
                            <h6 className="my-auto">Unmarried</h6>
                          </label>
                          <div className="d-flex justify-content-between w-50">
                            <button
                              className="w-25 btn btn-outline-secondary"
                              disabled={disable(noOfBrotherUnmarried)}
                              onClick={() =>
                                setNoOfBrotherUnmarried(noOfBrotherUnmarried - 1)
                              }
                              type="button"
                            >
                              <b>-</b>
                            </button>
                            <p className="w-50 text-center m-auto">
                              {noOfBrotherUnmarried}
                            </p>
                            <button
                              className="w-25 btn btn-outline-secondary"
                              onClick={() =>
                                setNoOfBrotherUnmarried(noOfBrotherUnmarried + 1)
                              }
                              type="button"
                            >
                              <b>+</b>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 px-md-4 px-0">
                        <div className="d-flex justify-content-between my-2 my-md-0">
                          <label
                            htmlFor="noOfBrotherMarried"
                            className="form-label w-50 d-flex m-auto"
                          >
                            <h6 className="my-auto">Married</h6>
                          </label>
                          <div className="d-flex justify-content-between w-50">
                            <button
                              className="w-25 btn btn-outline-secondary"
                              disabled={disable(noOfBrotherMarried)}
                              onClick={() =>
                                setNoOfBrotherMarried(noOfBrotherMarried - 1)
                              }
                              type="button"
                            >
                              <b>-</b>
                            </button>
                            <p className="w-50 text-center m-auto">
                              {noOfBrotherMarried}
                            </p>
                            <button
                              className="w-25 btn btn-outline-secondary"
                              onClick={() =>
                                setNoOfBrotherMarried(noOfBrotherMarried + 1)
                              }
                              type="button"
                            >
                              <b>+</b>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Sister details Div */}
                  <div className="col-12 ">
                    <label
                      htmlFor="sister"
                      className="form-label px-md-4 px-0 my-3"
                    >
                      <h6>Sister(s)</h6>
                    </label>
                    <div className="w-100 d-flex flex-column flex-md-row">
                      <div className="col-12 col-md-6 px-md-4 px-0">
                        <div className="d-flex justify-content-between my-2 my-md-0 pl-2">
                          <label
                            htmlFor="noOfSisterUnmarried"
                            className="form-label w-50 d-flex m-auto"
                          >
                            <h6 className="my-auto">Unmarried</h6>
                          </label>
                          <div className="d-flex justify-content-between w-50">
                            <button
                              className="w-25 btn btn-outline-secondary"
                              disabled={disable(noOfSisterUnmarried)}
                              onClick={() =>
                                setNoOfSisterUnmarried(noOfSisterUnmarried - 1)
                              }
                              type="button"
                            >
                              <b>-</b>
                            </button>
                            <p className="w-50 text-center m-auto">
                              {noOfSisterUnmarried}
                            </p>
                            <button
                              className="w-25 btn btn-outline-secondary"
                              onClick={() =>
                                setNoOfSisterUnmarried(noOfSisterUnmarried + 1)
                              }
                              type="button"
                            >
                              <b>+</b>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 px-md-4 px-0">
                        <div className="d-flex justify-content-between my-2 my-md-0">
                          <label
                            htmlFor="noOfSisterMarried"
                            className="form-label w-50 d-flex m-auto"
                          >
                            <h6 className="my-auto">Married</h6>
                          </label>
                          <div className="d-flex justify-content-between w-50">
                            <button
                              className="w-25 btn btn-outline-secondary"
                              disabled={disable(noOfSisterMarried)}
                              onClick={() =>
                                setNoOfSisterMarried(noOfSisterMarried - 1)
                              }
                              type="button"
                            >
                              <b>-</b>
                            </button>
                            <p className="w-50 text-center m-auto">
                              {noOfSisterMarried}
                            </p>
                            <button
                              className="w-25 btn btn-outline-secondary"
                              onClick={() =>
                                setNoOfSisterMarried(noOfSisterMarried + 1)
                              }
                              type="button"
                            >
                              <b>+</b>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Gotra and mama details */}
                <div className="container d-flex flex-column flex-md-row my-3">
                  <div className="col-12 col-md-6 d-flex justify-content-between px-0 px-md-4 my-2 my-md-0">
                    <label
                      htmlFor="gotra"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Gotra</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control border border-secondary w-50 text-center"
                      name="gotra"
                      placeholder="Eg : Vashista"
                      id="gotra"
                      value={familyDetails.gotra}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-between px-0 px-md-4 my-2 my-md-0">
                    <label
                      htmlFor="mamaSurname"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Mama Surname</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control w-50 border border-secondary text-center"
                      id="mamaSurname"
                      name="mamaSurname"
                      placeholder="Eg : Rajput"
                      value={familyDetails.mamaSurname}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                </div>
                {/* Family type and family value */}
                <div className="container d-flex flex-column flex-md-row my-3">
                  <div className="col-12 col-md-6 d-flex justify-content-between px-0 px-md-4 my-2 my-md-0">
                    <label
                      htmlFor="familyType"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Family Type</h6>
                    </label>
                    <div className="dropdown w-50">
                      <select
                        className={`form-select border rounded ${familyType ? "border-secondary" : "border-danger"
                          }`}
                        name="familyType"
                        id="familyType"
                        onChange={(e) => setFamilyType(e.target.value)}
                        value={familyType}
                        required
                      >
                        <optgroup label="Family Type" />
                        <option>Select Family Type</option>
                        {
                          masterData.familyType.map(elem => {
                            return(
                            <option key={elem._id}>{elem.familyTypeName}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-between px-0 px-md-4 my-2 my-md-0">
                    <label
                      htmlFor="familyValue"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Family Value</h6>
                    </label>
                    <div className="dropdown w-50">
                      <select
                        className="form-select border rounded border-secondary"
                        name="familyValue"
                        id="familyValue"
                        onChange={(e) => setFamilyValue(e.target.value)}
                        value={familyValue}
                        required
                      >
                        <optgroup label="Family Value" />
                        <option>{ OPTION_SELECT_EMPTY }</option>
                        {
                          masterData.familyValues.map(elem => {
                            return(
                            <option key={elem._id}>{elem.familyValueName}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                {/* Family Status and family Income */}
                <div className="container d-flex flex-column flex-md-row my-3">
                  <div className="col-12 col-md-6 d-flex justify-content-between px-0 px-md-4 my-2 my-md-0">
                    <label
                      htmlFor="familyStatus"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Family Status</h6>
                    </label>
                    <div className="dropdown w-50">
                      <select
                        className="form-select border rounded border-secondary"
                        name="familyStatus"
                        id="familyStatus"
                        onChange={(e) => setFamilyStatus(e.target.value)}
                        value={familyStatus}
                        required
                      >
                        <optgroup label="Family Status" />
                        <option>Select Family Status</option>
                        {
                          masterData.familyStatus.map(elem => {
                            return(
                            <option key={elem._id}>{elem.familyStatusName}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-between px-0 px-md-4 my-2 my-md-0">
                    <label
                      htmlFor="familyIncome"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Family Income</h6>
                    </label>
                    <div className="dropdown w-50">
                      <select
                        className="form-select border rounded border-secondary"
                        name="familyIncome"
                        id="familyIncome"
                        onChange={(e) => setFamilyIncome(e.target.value)}
                        value={familyIncome}
                        required
                      >
                        <optgroup label="Family Income" />
                        {
                          masterData.familyIncome.map(elem => {
                            return(
                            <option key={elem._id} value={elem.income}>{elem.income}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
                {/* Native Place */}
                <div className="container d-flex flex-column flex-md-row my-3">
                  <div className="col-12 col-md-6 d-flex justify-content-between px-0 px-md-4">
                    <label
                      htmlFor="nativePlace"
                      className="form-label w-50 d-flex m-auto"
                    >
                      <h6 className="my-auto">Native Place</h6>
                    </label>
                    <input
                      type="text"
                      className="form-control w-50 border border-secondary text-center"
                      name="nativePlace"
                      id="nativePlace"
                      placeholder="Eg : Rajasthan"
                      value={familyDetails.nativePlace}
                      onChange={handleOnChange}
                      required
                    />
                  </div>
                </div>
                {/* Bottom */}
                <div className="family-saveclosebtns">
                  <button type="button" onClick={() => setSteps(3)}>
                    Previous
                  </button>
                  <button type="button"  onClick={() => setClose(true)}>
                    Save & Close
                  </button>
                  <button type="submit"  onClick={() => setNext(true)}>
                    Save & Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FamilyPage;
