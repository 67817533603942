import React, { useEffect, useState } from 'react';
import axios from 'axios';
import server from "../../config/server.json";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineUser } from "react-icons/ai";

const PersonalInformation = ({ user, setEditPersonal, editPersonal }) => {
    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        email_id: "",
        phone_number: ""
    });

    const { first_name, last_name, email_id, phone_number } = userData;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    }

    // const handleValidation = () => {
    //     const emailRegex = /^([a-zA-Z0-9\_\-])+\@([a-zA-Z])+\.([a-zA-Z]){2,5}$/; //eslint-disable-line
    //     if (first_name.length < 3 || first_name.length > 15) {
    //         toast.error("*** Invalid Surname", {
    //             autoClose: 3000,
    //             position: "bottom-right",
    //         });
    //     } else if (last_name.length < 3 || last_name.length > 15) {
    //         toast.error("*** Invalid Surname", {
    //             autoClose: 3000,
    //             position: "bottom-right",
    //         });
    //     } else if (!emailRegex.test(email_id)) {
    //         toast.error("*** Invalid Surname", {
    //             autoClose: 3000,
    //             position: "bottom-right",
    //         });
    //     } else if (!(phone_number.length === 10)) {
    //         toast.error("*** Not Valid Mobile", {
    //             autoClose: 3000,
    //             position: "bottom-right",
    //         });
    //     } else {
    //         return true;
    //     }
    // }

    const handleOnSubmit = (e) => {
        e.preventDefault();

        const emailRegex = /^([a-zA-Z0-9\_\-])+\@([a-zA-Z])+\.([a-zA-Z]){2,5}$/; //eslint-disable-line
        if (first_name.length < 3 || first_name.length > 15) {
            console.log("firstname")
            toast.error("*** Invalid Surname", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (last_name.length < 3 || last_name.length > 15) {
            console.log("lastname")
            toast.error("*** Invalid Surname", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (!emailRegex.test(email_id)) {
            console.log("emailRegex")
            toast.error("*** Invalid Surname", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (!(phone_number.toString().length === 10)) {
            console.log(phone_number.toString().length);
            toast.error("*** Not Valid Mobile", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else {
            saveDetails();
        }

    }

    async function saveDetails() {
        try {
            const headers = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
            };
            const body = {
                first_name: first_name,
                last_name: last_name,
                email_id: email_id,
                phone_number: phone_number,
            };
            const response = await axios.patch(`${server.url.production}${server.api.USER_DETAILS_EDIT}`, body, { headers: headers, });
            if (response.status === 201) {
                toast.success(response.data.message, {
                    autoClose: 3000,
                    position: "bottom-right",
                });
                setEditPersonal(!editPersonal);
            }
            else {
                toast.success(response.data.message, {
                    autoClose: 3000,
                    position: "bottom-right",
                });
            }
        } catch (error) {
            toast.error(error.response.data.message, {
                autoClose: 3000,
                position: "bottom-right",
            });
        }
    }


    useEffect(() => {
        setUserData({
            first_name: user?.first_name ? user.first_name : "",
            last_name: user?.last_name ? user.last_name : "",
            email_id: user?.email_id ? user.email_id : "",
            phone_number: user?.phone_number ? user.phone_number : "",
        });
    }, [user])
    return (
        <section className='userInfo_details'>
            <div className='userinfo_data'>
                <span>first name</span>
                <span>:</span>
                <span>
                    {user === undefined
                        ? ""
                        : first_name
                            ? first_name
                            : "NA"
                    }
                </span>
            </div>
            <div className='userinfo_data'>
                <span>surname</span>
                <span>:</span>
                <span>
                    {user === undefined
                        ? ""
                        : last_name
                            ? last_name
                            : "NA"
                    }
                </span>
            </div>
            <div className='userinfo_data'>
                <span>email</span>
                <span>:</span>
                <span>
                    {user === undefined
                        ? ""
                        : email_id
                            ? email_id
                            : "NA"
                    }
                </span>
            </div>
            <div className='userinfo_data'>
                <span>phone</span>
                <span>:</span>
                <span>
                    {user === undefined
                        ? ""
                        : phone_number
                            ? phone_number
                            : "NA"
                    }
                </span>
            </div>
            {
                editPersonal &&
                <div className="edit_personal_details">
                    <h4>
                        <span><RxCross1 onClick={() => setEditPersonal(!editPersonal)} /></span>
                        <span>edit details</span>
                    </h4>
                    <h6>
                        <span><AiOutlineUser /></span>
                        <span>Personal Information</span>
                    </h6>
                    <form onSubmit={handleOnSubmit}>
                        <div className="input-block">
                            <label htmlFor="first_name">first name</label>
                            <input type="text" name='first_name' id='first_name' value={first_name} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="last_name">last name</label>
                            <input type="text" name='last_name' id='last_name' value={last_name} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="email_id">email</label>
                            <input type="email" name='email_id' id='email_id' value={email_id} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="phone_number">phone number</label>
                            <input type="number" name='phone_number' id='phone_number' value={phone_number} onChange={handleOnChange} />
                        </div>
                        <div className="input-block-btn">
                            <button type='submit'>save</button>
                        </div>
                    </form>
                </div>
            }
        </section>
    )
}

export default PersonalInformation