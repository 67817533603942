import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserImg from "../assets/avatar_placeholder.png";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProfileSlider from "../components/Slider/Slider";
import axios from "axios";
import server from "../config/server.json";
import { MdVerified } from "react-icons/md";
import { toast } from "react-toastify";

const Home = () => {
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [gender, setGender] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [aboutUs, setAboutUs] = useState(null);
  const [userId, setUserId] = useState(null);
  const GET_USER_DETAILS = server.api.GET_USER_DETAILS;

  const getProfilePhoto = async () => {
    try {
        const headers = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
        const response = await axios.get(
            `${server.url.production}${server.api.GET_USER_DATA}`,
            {
                headers: headers,
            }
        );
        const userProfilePhoto = response && response.data.userDetails.profileImage;
        const about_us = response && response.data.userDetails.about_us;
        setProfilePhoto(userProfilePhoto);
        setAboutUs(about_us)
    } catch (e) {
        toast.error(e.response.data.error, {
            autoClose: 3000,
            position: "bottom-right",
        });
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${server.url.production}${GET_USER_DETAILS}${userId}`
      );
      if (response.status === 200) {
        setName(response.data.first_name + " " + response.data.last_name);
        setEmailId(response.data.email_id);
        setPhoneNumber(response.data.phone_number);
        setGender(response.data.gender);
        setAccountStatus(response.data.account_status);
      } else {
        toast.error("Unable to fetch user details.", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (e) {
      if (e.response && e.response.status !== 200) {
        toast.error(e.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error("Something went wrong, please try again later.", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };

  const getGender = () => {
    if (gender === "M") {
      return "Male";
    } else if (gender === "F") {
      return "Female";
    } else {
      return "N/A";
    }
  };

  const getAccountStatus = () => {
    if (accountStatus === "A") {
      return "Active";
    } else if (accountStatus === "D") {
      return "Deactived";
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    if (userInfo) {
      setUserId(userInfo.id)
      fetchUserDetails(userInfo.id);
      getProfilePhoto();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <div>
      <div className="wrapper">
        <div className="content">
          <div className="userContent">
            <div>
            <a href={`/profile/view/${userId}`} target="_blank" rel="noreferrer">
              <img src={profilePhoto ? profilePhoto : UserImg} alt=""></img>
            </a>
            </div>
            <div className="userDetails">
              <p>Name : {name}</p>
              <p>Gender : {getGender()}</p>
              <p>Email : {emailId}</p>
              <p>Account Type : {getAccountStatus()}</p>
              <p>
                Mobile verified : {phoneNumber}
                <MdVerified style={{ color: "#3393e8" }} />
              </p>
            </div>
          </div>
          <div className="userSummary">
            {
              aboutUs ? 
              <p>{aboutUs}</p>
              :
              <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Aspernatur, assumenda maiores. Sequi deleniti, vel sunt adipisci
              nulla numquam inventore neque praesentium tempora vitae libero
              eveniet ducimus odio voluptatibus rem consequatur officia repellat
              iure mollitia quam molestias veritatis? Reprehenderit est odio
              molestias praesentium error omnis, sapiente modi facilis atque ea
              doloribus eveniet nobis tempora consectetur necessitatibus illo,
              expedita aliquam recusandae iusto libero laboriosam dignissimos ad
              enim obcaecati.
            </p>
            }
          </div>
        </div>
        <div className="sliderHead">Slider</div>
        <div>
          <ProfileSlider />
        </div>
      </div>
    </div>
  );
};

export default Home;
