import { configureStore } from "@reduxjs/toolkit";
import masterSlice from "./Master/MasterSlice";
import contactSharingSlice from "./ContactSharing/ContactSharingSlice";

const store = configureStore({
    reducer: {
        masterData: masterSlice.reducer,
        contactSharing: contactSharingSlice.reducer
    }
});

export default store;