import React, { useEffect, useState } from "react";
import RangeSelector from "./RangeSelector";
import { AiOutlineSearch } from "react-icons/ai";
import styles from "./MobileFilterPage.module.css";
import Clear from "../../assets/ClearFilterMobile.png";
import CloseFilter from "../../assets/CloseFilter.png";

const MobileFilterPage = ({
  showFilters,
  setShowFilters,
  searchData,
  selectedField,
  toggleFiltersButton,
  loading,
  filterArrayName,
  filterArray,
  toggleCheckbox,
  generateMobileRequest,
  toggleDropdown,
  setSearchData,
  gotra,
  resetFilterBug,
  gotraIndex,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [slideOut, setSlideOut] = useState(false);
  const [minValueError, setMinValueError] = useState(false);
  const [maxValueError, setMaxValueError] = useState(false);
  const [checkValidValue, setCheckValidValue] = useState(true);

  const applyFilters = () => {
    toggleFilterTab();
    generateMobileRequest();
  };

  const getCategoryCount = (category) => {
    let count = 0;
    if (
      searchData[category] &&
      searchData[category].inputType === "checkbox"
    ) {
      searchData[category].values?.forEach((value) => {
        if (value.checked) {
          count++;
        }
      });
    }

    return count;
  };

  const toggleFilterTab = () => {
    setSlideOut(true);
    setTimeout(() => {
      setShowFilters(!showFilters);
      setSlideOut(false);
    }, 500);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    setSearchInput("");
  }, [selectedField]);

  const newFilterArray =
    selectedField === "country" || selectedField === "language"
      ? filterArray.filter((item) =>
          item.value.toLowerCase().includes(searchInput.toLowerCase())
        )
      : filterArray;

  return showFilters ? (
    <div
      className={`${
        styles["filter-container"]
      } col-sm-12 d-block d-sm-none ${
        slideOut ? styles["slide-out"] : ""
      }`}
    >
      <div className={styles["filter-header"]}>
        <div
          className={styles["filter-header-clearall"]}
          onClick={resetFilterBug}
        >
          <img src={Clear} alt="Clear" />
          Clear All
        </div>
        <div className={styles["filter-header-filterhead"]}>Filters</div>
        <div className={styles["cancel"]}>
          <button onClick={toggleFilterTab}>
            <img src={CloseFilter} alt="Close" />
          </button>
        </div>
      </div>
      <div className={styles["filter-body"]}>
        <div className={styles["filter-body-left"]}>
          <ul>
            {Object.keys(searchData).map((field, i) => {
              if (searchData[field].inputType === "checkbox") {
                return (
                  <li
                    key={i}
                    className={
                      field.toString() === selectedField ? styles["active"] : ""
                    }
                    onClick={() => toggleFiltersButton(field, "advance")}
                    disabled={loading}
                  >
                    {searchData[field].label}
                    <span>
                      {getCategoryCount(field) !== 0
                        ? getCategoryCount(field)
                        : "-"}
                    </span>
                  </li>
                );
              }
              if (searchData[field].inputType === "dropdown") {
                return (
                  <li
                    key={i}
                    className={
                      field.toString() === selectedField ? styles["active"] : ""
                    }
                    onClick={() => toggleFiltersButton(field, "basic")}
                    disabled={loading}
                  >
                    {searchData[field].label}
                    {field !== "age" && field !== "height" && (
                      <span>
                        {getCategoryCount(field) !== 0
                          ? getCategoryCount(field)
                          : "-"}
                      </span>
                    )}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
        <div className={styles["filter-body-right"]}>
          {selectedField === "country" || selectedField === "language" ? (
            <div className={styles["search-icon-bar"]}>
              <AiOutlineSearch className={styles["search-icon"]} />
              <input
                type="text"
                value={searchInput}
                onChange={handleSearchInputChange}
                placeholder="Search..."
              />
            </div>
          ) : null}
          <p>Make Selections</p>
          {!loading && (
            <>
            {gotra && selectedField === "gotra" && gotraIndex ? (
              <div className={styles["self-gotra-checkbox-label"]}>
                <input
                  type="checkbox"
                  id="switch" 
                  onChange={() => toggleCheckbox(gotra)}
                  disabled={loading}
                  className={styles["gotra-check-input"]}
                  value={searchData[selectedField].values[gotraIndex]?.value}
                  checked={searchData[selectedField].values[gotraIndex]?.checked}
                />
                <label className={styles["form-check-label"]} htmlFor="switch">
                </label>
                <span>Exclude my gotra</span>
              </div>
            ) : (
              selectedField === "gotra" && (
                <span className={styles["no-gotra-label"]}>Please fill your gotra in the edit profile to access this feature.</span>
              )
            )}
            <ul>
              {selectedField === filterArrayName &&
              searchData[selectedField]?.inputType === "checkbox" ? (
                <>
                  {selectedField !== "gotra"
                    ? newFilterArray.map((selectedFields, i) => (
                        <li
                          key={i}
                          id={filterArrayName}
                          name={filterArrayName}
                          className={
                            selectedFields.checked
                              ? styles["selected-filter-value"]
                              : ""
                          }
                          onClick={() => toggleCheckbox(selectedFields.value)}
                          disabled={loading}
                          checked={selectedFields.checked}
                        >
                          {selectedFields.value === "0 - 0" ? "N/A" : selectedFields.value}
                        </li>
                      ))
                    : null}
                  </>
                ) : null}
                {searchData[selectedField]?.inputType === "dropdown" ? (
                  <RangeSelector
                    showFilters={showFilters}
                    setShowFilters={setShowFilters}
                    searchData={searchData}
                    resetFilterBug={resetFilterBug}
                    selectedField={selectedField}
                    toggleFiltersButton={toggleFiltersButton}
                    loading={loading}
                    filterArrayName={filterArrayName}
                    filterArray={filterArray}
                    toggleCheckbox={toggleCheckbox}
                    generateMobileRequest={generateMobileRequest}
                    toggleDropDown={toggleDropdown}
                    setSearchData={setSearchData}
                    setMaxValueError={setMaxValueError}
                    setMinValueError={setMinValueError}
                    minValueError={minValueError}
                    maxValueError={maxValueError}
                    checkValidValue={checkValidValue}
                    setCheckValidValue={setCheckValidValue}
                  />
                ) : null}
              </ul>
            </>
          )}
          {searchData[selectedField]?.inputType === "checkbox" ? (
            <p>You have made {getCategoryCount(selectedField)} selections</p>
          ) : null}
        </div>
      </div>
      <div className={styles["filter-apply"]}>
        <button
          disabled={!checkValidValue}
          onClick={applyFilters}
          className={`w-100 h-100 text-light ${
            checkValidValue ? styles["enabled"] : styles["disabled"]
          }`}
        >
          Apply
        </button>
      </div>
    </div>
  ) : null;
};

export default MobileFilterPage;
