import React, { useState, useEffect } from 'react';
import server from "../../../src/config/server.json";
import axios from "axios";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RxCross1 } from "react-icons/rx";
import { AiOutlineUser } from "react-icons/ai";
import { toast } from "react-toastify";

const FamilyDetails = ({ userDetails, setEditFamilyDetails, editFamilyDetails }) => {
    const minValue = 0;
    const maxValue = 10;
    const FAMILY_DETAILS_EDIT = server.api.FAMILY_DETAILS_EDIT;
    const masterData = useSelector(state => state.masterData);
    const [userId, setUserId] = useState(null);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();
    const [familyIncomeRangeFrom, setFamilyIncomeRangeFrom] = useState(0);
    const [familyIncomeRangeTo, setFamilyIncomeRangeTo] = useState(0);

    const [editFamilyData, setEditFamilyData] = useState({
        profileCreatedBy: "",
        fatherName: "",
        motherName: "",
        noOfBrotherUnmarried: 0,
        noOfBrotherMarried: 0,
        noOfSisterUnmarried: 0,
        noOfSisterMarried: 0,
        gotra: "",
        familyType: "",
        familyValue: "",
        familyStatus: "",
        mamaSurname: "",
        familyIncome: "",
        nativePlace: "",
    });

    const { profileCreatedBy, fatherName, motherName, noOfBrotherMarried, noOfBrotherUnmarried, noOfSisterMarried, noOfSisterUnmarried, gotra, familyType, familyStatus, familyValue, mamaSurname, familyIncome, nativePlace } = editFamilyData;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setEditFamilyData({ ...editFamilyData, [name]: value });
    };

    const setFamilyIncomeDetails = () => {
        const arr = familyIncome.split("").join("");
        let subStr = arr.split(/[to]/);
        const f = subStr[0].split("INR").join(" ");
        setFamilyIncomeRangeFrom(parseInt(f));
        setFamilyIncomeRangeTo(parseInt(subStr[2]));
    };


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            const config = {
                "Content-type": "application/json",
                token: token,
            };

            const body = {
                userId: userId,
                profileCreatedBy: profileCreatedBy,
                fatherName: fatherName,
                motherName: motherName,
                noOfBrotherUnmarried: noOfBrotherUnmarried,
                noOfBrotherMarried: noOfBrotherMarried,
                noOfSisterUnmarried: noOfSisterUnmarried,
                noOfSisterMarried: noOfSisterMarried,
                gotra: gotra,
                mamaSurname: mamaSurname,
                familyType: familyType,
                familyValue: familyValue,
                familyStatus: familyStatus,
                familyIncome: familyIncome,
                familyIncomeRangeFrom: familyIncomeRangeFrom ? familyIncomeRangeFrom : 0,
                familyIncomeRangeTo: familyIncomeRangeTo ? familyIncomeRangeTo : 0,
                nativePlace: nativePlace,
            }

            const response = await axios.post(`${server.url.production}${FAMILY_DETAILS_EDIT}`, body,
                { headers: config }
            );

            if (response.status === 201) {

                toast.success(response.data.message, {
                    autoClose: 3000,
                    position: "bottom-right",
                });
                setEditFamilyDetails(!editFamilyDetails);
            } else {
                toast.error("Something went wrong.", {
                    autoClose: 3000,
                    position: "bottom-right",
                });
            }
        } catch (error) {
            if (e.response && e.response.status !== 201) {
                toast.error(e.response.data.message, {
                    autoClose: 3000,
                    position: "bottom-right",
                });
            } else {
                toast.error("Something went wrong, please try again later.", {
                    autoClose: 3000,
                    position: "bottom-right",
                });
            }
        }
    }

    useEffect(() => {
        setFamilyIncomeDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familyIncome]);

    useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("user"));
        if (loggedUser) {
            setUserId(loggedUser.id);
            setToken(localStorage.getItem("token"));
        } else {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setEditFamilyData({
            profileCreatedBy: userDetails?.profileCreatedBy ? userDetails?.profileCreatedBy : "",
            fatherName: userDetails?.fatherName ? userDetails?.fatherName : "",
            motherName: userDetails?.motherName ? userDetails?.motherName : "",
            noOfBrotherMarried: userDetails?.noOfBrotherMarried ? userDetails?.noOfBrotherMarried : 0,
            noOfBrotherUnmarried: userDetails?.noOfBrotherUnmarried ? userDetails?.noOfBrotherUnmarried : 0,
            noOfSisterMarried: userDetails?.noOfSisterMarried ? userDetails?.noOfSisterMarried : 0,
            noOfSisterUnmarried: userDetails?.noOfSisterUnmarried ? userDetails?.noOfSisterUnmarried : 0,
            gotra: userDetails?.gotra ? userDetails?.gotra : "",
            mamaSurname: userDetails?.mamaSurname ? userDetails?.mamaSurname : "",
            familyType: userDetails?.familyType ? userDetails?.familyType : "",
            familyStatus: userDetails?.familyStatus ? userDetails?.familyStatus : "",
            familyValue: userDetails?.familyValue ? userDetails?.familyValue : "",
            familyIncome: userDetails?.familyIncome ? userDetails?.familyIncome : "",
            nativePlace: userDetails?.nativePlace ? userDetails?.nativePlace : "",
        })
    }, [userDetails]);

    return (
        <section>
            <div className="familyDetail-data">
                <div className="family-info">
                    <span>father name</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : fatherName
                                ? fatherName
                                : "NA"
                        }
                    </span>
                </div>
                <div className="family-info">
                    <span>mother name</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : motherName
                                ? motherName
                                : "NA"
                        }
                    </span>
                </div>
                <div className="family-info">
                    <span>gotra</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : gotra
                                ? gotra
                                : "NA"
                        }
                    </span>
                </div>
                <div className="family-info">
                    <span>mama surname</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : mamaSurname
                                ? mamaSurname
                                : "NA"
                        }
                    </span>
                </div>
                <div className="family-info">
                    <span>native place</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : nativePlace
                                ? nativePlace
                                : "NA"
                        }
                    </span>
                </div>
                <div className="family-info">
                    <span>family status</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : familyStatus
                                ? familyStatus
                                : "NA"
                        }
                    </span>
                </div>
                <div className="family-info">
                    <span>family income</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : familyIncome
                                ? familyIncome
                                : "NA"
                        }
                    </span>
                </div>
                <div className="family-info">
                    <span>family type</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : familyType
                                ? familyType
                                : "NA"
                        }
                    </span>
                </div>
            </div>
            {
                editFamilyDetails &&
                <div className='edits_family_details'>
                    <h4>
                        <span><RxCross1 onClick={() => setEditFamilyDetails(!editFamilyDetails)} />
                        </span><span>edit details</span>
                    </h4>
                    <h6>
                        <span><AiOutlineUser /></span>
                        <span>Family Details</span>
                    </h6>
                    <form onSubmit={handleOnSubmit}>
                        <div className="input-block">
                            <label htmlFor="profileCreatedBy">profile created by</label>
                            <select
                                name="profileCreatedBy"
                                id="profileCreatedBy"
                                value={profileCreatedBy}
                                onChange={handleOnChange}
                                required
                            >
                                <optgroup label="Profile Created By" />
                                <option>{OPTION_SELECT_EMPTY}</option>
                                {
                                    masterData.profileCreatedBy.map(elem => {
                                        return (
                                            <option key={elem._id}>{elem.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="fatherName">father name</label>
                            <input type="text" name='fatherName' id='fatherName' value={fatherName} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="motherName">mother name</label>
                            <input type="text" name='motherName' id='motherName' value={motherName} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <h6>Brother(s)</h6>
                            <div className="married-unmarried">
                                <div className="unmarried">
                                    <label htmlFor="unmarried">unmarried</label>
                                    <div className="btn-range">
                                        <button>{minValue}</button>
                                        <input type="range" name="noOfBrotherUnmarried" id="noOfBrotherUnmarried" min={minValue} max={maxValue} value={noOfBrotherUnmarried} onChange={handleOnChange} />
                                        <button>{maxValue}</button>
                                    </div>
                                    <span>Number of Unmarried Brother:- {noOfBrotherUnmarried}</span>
                                </div>
                                <div className="married">
                                    <label htmlFor="unmarried">married</label>
                                    <div className="btn-range">
                                        <button>{minValue}</button>
                                        <input type="range" name="noOfBrotherMarried" id="noOfBrotherMarried" min={minValue} max={maxValue} value={noOfBrotherMarried} onChange={handleOnChange} />
                                        <button>{maxValue}</button>
                                    </div>
                                    <span>Number of Married Brother:- {noOfBrotherMarried}</span>
                                </div>
                            </div>
                        </div>
                        <div className="input-block">
                            <h6>Sister(s)</h6>
                            <div className="married-unmarried">
                                <div className="unmarried">
                                    <label htmlFor="unmarried">unmarried</label>
                                    <div className="btn-range">
                                        <button>{minValue}</button>
                                        <input type="range" name="noOfSisterUnmarried" id="noOfSisterUnmarried" min={minValue} max={maxValue} value={noOfSisterUnmarried} onChange={handleOnChange} />
                                        <button>{maxValue}</button>
                                    </div>
                                    <span>Number of Unmarried Sister:- {noOfSisterUnmarried}</span>
                                </div>
                                <div className="married">
                                    <label htmlFor="unmarried">married</label>
                                    <div className="btn-range">
                                        <button>{minValue}</button>
                                        <input type="range" name="noOfSisterMarried" id="noOfSisterMarried" min={minValue} max={maxValue} value={noOfSisterMarried} onChange={handleOnChange} />
                                        <button>{maxValue}</button>
                                    </div>
                                    <span>Number of Married Sister:- {noOfSisterMarried}</span>
                                </div>
                            </div>
                        </div>
                        <div className="input-block">
                            <label htmlFor="gotra">gotra</label>
                            <input type="text" name='gotra' id='gotra' value={gotra} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="mamaSurname">mama surname</label>
                            <input type="text" name='mamaSurname' id='mamaSurname' value={mamaSurname} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="familyType">family type</label>
                            <select
                                // className={`form-select border rounded ${familyType ? "border-secondary" : "border-danger"
                                //     }`}
                                name="familyType"
                                id="familyType"
                                value={familyType} onChange={handleOnChange}
                                required
                            >
                                <optgroup label="Family Type" />
                                <option>{OPTION_SELECT_EMPTY}</option>
                                {
                                    masterData.familyType.map(elem => {
                                        return (
                                            <option key={elem._id}>{elem.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="familyValue">family value</label>
                            <select
                                // className="form-select border rounded border-secondary"
                                name="familyValue"
                                id="familyValue"
                                value={familyValue} onChange={handleOnChange}
                                required
                            >
                                <optgroup label="Family Value" />
                                <option>{OPTION_SELECT_EMPTY}</option>
                                {
                                    masterData.familyValues.map(elem => {
                                        return (
                                            <option key={elem._id}>{elem.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="familyStatus">family status</label>
                            <select
                                // className="form-select border rounded border-secondary"
                                name="familyStatus"
                                id="familyStatus"
                                value={familyStatus} onChange={handleOnChange}
                                required
                            >
                                <optgroup label="Family Status" />
                                <option>{OPTION_SELECT_EMPTY}</option>
                                {
                                    masterData.familyStatus.map(elem => {
                                        return (
                                            <option key={elem._id}>{elem.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="familyIncome">family income</label>
                            <select
                                // className="form-select border rounded border-secondary"
                                name="familyIncome"
                                id="familyIncome"
                                value={familyIncome} onChange={handleOnChange}
                                required
                            >
                                <optgroup label="Family Income" />.
                                <option value="none">{OPTION_SELECT_EMPTY}</option>
                                {
                                    masterData.familyIncome.slice().sort((a, b) => a.value - b.value).map(elem => {
                                        return (
                                            <option key={elem._id} value={elem.value}>{elem.value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="nativePlace">native place</label>
                            <input type="text" name='nativePlace' id='nativePlace' value={nativePlace} onChange={handleOnChange} />
                        </div>
                        <div className="input-block-btn">
                            <button type='submit'>save</button>
                        </div>
                    </form>
                </div>
            }
        </section>
    )
}

export default FamilyDetails