import { createAsyncThunk } from "@reduxjs/toolkit";
import server from "../../config/server.json";
import axios from "axios";



/* 
To fetch masterData
To use these components in other pages
Eg:-   dispatch(getOccupationData());
*/

export const getOccupationData = createAsyncThunk('masterData/getOccupationData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}occupation`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getCasteData = createAsyncThunk('masterData/getCasteData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}caste`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getGenderData = createAsyncThunk('masterData/getGenderData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}gender`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getFamilyTypeData = createAsyncThunk('masterData/getFamilyTypeData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}familyType`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getFamilyIncomeData = createAsyncThunk('masterData/getFamilyIncomeData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}family-income`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getBirthTimeData = createAsyncThunk('masterData/getBirthTimeData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}birth-time`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getFamilyStatusData = createAsyncThunk('masterData/getFamilyStatusData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}family-status`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getFamilyValuesData = createAsyncThunk('masterData/getFamilyValuesData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}familyValues`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getBloodGroupData = createAsyncThunk('masterData/getBloodGroupData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}blood-group`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getProfileCreatedByData = createAsyncThunk('masterData/getProfileCreatedByData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}profile-created-by`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getHeightData = createAsyncThunk('masterData/getHeightData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}height`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getAgeData = createAsyncThunk('masterData/getAgeData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}age`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getMotherTongueData = createAsyncThunk('masterData/getMotherTongueData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}mother-tongue`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getAnnualIncomeData = createAsyncThunk('masterData/getAnnualIncomeData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}annual-income`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getIncomeData = createAsyncThunk('masterData/getIncomeData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}income`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getOccupationCategoryData = createAsyncThunk('masterData/getOccupationCategoryData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}occupationcategory`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getDietPreferencesData = createAsyncThunk('masterData/getDietPreferencesData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}dietarypreferences`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWeightData = createAsyncThunk('masterData/getWeightData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}weight`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getEducationDegreeData = createAsyncThunk('masterData/getEducationDegreeData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}education-degree`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const geteducationCompletionYearData = createAsyncThunk('masterData/geteducationCompletionYearData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}education-completion-year`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getSpecializationData = createAsyncThunk('masterData/getSpecializationData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}specialization`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getMartialData = createAsyncThunk('masterData/getMartialData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}marital-status`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getHoroscopeData = createAsyncThunk('masterData/getHoroscopeData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}horoscope`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getLanguageData = createAsyncThunk('masterData/getLanguageData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}language`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getCountryData = createAsyncThunk('masterData/getCountryData', async (arg, { rejectWithValue }) => {
    const headers = {
    "Content-Type": "application/json",
    token: localStorage?.getItem("token"),
}
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITH_LOGIN}country`, { headers });
        return data;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});