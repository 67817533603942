import "./RangeSelector.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";

const RangeSelector = ({
  searchData,
  selectedField,
  toggleDropDown,
  setSearchData,
  setMaxValueError,
  setMinValueError,
  setCheckValidValue
}) => {
  const [maxHeightInFeet, setMaxHeightInFeet] = useState("");
  const [minHeightInFeet, setMinHeightInFeet] = useState("");
  let valueRange = [searchData[selectedField].minValue, searchData[selectedField].maxValue];
  let minValue = searchData[selectedField].values.minValueArray?.[1]?.value;
  let maxValue = searchData[selectedField].values.minValueArray?.slice?.(-1)?.[0]?.value;

  const handleSliderChange = (value) => {
    const [min, max] = value;
    if (min < max) {
      if(max !== searchData[selectedField].maxValue){
        toggleDropDown("max", selectedField, max);
      }
      if(min !== searchData[selectedField].minValue){
        toggleDropDown("min", selectedField, min);
      }
      if (selectedField === "height") {
        let maxFeet = Math.floor(max / 30.48);
        let maxInches = Math.round((max % 30.48) / 2.54);
        let minFeet = Math.floor(min / 30.48);
        let minInches = Math.round((min % 30.48) / 2.54);
        if (maxInches === 12) {
          maxFeet++;
          maxInches = 0;
        }
        if (minInches === 12) {
          minFeet++;
          minInches = 0;
        }
        setMaxHeightInFeet(`${maxFeet} ft ${maxInches} in`);
        setMinHeightInFeet(`${minFeet} ft ${minInches} in`);
      }
      let newFilterObject = { ...searchData };
      newFilterObject[selectedField].minValue = min;
      newFilterObject[selectedField].maxValue = max;
      setSearchData(newFilterObject);      
      setMaxValueError(false);
      setMinValueError(false);
    }
  };

 

  // const handleInputChange = (inputType, value) => {
  //   let newFilterObject = { ...searchData };
  //   if (inputType === "max") {
  //       newFilterObject[selectedField].maxValue = value;
  //       toggleDropDown("max", selectedField, value);
  //   } else if (inputType === "min") {
  //     newFilterObject[selectedField].minValue = value;
  //     toggleDropDown("min", selectedField, value);
  //   }
  //   if (selectedField === "height") {
  //     const cmValue = parseFloat(value);
  //     const feet = Math.floor(cmValue / 30.48);
  //     const inches = Math.round((cmValue % 30.48) / 2.54);

  //     if (inputType === "max") {
  //       setMaxHeightInFeet(`${feet} ft ${inches} in`);
  //     } else if (inputType === "min") {
  //       setMinHeightInFeet(`${feet} ft ${inches} in`);
  //     }
  //   }
  //   setSearchData(newFilterObject);
  //   toggleJsonValidation();
  // };
  const handleInputChange = (inputType, value, unitType) => {

    let newFilterObject = { ...searchData };
  
    if (selectedField === "height") {
      if (inputType === "max") {
        if (unitType === "ft") {
          const feetValue = parseFloat(value)||0;
          const inchesValue = parseFloat(newFilterObject[selectedField].maxValue) % 12;
          const cmValue = feetValue * 30.48 + inchesValue * 2.54;
          newFilterObject[selectedField].maxValue = cmValue;
        } else if (unitType === "in") {
          const inchesValue = parseFloat(value) || 0;
          const feetValue = Math.floor(newFilterObject[selectedField].maxValue / 30.48);
          const cmValue = feetValue * 30.48 + inchesValue * 2.54;
          newFilterObject[selectedField].maxValue = cmValue;
        }
        toggleDropDown("max", selectedField, Math.round( newFilterObject[selectedField].maxValue));
      } else if (inputType === "min") {
        if (unitType === "ft") {
          const feetValue = parseFloat(value) || 0;
          const inchesValue = parseFloat(newFilterObject[selectedField].minValue) % 12;
          const cmValue = feetValue * 30.48 + inchesValue * 2.54;
          newFilterObject[selectedField].minValue = cmValue;
        } else if (unitType === "in") {
          const inchesValue = parseFloat(value) || 0;
          const feetValue = Math.floor(newFilterObject[selectedField].minValue / 30.48);
          const cmValue = feetValue * 30.48 + inchesValue * 2.54;
          newFilterObject[selectedField].minValue = cmValue;
        }
        toggleDropDown("min", selectedField,Math.round( newFilterObject[selectedField].minValue));
      }
  
      const maxFeet = Math.floor(newFilterObject[selectedField].maxValue / 30.48);
      const maxInches = Math.round((newFilterObject[selectedField].maxValue % 30.48) / 2.54);
      const minFeet = Math.floor(newFilterObject[selectedField].minValue / 30.48);
      const minInches = Math.round((newFilterObject[selectedField].minValue % 30.48) / 2.54);
      setMaxHeightInFeet(`${maxFeet} ft ${maxInches} in`);
      setMinHeightInFeet(`${minFeet} ft ${minInches} in`);
    } else {
      if (inputType === "max") {
        newFilterObject[selectedField].maxValue = value;
        toggleDropDown("max", selectedField, value);
      } else if (inputType === "min") {
        newFilterObject[selectedField].minValue = value;
        toggleDropDown("min", selectedField, value);
      }
    }
  
    setSearchData(newFilterObject);
    toggleJsonValidation();
  };
  
  
  const isValueValid = (value) => { 
    return value !== "" && (value < minValue || value > maxValue);
  };

  const toggleJsonValidation = () => {
    let newFilterObject = { ...searchData };
    if(searchData[selectedField].minValue || searchData[selectedField].maxValue){
      if(searchData[selectedField].minValue < minValue || searchData[selectedField].minValue > maxValue){
        newFilterObject[selectedField].isValid = false;
      }
      else if(searchData[selectedField].maxValue > maxValue || searchData[selectedField].maxValue < minValue){
        newFilterObject[selectedField].isValid = false;
      }
      else{
        newFilterObject[selectedField].isValid = true;
      }
    }
    setSearchData(newFilterObject);
  }

  const handleValidJson = () => {
    if(searchData[selectedField].isValid){
      setCheckValidValue(true);
    }
    else{
      setCheckValidValue(false);
    }
  }

  useEffect(() => {
    if(selectedField === "height"){
      setMaxHeightInFeet(`${Math.floor(searchData[selectedField].maxValue/30.48)} ft ${Math.floor((searchData[selectedField].maxValue/2.54)%12)} in`);
      setMinHeightInFeet(`${Math.floor(searchData[selectedField].minValue/30.48)} ft ${Math.floor((searchData[selectedField].minValue/2.54)%12)} in`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedField])

  useEffect(() => {
    handleValidJson();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchData])
  
  return (
    <div className="filter-range-slider">
      <div className="slider-container-outer">
        <div className="slider-container">
          <Slider
            min={minValue}
            max={maxValue}
            value={valueRange}
            onChange={handleSliderChange}
            range
            vertical
          />
        </div>
      </div>

      <div className="range-input-container">
        {selectedField === "height" ? (
          <div className="range-values-maxheight">
            <span className="value-label-maxheight">
              Max {searchData[selectedField].label}:{" "}
              {selectedField === "height" && maxHeightInFeet}
            </span>
            <span className="value-label-input-maxheight">
              <input
                // className={`${maxValueError && isValueValid(searchData[selectedField].maxValue) ? "input-error" : ""}`}
                value={Math.floor(searchData[selectedField].maxValue/30.48)}
                onChange={(e) => handleInputChange("max", e.target.value, "ft")}
                type="number"
              />
              {" ft"}
              <input
                // className={`${maxValueError && isValueValid(searchData[selectedField].maxValue) ? "input-error" : ""}`}
                value={Math.floor((searchData[selectedField].maxValue/2.54)%12)}
                onChange={(e) => handleInputChange("max", e.target.value, "in")}
                type="number"
              />
              {" in"}
            </span>
            {searchData[selectedField].maxValue &&
              isValueValid(searchData[selectedField].maxValue) && (
                <div className="error-field text-danger">{`Value must be between ${minValue} ${searchData[selectedField].units} - ${maxValue} ${searchData[selectedField].units}`}</div>
              )}
          </div>
        ) : (
          <div className="range-values">
            <span className="value-label">
              Max {searchData[selectedField].label}:{" "}
              {selectedField === "height" && maxHeightInFeet}
            </span>
            <span className="value-label-input">
              <input
                // className={`${maxValueError && isValueValid(searchData[selectedField].maxValue) ? "input-error" : ""}`}
                value={searchData[selectedField].maxValue}
                onChange={(e) => handleInputChange("max", e.target.value)}
                type="number"
              />
              {searchData[selectedField].units}
          

            </span>
            {searchData[selectedField].maxValue &&
              isValueValid(searchData[selectedField].maxValue) && (
                <div className="error-field text-danger">{`Value must be between ${minValue} ${searchData[selectedField].units} - ${maxValue} ${searchData[selectedField].units}`}</div>
              )}
          </div>
        )}
          {selectedField === "height" ? (
          <div className="range-values-minheight">
            <span className="value-label-minheight">
              Max {searchData[selectedField].label}:{" "}
              {selectedField === "height" && minHeightInFeet}
            </span>
            <span className="value-label-input-minheight">
              <input
                // className={`${maxValueError && isValueValid(searchData[selectedField].maxValue) ? "input-error" : ""}`}
                value={Math.floor(searchData[selectedField].minValue/30.48)}
                onChange={(e) => handleInputChange("min", e.target.value,"ft")}
                type="number"
              />
              {" ft"}
              <input
                // className={`${maxValueError && isValueValid(searchData[selectedField].maxValue) ? "input-error" : ""}`}
                value={Math.floor((searchData[selectedField].minValue/2.54)%12)}
                onChange={(e) => handleInputChange("min", e.target.value,"in")}
                type="number"
              />
              {" in"}
            </span>
            {searchData[selectedField].minValue &&
              isValueValid(searchData[selectedField].minValue) && (
                <div className="error-field text-danger">{`Value must be between ${minValue} ${searchData[selectedField].units} - ${maxValue} ${searchData[selectedField].units}`}</div>
              )}
          </div>

 ) : (
        <div className="range-values">
          
          <span className="value-label">
            Min {searchData[selectedField].label}:{" "}
            {selectedField === "height" && minHeightInFeet}
          </span>
          <span className="value-label-input">
            <input
              // className={`${minValueError && isValueValid(searchData[selectedField].minValue) ? "input-error" : ""}`}
              value={searchData[selectedField].minValue}
              onChange={(e) => handleInputChange("min", e.target.value)}
              type="number"
            />
            {searchData[selectedField].units}
          </span>
          {searchData[selectedField].minValue &&
            isValueValid(searchData[selectedField].minValue) && (
              <div className="error-field text-danger">{`Value must be between ${minValue} ${searchData[selectedField].units} - ${maxValue} ${searchData[selectedField].units}`}</div>
            )}
        </div>
        )}
      </div>
    </div>
  );
};

export default RangeSelector;
