export const Profiles = [
    {
      name: "user1",
      age: 26,
      height: "5'2ft",
      zodiac: "Vigro",
      language: "Marathi",
      occupation: "Developer",
      number: 9000000001,
      email: "example1@test.com",
      address: "Mumbai",
    },
    {
      name: "user2",
      age: 26,
      height: "5'2ft",
      zodiac: "Cancer",
      language: "Hindi",
      occupation: "Manager",
      number: 9000000002,
      email: "example2@test.com",
      address: "Delhi",
    },
    {
      name: "user3",
      age: 26,
      height: "5'2ft",
      zodiac: "Aries",
      language: "Kannada",
      occupation: "Analyst",
      number: 9000000003,
      email: "example3@test.com",
      address: "Banglore",
    },
    {
      name: "user4",
      age: 26,
      height: "5'2ft",
      zodiac: "Gemini",
      language: "Gujrati",
      occupation: "Engineer",
      number: 9000000004,
      email: "example4@test.com",
      address: "Gujrat",
    },
    {
      name: "user5",
      age: 26,
      height: "5'2ft",
      zodiac: "Arias",
      language: "Punjabi",
      occupation: "Developer",
      number: 9000000005,
      email: "example5@test.com",
      address: "Punjab",
    },
  ];
  