import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  createRequest,
  fetchRequestStatus,
} from "../../Redux/ContactSharing/ApiCalls";
import { useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import "./ProfileListMobile.css";
import HorizontalSwiper from "./HorizontalSwiper";
import FilterImage from "../../assets/ProfileMobileImages/filter.png";
import BellInactive from "../../assets/ProfileMobileImages/Bell-Inactive.png";
import SearchIcon from "../../assets/ProfileMobileImages/search.png";
import HeartInactive from "../../assets/ProfileMobileImages/Heart-Inactive.png";
import HeartActive from "../../assets/ProfileMobileImages/Heart-Active.png";
import HeartShortlisted from "../../assets/ProfileMobileImages/HeartActive.png";
import HeartInactiveNew from "../../assets/ProfileMobileImages/Heart-InactiveNew.png";
import ProfileActive from "../../assets/ProfileMobileImages/Profile-Active.png";
import ProfileInactive from "../../assets/ProfileMobileImages/Profile-Inactive.png";
import CardActive from "../../assets/ProfileMobileImages/Card-Active.png";
import CardInactive from "../../assets/ProfileMobileImages/Card-Inactive.png";
import MessageBtn from "../../assets/ProfileMobileImages/Message-Inactive.png";
import DetailBtn from "../../assets/ProfileMobileImages/Profile-Details-Active.png";
import ShareBtn from "../../assets/ProfileMobileImages/Contact request CTA.png";
import LocationIcon from "../../assets/ProfileMobileImages/location-icon.png";
import CloseModalImage from "../../assets/ProfileMobileImages/close-model.png";
import VerfiedImage from "../../assets/ProfileMobileImages/verified.png";
import ShowingHalfDetails from "../../components/ShowingHalfDetails/ShowingHalfDetails";
import MessageModal from "../../components/Modal/MessageModal";
import ShortListProfile from "../ShortlistedProfileMob/ShortListProfile";
import NotificationPageMobile from "../Notification/NotificationPageMobile";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import server from "../../config/server.json";
import ProfilePreviewMobile from "../ProfilePreviewMobile/ProfilePreviewMobile";
import images from "../../assets/assets";
import { Link } from "react-router-dom";

function ProfileListMobile({
  data,
  showFilters,
  setShowFilters,
  setResetFilter,
  apprStatus,
  resetFilterBug,
  loading,
  searchResultsDiv,
  getMoreResults,
  pageNumber,
  totalCount
}) {
  const swiperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUserName] = useState("");
  const [activeButton, setActiveButton] = useState("card");
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [count, setCount] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [share, setShare] = useState("");
  const [currentCardId, setCurrentCardId] = useState(null);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [halfProfileData, setHalfProfileData] = useState({});
  const [shortlisted, setShortlisted] = useState([]);
  const [searchBarExpand, setSearchBarExpand] = useState(false);
  const [searchBarCurrentVal, setSearchBarCurrentVal] = useState('');
  const [listOfSearchByName, setListOfSearchByName] = useState([]);
  const [timer, setTimer] = useState(null);
  const [searchByNameLoader, setSearchByNameLoader] = useState(false);

  // Ref's
  const sliderRef = useRef(null);
  const searchByNameRef = useRef(null);

  const dispatch = useDispatch();
  let approvalStatus;

  useEffect(() => {
    if (data && data.length === 0) {
      setNoResultsFound(true);
    }
    else {
      setNoResultsFound(false);
    }
  }, [data]);

  const openModal = useCallback((userName, id) => {
    setShare(id);
    setIsOpen(true);
    setUserName(userName);
  }, []);

  const openMessageModal = (id) => {
    setMessageModal(true);
    setCurrentCardId(id);
  };

  const closeMessageModal = () => {
    setMessageModal(false);
  };

  const sendRequest = async () => {
    try {
      const body = {
        request_to: share,
        approval_status: 0,
      };
      approvalStatus = apprStatus.status.filter((x) => x.request_to === share);
      if (approvalStatus[0]) {
        toast.info("Already Sent!", {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.success("Sent!", {
          autoClose: 3000,
          position: "bottom-right",
        });
        dispatch(createRequest(body));
        dispatch(fetchRequestStatus());
      }
      setIsOpen(false);
    } catch (error) {
      toast.error(error.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const getImageSrc = (buttonName) => {
    if (buttonName === "heart") {
      return activeButton === "heart" ? HeartActive : HeartInactiveNew;
    } else if (buttonName === "card") {
      return activeButton === "card" ? CardActive : CardInactive;
    } else if (buttonName === "profile") {
      return activeButton === "profile" ? ProfileActive : ProfileInactive;
    } else if (buttonName === "bell") {
      return activeButton === "bell";
    }
  };

  const handleNotificationCount = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_PENDING_REQUESTS_COUNT}`,
        { headers }
      );
      if (response.status === 200) {
        setCount(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMessageCount = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_MESSAGE_DATA}`,
        { headers }
      );
      if (response.status === 200) {
        var count = 0;
        response.data.map((elem) => {
          if (elem.is_read === 0 ) {
            count = count + 1;
            }
            return null;
        })
        setMessageCount(count);
      }
    } catch (error) {
      console.log(error);
    }
  }

  let token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      handleNotificationCount();
      handleMessageCount();
    }
    // eslint-disable-next-line
  }, [activeButton, token]);

  const fetchShortlistData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.FETCH_SHORTLIST_PROFILE}`,
        {
          headers: headers,
        }
      );
      if (response?.data) {
        const { requestId } = response?.data;
        setShortlisted(requestId);
      }
    } catch (error) {
      toast.error(error.response?.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (activeButton === "card" && (data && data.length > 0)) {
      fetchShortlistData();
    }
  }, [data, activeButton]);

  const postShorlist = async (id) => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const body = { requestId: id }
      const response = await axios.post(
        `${server.url.production}${server.api.POST_SHORTLIST_PROFILE}`,
        body,
        { headers }
      );
      if (response.status === 201) {
        toast.success(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
        setShortlisted((prevShortlisted) => [...prevShortlisted, id]);
      } else if (response.status === 208) {
        toast.error(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error("There was some problem processing the request. Please try again later.", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  }

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;
    const handleSlideChange = () => {
      if (swiperInstance.isEnd && data.length < totalCount) {
        getMoreResults()
      }
    };
    swiperInstance?.on("slideChange", handleSlideChange);
    return () => {
      swiperInstance?.off("slideChange", handleSlideChange);
    };
    // eslint-disable-next-line
  });

  const limit = 5;
  const ProfileMobileSwiper = useMemo(() => {
    return (
      <div className="swipper-slide">
        <Swiper
          direction="vertical"
          className="profile-slider"
          slidesPerView={1}
          ref={swiperRef}
          initialSlide={((pageNumber - 1) * limit) - 1}
        >
          {data && data.length > 0 ? (
            <>
              {data?.map((data, index) => {
                const { _id, first_name, last_name } = data;
                const { age, profileImage, city, country } = data.userDetails;
                const isActiveSlide = index;
                const isShortlisted = shortlisted.includes(_id);

                return (
                  <SwiperSlide key={_id}>
                    <div className={`user-profile ${isActiveSlide ? "active" : ""}`}>
                      <div className="profile-info">
                        <div className="btn-section">
                          <button
                            className={`message-btn`}
                            name="message"
                            onClick={() => openMessageModal(_id)}
                          >
                            <img src={MessageBtn} alt="" />
                          </button>
                          <button
                            onClick={() => {
                              setIsBottomSheetOpen(true);
                              setHalfProfileData(data);
                            }}
                            className={`detail-btn `}
                            name="details"
                          >
                            <img src={DetailBtn} alt="" />
                          </button>
                          <button
                            className={`share-btn `}
                            name="share"
                            onClick={() =>
                              openModal(`${first_name} ${last_name}`, _id)
                            }
                          >
                            <img src={ShareBtn} alt="" />
                          </button>
                        </div>
                        <h1 className="user-name">
                          {`${first_name} ${last_name}`}, {age ? age : "N/A"}
                          <span className="verfied-icon">
                            <img src={VerfiedImage} alt="Verfy-logo" />
                          </span>
                        </h1>
                        <p className="user-location">
                          <span className="location-icon">
                            <img src={LocationIcon} alt="" />
                          </span>
                          {city ? city : "N/A"},{" "}
                          {country ? country : "N/A"}
                        </p>
                        <span className="heart-icon">
                          {isShortlisted ? (
                            <img
                              src={HeartShortlisted}
                              alt="shortlisted icon"
                              onClick={() => postShorlist(_id)}
                            />
                          ) : (
                            <img
                              src={HeartInactive}
                              alt="shortlisted icon"
                              onClick={() => postShorlist(_id)}
                            />
                          )}
                        </span>
                      </div>
                      <div className="image-slider">
                        <HorizontalSwiper profileImage={profileImage} id={_id} />
                        {/* The horizontal slider is a horizontal image slider of multiple images of the user */}
                      </div>
                    </div >
                  </SwiperSlide >
                );
              })}
            </>
          ) : null}
        </Swiper >
      </div >
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    openModal,
    messageModal,
    isBottomSheetOpen,
    shortlisted
  ]);

  const closeModal = useCallback((e) => {
    if (
      e.target.name === "modal-closed" ||
      e.target.name === "request-cancel" ||
      e.target.name === "request-send"
    ) {
      setIsOpen(false);
    }
  }, []);

  const toggleFilterTab = () => {
    setShowFilters(!showFilters);
  };

  /* START searchByName functionalitie's */
  // Will get trigger only when searchBar expand or shrink
  useEffect(() => {
    sliderRef.current.style.display = !searchBarExpand ? "block" : "none";
    searchByNameRef.current.style.display = searchBarExpand ? "block" : "none";
  }, [searchBarExpand]);

  const fetchSearchByName = async (queryName) => {
    try {
      setSearchByNameLoader(true);

      // extracting gender val from URL
      const gender = new URLSearchParams(window.location.search).get("gender");
      const headers = {
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.SEARCH_BY_NAME}?name=${queryName}&gender=${gender}`,
        {
          headers: headers,
        }
      );
      setListOfSearchByName(response?.data);
      setSearchByNameLoader(false);
    } catch (error) {
      toast.error(error.response?.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  // Use useEffect to start/reset the timer whenever the searchBarCurrentVal changes
  useEffect(() => {
    // Clear the previous timer (if any) when the input value changes
    if (timer) {
      clearTimeout(timer);
    }

    // .trim remove whitespace
    if (searchBarCurrentVal.trim() === '') {
      return;
    }

    // Set a new timer with 500ms (0.5 second) delay
    setTimer(setTimeout(() => {
      fetchSearchByName(searchBarCurrentVal)
    }, 500));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBarCurrentVal]);

  const ListOfSearchedName = useMemo(() => {
    return (
      <div className="searchByNameContainer">
        {
          listOfSearchByName.map((user) => (
            <Link key={user._id} to={`/profile/view/${user._id}`}>
              <div className="searchByNameProfileWrapper">
                <div className="searchByNameProfileDetails">
                  <img className="searchByNameProfileImg" src={user.userDetails.profileImage || images?.avata_placeholder} alt={user.first_name} />
                  <div className="searchByNameProfileName">
                    {user.first_name} {user.last_name}
                  </div>
                </div>
                <div className="searchByNameProfileArrow">
                  {images?.arrowRightSvg}
                </div>
              </div>
            </Link>
          ))
        }
      </div>
    )
  }, [listOfSearchByName]);
  /*  END searchByName functionalitie's */

  return (
    <>
      {activeButton === "heart" && <ShortListProfile data={data} />}

      {activeButton === "card" && (
        <>
          <header className="header">
            {
              searchBarExpand &&
              <button
                className="searchBarArrowLeft"
                onClick={() => {
                  setSearchBarExpand(false)
                  setSearchBarCurrentVal("");
                  setListOfSearchByName([]);
                }}
              >
                {images.arrowLeftSvg}
              </button>
            }
            <div className={`search-bar ${searchBarExpand ? "expanded" : ""}`}>
              <button
                className="search-btn"
                alt="Search Icon"
              >
                <img src={SearchIcon} alt="" />
              </button>
              <input
                type="text"
                placeholder="Search Name..."
                className={`input-bar ${searchBarExpand ? "expanded" : ""}`}
                value={searchBarCurrentVal}
                onClick={() => setSearchBarExpand(true)}
                onChange={(e) => setSearchBarCurrentVal(e.target.value)}
              />
              {
                searchBarExpand &&
                <button
                  className="searchBarCross"
                  onClick={(e) => {
                    setSearchBarCurrentVal("");
                    setListOfSearchByName([]);
                  }}
                  disabled={searchBarCurrentVal === ""}
                >
                  {images.crossSvg}
                </button>
              }
            </div>
            {!searchBarExpand && (
              <>
                <button
                  className="filter-btn"
                  alt="Filter-Button"
                  onClick={toggleFilterTab}
                >
                  <img src={FilterImage} alt="" />
                </button>
                <button
                  className="bell-btn"
                  alt="Bell-Button"
                  onClick={() => handleButtonClick("bell")}
                  name="bell"
                >
                  <img src={BellInactive} alt="" />
                  {count > 0 || messageCount > 0 ? <span className="bell-counter">{count + messageCount}</span> : null}
                </button>
              </>
            )}
          </header>

          {noResultsFound ? (
            <div className="no-results-mobile">
              <h1 className="text-center">"No Results Found"</h1>
              <div className="d-flex">
                <button className="mx-2" onClick={() => setShowFilters(true)}>
                  Open Filters
                </button>
                <button className="mx-2" onClick={() => { resetFilterBug(); setResetFilter(true) }}>
                  Reset Filters
                </button>
              </div>
              {loading ? <h3>fetching results, please wait!</h3> : null}
            </div>
          ) : (
            <>
              <div className="main-swiper-container" ref={sliderRef}>
                {ProfileMobileSwiper}
                <div className="background-slider-1"></div>
                <div className="background-slider-2"></div>
              </div>
              <div ref={searchByNameRef}>
                {
                  searchByNameLoader ? (
                    <div>
                      {images.magnifySvgAnimation}
                    </div>
                  ) : (
                    <>
                      {
                        searchBarCurrentVal.length >= 1 && listOfSearchByName.length === 0
                          ? <div className="text-center fs-6 py-4 font-weight-bold text-muted" >
                            User Not Available*
                          </div>
                          : <div>
                            {ListOfSearchedName}
                          </div>
                      }
                    </>
                  )
                }
              </div>
            </>
          )}
        </>
      )}

      {activeButton === "profile" && <ProfilePreviewMobile setActiveButton={setActiveButton} />}

      {activeButton === "bell" && (
        <NotificationPageMobile setActiveButton={setActiveButton} />
      )}

      <div className="lower-section">
        <button onClick={() => handleButtonClick("heart")} name="heart">
          <img
            src={getImageSrc("heart")}
            alt=""
            className={
              activeButton === "heart" ? "heart-active" : "heart-inactive"
            }
          />
        </button>
        <button onClick={() => handleButtonClick("card")} name="card">
          <img
            src={getImageSrc("card")}
            alt=""
            className={
              activeButton === "card" ? "card-active" : "card-inactive"
            }
          />
        </button>
        <button onClick={() => handleButtonClick("profile")} name="profile">
          <img
            src={getImageSrc("profile")}
            alt=""
            className={
              activeButton === "profile" ? "profile-active" : "profile-inactive"
            }
          />
        </button>
      </div>

      {messageModal ? (
        <MessageModal
          isOpen={messageModal}
          setIsOpen={openMessageModal}
          onClose={closeMessageModal}
          currentCardId={currentCardId}
        />
      ) : null}

      <ToastContainer />

      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <button className="close-modal-btn">
                <img
                  src={CloseModalImage}
                  alt="modal-close-btn"
                  onClick={closeModal}
                  name="modal-closed"
                />
              </button>
            </div>
            <div className="modal-info">
              <div className="modal-text">
                <img src={ShareBtn} alt="" />
                <p>Send Contact request to {username}</p>
              </div>
              <button
                className="cancel-request"
                onClick={closeModal}
                name="request-cancel"
              >
                Cancel
              </button>
              <button
                className="send-request"
                onClick={sendRequest}
                name="request-send"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}

      <ShowingHalfDetails
        data={halfProfileData}
        isBottomSheetOpen={isBottomSheetOpen}
        setIsBottomSheetOpen={setIsBottomSheetOpen}
      />
    </>
  );
}

export default ProfileListMobile;