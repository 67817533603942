import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import server from "../../config/server.json";
import axios from "axios";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { GoVerified } from "react-icons/go";
import ViewUserProfile from "../../assets/user1.jpeg";
import { toast } from "react-toastify";
import "./ProfileView.css";
import { useDispatch, useSelector } from 'react-redux';
import { createRequest, fetchRequestStatus } from '../../Redux/ContactSharing/ApiCalls';
import { getStatusSelector } from '../../Redux/ContactSharing/ContactSharingSlice';
import ProfileViewMob from './ProfileViewMob';

const ProfileView = () => {
    const [token, setToken] = useState(null);
    const [loggingUser, setLoggingUser] = useState(null); // eslint-disable-line
    const navigate = useNavigate();
    const { id } = useParams();
    const [profileViewDetails, setProfileViewDetails] = useState(null);
    const [backToTop, setBackToTop] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [userID, setUserID] = useState(null);
    const [partnerPreference, setPartnerPreference] = useState(null);
    const status = useSelector(getStatusSelector);

    const approvalStatus = status.status.filter(
        (x) => x.request_to === id
    );

    const dispatch = useDispatch();

    const commaSeperated = (index, length) => index === length - 1 ? "" : ", ";

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const phoneNumber = () => {
        const num = profileViewDetails === null
            ? ""
            : profileViewDetails.phone_number
        const last4Num = num.toString().substr(-4)
        return last4Num;
    }

    const emailAddres = () => {
        const mail_id = profileViewDetails === null
            ? ""
            : profileViewDetails.email_id
        const split_1 = mail_id.split('@');
        const split_2 = split_1[1]
        return split_2;
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 300) {
                setBackToTop(true);
            } else {
                setBackToTop(false)
            }
        })
    }, [backToTop]);

    useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("user"));
        if (loggedUser) {
            setToken(localStorage.getItem("token"));
            setUserID(loggedUser.id)
            setLoggingUser(loggedUser);
        } else {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        if (token) {
            const getProfileView = async () => {
                const api = `${server.url.production}${server.api.GET_USER_DATA}/${id}`
                const headers = {
                    "Content-Type": "application/json",
                    token: token,
                };
                const response = await axios.get(api, { headers });
                if (response.status === 200) {
                    let profileData = response.data;
                    setProfileViewDetails(profileData);
                    setProfilePhoto(profileData.userDetails.profileImage)
                } else {
                    toast.error("Something Went Wrong, Please Try Again Later", {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                }
            }
            getProfileView();
        }
    }, [token, id]);

    const sendContactRequest = () => {
        const body = {
            request_to: id,
            approval_status: 0,
        };
        dispatch(createRequest(body))
    }

    useEffect(() => {
        dispatch(fetchRequestStatus())
    }, [dispatch, status.isLoading])

    useEffect(() => {
        const fetchPreferences = async () => {
            try {
              const headers = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
              };
              const response = await axios.get(
                `${server.url.production}${server.api.FETCH_PREFERENCES}`,
                { headers }
              );
              setPartnerPreference(response.data);
            } catch (error) {
                toast.error(error.response.data.message, {
                  autoClose: 3000,
                  position: "bottom-right",
                });
              }
            };
            fetchPreferences();
    }, [])

  
    return (
        <>
            <div className="view-profile d-none d-sm-block">
                <div className="page-heading">
                    <div className="wrapper">
                        <h3 className="page-heading-text">view profile details</h3>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="view-section">
                        <div>
                            <div className="view-image">
                                <img src={profilePhoto ? profilePhoto : ViewUserProfile} alt="View User Name" />
                            </div>
                            <div className="view-details">
                                <h3>
                                    {
                                        profileViewDetails === null
                                            ? ""
                                            : profileViewDetails.first_name ? profileViewDetails.first_name : "virat"
                                    }
                                    {" "}
                                    {
                                        profileViewDetails === null
                                            ? ""
                                            : profileViewDetails.last_name ? profileViewDetails.last_name : "johnson"
                                    }
                                </h3>
                                <div>
                                    <span>
                                        <span>Birth Date :
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : profileViewDetails.userDetails.dob ? profileViewDetails.userDetails.dob : "XX-XX-XXXX"
                                            }
                                        </span>
                                        {
                                            profileViewDetails === null
                                                ? ""
                                                : profileViewDetails.userDetails.height
                                                    ? <span>, {profileViewDetails.userDetails.height}cm,</span>
                                                    : ""
                                        }
                                        {
                                            profileViewDetails === null
                                                ? ""
                                                : profileViewDetails.userDetails.horoscope
                                                    ? <span> {profileViewDetails.userDetails.horoscope}</span>
                                                    : ""
                                        }
                                    </span>
                                    {
                                        profileViewDetails === null
                                            ? ""
                                            : profileViewDetails.userDetails.marital
                                                ? <span>{profileViewDetails.userDetails.marital}</span>
                                                : ""
                                    }

                                    {
                                        profileViewDetails === null
                                            ? ""
                                            : profileViewDetails.userDetails.language
                                                ? profileViewDetails.userDetails.language.length > 0
                                                    ?
                                                    profileViewDetails.userDetails.language.map((elem, index) => (
                                                        <span key={index}>
                                                            {elem}
                                                            {commaSeperated(index, profileViewDetails.userDetails.language.length)}
                                                        </span>
                                                    ))
                                                    : ""
                                                : ""
                                    }
                                    {
                                        profileViewDetails === null
                                            ? ""
                                            : profileViewDetails.userDetails.nativePlace
                                                ? <span>lives in {profileViewDetails.userDetails.nativePlace}, {profileViewDetails.userDetails.country}</span>
                                                : ""

                                    }
                                    {
                                        profileViewDetails === null
                                            ? ""
                                            : profileViewDetails.userDetails.religion
                                                ? <span>{profileViewDetails.userDetails.religion}</span>
                                                : ""
                                    }
                                    {
                                        profileViewDetails === null
                                            ? ""
                                            : profileViewDetails.userDetails.companyName
                                                ? <span>working in {profileViewDetails.userDetails.companyName}</span>
                                                : ""
                                    }
                                </div>
                                <p> sent you an invitation on 26 dec 2022.</p>
                            </div>
                            <div className={id === userID ? "invitation-closed" : "invitation-active"}>
                                {approvalStatus[0]?.approval_status === 0 ? <button>Request Sent!</button> : <button onClick={sendContactRequest} >Send Request</button>}
                            </div>
                        </div>
                        <div className="detailed-profile">
                            <div className="profile-verified">
                                <div className="verified-logo">
                                    <h4>verifications <span><GoVerified /></span></h4>
                                    <p>verified with government iD</p>
                                    <p>mobile no. is verified</p>
                                </div>
                            </div>
                            <div className="all-details">
                            {profileViewDetails?.userDetails?.about_us ? 
                                <div className='aboutus-det'>
                                <h2>About Myself</h2>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <span>{profileViewDetails.userDetails.about_us}</span>
                                            }
                                        </div>
                                </div>
                                : null
                                }
                                <div className='personal-det'>
                                    <h2>Personal Details</h2>
                                    <div>
                                    <div className='pd1'>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Passing Year : </span><span>{profileViewDetails.userDetails.age}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Date of Brith : </span><span>{profileViewDetails.userDetails.dob}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Address : </span><span>{profileViewDetails.userDetails.address_1}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Landmark : </span><span>{profileViewDetails.userDetails.landmark}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>City : </span><span>{profileViewDetails.userDetails.city}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>State : </span><span>{profileViewDetails.userDetails.state}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Pincode : </span><span>{profileViewDetails.userDetails.pincode}</span></>
                                            }
                                        </div>
                                    </div>
                                        
                                    <div className='pd2'>
                                        
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Country : </span><span>{profileViewDetails.userDetails.country}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                ? ""
                                                : <><span className='left-text'>Height : </span><span>{profileViewDetails.userDetails.height}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Weight : </span><span>{profileViewDetails.userDetails.weight}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                ? ""
                                                : <><span className='left-text'>Horoscope : </span><span>{profileViewDetails.userDetails.horoscope}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                ? ""
                                                : <><span className='left-text'>Religion : </span><span>{profileViewDetails.userDetails.religion}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                ? ""
                                                : <><span className='left-text'>Marital : </span><span>{profileViewDetails.userDetails.marital}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                ? ""
                                                : <><span className='left-text'>Language : </span><span>{profileViewDetails.userDetails.language}</span></>
                                            }
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div>
                                <div className='user-family-det'>
                                    <div>
                                        <h2>User Details</h2>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>First Name  : </span><span>{profileViewDetails.first_name}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Last Name  : </span><span>{profileViewDetails.last_name}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Email id : </span><span>****{emailAddres()}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Phone no : </span><span>XXXXXX{phoneNumber()}</span></>
                                            }
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                        <h2>Family Details</h2>
                                            <div>
                                                {
                                                    profileViewDetails === null
                                                        ? ""
                                                        : <><span className='left-text'>Father's Name : </span><span>{profileViewDetails.userDetails.fatherName}</span></>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    profileViewDetails === null
                                                        ? ""
                                                        : <><span className='left-text'>Mothers's Name : </span><span>{profileViewDetails.userDetails.motherName}</span></>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    profileViewDetails === null
                                                        ? ""
                                                        : <><span className='left-text'>Gotra : </span><span>{profileViewDetails.userDetails.gotra}</span></>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    profileViewDetails === null
                                                        ? ""
                                                        : <><span className='left-text'>MamaSurname : </span><span>{profileViewDetails.userDetails.mamaSurname}</span></>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    profileViewDetails === null
                                                        ? ""
                                                        : <><span className='left-text'>Native Place : </span><span>{profileViewDetails.userDetails.nativePlace}</span></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='education-career-det'>
                                    <div>
                                    <h2>Education Details</h2>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Institute Name : </span><span>{profileViewDetails.userDetails.instituteName}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Qualification : </span><span>{profileViewDetails.userDetails.qualification}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Specialization : </span><span>{profileViewDetails.userDetails.specialization}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Percentage : </span><span>{profileViewDetails.userDetails.qualification}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Passing Year : </span><span>{profileViewDetails.userDetails.passingYear}</span></>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                    <h2>Career Details</h2>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Occuption Category : </span><span>{profileViewDetails.userDetails.occupation}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Current City : </span><span>{profileViewDetails.userDetails.city}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Company Name : </span><span>{profileViewDetails.userDetails.companyName}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Designation : </span><span>{profileViewDetails.userDetails.designation}</span></>
                                            }
                                        </div>
                                        <div>
                                            {
                                                profileViewDetails === null
                                                    ? ""
                                                    : <><span className='left-text'>Income Range : </span><span>{profileViewDetails.userDetails.income}</span></>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    partnerPreference &&
                                    <div className='personal-det'>
                                        <h2>Partner Preference</h2>
                                        <div>
                                        <div className='pd1'>
                                            
                                            <div>
                                                <span className='left-text'>Address : </span><span>{partnerPreference.address}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Height : </span><span>{partnerPreference.Height}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Age Range From : </span><span>{partnerPreference.ageRangeFrom}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Age Range To : </span><span>{partnerPreference.ageRangeTo}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Marital : </span><span>{partnerPreference.marital}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Education : </span><span>{partnerPreference.education}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Annual Income : </span><span>{partnerPreference.annualIncome}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Drinking : </span><span>{partnerPreference.drinking}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Smoking : </span><span>{partnerPreference.smoking}</span>
                                            </div>
                                        </div>
                                            
                                        <div className='pd2'>
                                            
                                            <div>
                                                <span className='left-text'>Family Type : </span><span>{partnerPreference.familyType}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Family Value : </span><span>{partnerPreference.familyValue}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Family Status : </span><span>{partnerPreference.familyStatus}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Occupation : </span><span>{partnerPreference.occupation}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Physical Status : </span><span>{partnerPreference.physicalStatus}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Open To Relocate : </span><span>{partnerPreference.openToRelocated}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Horoscope Matching : </span><span>{partnerPreference.horoscopeMatching}</span>
                                            </div>
                                            <div>
                                                <span className='left-text'>Profile Pictures : </span><span>{partnerPreference.profilePictures}</span>
                                            </div>
                                            
                                        </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                    backToTop && (
                                        <div className='top-button' onClick={scrollUp}><span><BsFillArrowUpCircleFill /></span></div>
                                    )
                                }
                            
                        </div>
                    </div>
                </div>
            </div>
            <ProfileViewMob userData={profileViewDetails} userProfilePhoto={profilePhoto}/>
        </>
    );
};

export default ProfileView;