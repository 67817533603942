import React from "react";
import { Helmet } from "react-helmet";
import "./TermsConditions.css";
import TermsContent from "./TermsContent";

function TermsConditions() {
  return (
    <section className="terms_conditions">
      <div className="body">
        <Helmet>
          <title>TermsConditions</title>
          <meta
            name="description"
            content="We expect that you would complete the online registration process with fairness and honesty in furnishing true, accurate, current, complete information and with providing recent photos of you which will help you to meet your parameters. "
          />
        </Helmet>
        <div className="page-heading">
          <div className="wrapper-heading">
            <h3 className="page-heading-text">Terms and Conditions</h3>
          </div>
        </div>
      </div>
      <div className="terms_content">
        <div className="wrapper">
          <p>
            {" "}
            Welcome to matrimonydemo.com. In order to use the matrimonydemo.com
            Site ("Site"), you must Register as a member of the Site ("Member")
            and agree to be bound by these Terms of Use ("Agreement"). If you
            wish to become a Member and communicate with other Members and make
            use of the service ("Service"), read these Terms of Use and follow
            the instructions in the Registration process. This Agreement sets
            out the legally binding terms for your membership. This Agreement
            may be modified by matrimonydemo.com from time to time effective
            upon notice to you as a Member. Whenever there is a change in the
            Terms of Use, matrimonydemo.com will intimate you of such change.
            Your continued use of the Site pursuant to such change will
            constitute deemed acceptance of such changes.
          </p>
          {TermsContent.map((elem, index) => {
            return (
              <div key={index}>
                <h3>
                  {index + 1}. {elem.heading}
                </h3>
                <p>{elem.content}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default TermsConditions;
