import { useState } from "react";
import axios from "axios";
import server from "../../../config/server.json";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Landing.css";

const countryCode = ["+91"];
const LoginPage = (props) => {
  const [disabledBtn, setDisabled] = useState(true);
  const [phone_number, setPhoneNumber] = useState("");

  const handleOnChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    props.setData({ ...props.data, phone_number: value });
    if (e.target.value.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleOnValidation = () => {
    if (!phone_number) {
      toast.error("Number Cannot be Empty", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (phone_number.length !== 10) {
      toast.error("Please Fill Valid Number", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else {
      return true;
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (handleOnValidation()) {
      try {
        setDisabled(true);
        const body = { phone_number: phone_number };
        const response = await axios.post(
          `${server.url.production}${server.api.CREATE_REGISTER_OTP}`,
          body
        );
        if (response.status === 201) {
          props.next();
          toast.success(response.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        } else {
          setDisabled(false);
          toast.error(response.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      } catch (e) {
        setDisabled(false);
        toast.error(e.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };

  return (
    <div>
      <div className="login-right-side">
        <div className="login-close-btn" onClick={() => props.cancel()}>
          <span>
            <AiOutlineClose />
          </span>
        </div>
        <form onSubmit={handleOnSubmit}>
          <div className="login-info-main">
            <div className="login-info-top">
              <select name="country" className="country-code">
                {countryCode.map((elem) => (
                  <option value={elem} key={elem}>
                    {elem}
                  </option>
                ))}
              </select>
              <input
                autoFocus
                placeholder="Enter mobile number"
                value={phone_number}
                onChange={handleOnChange}
                required
              ></input>
            </div>

            <div className="login-info-bottom">
              <button className="login-get-otp" onClick={handleOnValidation} disabled={disabledBtn}>Get OTP</button>
              <p>
                We no more support email based login. You can now login via
                mobile number & link email to access your account.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;