import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import server from "../../config/server.json";
import { Helmet } from "react-helmet";
import { FiChevronLeft } from "react-icons/fi";
import { AiOutlineUser, AiOutlineUsergroupDelete } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { CiStar } from "react-icons/ci";
import { BiShoppingBag } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import PersonalInformation from './PersonalInformation';
import PersonalDetails from './PersonalDetails';
import EducationDetails from './EducationDetails';
import CarrerDetails from './CarrerDetails';
import FamilyDetails from './FamilyDetails';
import { useDispatch } from 'react-redux';
import {
    getHeightData,
    getMartialData,
    getFamilyIncomeData,
    getFamilyStatusData,
    getFamilyTypeData,
    getFamilyValuesData,
    getProfileCreatedByData,
    getEducationDegreeData,
    geteducationCompletionYearData,
    getIncomeData,
} from "../../Redux/Master/ApiMaster";
import "./EditMyProfile.css";

const EditMyProfile = ({ setEditMyDetails, editMyProfileDetails }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [token, setToken] = useState(null);
    const [user, setUser] = useState();
    const [userDetails, setUserDetails] = useState();
    const [editPersonal, setEditPersonal] = useState(false);
    const [editEducationDetails, setEducationDetails] = useState(false);
    const [editFamilyDetails, setEditFamilyDetails] = useState(false);
    const [editCarrerDetails, setCarrerDetails] = useState(false);
    const [editPersonalEdit, setPersonalEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("user"));
        if (loggedUser) {
            setToken(localStorage.getItem("token"));
        } else {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        dispatch(getHeightData());
        dispatch(getMartialData());
        dispatch(getEducationDegreeData());
        dispatch(geteducationCompletionYearData());
        dispatch(getIncomeData());
        dispatch(getFamilyTypeData());
        dispatch(getProfileCreatedByData());
        dispatch(getFamilyValuesData());
        dispatch(getFamilyStatusData());
        dispatch(getFamilyIncomeData());
    }, [dispatch])

    useEffect(() => {
        if (token) {
            const getUserDetails = async () => {
                try {
                    const headers = {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    };
                    const response = await axios.get(
                        `${server.url.production}${server.api.GET_USER_DATA}`,
                        {
                            headers: headers,
                        }
                    );
                    if (response.status === 200) {
                        const user = response.data;
                        const userDetails = response && response.data.userDetails;
                        setUser(user);
                        setUserDetails(userDetails);
                    } else {
                        toast.error("Something Went Wrong, Please Try Again Later", {
                            autoClose: 3000,
                            position: "bottom-right",
                        });
                    }
                } catch (error) {
                    toast.error("Something Went Wrong, Please Try Again Later", {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                } finally {
                    setIsLoading(false); // Mark loading as false whether the request is successful or not
                }
            };
            getUserDetails()
        }
    }, [token]);

    return (
        <div className='editMyProfile_main_container'>
            {isLoading ? (
                <div className="view-loader-container">
                  <div className="lds-roller">
                    {[...Array(8)].map((_, index) => (
                      <div key={index}></div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                    <Helmet>
                        <title>UserProfile</title>
                        <meta
                            name="description"
                            content=" collection of information associated with a particular user."
                        />
                    </Helmet>
                    <div className="editMyProfile_wrapper">
                        <h3>
                            <span><FiChevronLeft onClick={() => setEditMyDetails(!editMyProfileDetails)} /></span>
                            <span>My Details</span>
                        </h3>
                        <div className="editMyProfile_main_container_personal_info">
                            <h4>
                                <span><AiOutlineUser /></span>
                                <span>Personal Information</span>
                                <span><MdEdit onClick={() => setEditPersonal(!editPersonal)} /></span>
                            </h4>
                            <PersonalInformation user={user} setEditPersonal={setEditPersonal} editPersonal={editPersonal} />
                        </div>
                        <div className="editMyProfile_main_container_personal_details">
                            <h4>
                                <span><CiStar /></span>
                                <span>personal details</span>
                                <span><MdEdit onClick={() => setPersonalEdit(!editCarrerDetails)} /></span>
                            </h4>
                            <PersonalDetails userDetails={userDetails} editPersonalEdit={editPersonalEdit} setPersonalEdit={setPersonalEdit} />
                        </div>
                        <div className="editMyProfile_main_container_family_details">
                            <h4>
                                <span><AiOutlineUsergroupDelete /></span>
                                <span>family details</span>
                                <span><MdEdit onClick={() => setEditFamilyDetails(!editFamilyDetails)} /></span>
                            </h4>
                            <FamilyDetails userDetails={userDetails} editFamilyDetails={editFamilyDetails} setEditFamilyDetails={setEditFamilyDetails} />
                        </div>
                        <div className="editMyProfile_main_container_education_details">
                            <h4>
                                <span><HiOutlineAcademicCap /></span>
                                <span>education</span>
                                <span><MdEdit onClick={() => setEducationDetails(!editEducationDetails)} /></span>
                            </h4>
                            <EducationDetails userDetails={userDetails} editEducationDetails={editEducationDetails} setEducationDetails={setEducationDetails} />
                        </div>
                        <div className="editMyProfile_main_container_carrer_details">
                            <h4>
                                <span><BiShoppingBag /></span>
                                <span>career</span>
                                <span><MdEdit onClick={() => setCarrerDetails(!editCarrerDetails)} /></span>
                            </h4>
                            <CarrerDetails userDetails={userDetails} setCarrerDetails={setCarrerDetails} editCarrerDetails={editCarrerDetails} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default EditMyProfile;