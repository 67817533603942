import React from "react";
import User from "../../assets/dummy-user.png";
import "./Shortlisted.css";
import userData from "./Shortlisted.json";
import { GrTrash } from "react-icons/gr";
import { GoTriangleRight } from "react-icons/go";
import { MdVerified } from "react-icons/md";
import { Helmet } from "react-helmet";

function Shortlisted() {
  const { data } = userData;
  return (
    <div className="body">
      <Helmet>
        <title>shortlisted</title>
        <meta
          name="description"
          content="This will make your Partner Search easier. For instance, if you know who has shortlisted you, you can initiate a conversation by sending an interest to the person."
        />
      </Helmet>
      <div className="user-welcome">
        <div className="wrapper-heading">
          <h3>matrimonydemo.com welcomes you</h3>
        </div>
      </div>
      <div className="page-heading">
        <div className="wrapper-heading">
          <h3 className="page-heading-text">My Shortlisted-new Profiles</h3>
        </div>
      </div>
      <div className="wrapper">
        <div className="shortlisted-content">
          <div className="shortlisted-boxes">
            <div className="shortlisted-menu">
              <ul>
                <li className="first-li">menu</li>
                <li>
                  <GoTriangleRight />
                  Shortlisted profiles
                </li>
                <li>
                  <GoTriangleRight />
                  Interested profiles
                </li>
                <li>
                  <GoTriangleRight />
                  My matches
                </li>
              </ul>
            </div>
            <div className="search-bar-shortlisted">
              <p className="first-li">Search by Profile ID:</p>
              <p className="search-shortlisted">
                <input></input>
                <button className="search-button-shortlisted">Search</button>
              </p>
            </div>
          </div>
          <div className="shortlisted-profile">
            {data.map((user) => (
              <div className="shortlisted-users">
                <h5>
                  <span>Profile created by her {user.created}</span>
                  <span>
                    Profile ID: {user.ID} <MdVerified />{" "}
                  </span>{" "}
                  <GrTrash />
                </h5>
                <div className="user-profile-shortlisted">
                  <div className="user-img-shortlisted">
                    <img src={User} alt="user"></img>
                    <button className="light-button">View album</button>
                    <button className="dark-button">Connect now</button>
                  </div>
                  <div className="user-info-shortlisted">
                    <h6>Online a few minutes ago.</h6>
                    <div className="shortlisted-bullets">
                      <h4>{user.name}</h4>
                      <p>
                        <span>Age: </span>
                        {user.age}
                      </p>
                      <p>
                        <span> Height: </span>
                        {user.height}
                      </p>
                      <p>
                        <span>Zodiac Sign: </span> {user.zodiac}
                      </p>
                      <p>
                        <span>Occupation: </span>
                        {user.occupation}
                      </p>
                      <p>
                        <span>Contact Number: </span>
                        {user.contact}
                      </p>
                    </div>
                    <p className="summary-shortlisted">{user.p}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Shortlisted;
