import React, { useEffect, useState } from "react";
import { MdEdit, MdDeleteForever } from "react-icons/md";
import axios from "axios";
import server from "../../../config/server.json";
import { Helmet } from "react-helmet";

const DisplayAllUsers = () => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${server.url.production}/api/users/`);
      setUsers(response.data);
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Display All Users</title>
        <meta
          name="description"
          content="The admin can view all users and their details under the matrimony site."
        />
      </Helmet>
      <h1 className="text-center">Users</h1>
      <div className="d-flex mx-auto col-11 col-md-5 p-2">
        <input
          className="form-control me-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
        />
        <button className="btn btn-primary" type="submit">
          Search
        </button>
      </div>
      <div
        className="m-auto col-11 col-md-10 border border-secondary shadow"
        style={{ overflowX: "auto" }}
      >
        <table className="table table-hover table-responsive">
          <thead>
            <tr>
              <th scope="col">Sr no.</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Gender</th>
              <th scope="col">E-mail</th>
              <th scope="col">Phone No</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user._id} className="cursor-pointer">
                <th scope="row">{index + 1}</th>
                <td className="col-md-2">{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.gender}</td>
                <td>{user.email_id}</td>
                <td>{user.phone_number}</td>
                <td>
                  <button type="button" class="btn btn-warning">
                    <MdEdit className="fs-4" />
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-danger">
                    <MdDeleteForever className="fs-4 " />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DisplayAllUsers;
