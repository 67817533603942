import React from "react";
import { useState, useEffect } from "react";
import "./AbuseReport.css";
import AbuseListData from "./AbuseListData.json";
import { Helmet } from "react-helmet";
import axios from "axios";
import server from "../../config/server.json";
import {OPTION_SELECT_EMPTY} from "../../utils/GlobalVariable";

function AbuseReport() {
  const [invalid, setInvalid] = useState(false);
  const [showErrorMessage, setErrorMessages] = useState("");
  const [selectCategory, setSelectCategory] = useState("none");
  const [val, setVal] = useState({
    againstProfile: "",
    againstEmail: "",
    subject: "",
    yourEmail: "",
    complaint: "",
  });

  const { againstProfile, againstEmail, subject, complaint, yourEmail } = val;
  const { AbuseList, AbuseCategory } = AbuseListData;

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (
      !againstProfile ||
      !againstEmail ||
      !subject ||
      !complaint ||
      !yourEmail
    ) {
      return setInvalid("*** Invalid Abuse Details");
    }
    console.log(againstProfile, againstEmail, subject, complaint, yourEmail);
    setVal({
      againstProfile: "",
      againstEmail: "",
      subject: "",
      yourEmail: "",
      complaint: "",
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setVal({ ...val, [name]: value });
  };

  const handleCategoryChange = (e) => {
    setSelectCategory(e.target.value);
  };

  const handleOnReset = () => {
    setVal({
      againstProfile: "",
      againstEmail: "",
      subject: "",
      yourEmail: "",
      complaint: "",
    });
  };

  const handleValidation = () => {
    const subjectRegex = /^[a-zA-Z ]{2,75}$/;
    const emailRegex = /^([a-zA-Z0-9\_\-])+\@([a-zA-Z])+\.([a-zA-Z]){2,5}$/; // eslint-disable-line
    const messageRegex = /^[a-zA-Z0-9\s\,\''\-\?]*$/; // eslint-disable-line

    if (selectCategory === "none") {
      setErrorMessages("Please select Abuse Category");
    } else if (!againstProfile.match(subjectRegex)) {
      setErrorMessages("Please enter Profile Id to register a complaint");
    } else if (!againstEmail.match(emailRegex)) {
      setErrorMessages("Email is Not Valid");
    } else if (!subject.match(subjectRegex)) {
      setErrorMessages("Enter a valid subject not exceding 75 characters");
    } else if (!complaint.match(subjectRegex)) {
      setErrorMessages("Enter a valid complaint not exceding 75 characters");
    } else if (!yourEmail.match(emailRegex)) {
      setErrorMessages("Email is Not Valid");
    } else {
      return;
    }
  };

  useEffect(() => {
    setErrorMessages("");
  }, [val]);

  const handleReportSubmit = async (e) => {
    e.preventDefault();
    handleValidation();
    let data = await axios.post(
      `${server.url.production}/api/reportAbuse/send`,
      {
        users_email_id: "",
        abuse_category: "",
        complaint_profile_id: "",
        email_id: "",
        subject: "",
        complaint_details: "",
      }
    );
    console.log(data);
  };
  return (
    <section className="body">
      <Helmet>
        <title>AbuseReport</title>
        <meta
          name="description"
          content="The misuse of your matrimonial profile can be reported. We need your cooperation to prevent abuse, harassment and violation."
        />
      </Helmet>
      <div className="page-heading">
        <div className="wrapper-heading">
          <h3 className="page-heading-text">abuse category</h3>
        </div>
      </div>
      <div className="content">
        <div className="wrapper content-heading">
          <p>
            We have stringent policies against those members who misuse our
            services. For us to act and stop any abuse or violation, we need all
            your support and extended co-operation .Alert us by writing to us:
            info@matrimonydemo.com, for us to initiate necessary action against
            the offender. Also, while reporting such complaints, please provide
            all evidence including any e-mail (Full header of the e-mail) you
            may have received.
          </p>
          <span>your personal details will not be disclosed.</span>
          <h4>the following are considered as abuse of our services :</h4>
          <ul className="abuse_lists">
            {AbuseList.map((elem, index) => (
              <li key={index}>
                <p>{elem}</p>
              </li>
            ))}
          </ul>
          <form onSubmit={handleOnSubmit}>
            {invalid ? <div className="invalid">{invalid}</div> : ""}
            <div className="form_group">
              <div className="select_input">
                <label htmlFor="abuseCategory">
                  select your abuse category
                </label>
                <select
                  name="abuseCategory"
                  id="abuseCategory"
                  onChange={handleCategoryChange}
                >
                  <option value={selectCategory}>{OPTION_SELECT_EMPTY}</option>
                  <optgroup label="Abuse Category">Abuse Category</optgroup>
                  {AbuseCategory.map((elem, index) => (
                    <option key={index} value={elem}>
                      {elem}
                    </option>
                  ))}
                </select>
              </div>
              <div className="againstProfile">
                <label htmlFor="againstProfile">
                  complaint aganist profile id
                </label>
                <input
                  type="text"
                  name="againstProfile"
                  id="againstProfile"
                  value={againstProfile}
                  onChange={handleOnChange}
                  placeholder="Complaint ID"
                />
              </div>
              <div className="againstEmail">
                <label htmlFor="againstEmail">(his/her) email id</label>
                <input
                  type="email"
                  name="againstEmail"
                  id="againstEmail"
                  value={againstEmail}
                  onChange={handleOnChange}
                  placeholder="example@test.com"
                />
              </div>
            </div>
            <div className="form_group">
              <div className="subject">
                <label htmlFor="subject">subject</label>
                <textarea
                  name="subject"
                  id="subject"
                  rows="5"
                  value={subject}
                  onChange={handleOnChange}
                  placeholder="Subject "
                />
              </div>
              <div className="complaint">
                <label htmlFor="complaint">complaint details</label>
                <textarea
                  name="complaint"
                  id="complaint"
                  rows="5"
                  value={complaint}
                  onChange={handleOnChange}
                  placeholder="Complaint Body"
                />
              </div>
            </div>
            <div className="form_group">
              <div className="yourEmail">
                <label htmlFor="yourEmail">your email :</label>
                <input
                  type="email"
                  name="yourEmail"
                  id="yourEmail"
                  value={yourEmail}
                  onChange={handleOnChange}
                  placeholder="youremail@test.com"
                />
              </div>
            </div>
            {showErrorMessage.length > 0 ? (
              <div id="career_form_error">
                <span>Alert: {showErrorMessage}</span>
              </div>
            ) : (
              ""
            )}
            <div className="form_group">
              <div className="submit">
                <button
                  type="submit"
                  className="light-button"
                  onClick={handleReportSubmit}
                >
                  send
                </button>
              </div>
              <div className="reset">
                <button
                  type="reset"
                  onClick={handleOnReset}
                  className="light-button"
                >
                  reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default AbuseReport;