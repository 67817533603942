import { createSelector, createSlice } from "@reduxjs/toolkit";
import { createRequest, fetchRequestStatus } from "./ApiCalls";

const contactSharingSlice = createSlice({
    name: "contactSharing",
    initialState: {
        status: [],
        request: {},
        isLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRequestStatus.fulfilled, (state, { payload }) => {
                state.status = payload;
            })
            .addCase(createRequest.pending, (state, { meta }) => {
                state.isLoading = true;
                state.request = meta.arg;
            })
            .addCase(createRequest.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.request = payload;
            });
    },
});

export const getStatusSelector = createSelector(
    (state) => state.contactSharing,
    (state) => state
);

export default contactSharingSlice;
