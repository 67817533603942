import React from "react";
import pdf from "@react-pdf/renderer";
import bgImg from "../../../assets/BiodataImages/bg6_1.png";
import Family_photo from "../../../assets/BiodataImages/Family_photo.jpg";

const { Page, Text, Image, Document, View, StyleSheet } = pdf;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },

  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  profileName: {
    textAlign: "left",
    padding: "10px 0 13px 7px",
    fontFamily: "Helvetica-Bold",
    fontSize: "18px",
    fontWeight: "900",
    // color: "#2b7a5f",
  },

  profileImgContainer1: {
    width: "200px",
    position: "absolute",
    top: "10%",
    left: "14%",
  },

  profileImg1: {
    width: "200px",
    borderRadius: "10px",
  },

  personalDetailsContainer1: {
    width: "240px",
    position: "absolute",
    top: "9%",
    right: "10%",
  },

  personalDetailsHeader1: {
    fontSize: "15px",
    fontFamily: "Helvetica-Bold",
    padding: "20px 0 0 0",
  },

  personalSingleDetail1: {
    padding: "5px 0 5px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  eduOccupConatiner: {
    width: "420px",
    position: "absolute",
    left: "14%",
    bottom: "33%",
  },

  eduOccupTitle: {
    padding: "10px 0",
    fontSize: "15px",
    fontFamily: "Helvetica-Bold",
    textAlign: "center",
  },

  eduOccupDetailsConatiner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  page1FamilyImgContainer: {
    width: "430px",
    position: "absolute",
    left: "14%",
    bottom: "12%",
  },

  page1FamilyImg: {
    width: "230px",
    height: "150px",
    margin: "0 auto",
    borderRadius: "5px",
  },

  personalDetailsHeader2: {
    width: "430px",
    position: "absolute",
    left: "14%",
    top: "10%",
    fontSize: "25px",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },

  personalDetailsContainer2: {
    width: "500px",
    padding: "5px",
    position: "absolute",
    right: "40px",
    left: "70px",
    top: "14%",
  },

  personalDetails2: {
    width: "300px",
    padding: "0 0 10px 20px",
    height: "270px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  personalSingleDetail2: {
    width: "248px",
    paddingTop: "13px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  page2FamilyImgContainer: {
    width: "430px",
    position: "absolute",
    left: "14%",
    top: "49%",
  },

  page2FamilyImg: {
    width: "250px",
    height: "170px",
    margin: "0 auto",
    borderRadius: "5px",
  },

  contactDetailsContainer2: {
    width: "370px",
    height: "135px",
    position: "absolute",
    bottom: "13%",
    left: "17%",
  },

  contactHeader2: {
    textAlign: "center",
    paddingBottom: "10px",
  },

  contactSingleDetail2: {
    paddingTop: "13px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

const FormatSeven = ({
  fields,
  uncleList,
  auntyList,
  mamaList,
  mamiList,
  brotherList,
  sisterList,
  cousinList,
  base64,
}) => {
  const {
    first_name,
    last_name,
    pob,
    gender,
    blood_group,
    height,
    marital,
    religion,
    language,
    country,
    horoscope,
    qualification,
    specialization,
    instituteName,
    companyName,
    designation,
    income,
    fatherName,
    father_job,
    motherName,
    mother_job,
    dada,
    dadi,
    siblings,
    nana,
    nani,
    nativePlace,
    familyStatus,
    phone_number,
    email_id,
    address_1,
  } = fields;

  const dob = new Date(fields.dob);
  const birthDate = isNaN(dob.getDate()) ? "" : dob.getDate();
  const birthYear = isNaN(dob.getFullYear()) ? "" : dob.getFullYear();
  const birthMonth =
    dob.toLocaleString("default", { month: "long" }) === "Invalid Date"
      ? ""
      : dob.toLocaleString("default", { month: "long" });
  const date_of_birth = `${birthDate} ${birthMonth} ${birthYear}`;
  const checkIfLastIndex = (index, length) => {
    return index === length - 1 ? "" : ", ";
  };

  const profileDetailListArr = [
    { title: "Profile Details" },
    [
      { label: "Date of birth", value: date_of_birth },
      { label: "Birth Place", value: pob },
      { label: "Gender", value: gender },
      { label: "Blood Group", value: blood_group },
      { label: "Height", value: height },
      { label: "Marital Status", value: marital },
      { label: "Religion", value: religion },
      { label: "Language Known", value: language },
      { label: "Country", value: country },
      { label: "Horoscope", value: horoscope },
    ],
  ];

  const qualificationListArr = [
    { title: "Qualification" },
    [
      { label: "Highest Qualification", value: qualification },
      { label: "Specialization", value: specialization },
      { label: "Institute Name", value: instituteName },
    ],
  ];

  const occupationListArr = [
    { title: "Occupation" },
    [
      { label: "Company Name", value: companyName },
      { label: "Occupation", value: designation },
      { label: "Income", value: income },
    ],
  ];

  const contactListDetails = [
    { title: "Contact Details" },
    [
      { label: "Conatct Number", value: phone_number },
      { label: "Email", value: email_id },
      { label: "Residential Address", value: address_1 },
    ],
  ];

  const profileDetailListArr2 = [
    { label: "Father", value: fatherName },
    { label: "Father's Job", value: father_job },
    { label: "Mother", value: motherName },
    { label: "Mother's Job", value: mother_job },
    { label: "Grand Father", value: dada },
    { label: "Grand Mother", value: dadi },
    { label: "Number Of Siblings", value: siblings },
    { label: "Brother Name", value: brotherList },
    { label: "Sister Name", value: sisterList },
    { label: "Nanaji", value: nana },
    { label: "Naniji", value: nani },
    { label: "Uncles", value: uncleList },
    { label: "Aunty", value: auntyList },
    { label: "Mama", value: mamaList },
    { label: "Mami", value: mamiList },
    { label: "Cousins Name", value: cousinList },
    { label: "Native Place", value: nativePlace },
    { label: "Family Status", value: familyStatus },
  ];

  return (
    <Document>
      {/* PAGE 1 */}
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={bgImg} />
        </View>
        {/* profile name & img */}
        <View style={styles.profileImgContainer1}>
          <Image
            src={{ uri: base64 }}
            alt={"User Image"}
            style={styles.profileImg1}
          />
        </View>
        {/* Personal Details Container 1 */}
        <View style={styles.personalDetailsContainer1}>
          <View style={styles.personalDetailsHeader1}>
            {/* <Text>{profileDetailListArr[0].title}</Text> */}
            <Text style={styles.profileName}>
              {first_name} {last_name}
            </Text>
          </View>
          {profileDetailListArr[1].map((detail, i) => (
            <View style={styles.personalSingleDetail1} key={i}>
              <Text style={styles.personalLeftText}>{detail.label}: </Text>
              <Text style={styles.personalRightText}>{detail.value}</Text>
            </View>
          ))}
          {
            fields.about_us? 
            <View style={styles.personalSingleDetail1}>
            <Text style={styles.personalLeftText}>About Myself: </Text>
            <Text style={styles.personalRightText}>{fields.about_us}</Text>
            </View> : null
          }
        </View>
        {/* Personal Edu and Occupation */}
        <View style={styles.eduOccupConatiner}>
          <View style={styles.eduOccupTitle}>
            <Text>Education & Occupation</Text>
          </View>
          <View style={styles.eduOccupDetailsConatiner}>
            <View>
              {qualificationListArr[1].map((detail, i) => (
                <View style={styles.personalSingleDetail1} key={i}>
                  <Text style={styles.personalLeftText}>{detail.label}: </Text>
                  <Text style={styles.personalRightText}>{detail.value}</Text>
                </View>
              ))}
            </View>
            <View>
              {occupationListArr[1].map((detail, i) => (
                <View style={styles.personalSingleDetail1} key={i}>
                  <Text style={styles.personalLeftText}>{detail.label}: </Text>
                  <Text style={styles.personalRightText}>{detail.value}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>
        {/* Family Img */}
        <View style={styles.page1FamilyImgContainer}>
          <Image
            src={Family_photo}
            alt={"User Image"}
            style={styles.page1FamilyImg}
          />
        </View>
      </Page>

      {/* PAGE 2 */}
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={bgImg} />
        </View>
        {/* Personal Details Container 2 */}
        <View style={styles.personalDetailsHeader2}>
          <Text>Family Details</Text>
        </View>
        <View style={styles.personalDetailsContainer2}>
          <View style={styles.personalDetails2}>
            {profileDetailListArr2.map((detail, i) => {
              return typeof detail.value === "object" ? (
                <View style={styles.personalSingleDetail2} key={i}>
                  <Text>{detail.label}: </Text>
                  <Text>
                    {detail.value.map((elem, i) => {
                      return (
                        elem.check && (
                          <Text key={i}>
                            {Object.values(elem)[0]}
                            {checkIfLastIndex(i, Object.keys(elem)[0].length)}
                          </Text>
                        )
                      );
                    })}
                  </Text>
                </View>
              ) : (
                <View style={styles.personalSingleDetail2} key={i}>
                  <Text>{detail.label}: </Text>
                  <Text>{detail.value}</Text>
                </View>
              );
            })}
          </View>
        </View>
        {/* Family Img */}
        <View style={styles.page2FamilyImgContainer}>
          <Image
            src={Family_photo}
            alt={"User Image"}
            style={styles.page2FamilyImg}
          />
        </View>
        {/* Contact Page */}
        <View style={styles.contactDetailsContainer2}>
          <View style={styles.contactHeader2}>
            <Text>Contact Details</Text>
          </View>
          <View style={styles.contactDetails2}>
            {contactListDetails[1].map((detail, i) => (
              <View style={styles.contactSingleDetail2} key={i}>
                <Text>{detail.label}: </Text>
                <Text>{detail.value}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FormatSeven;
