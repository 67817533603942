import React, { useEffect, useState } from "react";
import "./MyPreferenceMobile.css";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineUser } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { BsBag } from "react-icons/bs";
import { ImGlass2 } from "react-icons/im";
import { BsAsterisk } from "react-icons/bs";
import { MdArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import { toast } from "react-toastify";
import server from "../../config/server.json";
import axios from "axios";
import { useSelector } from "react-redux";

function MyPreferenceMobile({ setEditPreference }) {
  const masterData = useSelector(state => state.masterData);
  const [user, setUser] = useState(null);
  let [steps, setSteps] = useState(0);
  const EditPreferenceLabels = [
    "Basic Information",
    "Family Background",
    "Career",
    "Lifestyle Choices",
    "Other",
  ];

  const [myPreference, setMyPreference] = useState({
    address: "",
    ageRangeFrom: "",
    ageRangeTo: "",
    heightRangeFrom: "",
    heightRangeTo: "",
    marital: "",
    education: "",
    annualIncome: "",
    drinking: "",
    smoking: "",
    familyType: "",
    familyValue: "",
    familyStatus: "",
    occupation: "",
    physicalStatus: "",
    openToRelocate: "",
    horoscopeMatching: "",
    profilePictures: "",
  });

  const {
    address,
    ageRangeFrom,
    ageRangeTo,
    heightRangeFrom,
    heightRangeTo,
    marital,
    education,
    annualIncome,
    drinking,
    smoking,
    familyType,
    familyValue,
    familyStatus,
    occupation,
    physicalStatus,
    openToRelocate,
    horoscopeMatching,
    profilePictures,
  } = myPreference;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setMyPreference({ ...myPreference, [name]: value });
  };

  const postPreference = async () => {
    if (
      !address &&
      !heightRangeFrom &&
      !heightRangeTo &&
      !ageRangeFrom &&
      !ageRangeTo &&
      !marital &&
      !education &&
      !annualIncome &&
      !drinking &&
      !smoking &&
      !familyType &&
      !familyValue &&
      !familyStatus &&
      !occupation &&
      !physicalStatus &&
      !openToRelocate &&
      !horoscopeMatching &&
      !profilePictures
    ) {
      toast.error("Please select atleast 1 field.", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else {
      try {
        const headers = {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        };
        const body = {
          userId: user.id,
          address: address ? address : "",
          heightRangeFrom: heightRangeFrom ? heightRangeFrom : "",
          heightRangeTo: heightRangeTo ? heightRangeTo : "",
          ageRangeFrom: ageRangeFrom ? ageRangeFrom : "",
          ageRangeTo: ageRangeTo ? ageRangeTo : "",
          marital: marital ? marital : "",
          education: education ? education : "",
          annualIncome: annualIncome ? annualIncome : "",
          drinking: drinking ? drinking : "",
          smoking: smoking ? smoking : "",
          familyType: familyType ? familyType : "",
          familyValue: familyValue ? familyValue : "",
          familyStatus: familyStatus ? familyStatus : "",
          occupation: occupation ? occupation : "",
          physicalStatus: physicalStatus ? physicalStatus : "",
          openToRelocated: openToRelocate ? openToRelocate : "",
          horoscopeMatching: horoscopeMatching ? horoscopeMatching : "",
          profilePictures: profilePictures ? profilePictures : "",
        };
        const result = await axios.post(
          `${server.url.production}${server.api.EDIT_PREFERENCE}`,
          body,
          { headers }
        );
        if (result.status === 201) {
          toast.success(result.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
          setEditPreference(false);
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        };
        const response = await axios.get(
          `${server.url.production}${server.api.FETCH_PREFERENCES}`,
          { headers }
        );
        setMyPreference({
          address: response.data.address ? response.data.address : "",
          height: response.data.Height ? response.data.Height : "",
          heightRangeFrom: response.data.heightRangeFrom
            ? response.data.heightRangeFrom
            : "",
          heightRangeTo: response.data.heightRangeTo
            ? response.data.heightRangeTo
            : "",
          ageRangeFrom: response.data.ageRangeFrom
            ? response.data.ageRangeFrom
            : "",
          ageRangeTo: response.data.ageRangeTo ? response.data.ageRangeTo : "",
          marital: response.data.marital ? response.data.marital : "",
          education: response.data.education ? response.data.education : "",
          annualIncome: response.data.annualIncome
            ? response.data.annualIncome
            : "",
          drinking: response.data.drinking ? response.data.drinking : "",
          smoking: response.data.smoking ? response.data.smoking : "",
          familyType: response.data.familyType ? response.data.familyType : "",
          familyValue: response.data.familyValue
            ? response.data.familyValue
            : "",
          familyStatus: response.data.familyStatus
            ? response.data.familyStatus
            : "",
          occupation: response.data.occupation ? response.data.occupation : "",
          physicalStatus: response.data.physicalStatus
            ? response.data.physicalStatus
            : "",
          openToRelocate: response.data.openToRelocated
            ? response.data.openToRelocated
            : "",
          horoscopeMatching: response.data.horoscopeMatching
            ? response.data.horoscopeMatching
            : "",
          profilePictures: response.data.profilePictures
            ? response.data.profilePictures
            : "",
        });
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    };
    fetchPreferences();
  }, []);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setUser(loggedUser);
    } else {
      toast.error("Something Went Wrong, Please Try Again Later", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  }, []);

  const clearSection = (steps) => {
    if (steps === 0) {
      setMyPreference({
        ...myPreference,
        address: "",
        ageRangeFrom: "",
        ageRangeTo: "",
        heightRangeFrom: "",
        heightRangeTo: "",
        marital: "",
        education: "",
      });
    }
    if (steps === 1) {
      setMyPreference({
        ...myPreference,
        familyType: "",
        familyValue: "",
        familyStatus: "",
      });
    }
    if (steps === 2) {
      setMyPreference({ ...myPreference, occupation: "", annualIncome: "" });
    }
    if (steps === 3) {
      setMyPreference({ ...myPreference, drinking: "", smoking: "" });
    }
    if (steps === 4) {
      setMyPreference({
        ...myPreference,
        openToRelocate: "",
        horoscopeMatching: "",
        profilePictures: "",
        physicalStatus: "",
      });
    }
  };

  return (
    <div className="edit-preference-mobile">
      <div className="edit-preference-head">
        <RxCross2
          className="cross-btn"
          onClick={() => setEditPreference(false)}
        />
        <h5>Edit Preferences</h5>
      </div>
      <div className="edit-preference-icons">
        <span className={steps === 0 ? "red" : ""}>
          <AiOutlineUser />
        </span>
        <div className="space-between"></div>
        <span className={steps === 1 ? "red" : ""}>
          <FaUsers />
        </span>
        <div className="space-between"></div>
        <span className={steps === 2 ? "red" : ""}>
          <BsBag />
        </span>
        <div className="space-between"></div>
        <span className={steps === 3 ? "red" : ""}>
          <ImGlass2 />
        </span>
        <div className="space-between"></div>
        <span className={steps === 4 ? "red" : ""}>
          <BsAsterisk style={{ fontSize: "15px" }} />
        </span>
      </div>
      <div className="edit-preference-content">
        <div>
          <h4>{EditPreferenceLabels[steps]}</h4>
          <span onClick={() => clearSection(steps)}>CLEAR SECTION</span>
        </div>
        <div className="edit-basic">
          {steps === 0 ? (
            <>
              <div>
                <label htmlFor="address">Location</label>
                <input
                  id="address"
                  type="text"
                  name="address"
                  placeholder="Enter location"
                  value={address}
                  onChange={handleOnChange}
                />
              </div>
              <div>
                <label htmlFor="ageRangeFrom">Age Range From</label>
                <select
                  name="ageRangeFrom"
                  id=""
                  value={ageRangeFrom}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.age?.slice().sort((a, b) => a.value - b.value).map((elem, index) => {
                      return (
                        <option key={index} value={elem.value}>
                          {elem.value}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <label htmlFor="ageRangeTo">Age Range To</label>
                <select
                  name="ageRangeTo"
                  id=""
                  value={ageRangeTo}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.age?.slice().sort((a, b) => a.value - b.value).map((elem, index) => {
                      return (
                        <option key={index} value={elem.value}>
                          {elem.value}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <label htmlFor="heightRangeFrom">Height Range From</label>
                <select
                  name="heightRangeFrom"
                  id=""
                  value={heightRangeFrom}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.height?.slice().sort((a, b) => a.value - b.value).map((elem, index) => {
                      return (
                        <option key={index} value={elem.value}>
                          {elem.value}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <label htmlFor="heightRangeTo">Height Range To</label>
                <select
                  name="heightRangeTo"
                  id=""
                  value={heightRangeTo}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.height?.slice().sort((a, b) => a.value - b.value).map((elem, index) => {
                      return (
                        <option key={index} value={elem.value}>
                          {elem.value}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div>
                <label htmlFor="marital">Marital Status</label>
                <select
                  name="marital"
                  id=""
                  value={marital}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.maritalStatus.map((elem, index) => (
                      <option key={index} value={elem.value}>
                        {elem.value}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label htmlFor="education">Education</label>
                <input
                  type="text"
                  name="education"
                  placeholder="Enter education"
                  value={education}
                  onChange={handleOnChange}
                />
              </div>
            </>
          ) : null}
          {steps === 1 ? (
            <>
              <div>
                <label htmlFor="familyType">Family Type</label>
                <select
                  name="familyType"
                  id="familyType"
                  value={familyType}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.familyType?.map((elem, index) => (
                      <option key={index} value={elem.value}>
                        {elem.value}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label htmlFor="ageRangeFrom">Family Status</label>
                <select
                  name="familyStatus"
                  id="familyStatus"
                  value={familyStatus}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.familyStatus?.map((elem, index) => (
                      <option key={index} value={elem.value}>
                        {elem.value}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label htmlFor="familyValue">Family Values</label>
                <select
                  name="familyValue"
                  id="familyValue"
                  value={familyValue}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.familyValues?.map((elem, index) => (
                      <option key={index} value={elem.value}>
                        {elem.value}
                      </option>
                    ))}
                </select>
              </div>
            </>
          ) : null}
          {steps === 2 ? (
            <>
              <div>
                <label htmlFor="occupation">Occupation</label>
                <input
                  type="text"
                  name="occupation"
                  placeholder="occupation"
                  value={occupation}
                  onChange={handleOnChange}
                  autoFocus
                />
              </div>
              <div>
                <label htmlFor="annualIncome">Annual Income</label>
                <select
                  name="annualIncome"
                  id=""
                  value={annualIncome}
                  onChange={handleOnChange}
                >
                  <option value="none">{OPTION_SELECT_EMPTY}</option>
                    {masterData.annualIncome?.slice().sort((a, b) => a.value - b.value).map((elem, index) => (
                      <option key={index} value={elem.value}>
                        {elem.value}
                      </option>
                    ))}
                </select>
              </div>
            </>
          ) : null}
          {steps === 3 ? (
            <>
              <div>
                <label htmlFor="drinkingHabits">Drinking Habits</label>
                <div className="mypreference-radio">
                  <input
                    id="drinking1"
                    type="radio"
                    name="drinking"
                    value="Yes"
                    checked={drinking === "Yes"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="drinking1">Yes</label>
                  <input
                    id="drinking2"
                    type="radio"
                    name="drinking"
                    value="No"
                    checked={drinking === "No"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="drinking2">No</label>
                </div>
              </div>
              <div>
                <label htmlFor="smokingHabits">Smoking Habits</label>
                <div className="mypreference-radio">
                  <input
                    id="smoking1"
                    type="radio"
                    name="smoking"
                    value="Yes"
                    checked={smoking === "Yes"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="smoking1">Yes</label>
                  <input
                    id="smoking2"
                    type="radio"
                    name="smoking"
                    value="No"
                    checked={smoking === "No"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="smoking2">No</label>
                </div>
              </div>
            </>
          ) : null}
          {steps === 4 ? (
            <>
              <div>
                <label htmlFor="openToRelocate">Open to Relocate</label>
                <div className="mypreference-radio">
                  <input
                    id="yes3"
                    type="radio"
                    name="openToRelocate"
                    value="Yes"
                    checked={openToRelocate === "Yes"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="yes3">Yes</label>
                  <input
                    id="no3"
                    type="radio"
                    name="openToRelocate"
                    value="No"
                    checked={openToRelocate === "No"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="no3">No</label>
                </div>
              </div>
              <div>
                <label htmlFor="horoscopeMatching">Horoscope Matching</label>
                <div className="mypreference-radio">
                  <input
                    id="yes4"
                    type="radio"
                    name="horoscopeMatching"
                    value="Yes"
                    checked={horoscopeMatching === "Yes"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="yes4">Yes</label>
                  <input
                    id="no4"
                    type="radio"
                    name="horoscopeMatching"
                    value="No"
                    checked={horoscopeMatching === "No"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="no4">No</label>
                </div>
              </div>
              <div>
                <label htmlFor="profilePictures">Profile with Pictures</label>
                <div className="mypreference-radio">
                  <input
                    id="yes"
                    type="radio"
                    name="profilePictures"
                    value="Yes"
                    checked={profilePictures === "Yes"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="yes">Yes</label>
                  <input
                    id="no"
                    type="radio"
                    name="profilePictures"
                    value="No"
                    checked={profilePictures === "No"}
                    onChange={handleOnChange}
                  />{" "}
                  <label htmlFor="no">No</label>
                </div>
              </div>
              <div>
                <label htmlFor="physicalStatus">Physical Status</label>
                <input
                  type="text"
                  placeholder="Enter physical status"
                  name="physicalStatus"
                  value={physicalStatus}
                  onChange={handleOnChange}
                  autoFocus
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="save-next-btn">
        {steps === 0 ? null : (
          <button onClick={() => setSteps(steps - 1)}>
            <MdArrowBackIosNew />
            Back
          </button>
        )}
        {steps === 4 ? (
          <button className="save-btn" onClick={postPreference}>
            Save
          </button>
        ) : (
          <button
            className={steps === 0 ? "save-btn w-100" : "save-btn"}
            onClick={() => setSteps(steps + 1)}
          >
            Save & Next <MdArrowForwardIos />
          </button>
        )}
      </div>
    </div>
  );
}

export default MyPreferenceMobile;
