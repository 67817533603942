import React, { useEffect, useState } from "react";
import "./MessageModal.css";
import { figmaIcons } from "../../assets/FigmaIcons/figmaIcons";
import { toast } from "react-toastify";
import axios from "axios";
import server from "../../config/server.json";

const MessageModal = ({ isOpen, setIsOpen, onClose, children, currentCardId }) => {
  const [message, setMessage] = useState("")
  // useEffect is used to prevent scrolling in the background when the bottom sheet is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);
  // If the bottom sheet is not open, render nothing
  if (!isOpen) {
    return null;
  }

  const handleTextArea = (e) => {
    setMessage(e.target.value);
  }

  const sendMessage = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token")
      }
      const body = {
        to_user_id: currentCardId,
        message: message
      }
      const response = await axios.post(
        `${server.url.production}${server.api.POST_MESSAGE}`,
        body,
        { headers: headers }
      );
      if (response.status === 201) {
        toast.success("Sent", {
          autoClose: 3000,
          position: "bottom-right",
        });
        onClose();
      } else {
        toast.error("Something went wrong, please try again later", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Failed", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  }

  return (
    <div className="message-modal">
      <div className="message-content">
        <div className="message-head">
          <span>Message</span>
          <img
            src={figmaIcons.basil_cross}
            alt="cross"
            className="ModalCross"
            onClick={onClose}
          />
        </div>
        <div className="message-text">
          <textarea name="message" id="" placeholder="Please enter your message here." value={message} onChange={handleTextArea} />
        </div>
        <div className="message-btns">
          <button onClick={onClose}>Cancel</button>
          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};
export default MessageModal;
