import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import Fb from '../assets/fb.png'
import Insta from '../assets/insta.png'
import Twitter from '../assets/twitter.png'
import './Footer.css'

const Footer = () => {

  const footerLinks = {
    needHelp: [
      { id: 1, linkName: "Member Login", link: "#FIXME" },
      { id: 2, linkName: "Register Free", link: "/" },
      { id: 3, linkName: "Partner Search", link: "#FIXME" },
      { id: 4, linkName: "Contact", link: "/contact" },
    ],
    company: [
      { id: 1, linkName: "About", link: "/about" },
      { id: 2, linkName: "Testimonial", link: "/testimonial" },
      { id: 3, linkName: "FAQs", link: "/faqs" },
    ],
    privacy: [
      { id: 1, linkName: "Terms Of User", link: "/terms-conditions" },
      { id: 2, linkName: "Privacy Policy", link: "/privacy-policy" },
      { id: 3, linkName: "Report Missue", link: "/abuse" },
    ]
  }

  const location = useLocation();
  let pathname = location.pathname === "/" || location.pathname === "/home";

  return (
    <footer className={`d-none d-sm-block ${pathname ? "" : "d-none d-sm-block"}`}>
      <div className={`wrapper`}>
        <div className="footer-container">
          <ul className='need-help'>
            <li><h5>need help?</h5></li>
            {
              footerLinks.needHelp.map((item, index) => (
                <li key={index}><Link to={item.link} title={item.linkName}>{item.linkName}</Link></li>
              ))
            }
          </ul>
          <ul className="company">
            <li><h5>company</h5></li>
            {
              footerLinks.company.map((item, index) => (
                <li key={index}><Link to={item.link} title={item.linkName}>{item.linkName}</Link></li>
              ))
            }
          </ul>
          <ul className="privacy-you">
            <li><h5>privacy & you</h5></li>
            {
              footerLinks.privacy.map((item, index) => (
                <li key={index}><Link to={item.link} title={item.linkName}>{item.linkName}</Link></li>
              ))
            }
          </ul>
        </div>
        <div className="follows-us">
          <p>&copy;	copyrights techsierra-matrimonial.com 2022-2023. all rights reserved.</p>
          <ul className="social-links">
            <li><h5>follow us on:</h5></li>
            <li>
              <Link title='Instagram'><img src={Insta} alt="Instagram" /></Link>
              <Link title='Facebook'><img src={Fb} alt="Facebook" /></Link>
              <Link title='Twitter'><img src={Twitter} alt="Twitter" /></Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer;