import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import server from "../../config/server.json";
import { toast } from "react-toastify";

export const fetchRequestStatus = createAsyncThunk(
  "contactSharing/fetchRequestStatus",
  async (arg, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const { data } = await axios.get(
        `${server.url.production}${server.api.GET_CONTACT_APPROVAL_STATUS}`,
        { headers: headers }
      );
      return data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const createRequest = createAsyncThunk(
  "createRequest",
  async (data, { rejectWithValue }) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.post(
        `${server.url.production}${server.api.CREATE_CONTACT_APPROVAL_REQUEST}`,
        data,
        { headers: headers }
      );
      if (response.status === 201) {
        toast.success(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);
