import axios from "axios";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import BasicSearch from "./BasicSearch";
import AdvanceSearch from "./AdvanceSearch";
import MobileFilterPage from "./MobileFilterPage";
import React, { useState, useEffect, useRef } from "react";
import server from "../../../src/config/server.json";
import { useDispatch, useSelector } from "react-redux";
import searchOptionsData from "./SearchOptionsData.json";
import { useLocation, useNavigate } from "react-router-dom";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import { ProfileListCard } from "../ProfileListingPage/ProfileList";
import ProfileListMobile from "../ProfileListingPage/ProfileListMobile";
import { fetchRequestStatus } from "../../Redux/ContactSharing/ApiCalls";
import { getStatusSelector } from "../../Redux/ContactSharing/ContactSharingSlice";
import { 
  getOccupationData,
  getCasteData,
  getGenderData,
  getFamilyTypeData,
  getFamilyIncomeData,
  getBirthTimeData,
  getFamilyStatusData,
  getFamilyValuesData,
  getBloodGroupData,  
  getProfileCreatedByData, 
  getHeightData, 
  getMotherTongueData,
  getIncomeData,
  getOccupationCategoryData,
  getDietPreferencesData,
  getWeightData,
  getEducationDegreeData,
  geteducationCompletionYearData,
  getSpecializationData,
  getMartialData, 
  getHoroscopeData, 
  getLanguageData,
  getCountryData,
  getAgeData,
  getAnnualIncomeData
} from '../../Redux/Master/ApiMaster';

const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchResultsDiv = useRef(null);
  const [token, setToken] = useState("");
  const [gender, setGender] = useState("");
  const [gotra, setGotra] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [gotraIndex, setGotraIndex] = useState(null);
  // loading state is used to enable and disable the checkbox and buttons of advanced and basic search filters.
  const [loading, setLoading] = useState(false);
  // searchLoader state is responsible for the main loader while performing searching functionality.
  const [searchLoader, setSearchLoader] = useState(false);
  const SEARCH_FILTER = server.api.SEARCH_FILTER;
  const [filterArray, setFilterArray] = useState([]);
  const [maxAgeArray, setMaxAgeArray] = useState([]);
  // isModalOpen state is responsible for the rendering of modal.
  const [isModalOpen, setIsModalOpen] = useState(false);
  // modalLoader state is used to enable and disable the checkbox of the modal container.
  const [modalLoader, setModalLoader] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedField, setSelectedField] = useState("");
  const [maxHeightArray, setMaxHeightArray] = useState([]);
  const [filterArrayName, setFilterArrayName] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const [totalCount, setTotalCount] = useState("")
  const master = useSelector(state => state.masterData);
  const initialSearchOptionsData = cloneDeep(searchOptionsData);
  const [searchData, setSearchData] = useState(
    initialSearchOptionsData.searchField
  );
  const dispatch = useDispatch();
  const apprStatus = useSelector(getStatusSelector);

  // This function is used to toggle the filter button, it takes the current selected field array name as parameter and set the clicked button field name as selectedField
  // For eg : if we clicked on Family Status filter button it sets the selectedField = familyStatus.
  // Now extra params filterType is used to update the state of the basic search filter while we hover on the field of the basic search field.
  const toggleFiltersButton = (currentSelectedFieldArrayName, filterType) => {
    setPageNumber(1);
    if (filterType === "basic") {
      setSelectedField(currentSelectedFieldArrayName);
    } else {
      // if the current clicked filter button is already selected then we are going to deselect the button and setting selected field and filter array names as blank
      if (currentSelectedFieldArrayName === selectedField) {
        setSelectedField("");
        setFilterArrayName("");
        setFilterArray([]);
      }
      // if the current clicked filter button is not selected then we are setting current clicked filter button to selectedField
      else {
        setSelectedField(currentSelectedFieldArrayName);
        setFilterArrayName(currentSelectedFieldArrayName);
        setFilterArray(searchData[currentSelectedFieldArrayName].values);
      }
    }
  };

  // This function is used to toggle the checkbox and update the searchData useState by taking the value of the toggled checkbox.
  // For eg : you checked "Upper Class" then value = "Upper Class".
  // This function will find the index of the current value and toggle the checked of the current field.
  const toggleCheckbox = (value) => {
    let newFilterObject = { ...searchData };
    const index = newFilterObject[selectedField].values.findIndex(
      (item) => item.value === value
    );
    // Always set the negative of the previous state if previous value is true then currently we will set it to !true i.e, false.
    newFilterObject[selectedField].values[index].checked =
      !newFilterObject[selectedField].values[index].checked;
    generateRequest(value, "checkbox", "", index);
    setSearchData(newFilterObject);
  };

  // This function takes the current checked or unchecked value of the check box as parameter and add or delete from the state json searchData
  // For eg : if you checked the value "Upper Class" then "Upper Class" will be passed as parameter then value = "Upper Class"
  // if "Upper Class" is checked ...searchData.familyStatus.currentValues = ["Upper Class"]
  // if "Upper Class" is unchecked ...searchData.familyStatus.currentValues = [""]
  const updateCurrentValues = (value) => {
    const { currentValues } = searchData[selectedField];
    if (currentValues.includes(value)) {
      const index = currentValues.indexOf(value);
      currentValues.splice(index, 1);
    } else {
      currentValues.push(value);
    }
    setSearchData(
      (state) => (state[selectedField].currentValues = currentValues)
    );
  };

  // This function takes type, field, value and trimValueFrom as parameter and set the value of dropdown in state json searchData
  // For eg : (type="min", field="age",value="40",trimValueFrom="40")
  // The result will store in searchData as searchDate.age.minValue = "40"
  const setDropDownValue = (type, field, value, trimValueFrom) => {
    let newFilterObject = { ...searchData };
    // we are setting the value in minValue. If the type is "min"
    if (type === "min") {
      newFilterObject[selectedField].minValue = value;
      // We are only calling trimMaxValueStaringPoint function only in minValue array because the starting value of maxValue depends on minValue Array
      trimMaxValueStartingPoint(field, trimValueFrom);
    }
    // we are setting the value in maxValue. If the type is "max"
    else if (type === "max") {
      newFilterObject[selectedField].maxValue = value;
    }
    setSearchData(newFilterObject);
  };

  // This function is used to toggle the dropdown condition according to the type, field and value of the event.
  // This function is also responsible to pass the trimming value to the trimValueFrom function.
  // It also pass the changed value of dropdown to set the dropdown value
  // For eg : If you select maxHeight = 175.
  // type = "max", field = "height" and value = 175, dropdownValue = 175, trimValueFrom = 175
  const toggleDropdownCondition = (type, field, value) => {
    const dropdownValue = value === OPTION_SELECT_EMPTY ? "" : value;
    const trimValueFrom =
      type === "min"
        ? value === OPTION_SELECT_EMPTY
          ? OPTION_SELECT_EMPTY
          : value
        : "";
    setDropDownValue(type, field, dropdownValue, trimValueFrom);
  };

  // This function is used to toggle the dropdown and helps to update the state.
  // By spliting the events according to the type, field and value.
  // This function plays an important role in trimming the max values of dropdown and generate request
  // It takes type, field and value of the dropdown
  // For eg : If you select maxHeight = 175.
  // type = "max", field = "height" and value = 175.
  const toggleDropdown = (type, field, value) => {
    if (field === "age" || field === "height" || field === "familyIncome" || field === "annualIncome") {
      toggleDropdownCondition(type, field, value);
      generateRequest(value, "dropdown", type, "");
    } else {
      let newFilterObject = { ...searchData };
      newFilterObject.sortBy.currentSelectedValue = value;
      setSearchData(newFilterObject);
    }
  };

  // This function will take field and value of the minValue Array and dynamically iterate the maxValue array.
  // For eg : field = age, value = 40
  // then searchData.age.values.maxValueArray start from 41(next value of the parameter) to the end of maxValueArray;
  const trimMaxValueStartingPoint = (field, value) => {
    let newFilterObject = { ...searchData };
    let result = newFilterObject[field].values.minValueArray.filter(
      (item) => item.value > value
    );
    // if the user is selecting an non empty field for example value = 40 then we are adding an extra empty field at the start of the dropdown
    if (value !== OPTION_SELECT_EMPTY) {
      const emptyObj = {
        value: OPTION_SELECT_EMPTY,
        label: OPTION_SELECT_EMPTY,
      };
      result.unshift(emptyObj);
      newFilterObject[field].values.maxValueArray = result;
    }
    // else we are just setting the maxValueArray to minValueArray
    else {
      newFilterObject[field].values.maxValueArray =
        newFilterObject[field].values.minValueArray;
    }
    // Updating the maxValueArray in the searchData state
    setSearchData(newFilterObject);
  };

  const getPreviousFieldValue = (slicedUrl, previousFieldName) => {
    let startIndex = slicedUrl.indexOf(previousFieldName);
    if (startIndex === -1) return null;
    let parameterString = slicedUrl.slice(startIndex);
    let valueStartIndex = parameterString.indexOf("=") + 1;
    let valueEndIndex = parameterString.indexOf("&", valueStartIndex);
    if (valueEndIndex === -1) valueEndIndex = parameterString.length;
    let value = parameterString.slice(valueStartIndex, valueEndIndex);
    return value;
  };

  // This function is used to generate Search query parameters according to the selectedInputFieldValue, inputType, inputValueType and inputIndex of the updated input field.
  // It also adds and removes the search query parameters according to the searchData and sends the get request in every changes.
  // For eg : selecting minimum height = 152 then the resulted search query parameter will be
  // selectedInputFieldValue = 152, inputType = "dropdown", inputValueType = "min" and inputIndex parameter is optional for dropdown
  // inputIndex is only mandatory for inputType = "checkbox" because inputIndex is the key of that checkbox after iteration.
  const generateRequest = (
    selectedInputFieldValue,
    inputType,
    inputValueType,
    inputIndex
  ) => {
    var currentUrl = location.search.toString();
    let searchQuery = currentUrl;
    // For eg : we check "Upper Class" then inputType = "checkbox"
    if (inputType === "checkbox") {
      // newFilter = `&familyStatus[]=Upper Class`;
      const newFilter = `&${selectedField}[]=${selectedInputFieldValue}`;
      // If the value is checked then we will add the search Query to url and navigate to the updated URL
      if (searchData[selectedField].values[inputIndex].checked) {
        searchQuery = currentUrl + newFilter;
        navigate(currentUrl + newFilter);
      }
      // If the value is unchecked then we will remove the value from the searchQuery using replace function and navigate to updated URL
      else {
        const updatedUrl = currentUrl
          .replace(/%20/g, " ")
          .replace(newFilter, "");
        searchQuery = updatedUrl;
        navigate(updatedUrl);
      }
      // As the value of the checkbox is changing then we will update currentValues array in searchData state json.
      updateCurrentValues(selectedInputFieldValue);
    }
    // For eg : we select minAge = 35 then inputType = "dropdown"
    else if (inputType === "dropdown") {
      const valueTypeToFieldName = {
        min: "min",
        max: "max",
      };
      const fieldName = valueTypeToFieldName[inputValueType] + searchData[selectedField].label?.replace(/\s/g, '');
      const fieldValue = selectedInputFieldValue === OPTION_SELECT_EMPTY ? "" : selectedInputFieldValue;
      // fieldName = minAge, fieldValue = 35 because here selectedInputFieldValue != "--select--"
      const filterField = `&${fieldName}=${fieldValue}`;
      const filterFieldName = `&${fieldName}=`;
      // filterField = `&minAge=35`  filterFieldName = `&minAge=`
      let updatedUrl;
      // if the selected field is already present we have to replace the field with current selected field
      if (currentUrl.includes(filterFieldName)) {
        // slicedUrl will store the sliced string till the filterFieldName is founded
        const slicedUrl = currentUrl.slice(currentUrl.indexOf(filterFieldName));
        let previousFieldValue = getPreviousFieldValue(slicedUrl, filterFieldName);
        let previousField = `${filterFieldName}${previousFieldValue}`;
        // Now replacing the previous field to the filterField using replace function.
        updatedUrl =
          selectedInputFieldValue === OPTION_SELECT_EMPTY
            ? currentUrl.replace(previousField, "")
            : currentUrl.replace(previousField, filterField);
      }
      // if the selected is not present then we are adding the filterField using string concatination
      else {
        updatedUrl = currentUrl + filterField;
      }
      // Updating the searchQuery with the lastest filterField values and navigating.
      searchQuery = updatedUrl;
      navigate(searchQuery);
    }
    // sending the lastest searchQuery after every single change.
    if (showFilters === false) {
      getSearchRequest(searchQuery);
    }
  };

  const generateMobileRequest = () => {
    var currentUrl = location.search.toString();
    let searchQuery = currentUrl;
    getSearchRequest(searchQuery);
  };

  // This function is used to get the search result using searchQuery as parameter.
  // It sets the result in searchResults state variable.
  const getSearchRequest = async (searchQuery) => {
    // This logic will toggle the loader of the modal only if modal is open else it will toggle the basic loader.
    isModalOpen ? setModalLoader(true) : setLoading(true);
    // Before sending search request, we are setting the main loader to true.
    setSearchLoader(true);
    try {
      const config = {
        "Content-type": "application/json",
        token: token,
      };
      const response = await axios.get(
        `${server.url.production}${SEARCH_FILTER}${searchQuery}&pageNumber=${pageNumber}`,
        { headers: config }
      );
      if (response.status === 200) {
        if (pageNumber === 1) {
          setTotalCount(() => response.data.totalCount);
          setSearchResults(response.data.users);
        } else {
          setSearchResults([...searchResults, ...response.data.users]);
        }
        // This logic will toggle the loader of the modal only if modal is open else it will toggle the basic loader.
        isModalOpen ? setModalLoader(false) : setLoading(false);
        // After getting the search response, we are setting the main loader to false.
        setSearchLoader(false);
        if (response.data.users.length === 0) {
          toast.error("No result found", {
            autoClose: 1000,
            position: "bottom-right",
          });
        } else {
          setPageNumber(pageNumber + 1);
          toast.success(response.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      } else {
        toast.error("Something went wrong.", {
          autoClose: 3000,
          position: "bottom-right",
        });
        console.log("Something went wrong.");
      }
    } catch (e) {
      if (e.response && e.response.status !== 200) {
        toast.error(e.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
        console.log(e.response.data.message);
      } else {
        toast.error("Something went wrong, please try again later.", {
          autoClose: 3000,
          position: "bottom-right",
        });
        console.log("Something went wrong, please try again later.");
      }
    }
    navigate(`/search${searchQuery}`);
  };

  // This function takes an json and return true if the json is valid and false if json is invalid
  const isJson = (json) => {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  const resetFilterBug = () => {
    setLoading(true);
    setSelectedField("");
    setSearchData(initialSearchOptionsData.searchField);
    let initialQuery = `?gender=${gender === "M" ? "F" : "M"}`;
    if (showFilters) {
      navigate(`/search${initialQuery}`);
      setLoading(false)
    }
    else {
      getSearchRequest(initialQuery);
    }
    fetchSearchOptionDataJson();
  };

  const getMoreResults = async () => {
    var currentUrl = location.search.toString();
    let searchQuery = currentUrl;
    await getSearchRequest(searchQuery);
  }

  const fetchSearchOptionDataJson = () => { 
    dispatch(fetchRequestStatus());
    dispatch(getOccupationData());
    dispatch(getCasteData());
    dispatch(getGenderData());
    dispatch(getFamilyTypeData());
    dispatch(getFamilyIncomeData());
    dispatch(getBirthTimeData());
    dispatch(getFamilyStatusData());                              
    dispatch(getFamilyValuesData());
    dispatch(getBloodGroupData());  
    dispatch(getProfileCreatedByData()); 
    dispatch(getHeightData()); 
    dispatch(getMotherTongueData());
    dispatch(getIncomeData());
    dispatch(getOccupationCategoryData());
    dispatch(getDietPreferencesData());
    dispatch(getWeightData());
    dispatch(getEducationDegreeData());
    dispatch(geteducationCompletionYearData());
    dispatch(getSpecializationData());
    dispatch(getMartialData());
    dispatch(getHoroscopeData());
    dispatch(getLanguageData());
    dispatch(getCountryData());
    dispatch(getAgeData());
    dispatch(getAnnualIncomeData());
  }

  const updateSearchOptionDataJson = () => {
    const updatedSearchData = { ...searchData };
    Object.entries(updatedSearchData).forEach(entry => {
      const key = entry[0];
      const value = entry[1];
      if (value.inputType === "checkbox" && 
      (
        key === "familyStatus" || 
        key === "familyValues" || 
        key === "familyType" || 
        key === "qualification" || 
        key === "maritalStatus" || 
        key === "profileCreatedBy" ||
        key === "occupationCategory" ||
        key === "horoscope" ||
        key === "language" ||
        key === "country" ||
        key === "dietPreference"
      )) {
        const newEntry = master[key]?.map(elem => ({
          "value": elem.value, 
          "checked" : false
        }));
        updatedSearchData[key].values = newEntry;
      }
      if (value.inputType === "dropdown" && 
      (
        key === "familyIncome" ||
        key === "height" ||
        key === "age" ||
        key === "annualIncome" 
      )){
        const blankEntry = {
          "value" : "--select--",
          "label" : "--select--"
        }
        const newEntry = master[key]?.map(elem => ({
          "value": elem.value,
          "label" : elem.value,
        }));
        newEntry?.sort((a, b) => a.value - b.value);
        newEntry?.unshift(blankEntry);
        updatedSearchData[key].values.minValueArray = newEntry;
        updatedSearchData[key].values.maxValueArray = newEntry;
      }
    });
    setSearchData(updatedSearchData);
  };
  
  useEffect(() => {
    // fetching user from localstorage
    let loggedUser = localStorage.getItem("user");
    // if user exist then check the json is valid or not and if user is not exist then redirect to "/"
    if (loggedUser && isJson(loggedUser)) {
      loggedUser = JSON.parse(loggedUser);
      setGender(loggedUser.gender);
      setToken(localStorage.getItem("token"));
      if (loggedUser?.gotra) {
        setGotra(loggedUser.gotra);
      }
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    // If gender exist then we are going to send opposite gender in search URL
    if (gender) {
      // If gender is M then we'll add gender=F in search URL and vice-versa
      if (gender === "M") {
        getSearchRequest(`?gender=F`);
      } else if (gender === "F") {
        getSearchRequest(`?gender=M`);
      }
    }
    if (gotra) {
      setGotraIndex(searchData.gotra.values.findIndex(item => item.value === gotra));
    }
  }, [gender]); //eslint-disable-line

  useEffect(() => {   
    fetchSearchOptionDataJson();
  }, [dispatch]); //eslint-disable-line

  useEffect(()=> {
    updateSearchOptionDataJson();
    //eslint-disable-next-line
  }, [master])

  useEffect(() => {
    const handleScroll = () => {
      const div = searchResultsDiv.current;
      if (div && div.scrollTop + div.clientHeight === div.scrollHeight) {
        getMoreResults(); 
      }
    };;

    const div = searchResultsDiv.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, [searchResults]); //eslint-disable-line

  return (
    <>
      {showFilters && (
        <MobileFilterPage
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          searchData={searchData}
          resetFilter={resetFilter}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          toggleFiltersButton={toggleFiltersButton}
          loading={loading}
          filterArray={filterArray}
          filterArrayName={filterArrayName}
          toggleCheckbox={toggleCheckbox}
          generateMobileRequest={generateMobileRequest}
          toggleDropdown={toggleDropdown}
          setSearchData={setSearchData}
          gotra={gotra}
          gotraIndex={gotraIndex}
          resetFilterBug={resetFilterBug}
        />
      )}
      <>
        {" "}
        <div className="main-search-container">
          <div className="row d-none d-sm-block">
            <div>
              <AdvanceSearch
                filterArray={filterArray}
                selectedField={selectedField}
                filterArrayName={filterArrayName}
                searchData={searchData}
                toggleFiltersButton={toggleFiltersButton}
                toggleCheckbox={toggleCheckbox}
                loading={loading}
                searchResults={searchResults}
                toggleDropdown={toggleDropdown}
              />
            </div>
          </div>
        </div>
        <div className="search-container2">
          <div className="search-left-container d-none d-sm-block">
            <BasicSearch
              filterArray={filterArray}
              selectedField={selectedField}
              filterArrayName={filterArrayName}
              searchData={searchData}
              toggleFiltersButton={toggleFiltersButton}
              toggleCheckbox={toggleCheckbox}
              trimMaxValueStartingPoint={trimMaxValueStartingPoint}
              maxAgeArray={maxAgeArray}
              maxHeightArray={maxHeightArray}
              setMaxAgeArray={setMaxAgeArray}
              setMaxHeightArray={setMaxHeightArray}
              toggleDropdown={toggleDropdown}
              loading={loading}
              setLoading={setLoading}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              modalLoader={modalLoader}
              setModalLoader={setModalLoader}
              gotra={gotra}
              gotraIndex={gotraIndex}
            />
          </div>
          <div className="search-right-container">
            <div className="order-sm-2 mx-auto">
              {loading && searchLoader ? (
                <div className="searchPageLoader">
                  <div className="view-loader-container">
                    <div className="lds-roller">
                      {[...Array(8)].map((_, index) => (
                        <div key={index}></div>
                      ))}
                    </div>
                            
                  </div>
                </div>
              ) : searchResults.length >= 0 ? (
                <>
                  {searchResults.length === 0 ? (
                    <div className="mt-5 d-none d-sm-block">
                      <h1 className="text-center">"No Results Found"</h1>
                    </div>
                  ) : (
                    <div className="search_div d-none d-sm-block">
                      <div className="search_result vertical" ref={searchResultsDiv}>
                        <div className="profile_feed_container vertical" >
                          {searchResults.map((data) => (
                            <ProfileListCard
                              key={data.userDetails._id}
                              data={data}
                              apprStatus={apprStatus}
                              id="searchResult"
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    style={
                      showFilters
                        ? { display: "none !important" }
                        : { display: "block !important" }
                    }
                    className="search-div-mobile col-sm-12 d-block d-sm-none"
                  >
                    <ProfileListMobile
                      data={searchResults}
                      apprStatus={apprStatus}
                      searchResultsDiv={searchResultsDiv}
                      resetFilter={resetFilter}
                      setResetFilter={setResetFilter}
                      setShowFilters={setShowFilters}
                      resetFilterBug={resetFilterBug}
                      loading={loading}
                      getMoreResults={getMoreResults}
                      pageNumber={(pageNumber - 1)}
                      totalCount={totalCount}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SearchPage;