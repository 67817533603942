import { React, useState } from "react";
import "./InterestedProfile.css";
import UserImg from "../../assets/dummy-user.png";
import { Profiles } from "./Profiles";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { GoTriangleRight } from "react-icons/go";
import { FcApproval } from "react-icons/fc";
import { FcOk } from "react-icons/fc";
import { CgMenu } from "react-icons/cg";
import { Helmet } from "react-helmet";

function Profile() {
  return (
    <>
      {Profiles.map((elem, index) => {
        return (
          <div className="interested-content" key={index}>
            <div className="interested-content1">
              <div className="interested-content1-top">
                <h4>Profile created by XYZ</h4>
                <h4>|</h4>
                <h4>
                  Profile ID - 839t9348r
                  <FcApproval className="interested-icons" />
                </h4>
                <p>
                  <button>
                    <RiDeleteBin5Line className="interested-icons" />
                  </button>
                </p>
              </div>
            </div>
            <div className="interested-content2">
              <div className="interested-content2-left">
                <img src={UserImg} alt="User" />
                <button className="light-button">View Album</button>
                <button className="dark-button">
                  Connect Now <FcOk className="interested-icons" />
                </button>
              </div>
              <div className="interested-content2-right">
                <div className="user-online-interested">
                  <p>Online few hours ago</p>
                </div>

                <div className="user-profile-interested">
                  {
                    <>
                      <div className="view-full">
                        <button className="text-button-interested">
                          <ImProfile className="interested-icons" />
                          <span>View Full Profile</span>
                        </button>
                      </div>
                      <p className="interested-name-element">{elem.name}</p>
                      <p>
                        <strong> Age: </strong> {elem.age}
                      </p>
                      <p>
                        <strong> Height:</strong> {elem.height}
                      </p>
                      <p>
                        <strong> Zodiac:</strong> {elem.zodiac}
                      </p>
                      <p>
                        <strong> Language:</strong> {elem.language}
                      </p>
                      <p>
                        <strong> Occupation:</strong> {elem.occupation}
                      </p>
                      <p>
                        <strong> Number:</strong> {elem.number}
                      </p>
                      <p>
                        <strong> Email ID:</strong> {elem.email}
                      </p>
                      <p>
                        <strong> Address:</strong> {elem.address}
                      </p>
                    </>
                  }
                </div>
                <div className="para-element-interested">
                  <p>
                    Let me introduce myself. In terms of education, I have
                    completed Bachelors. I share a close bond with my family
                    members. Someone calm yet confident in nature...
                    <button className="text-button-interested">
                      <span>Read More</span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
function InterestedProfile() {
  const [isActive, setActive] = useState(false);
  const ToggleClass = () => setActive(!isActive);

  return (
    <div>
      <div className="body">
        <Helmet>
          <title>Interested-Profile</title>
          <meta
            name="description"
            content="A service that allows users to discover the types of occupations and work activities they would like, and find a similar partner."
          />
        </Helmet>
        <div className="user-welcome">
          <div className="wrapper-heading">
            <h3>matrimonydemo.com welcomes you</h3>
          </div>
        </div>
        <div className="page-heading">
          <div className="wrapper-heading">
            <h3 className="page-heading-text">My interested profiles</h3>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div>
          <button className="interested-side-menu" onClick={ToggleClass}>
            <CgMenu className="interested-icons" />
          </button>
        </div>
        <div
          className={
            isActive ? "interested-container active" : "interested-container"
          }
        >
          <div
            className={isActive ? "interested-left-container active" : "interested-left-container"}
          >
            <div className="interested-matches">
              <h4>Matches</h4>
              <div>
                <p>
                  <GoTriangleRight className="interested-icons" />
                  New Matches
                </p>
              </div>
              <div>
                <p>
                  <GoTriangleRight className="interested-icons" />
                  Shortlisted Profiles (25)
                </p>
              </div>
              <div>
                <p>
                  <GoTriangleRight className="interested-icons" />
                  Interested Profiles (15)
                </p>
              </div>
            </div>
            <div className="interested-search">
              <div>
                <h4>Search by Profile ID</h4>
              </div>
              <div className="interested-search-content">
                <input type="text" placeholder="Enter Profile ID" />
                <button className="dark-button">Search</button>
              </div>
            </div>
            <div className="interested-similar">
              <h4>View Similar Profiles</h4>
              <div className="interested-similar-profiles">
                <div className="similar-user-image">
                  <img src={UserImg} alt="User 1" style={{ width: "100%" }} />
                </div>
                <div className="similar-user-content">
                  <p>Mrs. Laxmi</p>
                  <p>ID: 564656556</p>
                  <p>29 yrs. 5ft 5in, Hindu, MBA...</p>
                </div>
              </div>
              <div className="interested-similar-profiles">
                <div className="similar-user-image">
                  <img src={UserImg} alt="User 2" style={{ width: "100%" }} />
                </div>
                <div className="similar-user-content">
                  <p>Mrs. Laxmi</p>
                  <p>ID: 564656556</p>
                  <p>29 yrs. 5ft 5in, Hindu, MBA...</p>
                </div>
              </div>
              <div className="interested-similar-profiles">
                <div className="similar-user-image">
                  <img src={UserImg} alt="User 3" style={{ width: "100%" }} />
                </div>
                <div className="similar-user-content">
                  <p>Mrs. Laxmi</p>
                  <p>ID: 564656556</p>
                  <p>29 yrs. 5ft 5in, Hindu, MBA...</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={isActive ? "interested-right-container active" : "interested-right-container"}
          >
            <Profile />
          </div>
        </div>
      </div>
    </div>
  );
}
export default InterestedProfile;
