import React from "react";
import "./FAQs.css";
import faqsData from "./Questions/faqsData.js";
import MyQuestons from "./MyQuestion";
import { Helmet } from "react-helmet";

function FAQs() {
  return (
    <div className="body">
      <Helmet>
        <title>FQAs</title>
        <meta
          name="description"
          content="Please go through our frequently asked questions. If you still can't find your answers, please feel free to contact us 24 x 7 or call us."
        />
      </Helmet>
      <div className="page-heading">
        <div className="wrapper-heading">
          <h3 className="page-heading-text">FAQs</h3>
        </div>
      </div>
      <div className="wrapper">
        <div className="question">
          <h3>frequently asked qusetions [?]</h3>
          {faqsData.map((elem) => {
            const { id } = elem;
            return (
              <MyQuestons
                key={id}
                question={elem.question}
                answer={elem.answer}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FAQs;
