import React, { useEffect, useMemo } from "react";
import axios from "axios";
import server from "../../config/server.json";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./ProfileViewMob.css";
import CareerIcon from "../../assets/WorkIcon.png";
import FamilyIcon from "../../assets/FamilyIcon.png";
import ReligionIcon from "../../assets/StarIcon.png";
import EducationIcon from "../../assets/EducationIcon.png";
import defaultImg from "../../assets/avatar_placeholder.png";
import ContactaddressIcon from "../../assets/AddressIcon.png";
import OtherinformationIcon from "../../assets/OtherinformationIcon.png";
import PersonalInformationIcon from "../../assets/PersonalInformationIcon.png";
import { figmaIcons } from "../../assets/FigmaIcons/figmaIcons";
import {
  createRequest,
  fetchRequestStatus,
} from "../../Redux/ContactSharing/ApiCalls";
import MessageModal from "../../components/Modal/MessageModal";
import ShareBtn from "../../assets/ProfileMobileImages/Share-Inactive.png";
import CloseModalImage from "../../assets/ProfileMobileImages/close-model.png";
import { useDispatch, useSelector } from "react-redux";
import { getStatusSelector } from "../../Redux/ContactSharing/ContactSharingSlice";
import { useNavigate } from "react-router-dom";
import MultipleImagesSlider from "../../components/MultipleImagesSlider/MultipleImagesSlider";

const ProfileViewMob = ({ userData, userProfilePhoto, onBack, setIsBottomSheetOpen }) => {
  const user = userData;
  const [multipleImages, setMultipleImages] = useState([]);
  const userDetails = userData?.userDetails;
  const [showSlider, setShowSlider] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messageModal, setMessageModal] = useState(false);
  const [currentCardId, setCurrentCardId] = useState(null);
  const [contactModal, setContactModal] = useState(false);
  const dispatch = useDispatch();
  const apprStatus = useSelector(getStatusSelector);
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (onBack) {
      onBack();
      if (setIsBottomSheetOpen) {
        setIsBottomSheetOpen(false)
      }
    }
    else {
      navigate('/search')
    }
  };

  const hashedData = (userAsterikData, appStatus, data) => {
    const foundElem = appStatus.find(
      (elem) => elem.request_to === data?._id && elem.approval_status === 1
    );

    if (foundElem) {
      return userAsterikData === data?.phone_number
        ? data?.phone_number?.toString()
        : data?.email_id;
    } else {
      return userAsterikData === data?.phone_number
        ? data?.phone_number?.toString().replace(/\d/g, "*")
        : data?.email_id?.replace(/[a-zA-Z1-9](?=.*@)/g, "*");
    }
  };

  const formatDOB = (unFormattedDate) => {
    if (unFormattedDate) {
      let dateParts = unFormattedDate.split("-");
      let date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      let formattedDate = new Intl.DateTimeFormat("en", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).format(date);
      return formattedDate;
    }
    return "N/A";
  };

  const getMultipleImages = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_MULTIPLE_IMAGES}/${userData?._id}`,
        {
          headers: headers,
        }
      );
      if (response?.data) {
        setMultipleImages(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (userData) {
      getMultipleImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const FeaturedCard = useMemo(() => {
    return (
      <>
        {multipleImages?.map((image, index) => (
          <img
            key={index}
            src={image?.profileImages}
            alt={userData?.first_name}
            className="rounded featuredImage"
          />
        ))}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleImages]);

  const openMessageModal = (_id) => {
    setMessageModal(true);
    setCurrentCardId(_id);
  };
  const closeMessageModal = () => {
    setMessageModal(false);
  };

  const sendRequest = async () => {
    try {
      const body = {
        request_to: currentCardId,
        approval_status: 0,
      };
      // let approvalStatus = apprStatus.status.filter((x) => x.request_to === currentCardId);
      const filteredStatus = apprStatus.status.reduce((filteredArray, item) => {
        if (item.request_to === currentCardId) {
          filteredArray.push(item);
        }
        return filteredArray;

      }, []);

      if (filteredStatus.length > 1) {
        toast.info("Already Sent!", {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.success("Sent!", {
          autoClose: 3000,
          position: "bottom-right",
        });
        dispatch(createRequest(body));
        dispatch(fetchRequestStatus());
      }
    } catch (error) {
      console.log(error)
    }
    setContactModal(false)
  };

  const openContactModal = (_id) => {
    setContactModal(true)
    setCurrentCardId(_id);
    setCurrentCardId((state) => {
      return state;
    })
  }

  return (
    <>
      <div className="profileViewMobContainer col-sm-12 d-block d-sm-none ">
        <div className=" backToHome mx-2 d-flex align-item-center">
          <img src={figmaIcons.BackIcon} alt="reload" className="mx-3" onClick={handleBackButton} />
          <span style={{ color: "black", size: "14px", fontWeight: 400 }}>
            Home
          </span>
        </div>
        <div className=" d-flex justify-content-between mx-3 align-items-center ">
          <span className="d-flex align-items-center fullName mx-2">
            {userData?.first_name} {userData?.last_name}
          </span>
          <span className=" pdf px-3 py-1 rounded ">
            {" "}
            <img src={figmaIcons.PdfIcon} alt="reload" />
            PDF
          </span>
        </div>
        <div className="m-auto d-flex justify-content-around mx-2 my-3">
          <div className="col-4">
            {userProfilePhoto ? (
              <img
                src={userProfilePhoto}
                alt=" unable to load "
                className=" profileImage rounded+50% d-block"
              />
            ) : (
              <img
                src={defaultImg}
                alt=" unable to load "
                className="profileImage rounded+50% d-block w-100"
              />
            )}
            {/* <img src={GalleryIcon} alt="Reload"  className="galleryIcon"/> */}
          </div>
          <div className="myProfileMobdetail col-7">
            <ul className="ulStyle my-1">
              <li className="textSmall list">
                {userDetails?.age} Years | {userDetails?.height || "NA"} ft{" "}
              </li>
              <li className="textSmall list">
                {userDetails?.horoscope} / {userDetails?.religion}
              </li>
              <li className="textSmall  list">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi.
              </li>
            </ul>
            {/* <p className="m-0">
              <img src={figmaIcons.VerifiedIcon} alt="Reload" style={{height:"22px",width:"21px"}}/>
              <span className="textSmall px-2 text-decoration-underline text-secondary">Verified User*</span>
            </p> */}
          </div>
        </div>
        <div
          className="alert alert-danger mt-4 mx-3 d-flex justify-content-center align-items-center textSmall"
          role="alert"
        >
          <span className="mx-2 d-flex align-item-center">
            <img src={figmaIcons.HeartIcon} alt=" Unable to load" />
          </span>
          <span>
            {" "}
            <span className="shortlistedsection">
              Add to 'Shortlisted Section'
            </span>
          </span>
        </div>
        <div className="d-flex d-flex justify-content-between mx-3 mb-2">
          <span className=" messageButton d-flex align-items-center justify-content-center textSmall  rounded"
            onClick={() => openMessageModal(userData?._id)}>
            {" "}
            <img src={figmaIcons.MessageIcon} alt="Unable to load" />
            <span className="mx-1">
              {" "}
              <b>Message</b>
            </span>

          </span>
          {messageModal ? (
            <MessageModal
              isOpen={messageModal}
              setIsOpen={openMessageModal}
              onClose={closeMessageModal}
              currentCardId={currentCardId}
            />
          ) : null}
          <span className=" contactButton d-flex align-items-center justify-content-center textSmall rounded"
            onClick={() => openContactModal(userData?._id)}>
            <img src={figmaIcons.SendIcon} alt=" Unable to load" />
            <span className="mx-1 ">
              <b>Contact Request</b>
            </span>
          </span>
        </div>
        <hr className="hr" />
        <div className="featured">
          <h6 className="mx-3">Featured</h6>
          {isLoading ? (
            <div className="view-loader-container">
              <div className="lds-roller">
                {[...Array(8)].map((_, index) => (
                  <div key={index}></div>
                ))}
              </div>
            </div>
          ) : <div className="featuredImages mx-3 d-flex" onClick={() => setShowSlider(true)}>{multipleImages?.length > 0 ? FeaturedCard : <h4>No Images Found</h4>}</div>
          }

        </div>
        <hr className="hr" />
        <h6 className="mx-3 my-3">Details</h6>
        <div className="col-11 accordionItem">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item px-0 py-2 border-bottom">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  <img
                    src={PersonalInformationIcon}
                    alt=" Reload"
                    style={{ marginRight: "10px" }}
                  />
                  <b className="textBold">Personal information</b>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body px-5 p-0 textSmall">
                  First name: {user?.first_name} <br />
                  Last name: {user?.last_name || "NA"}
                  <br />
                  Gender: {user?.gender === "M" ? "Male" : "Female"}
                  <br />
                  Age: {userDetails?.age || "NA"} years
                  <br />
                  Date of Birth:{" "}
                  {userDetails?.dob ? formatDOB(userDetails.dob) : "NA"}
                  <br />
                  Marital status: {userDetails?.marital || "NA"}
                  <br />
                </div>
              </div>
            </div>
            <div className="accordion-item px-0 py-2 border-bottom">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  <img
                    src={ReligionIcon}
                    alt=" Reload"
                    style={{ marginRight: "10px" }}
                  />
                  <b className="textBold">Culture & Religion</b>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body px-5 p-0 textSmall">
                  Religion: {userDetails?.religion || "NA"} <br />
                  Horoscope: {userDetails?.horoscope || "NA"}
                </div>
              </div>
            </div>
            <div className="accordion-item px-0 py-2 border-bottom">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  <img
                    src={FamilyIcon}
                    alt=" Reload"
                    style={{ marginRight: "10px" }}
                  />
                  <b className="textBold">Family</b>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body px-5 p-0 textSmall">
                  Father's name: {userDetails?.fatherName || "NA"} <br />
                  Mother's name: {userDetails?.motherName || "NA"} <br />
                  Gotra: {userDetails?.gotra || "NA"} <br />
                  Mama surname: {userDetails?.mamaSurname || "NA"} <br />
                  Native place: {userDetails?.nativePlace || "NA"} <br />
                  Family status: {userDetails?.familyStatus || "NA"} <br />
                  Family income (annual): {userDetails?.familyIncome || "NA"}{" "}
                  <br />
                  Family-type: {userDetails?.familyType || "NA"} <br />
                </div>
              </div>
            </div>
            <div className="accordion-item px-0 py-2 border-bottom">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  <img
                    src={OtherinformationIcon}
                    alt=" Reload"
                    style={{ marginRight: "10px" }}
                  />
                  <b className="textBold">Other information</b>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body px-5 p-0 textSmall">
                  Height: {userDetails?.height * 0.032808399 || "NA"} ft <br />
                  Weight: {userDetails?.weight || "NA"} kgs <br />
                  Languages:{" "}
                  {userDetails?.language?.map((lang, index) => (
                    <span className="mx-1" key={index}>
                      {lang},
                    </span>
                  )) || "NA"}{" "}
                  <br />
                </div>
              </div>
            </div>
            <div className="accordion-item px-0 py-2 border-bottom">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  <img
                    src={EducationIcon}
                    alt=" Reload"
                    style={{ marginRight: "10px" }}
                  />
                  <b className="textBold">Education</b>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body px-5 p-0 textSmall">
                  Highest degree: {userDetails?.qualification || "NA"} <br />
                  Specialization: {userDetails?.specialization || "NA"} <br />
                  Institute: {userDetails?.instituteName || "NA"} <br />
                  Percentage/ Pointer: {userDetails?.percentage || "NA"} <br />
                  Passing year: {userDetails?.passingYear || "NA"} <br />
                </div>
              </div>
            </div>
            <div className="accordion-item px-0 py-2 border-bottom">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSix"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseSix"
                >
                  <img
                    src={CareerIcon}
                    alt=" Reload"
                    style={{ marginRight: "10px" }}
                  />
                  <b className="textBold">Career</b>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseSix"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body px-5 p-0 textSmall">
                  Occupation category: {userDetails?.occupation || "NA"} <br />
                  Company: {userDetails?.companyName || "NA"} <br />
                  Location: {userDetails?.landmark || "NA"}
                  <br />
                  Designation: {userDetails?.designation || "NA"} <br />
                  Income range: {userDetails?.income || "NA"}
                </div>
              </div>
            </div>
            <div className="accordion-item px-0 py-2 border-bottom">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSeven"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseSeven"
                >
                  <img
                    src={ContactaddressIcon}
                    alt=" Reload"
                    style={{ marginRight: "10px" }}
                  />
                  <b className="textBold">Contact Address</b>
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseSeven"
                className="accordion-collapse collapse"
              >
                <div className="accordion-body px-5 p-0 textSmall">
                  Address: {userDetails?.address_1 || "NA"} <br />
                  Landmark: {userDetails?.landmark || "NA"} <br />
                  City: {userDetails?.city || "NA"}
                  <br />
                  State: {userDetails?.state || "NA"} <br />
                  Country: {userDetails?.country || "NA"} <br />
                  Pincode: {userDetails?.pincode || "NA"} <br />
                  phone: {hashedData(user?.phone_number, apprStatus.status, user)}
                  <br />
                  Email-id: {hashedData(user?.email_id, apprStatus.status, user)}
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSlider ? (
        <div className="profile-view-slider">
          <MultipleImagesSlider multipleImages={multipleImages} setShowSlider={setShowSlider} />
        </div>
      ) : null}
      {
        contactModal ?
          <div className="contactModal">
            <div>
              <div className="modal-content">
                <div className="modal-header">
                  <button className="close-modal-btn">
                    <img
                      src={CloseModalImage}
                      alt="modal-close-btn"
                      onClick={() => setContactModal(false)}
                      name="modal-closed"
                    />
                  </button>
                </div>
                <div className="modal-info">
                  <div className="modal-text">
                    <img src={ShareBtn} alt="" />
                    <p>Send Contact request to {"username"}</p>
                  </div>
                  <button
                    className="cancel-request"
                    onClick={() => setContactModal(false)}
                    name="request-cancel"
                  >
                    Cancel
                  </button>
                  <button
                    className="send-request"
                    onClick={sendRequest}
                    name="request-send"
                  >
                    Send
                  </button>
                </div>
              </div>

            </div>
          </div> : null
      }
      <ToastContainer />
    </>
  );
};

export default ProfileViewMob;