const faqsData = [
    {
        "id": 1,
        "question": "How can I register on matrimonydemo.com ?",
        "answer": "Registering on our matrimony site is a simple process, you can register by filling out the online registration that runs for 3 pages or use the Quick registration form, a shorter and simpler process available"
    },
    {
        "id": 2,
        "question": "I did my registration, but my profile does not show up online.",
        "answer": "Every new profile will be validated by our ADMIN (Backend Team) and upon activation, your profile will be visible to all! Verification of profiles is done manually. Our support team checks each and every profile carefully for any invalid or incorrect information and also candidates are contacted over the phone for confirmation of authority. You will get a notification once the profile is active!"
    },
    {
        "id": 3,
        "question": "Can I upload my photograph?",
        "answer": "You have the option of uploading your photograph on the My Profile Page. You can upload a maximum of three photographs."
    },
    {
        "id": 4,
        "question": "How do I upload Horoscope?",
        "answer": "We have an exclusive interface to key in your horoscope details. Login to your Matrimony account and click Manage Horoscope."
    },
    {
        "id": 5,
        "question": "Can I edit all my details?",
        "answer": "At any time, you can update your profile by clicking Modify My Profile button."
    },
    {
        "id": 6,
        "question": "I see a tab called MY MATCHES, What's the use of it?",
        "answer": "My Matches fetches the profiles matching your partner preferences that you keyed in while registering your profile. Its dynamically updated "
    },
    {
        "id": 7,
        "question": "Can I shortlist/bookmark a Profile ?",
        "answer": "Yes, you can! Its a useful feature to make a note of the interested profiles. You need to be logged in to use the shortlist feature."
    },
    {
        "id": 8,
        "question": "How do I delete Shortlisted profiles?",
        "answer": "Login using your matrimonial 'User ID' and 'Password'. Click on the 'Shortlisted Profiles'. You could view and delete the Bookmarked members!"
    },
    {
        "id": 9,
        "question": "How do I change my password?",
        "answer": "After logging into your account, click on the change password link. The system will ask for your old password and then the new one then log in with your new password."
    },
    {
        "id": 10,
        "question": "What are the benefits of a FREE membership?",
        "answer": "Most trusted Matrimonial service 100% verified Matrimonial profiles.Managed by Complete Professionals Dedicated Customer Care Service. Post your personal profile for Free! Add more information about yourself and your family Upload/add multiple photographs to your profile Display your contact details to paid members Express interest in other members for FREE"
    },
    {
        "id": 11,
        "question": "Why should I choose your paid membership package?",
        "answer": "A paid membership has various packages and options to help you access the advanced features of matrimonydemo.com. Its Benefits : Search suitable profiles through the matrimonial Website Contact suitable matches via contact number, personalized messages, and customer service. Send and receive personalized messages. Customer care support. Paid Matrimonial Members get top services and benefits that are not available to members who choose a free membership. Paid Matrimonial Members can express interest and write messages to other members of matrimonydemo.com"
    },
    {
        "id": 12,
        "question": "Is my personal information safe?",
        "answer": "Please read our privacy policy here"
    },
    {
        "id": 13,
        "question": "How can I upgrade my Free membership to a paid one?",
        "answer": "We provide various options for upgrading your membership. You can log in to your matrimony account page and click the upgrade button. Choose the right package for you, which will lead you to the payment page. You will be provided with various options for payment. How do I contact customer care? matrimonydemo.com is eager to help you find your partner at the earliest. Customer Support is a top priority for us. You can contact our customer care team in any of the following ways listed here."
    }
]
export default faqsData;