import './SearchPage.css';
import ModalContainer from "./ModalContainer";
import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

const BasicSearch = (props) => {

    // searchData is passed as props in advanceSearh page using prop drilling.
    // here searchData is the state json data.
    let toggleCheckbox = props.toggleCheckbox;
    let gotra = props.gotra;
    let loading = props.loading;
    let searchData = props.searchData;
    let isModalOpen = props.isModalOpen;
    let modalLoader = props.modalLoader;
    // modalFieldName state will store the name of the field.
    // when we are opening modal by pressing the +result of the specfic fields like language and country.
    const [modalFieldName, setModalFieldName] = useState("");
    let gotraIndex = props.gotraIndex;

    // This function is used to toggle the modal.
    // It takes currentModalFieldName for eg when we click on language field, then it will take language as parameter.
    // This function is also responsible to disable and enable the checkbox of the basic and advanced checkbox by changing value of loading state.
    const toggleModal = (currentModalFieldName) => {
        if(isModalOpen){
            props.setLoading(false);
        }
        else{
            props.setLoading(true);
        }
        props.setIsModalOpen(!isModalOpen);
        setModalFieldName(currentModalFieldName?.toLowerCase()); 
    };

    return (
        <>
            <div className="basic-search-container w-100 d-flex flex-column basicSideFilter">
                {
                    // Here we are iterating the buttons for basic search
                    Object.keys(searchData).map((field,i) => {
                    
                    // if the field has property top = false, then it is considered as part of basic search
                    // Basic search consists of both checkbox and drop down. The logic for rendering both is different.
                    // Here we are writing logic for inputType = "checkbox"
                    if (searchData[field].inputType === "checkbox" && !searchData[field].top) {
                        return (
                            <div key={i} className="checkbox-search" onMouseOver={() => props.toggleFiltersButton(field,"basic")} style={isModalOpen?{pointerEvents:"none"}:null}>
                                <h6>{searchData[field].label}</h6>
                                <div className="overflow-auto basicCheckBoxDropDown" style={{maxHeight:"300px"}}>
                                    { 
                                         gotra && field === "gotra" ? (
                                            <>
                                            <input 
                                                type="checkbox" 
                                                onChange={() => toggleCheckbox(gotra)}
                                                disabled={loading}
                                                className="form-check-input mx-2"
                                                value={searchData.gotra?.values[gotraIndex]?.value}
                                                checked={searchData.gotra?.values[gotraIndex]?.checked}
                                                />
                                                <label className="form-check-label">Include my gotra</label>
                                            </>
                                        ) : null
                                    }
                                    {
                                        // here we are iterating checkboxes for basic search field
                                        searchData[field].values?.slice(0,8).map((selectedField, i) => {
                                            return (
                                                <div className="form-check my-2 basicDropDownField" key={i}>
                                                    <input
                                                        className="form-check-input mx-2"
                                                        type="checkbox"
                                                        id={props.filterArrayName}
                                                        name={props.filterArrayName}
                                                        value={selectedField.value}
                                                        checked={selectedField.checked}
                                                        onChange={(e)=>props.toggleCheckbox(e.target.value)}
                                                        disabled={loading}
                                                    />
                                                    <label
                                                        className="form-check-label "
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        {selectedField.value === "0 - 0" ? "N/A" : selectedField.value}
                                                    </label>
                                                </div>
                                            );
                                        })
                                    }
                                </div>                            
                                {
                                    searchData[field].values?.length>10
                                    ? <div className="modal-btn"><button onClick={()=>toggleModal(searchData[field].label)}>+ {searchData[field].values.length - 9} more</button></div>
                                    : null
                                }
                            </div>
                        );
                    }
                    // Here we are writing logic for inputType = "dropdown" 
                    else if (searchData[field].inputType === "dropdown" && !searchData[field].top){
                        return (
                            <div className='search-select-container' key={i} onMouseOver={() => props.toggleFiltersButton(field,"basic")} style={isModalOpen?{pointerEvents:"none"}:null}>
                                <button 
                                    type="button" 
                                    className="basic-search-btn w-100"
                                    onClick={() => props.toggleFiltersButton(field,"basic")}
                                    disabled={loading}
                                >
                                    {searchData[field].label}{props.selectedField===field?<IoIosArrowUp/>:<IoIosArrowDown/>}
                                </button>
                                {
                                    props.selectedField === field ? 
                                    <>
                                    {/* In dropdown field there will be range consist of both min and max values.
                                        We have iterate two different input field min and max    */}
                                    {
                                        <div className="d-flex flex-column basicDropDown">
                                            <div className="d-flex">
                                                <label className="w-50 align-self-center">Min {searchData[field].label}</label>
                                                <select className="form-select w-50" value={searchData[props.selectedField].minValue} onChange={(e)=>props.toggleDropdown("min",field,e.target.value)} disabled={loading}>
                                                    {/* The range for the option field in min field will be taken from minValuesArray */}
                                                    {
                                                        searchData[props.selectedField].values.minValueArray.map((field,i) => {
                                                            return (
                                                                <option key={i} value={field.value}>{field.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <h6 className="text-center my-2">To</h6>
                                            <div className="d-flex">
                                                <label className="w-50 align-self-center">Max {searchData[field].label}</label>
                                                <select className="form-select w-50" value={searchData[props.selectedField].maxValue} onChange={(e)=>props.toggleDropdown("max",field,e.target.value)} disabled={loading}>
                                                    {/* The range for the option field in max field will be taken from maxValuesArray */}
                                                    {
                                                        searchData[props.selectedField].values.maxValueArray.map((field,i) => {
                                                            return (
                                                                <option key={i} value={field.value}>{field.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    </> : null
                                }
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
            {/* Modal is only rendered if isModalOpen is true.*/}
            {
                isModalOpen && (
                    <ModalContainer
                        searchData={searchData} 
                        isModalOpen={isModalOpen} 
                        toggleModal={toggleModal}
                        setIsModalOpen={props.setIsModalOpen} 
                        currentSelectedField={modalFieldName} 
                        toggleCheckbox={toggleCheckbox} 
                        modalLoader={modalLoader}
                        setModalLoader={props.setModalLoader}
                    />
                )
            }
        </>
    );    
};

export default BasicSearch;