import React, { useEffect, useState } from "react";
import avatar from "../../assets/avatar_placeholder.png";
import profileListJson from "./ProfileListData.json";
import UserProfile from "./UserProfile";
import "./ProfileList.css";
import { useDispatch } from "react-redux";
import {
  createRequest,
  fetchRequestStatus,
} from "../../Redux/ContactSharing/ApiCalls";
import { GoLocation } from "react-icons/go";
import { ImCross } from "react-icons/im";
import { BsInfo } from "react-icons/bs";
import {
  AiOutlineReload,
  AiFillStar,
  AiFillHeart,
  AiTwotoneThunderbolt,
} from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import server from "../../config/server.json";
import Slider from "react-slick";
export const ProfileListCard = ({ data, apprStatus }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [multipleImages, setMultipleImages] = useState([]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const dispatch = useDispatch();
  let approvalStatus;
  const cancel = () => {
    setModalOpen(!modalOpen);
  };
  let phoneNumber = data.phone_number.toString();
  const emailAddress = () => {
    const split_1 = data.email_id.split("@");
    const split_2 = split_1[1];
    return split_2;
  };
  const sendRequest = () => {
    const body = {
      request_to: data._id,
      approval_status: 0,
    };
    approvalStatus = apprStatus.status.filter((x) => x.request_to === data._id);
    if (approvalStatus[0]) {
      toast.info("Already Sent!", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else {
      toast.success("Sent!", {
        autoClose: 3000,
        position: "bottom-right",
      });
      dispatch(createRequest(body));
      dispatch(fetchRequestStatus());
    }
  };
  const getMultipleImages = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_MULTIPLE_IMAGES}/${data._id}`,
        {
          headers: headers,
        }
      )
      setMultipleImages(response.data);
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  }
  useEffect(() => {
    getMultipleImages();
    // eslint-disable-next-line
  }, []);

  return data ? (
    <div className="single_profile_feed">
      <div className="container_profile_feed">
        <div className="col-12  profile_feed_name">
          {data.first_name} {data.last_name}
        </div>
        <div className="horizontal_line"></div>
        <div className="profile_feed_img_desc_wrapper  ">
          <div className="col-12 profile_feed_img">
            <div className="single-profile">
              <Slider {...settings} >
                <img
                  src={
                    data.userDetails.profileImage ? data.userDetails.profileImage : avatar
                  }
                  alt="Profile"
                />
                {multipleImages.map((slide) => (
                  <img key={slide._id}
                    src={slide.profileImages}
                    style={{ height: "100%", width: "100%" }}
                    alt="img"
                  />
                ))}
              </Slider>
              <div className="shadow"></div>
              <div className="single-profile-data">
                <h3>{data.first_name},</h3>
                <h4>
                  <div className="profile_card_row">
                    {data.userDetails.age ? (
                      <span className="">
                        {data.userDetails.age}yrs, &nbsp;
                      </span>
                    ) : (
                      ""
                    )}
                    {data.userDetails.height ? (
                      <span>{data.userDetails.height}cms, &nbsp;</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="profile_card_row">
                    {data.userDetails.horoscope ? (
                      <span>{data.userDetails.horoscope}, &nbsp;</span>
                    ) : (
                      ""
                    )}
                    {data.userDetails.religion ? (
                      <span>{data.userDetails.religion}, &nbsp;</span>
                    ) : (
                      ""
                    )}
                  </div>
                </h4>
                <h5>
                  <span>
                    <GoLocation />{" "}
                    {data.userDetails.city ? data.userDetails.city : "Unknown"}
                  </span>
                  <span onClick={() => setModalOpen(!modalOpen)}>
                    <BsInfo />
                  </span>
                  {modalOpen && <UserProfile cancel={cancel} data={data} />}
                </h5>
                <div className="icon-button">
                  <span>
                    <AiOutlineReload />
                  </span>
                  <span>
                    <ImCross />
                  </span>
                  <span>
                    <AiFillStar />
                  </span>
                  <span onClick={sendRequest}>
                    <AiFillHeart />
                  </span>
                  <span>
                    <AiTwotoneThunderbolt />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12  profile_feed_details">
            <div className="flex-item-left">
              <p>
                <strong>
                  {data.userDetails.age} yrs, &nbsp; {data.userDetails.height}{" "}
                  cms, &nbsp; {data.userDetails.horoscope},
                </strong>
              </p>
              <p>
                <strong>Occupation</strong>: {data.userDetails.designation}
              </p>
              {/* <p>
              <strong>Mother tongue</strong>: {data.motherTongue}
            </p> */}
              <p>
                <strong>Religion</strong>: {data.userDetails.religion}
              </p>
              <p>
                <strong>Location</strong>: {data.userDetails.address_1},{" "}
                {data.userDetails.landmark}, {data.userDetails.city},{" "}
                {data.userDetails.state} {data.userDetails.pincode}
              </p>
            </div>
            <div className="flex-item-right">
              {/* <p>
              <strong>Discription</strong>: {data.discription}{" "}
            </p> */}
              <div>
                <p>
                  <strong>Father's Name</strong>: {data.userDetails.fatherName}
                </p>
                {/* <p>
                  <strong>Father's Occupation</strong>: {data.fatherOccupation}{" "}
                </p> */}
                {/* <p>
                  <strong>Mother's Name</strong>: {data.motherName}{" "}
                </p> */}
                <p>
                  <strong>Phone Number</strong>:{" "}
                  {approvalStatus && approvalStatus[0].approval_status === 1
                    ? phoneNumber
                    : phoneNumber.slice(0, -3) + "***"}
                </p>
                <p>
                  <strong>Email ID</strong>:******@{emailAddress()}
                </p>
              </div>
              <div
                className="profile_list_showmoreBtn"
                onClick={() => {
                  setModalOpen(!modalOpen);
                }}
              >
                <strong>Show more...</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
const ProfileList = () => {
  return (
    <div className="profile_feed_container">
      {profileListJson.profileListData.map((data) => (
        <ProfileListCard key={data.id} data={data} />
      ))}
    </div>
  );
};
export default ProfileList;