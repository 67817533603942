import React, { useEffect, useState } from "react";
import axios from "axios";
import server from "../../config/server.json";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./UserProfile.css";

function UserProfile({setSteps, userInfo, next, setNext}) {
  const [phone, setPhone] = useState();
  const [details, setDetails] = useState({
    fname: "",
    lname: "",
    email: "",
  });
  const [close, setClose] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setDetails({
      fname: userInfo ? userInfo.first_name : "",
      lname: userInfo ? userInfo.last_name : "",
      email: userInfo ? userInfo.email_id : "",
    });
    setPhone(userInfo ? JSON.stringify(userInfo.phone_number) : "");
  }, [userInfo]);

  const { fname, lname, email } = details;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handlePhone = (e) => setPhone(e.target.value);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!fname || !lname || !email || !phone) {
      return toast.error("*** Please Fill All Details", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
    const emailRegex = /^([a-zA-Z0-9\_\-])+\@([a-zA-Z])+\.([a-zA-Z]){2,5}$/; //eslint-disable-line
    if (fname.length < 2 || fname.length > 15) {
      toast.error("*** Invalid Name", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (lname.length < 3 || lname.length > 15) {
      toast.error("*** Invalid Surname", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (!emailRegex.test(email)) {
      toast.error("*** Not Valid Email", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (!(phone.length === 10)) {
      toast.error("*** Not Valid Mobile", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else {
      saveDetails();
    }
  };

  const saveDetails = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const body = {
        first_name: fname,
        last_name: lname,
        email_id: email,
        phone_number: phone,
      };
      const response = await axios.patch(
        `${server.url.production}${server.api.USER_DETAILS_EDIT}`,
        body,
        {
          headers: headers,
        }
      );
      if (close) {
        navigate('/');
      } 
      if (next) {
        setSteps(2);
        setNext(false);
      } 
      toast.success(response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    } catch (e) {
      toast.error(e.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  return (
    <>
      <div className="body">
        <Helmet>
          <title>UserProfile</title>
          <meta
            name="description"
            content=" collection of information associated with a particular user."
          />
        </Helmet>
        <section className="profile">
          <div className="">
            <div className="profile_details">
              <form onSubmit={handleOnSubmit} autoComplete={"off"}>
                <div className="form-group">
                  <div className="fname">
                    <div className="input-field">
                      <label htmlFor="fname">first name </label>
                      <input
                        type="text"
                        name="fname"
                        id="fname"
                        placeholder="Eg: John"
                        value={fname}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="lname">
                    <div className="input-field">
                      <label htmlFor="lname">surname </label>
                      <input
                        type="text"
                        name="lname"
                        id="lname"
                        placeholder="Eg: Doe"
                        value={lname}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group df">
                  <div className="email df fdc">
                    <div className="input-field">
                      <label htmlFor="email">email </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Eg: john123@test.com"
                        value={email}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="phone">
                    <div className="input-field">
                      <label htmlFor="contact">mobile</label>

                      <input
                        type="number"
                        value={phone}
                        onChange={handlePhone}
                      />

                    </div>
                  </div>
                </div>
                <div className="saveclosebtns">
                  <div>
                    <button type="submit" onClick={() => setClose(true)}>
                      Save & Close
                    </button>
                  </div>
                  <div>
                    <button type="submit"  onClick={() => setNext(true)}>
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default UserProfile;