import React from "react";
import FooterLandingPage from "../LandingPagesMobile/FooterLandingPage";
import "./TermsAndCondition.css"

const TermAndCondition = () => {
  return (
    <>
    <div className="terms-conditions-mobile">
      <div>
        <h1>Terms Of Use</h1>
        <h5>Last revised on August 15, 2023</h5>
        <p>
          Lorem ipsum dolor sit amet consectetur. Aliquet sed purus quis tortor
          sed ac magna. Mi aenean at gravida mauris aliquam enim vestibulum.
          Pellentesque arcu aliquet adipiscing eget quis in.
        </p>
        <p>
          Eget accumsan eget senectus non volutpat leo et at commodo. Nascetur
          etiam praesent mauris scelerisque commodo. Adipiscing neque bibendum
          convallis eget elit non. Sed commodo
        </p>
      </div>
      <div>
        <h2>1. Lorem Ipsum</h2>
        <h3>a) Lorem ipsum dolor</h3>
        <p>
          sit amet consectetur. Aliquet sed purus quis tortor sed ac magna.
          lOREM IPSUM LOREM IPSUM SIT AMET CONSECTEOUE, ALIQUET SMSED SIUFC
          SIUEH EGET NUIS DPOOGLE JUUUOSENN SUCNEAPO EOJS OFURN SOUEJ IN TUYGUR
          SKOON. lOREM IPSUM LOREM IPSUM SIT AMET CONSECTEOUE, ALIQUET SMSED
          SIUFC SIUEH EGET NUIS DPOOGLE JUUUOSENN SUCNEAPO EOJS OFURN SOUEJ IN
          TUYGUR SKOON.
        </p>
        <h3>b) Lorem ipsum dolor</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur. Aliquet sed purus quis tortor
          sed ac magna. Mi aenean at gravida mauris aliquam enim vestibulum.
          Pellentesque arcu aliquet adipiscing eget quis in.
        </p>
        <ul className="terms-conditions-points">
          <li>
            Eget accumsan eget senectus non volutpat leo et at commodo. Nascetur
            etiam praesent mauris scelerisque commodo. Adipiscing neque bibendum
            convallis eget elit non. Sed commodo{" "}
          </li>
          <li>
            TONSECTEOUE, ALIQUET SMSED SIUFC SIUEH EGET NUIS DPOOGLE JUUUOSENN
            SUCNEAPO EOJS OFURN SOUEJ IN TUYGUR SKOON.
          </li>
          <li>
            Eget accumsan eget senectus non volutpat leo et at commodo. Nascetur
            etiam praesent mauris scelerisque commodo. Adipiscing neque bibendum
            convallis eget el
          </li>
        </ul>
      </div>
    </div>
      <FooterLandingPage />
    </>
  );
};

export default TermAndCondition;
