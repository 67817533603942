import React from "react";
import pdf from "@react-pdf/renderer";
import firstPage from "../../../assets/BiodataImages/BackgroundImage1.png";
import secondPage from "../../../assets/BiodataImages/Group 49.png";
import Family_photo from "../../../assets/BiodataImages/Family_photo.jpg";

const { Page, Text, Image, Document, View, StyleSheet } = pdf;

const styles = StyleSheet.create({
  pageBackground: {
    position: "absolute",
    width: "100%",
    backgroundSize: "cover",
  },

  dummyImage: {
    width: "40%",
    height: 200,
    top: 110,
    alignSelf: "center",
    borderWidth: "10",
    borderRadius: "30",
  },

  familyTopImage: {
    width: "40%",
    top: 70,
    alignSelf: "center",
    borderWidth: "10",
    borderRadius: "30",
  },

  personal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  heading: {
    fontSize: 20,
    color: "#964B00",
    paddingLeft: 20,
    paddingTop: 5,
    fontWeight: "900",
    alignSelf: "center",
    fontFamily: "Helvetica-Bold",
    top: 70,
  },

  headingName: {
    alignSelf: "center",
    fontFamily: "Helvetica-Bold",
    fontSize: "18px",
    fontWeight: "900",
    color: "#964B00",
    top: 80,
  },

  heading1: {
    fontSize: 20,
    color: "#964B00",
    fontFamily: "Helvetica-Bold",
    paddingLeft: 20,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 500,
  },

  Text: {
    color: "#964B00",
    padding: "5 0",
    fontSize: 13,
  },

  personalContainer1: {
    flexBasis: "65%",
    justifyContent: "space-between",
  },

  Name1: {},
  personalContent: {
    paddingLeft: 10,
    paddingTop: 130,
  },

  familyContent: {
    paddingLeft: 20,
    paddingTop: 100,
  },

  personalContent1: {
    display: "flex",
    flexDirection: "row",
  },

  personalLeftText: {
    color: "#964B00",
    padding: "3 0",
    fontSize: 12,
    width: "45%",
  },

  personalRightText: {
    color: "#964B00",
    padding: "3 0",
    fontSize: 12,
    width: "55%",
  },
});

const FormatOne = ({
  fields,
  uncleList,
  auntyList,
  mamaList,
  mamiList,
  brotherList,
  sisterList,
  cousinList,
  base64,
}) => {
  const dob = new Date(fields.dob);
  const birthDate = isNaN(dob.getDate()) ? "" : dob.getDate();
  const birthYear = isNaN(dob.getFullYear()) ? "" : dob.getFullYear();
  const birthMonth =
    dob.toLocaleString("default", { month: "long" }) === "Invalid Date"
      ? ""
      : dob.toLocaleString("default", { month: "long" });
  const date_of_birth = `${birthDate} ${birthMonth} ${birthYear}`;

  const checkIfLastIndex = (index, length) => {
    return index === length - 1 ? "" : ", ";
  };

  return (
    <Document>
      <Page>
        <View>
          <Image
            src={firstPage}
            alt={"Scond Page"}
            style={styles.pageBackground}
          />
        </View>

        <View style={styles.userImage}>
          <Text style={styles.headingName}>
            {fields.first_name} {fields.last_name}
          </Text>
          <Image
            src={{ uri: base64 }}
            alt={"User Image"}
            style={styles.dummyImage}
          />
        </View>
        <View style={styles.personal}>
          <View style={styles.personalContainer1}>
            <View style={styles.personalContent}>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Date of birth </Text>
                <Text style={styles.personalRightText}>{date_of_birth}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Birth Place </Text>
                <Text style={styles.personalRightText}>{fields.pob}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Gender </Text>
                <Text style={styles.personalRightText}>{fields.gender}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Blood Group </Text>
                <Text style={styles.personalRightText}>
                  {fields.blood_group}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Height </Text>
                <Text style={styles.personalRightText}>{fields.height}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Marital Status </Text>
                <Text style={styles.personalRightText}>{fields.marital}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Religion </Text>
                <Text style={styles.personalRightText}>{fields.religion}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Language Known </Text>
                <Text style={styles.personalRightText}>{fields.language}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Country </Text>
                <Text style={styles.personalRightText}>{fields.country}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Horoscope </Text>
                <Text style={styles.personalRightText}>{fields.horoscope}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>
                  Highest Qualification
                </Text>

                <Text style={styles.personalRightText}>
                  {fields.qualification}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Specialization </Text>
                <Text style={styles.personalRightText}>
                  {fields.specialization}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Institute Name </Text>
                <Text style={styles.personalRightText}>
                  {fields.instituteName}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Company Name </Text>
                <Text style={styles.personalRightText}>
                  {fields.companyName}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Occupation </Text>
                <Text style={styles.personalRightText}>
                  {fields.designation}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Income </Text>
                <Text style={styles.personalRightText}>{fields.income}</Text>
              </View>
              {fields.about_us ?
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>About Myself </Text>
                <Text style={styles.personalRightText}>{fields.about_us}</Text>
              </View>
              : 
              null
              }
            </View>
          </View>
        </View>
      </Page>
      <Page>
        <View>
          <Image
            src={secondPage}
            alt={"Second Top"}
            style={styles.pageBackground}
          />
        </View>
        <View style={styles.userImage}>
          <View style={styles.heading}>
            <Text>Family Details</Text>
          </View>
          <Image
            src={Family_photo}
            alt={"User Image"}
            style={styles.familyTopImage}
          />
        </View>

        <View style={styles.personal}>
          <View style={styles.personalContainer1}>
            <View style={styles.familyContent}>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Father </Text>
                <Text style={styles.personalRightText}>
                  {fields.fatherName}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Father's Job </Text>
                <Text style={styles.personalRightText}>
                  {fields.father_job}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Mother </Text>
                <Text style={styles.personalRightText}>
                  {fields.motherName}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Mother's Job </Text>
                <Text style={styles.personalRightText}>
                  {fields.mother_job}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Grand Father </Text>
                <Text style={styles.personalRightText}>{fields.dada}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Grand Mother </Text>
                <Text style={styles.personalRightText}>{fields.dadi}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Number Of Siblings</Text>
                <Text style={styles.personalRightText}>{fields.siblings}</Text>
              </View>

              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Brother Name </Text>
                <Text style={styles.personalRightText}>
                  {
                    // eslint-disable-next-line
                    brotherList.map((elem, index) => {
                      if (elem.check) {
                        return (
                          <Text key={index}>
                            {elem.brother}
                            {checkIfLastIndex(index, brotherList.length)}
                          </Text>
                        );
                      }
                    })
                  }
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Sister Name </Text>
                <Text style={styles.personalRightText}>
                  {
                    // eslint-disable-next-line
                    sisterList.map((elem, index) => {
                      if (elem.check) {
                        return (
                          <Text key={index}>
                            {elem.sister}
                            {checkIfLastIndex(index, sisterList.length)}
                          </Text>
                        );
                      }
                    })
                  }
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Nanaji </Text>
                <Text style={styles.personalRightText}>{fields.nana}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Naniji </Text>
                <Text style={styles.personalRightText}>{fields.nani}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Uncles </Text>
                <Text style={styles.personalRightText}>
                  {
                    // eslint-disable-next-line
                    uncleList.map((elem, index) => {
                      if (elem.check) {
                        return (
                          <Text key={index}>
                            {elem.chacha}
                            {checkIfLastIndex(index, uncleList.length)}
                          </Text>
                        );
                      }
                    })
                  }
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Aunty </Text>
                <Text style={styles.personalRightText}>
                  {
                    // eslint-disable-next-line
                    auntyList.map((elem, index) => {
                      if (elem.check) {
                        return (
                          <Text key={index}>
                            {elem.chachi}
                            {checkIfLastIndex(index, auntyList.length)}
                          </Text>
                        );
                      }
                    })
                  }
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Mama </Text>
                <Text style={styles.personalRightText}>
                  {
                    // eslint-disable-next-line
                    mamaList.map((elem, index) => {
                      if (elem.check) {
                        return (
                          <Text key={index}>
                            {elem.mama}
                            {checkIfLastIndex(index, mamaList.length)}
                          </Text>
                        );
                      }
                    })
                  }
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Mami </Text>
                <Text style={styles.personalRightText}>
                  {
                    // eslint-disable-next-line
                    mamiList.map((elem, index) => {
                      if (elem.check) {
                        return (
                          <Text key={index}>
                            {elem.mami}
                            {checkIfLastIndex(index, mamiList.length)}
                          </Text>
                        );
                      }
                    })
                  }
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Cousins Name </Text>
                <Text style={styles.personalRightText}>
                  {
                    // eslint-disable-next-line
                    cousinList.map((elem, index) => {
                      if (elem.check) {
                        return (
                          <Text key={index}>
                            {elem.cousins}
                            {checkIfLastIndex(index, cousinList.length)}
                          </Text>
                        );
                      }
                    })
                  }
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Native Place </Text>
                <Text style={styles.personalRightText}>
                  {fields.nativePlace}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Family Status </Text>
                <Text style={styles.personalRightText}>
                  {fields.familyStatus}
                </Text>
              </View>

              <View style={styles.heading1}>
                <Text>Contact Details</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Conatct Number </Text>
                <Text style={styles.personalRightText}>
                  {fields.phone_number}
                </Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Email </Text>
                <Text style={styles.personalRightText}>{fields.email_id}</Text>
              </View>
              <View style={styles.personalContent1}>
                <Text style={styles.personalLeftText}>Residential Address</Text>
                <Text style={styles.personalRightText}>{fields.address_1}</Text>
              </View>
            </View>
            <View></View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default FormatOne;
