import React, { useState, useEffect } from "react";
import axios from "axios";
import server from "../../config/server";
import { clearUserData } from "../../utils/ClearUserData";
import "./SideMenuPanel.css";
import { toast } from "react-toastify";
// import Logout from "../../assets/Logout.png";
// import Next from "../../assets/Next.png";
import { useNavigate } from "react-router-dom";
import {MdOutlineNotifications, MdOutlineLogout} from "react-icons/md";
import {IoIosArrowForward} from "react-icons/io";
import {BsCircleHalf, BsTelephone} from "react-icons/bs";
import {CiSettings} from "react-icons/ci";
import {HiOutlineInformationCircle} from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";

function SideMenuPanel({setSideMenuPanel}) {
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);

    const logoutHandler = async () => {
        try {
          const headers = {
            token: token,
          };
          const response = await axios.post(
            `${server.url.production}${server.api.LOGOUT}`,
            user,
            { headers: headers }
          );
          if (response.status === 200) {
            clearUserData();
            setUser(null);
            toast.success(response.data.message, {
              autoClose: 3000,
              position: "bottom-right",
            });
            navigate("/");
          } else {
            toast.error("Something went wrong, Please try again", {
              autoClose: 3000,
              position: "bottom-right",
            });
          }
        } catch (e) {
          toast.error(e.response.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      };

      useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("user"));
        if (loggedUser) {
          setToken(localStorage.getItem("token"));
        } else {
          navigate("/");
        }
        // eslint-disable-next-line
      }, [navigate]);
    

    return(
        <div className="main-side-panel">
          <div>
            <span onClick={()=> setSideMenuPanel(false)}><RxCross2 /></span>
          </div>
          <div>
            <ul>
                <li><span><MdOutlineNotifications className="main-side-panel-icons"/><span>Notifications</span></span><IoIosArrowForward /></li>
                <li><span><CiSettings className="main-side-panel-icons" /><span>Account settings</span></span><IoIosArrowForward /></li>
                <li><span><BsCircleHalf className="main-side-panel-icons" /><span>Appearance Mode</span></span><IoIosArrowForward /></li>
                <li><span><HiOutlineInformationCircle className="main-side-panel-icons" /><span>About</span></span><IoIosArrowForward /></li>
                <li><span><BsTelephone className="main-side-panel-icons" /><span>Contact Us</span></span><IoIosArrowForward /></li>
            </ul>
          </div>
          <div>
            <span onClick={logoutHandler}><MdOutlineLogout /> Logout </span><span onClick={logoutHandler}><IoIosArrowForward /></span>
          </div>
        </div>
    )

}

export default SideMenuPanel;
