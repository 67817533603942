import React from "react";
import { Helmet } from "react-helmet";
function About() {
  return (
    <div className="body">
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="We are always ready to help people who are serious about marriage. They can make use of our user-friendly portal, explore and choose as per their preferences and meet their life partner in a few clicks."
        />
      </Helmet>
      <div className="page-heading">
        <div className="wrapper-heading">
          <h3 className="page-heading-text">About Us</h3>
        </div>
      </div>
      <div className="wrapper-content">
        <div className="content">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
            dolor porro odit corporis consequatur ex nobis beatae consequuntur
            mollitia ducimus saepe esse est exercitationem repellat dignissimos,
            sit dolorum aspernatur animi!
          </p>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis eos
            voluptates aut fugiat natus laborum nisi beatae quibusdam, velit
            sed, eveniet veritatis nostrum enim eligendi pariatur cum ad fugit
            id. Recusandae quae ad est architecto corporis quasi, expedita
            minima repellendus ab consequatur in eius aliquid deserunt. Eligendi
            deserunt recusandae magnam?Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Nesciunt officiis earum quis dicta ut perspiciatis
            numquam vero, tenetur id blanditiis? saepe esse est exercitationem
            repellat dignissimos, sit dolorum aspernatur animi!
          </p>

          <div className="content-heading">
            <h4>Our Vision</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum dolor porro odit corporis consequatur ex nobis beatae
              consequuntur mollitia ducimus saepe esse est exercitationem
              repellat dignissimos, sit dolorum aspernatur animi!
            </p>
          </div>
          <div className="content-heading">
            <h4>Our Vision</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum dolor porro odit corporis consequatur ex nobis beatae
              consequuntur mollitia ducimus saepe esse est exercitationem
              repellat dignissimos, sit dolorum aspernatur animi!
            </p>
          </div>
          <div className="content-heading">
            <h4>Our Vision</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatum dolor porro odit corporis consequatur ex nobis beatae
              consequuntur mollitia ducimus saepe esse est exercitationem
              repellat dignissimos, sit dolorum aspernatur animi!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
