import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Biodata.css";
import Biodata from "../PersonalDetails/PersonalDetailsData.json";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import dummyUser from "../../assets/user1.jpeg";
import FormatOne from "../Biodata/BiodataFormats/FormatOne";
import FormatTwo from "../Biodata/BiodataFormats/FormatTwo";
import FormatThree from "../Biodata/BiodataFormats/FormatThree";
import FormatFour from "../Biodata/BiodataFormats/FormatFour";
import FormatFive from "../Biodata/BiodataFormats/FormatFive";
import FormatSix from "../Biodata/BiodataFormats/FormatSix";
import FormatSeven from "../Biodata/BiodataFormats/FormatSeven";
import FormatEight from "../Biodata/BiodataFormats/FormatEight";
import FormatOnePreview from "../../assets/BiodataFormatimages/biodata1.jpg";
import FormatTwoPreview from "../../assets/BiodataFormatimages/biodata2.jpg";
import FormatThreePreview from "../../assets/BiodataFormatimages/biodata3.jpg";
import FormatFourPreview from "../../assets/BiodataFormatimages/biodata4.jpg";
import FormatFivePreview from "../../assets/BiodataFormatimages/biodata5.jpg";
import FormatSixPreview from "../../assets/BiodataFormatimages/biodata6.jpg";
import FormatSevenPreview from "../../assets/BiodataFormatimages/biodata7.jpg";
import FormatEightPreview from "../../assets/BiodataFormatimages/biodata8.jpg";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";
import server from "../../config/server.json";
import {
  getEducationDegreeData,
  getFamilyStatusData,
  getHeightData,
  getIncomeData,
  getMartialData,
} from "../../Redux/Master/ApiMaster";
import { toFeet } from "../../utils/ConvertCmToFeet";
import { useNavigate } from "react-router-dom";
import {
  getWithoutFamilyStatusData,
  getWithoutIncomeData,
  getWithoutLoginHeightData,
  getWithoutMaritalStatusData,
  getWithoutQualificationData,
} from "../../Redux/Master/MasteData/MasterDataWithoutLogin";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import RichTextEditor from "../../pages/RichTextEditor/RichTextEditor.js";

function BioData() {
  const [userToken, setUserToken] = useState(null);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const masterData = useSelector((state) => state.masterData);
  const [uncleList, setUncleList] = useState([{ chacha: "", check: false }]);
  const [auntyList, setAuntyList] = useState([{ chachi: "", check: false }]);
  const [mamaList, setMamaList] = useState([{ mama: "", check: false }]);
  const [mamiList, setMamiList] = useState([{ mami: "", check: false }]);
  const [brotherList, setBrotherList] = useState([
    { brother: "", check: false },
  ]);
  const [sisterList, setSisterList] = useState([{ sister: "", check: false }]);
  const [cousinList, setCousinList] = useState([{ cousins: "", check: false }]);
  const [isFormatLoaded, setIsFormatLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadedPhoto, setUploadedPhoto] = useState("");
  // const [profilePhoto, setProfilePhoto] = useState("");
  const [fields, setFields] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    pob: "",
    gender: "",
    current_location: "",
    blood_group: "",
    height: "",
    marital: "",
    religion: "",
    language: "",
    country: "",
    horoscope: "",
    fatherName: "",
    father_job: "",
    motherName: "",
    mother_job: "",
    siblings: "",
    nativePlace: "",
    familyStatus: "",
    qualification: "",
    instituteName: "",
    companyName: "",
    designation: "",
    income: "",
    phone_number: "",
    email_id: "",
    address_1: "",
    dada: "",
    dadi: "",
    nana: "",
    nani: "",
    specialization: "",
    about_us: ""
  });

  const {
    first_name,
    last_name,
    dob,
    pob,
    gender,
    current_location,
    blood_group,
    height,
    marital,
    religion,
    language,
    country,
    horoscope,
    father_job,
    fatherName,
    motherName,
    mother_job,
    siblings,
    nativePlace,
    familyStatus,
    qualification,
    instituteName,
    companyName,
    designation,
    income,
    phone_number,
    email_id,
    address_1,
    dada,
    dadi,
    nana,
    nani,
    specialization,
    about_us
  } = fields;
  const [about, setAbout] = useState("");

  const { hScope, userReligion, countryList } = Biodata;

  const filterName = (state) => {
    const nameFilter = state.filter((elem) => elem.check === true);
    return nameFilter;
  };

  const getUserData = async () => {
    if (userToken) {
      try {
        const headers = {
          "Content-Type": "application/json",
          token: userToken,
        };
        const response = await axios.get(
          `${server.url.production}${server.api.GET_USER_DATA}`,
          { headers: headers }
        );
        const details = response.data.userDetails;
        if (response.data) {
          setFields({
            first_name: response.data.first_name
              ? response.data.first_name
              : first_name,
            last_name: response.data.last_name
              ? response.data.last_name
              : last_name,
            dob: details.dob ? details.dob : dob,
            pob: details.pob ? details.pob : pob,
            gender: response.data.gender ? response.data.gender : gender,
            current_location: response.data.current_location
              ? response.data.current_location
              : current_location,
            blood_group: details.blood_group
              ? details.blood_group
              : blood_group,
            marital: details.marital ? details.marital : marital,
            height: details.height ? details.height : height,
            religion: details.religion ? details.religion : religion,
            language: details.language.toString()
              ? details.language.toString()
              : language,
            country: details.country ? details.country : country,
            horoscope: details.horoscope ? details.horoscope : horoscope,
            fatherName: details.fatherName ? details.fatherName : fatherName,
            father_job: details.father_job ? details.father_job : father_job,
            motherName: details.motherName ? details.motherName : motherName,
            mother_job: details.mother_job ? details.mother_job : mother_job,
            siblings: details.siblings ? details.siblings : siblings,
            nativePlace: details.nativePlace
              ? details.nativePlace
              : nativePlace,
            familyStatus: details.familyStatus
              ? details.familyStatus
              : familyStatus,
            qualification: details.qualification
              ? details.qualification
              : qualification,
            instituteName: details.instituteName
              ? details.instituteName
              : instituteName,
            companyName: details.companyName
              ? details.companyName
              : companyName,
            designation: details.designation
              ? details.designation
              : designation,
            income: details.income ? details.income : income,
            phone_number: response.data.phone_number
              ? response.data.phone_number
              : phone_number,
            email_id: response.data.email_id ? details.email_id : email_id,
            address_1: details.address_1 ? details.address_1 : address_1,
            dada: details.dada ? details.dada : dada,
            dadi: details.dadi ? details.dadi : dadi,
            nana: details.nana ? details.nana : nana,
            nani: details.nani ? details.nani : nani,
            specialization: details.specialization
              ? details.specialization
              : specialization,
            about_us: details.about_us ? details.about_us : about_us,
          });

          // setProfilePhoto(details.profileImage);

          setUncleList(
            details?.chacha.length === 0
              ? [{ chacha: "", check: false }]
              : details?.chacha
          );
          setAuntyList(
            details?.chachi.length === 0
              ? [{ chachi: "", check: false }]
              : details?.chachi
          );
          setBrotherList(
            details?.brother.length === 0
              ? [{ brother: "", check: false }]
              : details?.brother
          );
          setSisterList(
            details?.sister.length === 0
              ? [{ sister: "", check: false }]
              : details?.sister
          );
          setMamaList(
            details?.mama.length === 0
              ? [{ mama: "", check: false }]
              : details?.mama
          );
          setMamiList(
            details?.mami.length === 0
              ? [{ mami: "", check: false }]
              : details?.mami
          );
          setCousinList(
            details?.cousins.length === 0
              ? [{ cousins: "", check: false }]
              : details?.cousins
          );
        }
      } catch (e) {
        toast.error("Something went wrong while loading the user's data", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    getUserData();
    //eslint-disable-next-line
  }, [userToken]);

  const handleValidation = () => {
    let today = new Date();
    let date = dob.split("-");
    let birth = today.getFullYear() - Number(date[0]);
    if (!first_name.match(/^[A-Za-z ]{2,30}$/)) {
      setErrorMessage("Enter Your First Name");
    } else if (!last_name.match(/^[A-Za-z ]{2,30}$/)) {
      setErrorMessage("Enter you Last Name");
    } else if (dob === "") {
      setErrorMessage("Date of Birth cannot be empty");
    } else if (birth < 18) {
      setErrorMessage("User must be 18 or Above");
    } else if (pob === "") {
      setErrorMessage("Enter Your Place of Bith");
    } else if (current_location === "") {
      setErrorMessage("Enter Your current location");
    } else if (!blood_group.match(/^(A|B|AB|O)[+-]$/i)) {
      setErrorMessage("Enter your Blood group");
    } else if (gender === "") {
      setErrorMessage("Choose your gender");
    } else if (height === "") {
      setErrorMessage("Select your Height");
    } else if (marital === "") {
      setErrorMessage("Choose your Marital Status");
    } else if (religion === "") {
      setErrorMessage("Choose your Religion");
    }
    //regex validates language string to match pattern: empty / single-word / words-separated-by-commas, otherwise sets errorMessage
    else if (!language.match(/^\b\w+\b(?:\s*,\s*\b\w+\b)+$/)) {
      setErrorMessage(
        "Separate Languages with a ' , ' if there are multiple languages"
      );
    } else if (country === "") {
      setErrorMessage("Enter Your country");
    } else if (horoscope === "") {
      setErrorMessage("Choose your Horoscope");
    } else if (!fatherName.match(/^[A-Za-z ]{2,30}$/)) {
      // start with albhabet character with minimum 2 character and maximum 30 character
      setErrorMessage("Enter Your Father Name");
    } else if (!father_job.match(/^[A-Za-z ]{2,30}$/)) {
      // string with alphabet character only
      setErrorMessage("Enter Your Father Job");
    } else if (!motherName.match(/^[A-Za-z ]{2,30}$/)) {
      // string with alphabet character only
      setErrorMessage("Enter Your Mother Name");
    } else if (!mother_job.match(/^[A-Za-z ]{2,30}$/)) {
      // Start with alphabet with space also
      setErrorMessage("Enter Your Mother Job");
    } else if (filterName(uncleList).length === 0) {
      setErrorMessage("select the uncle checkbox");
    } else if (filterName(auntyList)?.length === 0) {
      setErrorMessage("select the Aunty checkbox");
    } else if (filterName(mamaList)?.length === 0) {
      setErrorMessage("select the mama checkbox");
    } else if (filterName(mamiList)?.length === 0) {
      setErrorMessage("select the mami checkbox");
    } else if (nana === "") {
      setErrorMessage("Enter your nana name");
    } else if (nani === "") {
      setErrorMessage("Enter your nani name");
    } else if (dada === "") {
      setErrorMessage("Enter grandFather name");
    } else if (dadi === "") {
      setErrorMessage("Enter you grandMother name");
    } else if (filterName(brotherList)?.length === 0) {
      setErrorMessage("select the brother checkbox");
    } else if (filterName(sisterList)?.length === 0) {
      setErrorMessage("select the sister checkbox");
    } else if (filterName(cousinList)?.length === 0) {
      setErrorMessage("select the cousins checkbox");
    } else if (siblings === "") {
      setErrorMessage("Enter the sibilings");
    } else if (nativePlace === "") {
      setErrorMessage("Enter Your Native Place");
    } else if (familyStatus === "") {
      setErrorMessage("Enter Your Affluence");
    } else if (qualification === "") {
      setErrorMessage("Enter Your Qualification");
    } else if (instituteName === "") {
      setErrorMessage("Enter Your College Name");
    } else if (companyName === "") {
      setErrorMessage("Enter Your Working Job");
    } else if (designation === "") {
      setErrorMessage("Enter Your Working As");
    } else if (income === "") {
      setErrorMessage("Enter Your Annual Income");
    } else if (!phone_number.toString().match(/^[0-9]{10}$/)) {
      // Only Number will be 0-9 & length will be exact 10
      setErrorMessage("Enter a Valid Mobile Number");
    } else if (
      !email_id.match(/^([a-zA-Z0-9\_\-])+\@([a-zA-Z])+\.([a-zA-Z]){2,5}$/) // eslint-disable-line
    ) {
      // 'In email_id Input Text Will Compare With The Regex'
      setErrorMessage("Enter a Valid Email Id");
    } else if (!address_1.match(/^[a-zA-Z0-9\s,''-]*$/)) {
      // eslint-disable-line
      // 'Start with Alphabet Numeric Character, if they want some special character are (, . ''  -) \s is contain spacing character'
      // eslint-disable-line
      setErrorMessage("Enter Your address");
    } else {
      return true;
    }
  };

  const postBiodataDetails = async () => {
    //match is a regex that is used to separates the height in (feet) & (Cm)
    const match = height.toString().match(/-\s*(\d{3})/);
    const heightValue = match ? match[1] : "";
    // languageArr stores the languages in array format by doing string operation
    const langArr = fields.language.replaceAll(" ", "").split(",");

    handleValidation();

    try {
      const payload = {
        first_name: first_name,
        last_name: last_name,
        email_id: email_id,
        phone_number: phone_number,
        gender: gender,
        current_location: current_location,
        address_1: address_1,
        familyStatus: familyStatus,
        income: income,
        dob: dob,
        blood_group: blood_group,
        instituteName: instituteName,
        father_job: father_job,
        fatherName: fatherName,
        height: Number(heightValue),
        horoscope: horoscope,
        language: langArr,
        marital: marital,
        mother_job: mother_job,
        motherName: motherName,
        country: country,
        nativePlace: nativePlace,
        pob: pob,
        qualification: qualification,
        religion: religion,
        siblings: siblings,
        designation: designation,
        companyName: companyName,
        chacha: uncleList,
        chachi: auntyList,
        mama: mamaList,
        mami: mamiList,
        brother: brotherList,
        sister: sisterList,
        cousins: cousinList,
        dada: dada,
        dadi: dadi,
        nana: nana,
        nani: nani,
        specialization: specialization,
        profileImage: uploadedPhoto,
        about_us: about,
      };
      const response = await axios.post(
        `${server.url.production}${server.api.CREATE_BIODATA}`,
        payload
      );
      setIsFormatLoaded(true);
      toast.success(response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    } catch (e) {
      if (e.response.status === 400) {
        setIsFormatLoaded(true);
        toast.info(e.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error(e.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };

  const updateBiodataDetails = async () => {
    //match is a regex that is used to separates the height in (feet) & (Cm)
    const match = height?.toString()?.match(/-\s*(\d{3})/);

    const heightValue = match ? match[1] : "";

    // languageArr stores the languages in array format by doing string operation
    const langArr = fields?.language?.replaceAll(" ", "").split(",");

    handleValidation();
    try {
      const payload = {
        first_name: first_name,
        last_name: last_name,
        email_id: email_id,
        phone_number: phone_number,
        gender: gender,
        current_location: current_location,
        address_1: address_1,
        familyStatus: familyStatus,
        income: income,
        dob: dob,
        blood_group: blood_group,
        instituteName: instituteName,
        father_job: father_job,
        fatherName: fatherName,
        height: Number(heightValue),
        horoscope: horoscope,
        language: langArr,
        marital: marital,
        mother_job: mother_job,
        motherName: motherName,
        country: country,
        nativePlace: nativePlace,
        pob: pob,
        qualification: qualification,
        religion: religion,
        siblings: siblings,
        designation: designation,
        companyName: companyName,
        chacha: uncleList,
        chachi: auntyList,
        mama: mamaList,
        mami: mamiList,
        brother: brotherList,
        sister: sisterList,
        cousins: cousinList,
        dada: dada,
        dadi: dadi,
        nana: nana,
        nani: nani,
        specialization: specialization,
        profileImage: uploadedPhoto,
      };
      const headers = {
        "Content-Type": "application/json",
        token: userToken,
      };
      const response = await axios.post(
        `${server.url.production}${server.api.UPDATE_BIODATA}`,
        payload,
        { headers: headers }
      );
      getUserData();
      toast.success(response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    } catch (e) {
      toast.error(e.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const handleDummyData = () => {
    setFields({
      first_name: "Surya",
      last_name: "Vardhan",
      dob: "2000-02-12",
      pob: "Mumbai",
      gender: "M",
      current_location: "Delhi",
      blood_group: "A+",
      height: "5ft 7in - 170cm",
      marital: "Single",
      religion: "Hindu",
      language: "English, Hindi",
      country: "India",
      horoscope: "Aquarius",
      fatherName: "Suresh Vardhan",
      father_job: "Bank Manager",
      motherName: "Tina Vardhan",
      mother_job: "Housewife",
      siblings: "2",
      nativePlace: "Goa",
      familyStatus: "Middle Class",
      qualification: "Bachelor",
      instituteName: "IIT Kharagpur",
      companyName: "Tech Sierra",
      designation: "Software Developer",
      income: "INR 5 Lakh to 10 Lakh",
      phone_number: "9874561289",
      email_id: "surya122@test.com",
      address_1: "ABC Colony, ABC Street Number 2",
      dada: "Vishnu Vardhan",
      dadi: "Geeta Vardhan",
      nana: "Prashant Joshi",
      nani: "Seema Joshi",
      specialization: "Computer Science",
    });

    setUncleList([{ chacha: "Raghunath Vardhan", check: true }]);

    setAuntyList([{ chachi: "Reshma Vardhan", check: true }]);

    setMamaList([{ mama: "Santosh Joshi", check: true }]);

    setMamiList([{ mami: "Karuna Joshi", check: true }]);

    setBrotherList([{ brother: "Sameer Vardhan", check: true }]);

    setSisterList([{ sister: "Pooja Vardhan", check: true }]);

    setCousinList([{ cousins: "Aman Joshi", check: true }]);
  };

  const handlePhoto = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      return setUploadedPhoto(reader.result);
    };
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (userToken) {
      updateBiodataDetails();
    } else {
      postBiodataDetails();
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleOnName = (e, index, state, setState, fieldName) => {
    const values = [...state];
    if (e.target.name === `${fieldName}`) {
      values[index][e.target.name] = e.target.value;
    }
    if (e.target.name === "check") {
      values[index][e.target.name] = e.target.checked;
    }
    setState(values);
  };

  const handleAdd = (state, setState) => {
    setState([...state, {}]);
  };

  const handleRemoveList = (index, state, setState) => {
    const values = [...state];
    values.splice(index, 1);
    setState(values);
  };

  const formats1 = [
    { format: FormatOne, formatImg: FormatOnePreview },
    { format: FormatTwo, formatImg: FormatTwoPreview },
    { format: FormatThree, formatImg: FormatThreePreview },
    { format: FormatFour, formatImg: FormatFourPreview },
  ];
  const formats2 = [
    { format: FormatFive, formatImg: FormatFivePreview },
    { format: FormatSix, formatImg: FormatSixPreview },
    { format: FormatSeven, formatImg: FormatSevenPreview },
    { format: FormatEight, formatImg: FormatEightPreview },
  ];

  const editor = useRef(null);

  // const PDFPreview = (FormatNo, formatImg) => {
  //   return (
  //     <div className="format-previews">
  //       <PDFDownloadLink
  //         document={
  //           <FormatNo
  //             fields={fields}
  //             uncleList={uncleList}
  //             mamaList={mamaList}
  //             brotherList={brotherList}
  //             sisterList={sisterList}
  //             cousinList={cousinList}
  //             auntyList={auntyList}
  //             mamiList={mamiList}
  //             base64={
  //               uploadedPhoto
  //                 ? uploadedPhoto
  //                 : profilePhoto
  //                 ? profilePhoto
  //                 : dummyUser
  //             }
  //           />
  //         }
  //       >
  //         {({ loading }) =>
  //           loading ? (
  //             "Loading"
  //           ) : (
  //             <img
  //               className="download-image-biodata"
  //               src={formatImg}
  //               alt="Format Preview"
  //             />
  //           )
  //         }
  //       </PDFDownloadLink>
  //     </div>
  //   );
  // };

  useEffect(() => {
    // err msg set to empty, when user type on pending inputfield.
    setErrorMessage("");
  }, [
    fields,
    uncleList,
    auntyList,
    mamaList,
    mamiList,
    brotherList,
    sisterList,
    cousinList,
  ]);

  useEffect(() => {
    dispatch(getWithoutLoginHeightData());
    dispatch(getWithoutFamilyStatusData());
    dispatch(getWithoutMaritalStatusData());
    dispatch(getWithoutQualificationData());
    dispatch(getWithoutIncomeData());
    dispatch(getHeightData());
    dispatch(getFamilyStatusData());
    dispatch(getEducationDegreeData());
    dispatch(getIncomeData());
    dispatch(getMartialData());
  }, [dispatch]);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setUserToken(localStorage.getItem("token"));
      setUser(loggedUser.id);
    } else {
      navigate("/biodata");
    }
  }, [navigate]);

  return (
    <div>
      <Helmet>
        <title>BioData</title>
        <meta
          name="description"
          content="It is the biographical data that informs us about a person's background and enables us to determine if he/she is suitable as a partner for the future."
        />
      </Helmet>
      <div className="biodata-heading">
        <div className="biodata-wrapper">
          <h2>Bio Data</h2>
        </div>
      </div>

      <div className="biodata-content-pdf-grid">
        <div className="bio-data-grid">
          <div className="pdf-previews">
            {isFormatLoaded || userToken ? (
              <>
                {formats1.map((item, index) => {
                  return (
                    // <div key={index} className="">
                    //   {PDFPreview(item.format, item.formatImg)}
                    // </div>
                    <div>
                      <img
                      alt=""
                        src={item.formatImg}
                        className="download-image-biodata"
                      ></img>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="biodata-wrapper">
          <div className="biodata-content">
            <div className="bio-data-grid-display">
              <form onSubmit={handleOnSubmit}>
                <div>
                  <div className="dummy-data">
                    <h3>Personal Details</h3>
                    <span onClick={handleDummyData}>Dummy Data</span>
                  </div>
                  <div className="biodata-main-details">
                    <div className="biodata-details">
                      <div>
                        <label htmlFor="first_name">First Name:</label>
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          placeholder="Eg. John"
                          value={first_name}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="last_name">Last Name:</label>
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          placeholder="Eg. Doe"
                          value={last_name}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="birth_date">Date Of Birth:</label>
                        <input
                          type="date"
                          id="dob"
                          name="dob"
                          value={dob}
                          onChange={handleOnChange}
                          className="biodata-dob-input"
                        />
                      </div>
                      <div>
                        <label htmlFor="birth-place">Place Of Birth:</label>
                        <input
                          type="text"
                          id="pob"
                          name="pob"
                          placeholder="Eg. Banglore"
                          value={pob}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div className="gender-checkbox">
                        <label htmlFor="gender">Gender:</label>
                        <div className="gender">
                          <input
                            type="radio"
                            name="gender"
                            value="M"
                            checked={gender === "M"}
                            onChange={handleOnChange}
                          />{" "}
                          <label className="gender-name">Male</label>
                          <input
                            type="radio"
                            name="gender"
                            value="F"
                            checked={gender === "F"}
                            onChange={handleOnChange}
                          />
                          <label className="gender-name">Female</label>
                          <input
                            type="radio"
                            name="gender"
                            value="other"
                            onChange={handleOnChange}
                          />{" "}
                          <label className="gender-name">Other</label>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="blood_group">Blood Group:</label>
                        <input
                          type="text"
                          id="blood_group"
                          name="blood_group"
                          placeholder="Eg. O+"
                          value={blood_group}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="height">Height</label>
                        <select
                          name="height"
                          id="height"
                          value={height}
                          onChange={handleOnChange}
                        >
                          <optgroup label="Heights" />
                          <option value="none">{OPTION_SELECT_EMPTY}</option>
                          {user === null
                            ? masterData.withoutLogin.height.map((elem) => (
                                <option key={elem._id} value={elem.height}>
                                  {" "}
                                  {toFeet(elem.height)}{" "}
                                </option>
                              ))
                            : masterData.height.map((elem) => (
                                <option key={elem._id} value={elem.height}>
                                  {" "}
                                  {toFeet(elem.height)}{" "}
                                </option>
                              ))}
                        </select>
                      </div>
                      <div>
                        <label htmlFor="marital">Marital Status</label>
                        <select
                          name="marital"
                          id="marital"
                          value={marital}
                          onChange={handleOnChange}
                        >
                          <option value="none">Choose Your Status</option>
                          <optgroup label="Matial Status" />
                          {user === null
                            ? masterData.withoutLogin.marital.map((elem) => (
                                <option
                                  key={elem._id}
                                  value={elem.maritalStatus}
                                >
                                  {elem.maritalStatus}
                                </option>
                              ))
                            : masterData.marital.map((elem) => (
                                <option
                                  key={elem._id}
                                  value={elem.maritalStatus}
                                >
                                  {" "}
                                  {elem.maritalStatus}{" "}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="religion">Religion</label>
                        <select
                          name="religion"
                          id="religion"
                          value={religion}
                          onChange={handleOnChange}
                        >
                          <option value="none">Choose Your Religion</option>
                          <optgroup label="Religion">Religion</optgroup>
                          {userReligion?.map((elem) => (
                            <option key={elem} value={elem}>
                              {elem}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label htmlFor="language">Language Known:</label>
                        <input
                          type="text"
                          id="language"
                          name="language"
                          placeholder="Eg: English, Hindi..."
                          value={language}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="country">Country:</label>
                        <select
                          name="country"
                          id="country"
                          value={country}
                          onChange={handleOnChange}
                        >
                          <option value="none">Choose Your Country</option>
                          {countryList?.map((elem) => (
                            <option key={elem} value={elem}>
                              {elem}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label htmlFor="horoscope">Horoscope</label>
                        <select
                          name="horoscope"
                          id="horoscope"
                          value={horoscope}
                          onChange={handleOnChange}
                        >
                          <option value="none">Choose Your Horoscope</option>
                          <optgroup label="Horoscope">
                            Choose Your Horoscope
                          </optgroup>
                          {hScope?.map((elem) => (
                            <option key={elem} value={elem}>
                              {elem}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="current-location">
                          Current Location:
                        </label>
                        <input
                          type="text"
                          id="currentLocation"
                          name="current_location"
                          placeholder="Eg. Mumbai"
                          value={current_location}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="img">Upload profile photo:</label>
                        <input
                          type="file"
                          id="img"
                          name="img"
                          accept="image/*"
                          onChange={handlePhoto}
                        />
                      </div>
                    </div>
                    <div className="about">
                      <div className="input-field df jcb ac">
                        <label htmlFor="about_us" className="text-box-label">
                          About
                        </label>

                        <div className="text-box">
                          <RichTextEditor
                            name="about_us"
                            id="about_us"
                            ref={editor}
                            value={about}
                            setAbout={setAbout}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h3>Family Details</h3>
                  <div classame="biodata-family-details">
                    <div className="biodata-details">
                      <div>
                        <label htmlFor="fatherName">Father's Name:</label>
                        <input
                          type="text"
                          id="fatherName"
                          name="fatherName"
                          placeholder="Eg. Rick"
                          value={fatherName}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="father_job">Father's Job:</label>
                        <input
                          type="text"
                          id="father_job"
                          name="father_job"
                          placeholder="Eg. Manager"
                          value={father_job}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="motherName">Mother's name:</label>
                        <input
                          type="text"
                          id="motherName"
                          name="motherName"
                          placeholder="Eg. Seema"
                          value={motherName}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="mother_job">Mother's Job:</label>
                        <input
                          type="text"
                          id="mother_job"
                          name="mother_job"
                          placeholder="Eg. Teacher"
                          value={mother_job}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="dada">Grand Father Name: </label>
                        <input
                          type="text"
                          name="dada"
                          placeholder="Dadaji's Name"
                          value={dada}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="dadi">Grand Mother Name: </label>
                        <input
                          type="text"
                          name="dadi"
                          placeholder="Dadiji's Name"
                          value={dadi}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="siblings">Number Of Siblings:</label>
                        <input
                          type="number"
                          id="siblings"
                          name="siblings"
                          placeholder="Eg. 2"
                          value={siblings}
                          onChange={handleOnChange}
                        />
                      </div>

                      <div className="added-labels">
                        <label htmlFor="brother">Brother Name: </label>
                        <div className="added-fields">
                          {brotherList?.map((elm, index) => {
                            return (
                              <div key={index} className={"added_inputs"}>
                                <input
                                  type="text"
                                  name="brother"
                                  value={elm.brother}
                                  onChange={(e) =>
                                    handleOnName(
                                      e,
                                      index,
                                      brotherList,
                                      setBrotherList,
                                      "brother"
                                    )
                                  }
                                />
                                <div>
                                  <input
                                    type="checkbox"
                                    name="check"
                                    className="tickbox"
                                    value={elm.check}
                                    checked={elm.check}
                                    onChange={(e) =>
                                      handleOnName(
                                        e,
                                        index,
                                        brotherList,
                                        setBrotherList,
                                        ""
                                      )
                                    }
                                  />
                                  {index === 0 ? (
                                    <span
                                      className="add-btn"
                                      onClick={() =>
                                        handleAdd(brotherList, setBrotherList)
                                      }
                                    >
                                      <FaPlus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {index !== 0 ? (
                                    <span
                                      className="remove-btn"
                                      onClick={() =>
                                        handleRemoveList(
                                          index,
                                          brotherList,
                                          setBrotherList
                                        )
                                      }
                                    >
                                      <FaMinus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div className="added-labels">
                        <label htmlFor="sister">Sister Name: </label>
                        <div className="added-fields">
                          {sisterList?.map((elm, index) => {
                            return (
                              <div key={index} className={"added_inputs"}>
                                <input
                                  type="text"
                                  name="sister"
                                  value={elm.sister}
                                  onChange={(e) =>
                                    handleOnName(
                                      e,
                                      index,
                                      sisterList,
                                      setSisterList,
                                      "sister"
                                    )
                                  }
                                />
                                <div>
                                  <input
                                    type="checkbox"
                                    name="check"
                                    className="tickbox"
                                    value={elm.check}
                                    checked={elm.check}
                                    onChange={(e) =>
                                      handleOnName(
                                        e,
                                        index,
                                        sisterList,
                                        setSisterList,
                                        ""
                                      )
                                    }
                                  />
                                  {index === 0 ? (
                                    <span
                                      className="add-btn"
                                      onClick={() =>
                                        handleAdd(sisterList, setSisterList)
                                      }
                                    >
                                      <FaPlus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {index !== 0 ? (
                                    <span
                                      className="remove-btn"
                                      onClick={() =>
                                        handleRemoveList(
                                          index,
                                          sisterList,
                                          setSisterList
                                        )
                                      }
                                    >
                                      <FaMinus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="added-labels">
                        <label htmlFor="chacha">Uncle Name: </label>
                        <div className="added-fields">
                          {uncleList?.map((elm, index) => {
                            return (
                              <div key={index} className={"added_inputs"}>
                                <input
                                  type="text"
                                  name="chacha"
                                  value={elm.chacha}
                                  onChange={(e) =>
                                    handleOnName(
                                      e,
                                      index,
                                      uncleList,
                                      setUncleList,
                                      "chacha"
                                    )
                                  }
                                />
                                <div>
                                  <input
                                    type="checkbox"
                                    name="check"
                                    className="tickbox"
                                    placeholder="Chacha's Name"
                                    value={elm.check}
                                    checked={elm.check}
                                    onChange={(e) =>
                                      handleOnName(
                                        e,
                                        index,
                                        uncleList,
                                        setUncleList,
                                        ""
                                      )
                                    }
                                  />
                                  {index === 0 ? (
                                    <span
                                      className="add-btn"
                                      onClick={() =>
                                        handleAdd(uncleList, setUncleList)
                                      }
                                    >
                                      <FaPlus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {index !== 0 ? (
                                    <span
                                      className="remove-btn"
                                      onClick={() =>
                                        handleRemoveList(
                                          index,
                                          uncleList,
                                          setUncleList
                                        )
                                      }
                                    >
                                      <FaMinus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div className="added-labels">
                        <label htmlFor="chachi">Aunty Name: </label>
                        <div className="added-fields">
                          {auntyList?.map((elm, index) => {
                            return (
                              <div key={index} className={"added_inputs"}>
                                <input
                                  type="text"
                                  name="chachi"
                                  value={elm.chachi}
                                  onChange={(e) =>
                                    handleOnName(
                                      e,
                                      index,
                                      auntyList,
                                      setAuntyList,
                                      "chachi"
                                    )
                                  }
                                />
                                <div>
                                  <input
                                    type="checkbox"
                                    name="check"
                                    className="tickbox"
                                    value={elm.check}
                                    checked={elm.check}
                                    onChange={(e) =>
                                      handleOnName(
                                        e,
                                        index,
                                        auntyList,
                                        setAuntyList,
                                        ""
                                      )
                                    }
                                  />
                                  {index === 0 ? (
                                    <span
                                      className="add-btn"
                                      onClick={() =>
                                        handleAdd(auntyList, setAuntyList)
                                      }
                                    >
                                      <FaPlus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {index !== 0 ? (
                                    <span
                                      className="remove-btn"
                                      onClick={() =>
                                        handleRemoveList(
                                          index,
                                          auntyList,
                                          setAuntyList
                                        )
                                      }
                                    >
                                      <FaMinus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="added-labels">
                        <label htmlFor="mama">Mama Name: </label>
                        <div className="added-fields">
                          {mamaList?.map((elm, index) => {
                            return (
                              <div key={index} className={"added_inputs"}>
                                <input
                                  type="text"
                                  name="mama"
                                  value={elm.mama}
                                  onChange={(e) =>
                                    handleOnName(
                                      e,
                                      index,
                                      mamaList,
                                      setMamaList,
                                      "mama"
                                    )
                                  }
                                />
                                <div>
                                  <input
                                    type="checkbox"
                                    name="check"
                                    className="tickbox"
                                    value={elm.check}
                                    checked={elm.check}
                                    onChange={(e) =>
                                      handleOnName(
                                        e,
                                        index,
                                        mamaList,
                                        setMamaList,
                                        ""
                                      )
                                    }
                                  />
                                  {index === 0 ? (
                                    <span
                                      className="add-btn"
                                      onClick={() =>
                                        handleAdd(mamaList, setMamaList)
                                      }
                                    >
                                      <FaPlus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {index !== 0 ? (
                                    <span
                                      className="remove-btn"
                                      onClick={() =>
                                        handleRemoveList(
                                          index,
                                          mamaList,
                                          setMamaList
                                        )
                                      }
                                    >
                                      <FaMinus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div className="added-labels">
                        <label htmlFor="mami">Mami Name: </label>
                        <div className="added-fields">
                          {mamiList?.map((elm, index) => {
                            return (
                              <div key={index} className={"added_inputs"}>
                                <input
                                  type="text"
                                  name="mami"
                                  value={elm.mami}
                                  onChange={(e) =>
                                    handleOnName(
                                      e,
                                      index,
                                      mamiList,
                                      setMamiList,
                                      "mami"
                                    )
                                  }
                                />
                                <div>
                                  <input
                                    type="checkbox"
                                    name="check"
                                    className="tickbox"
                                    value={elm.check}
                                    checked={elm.check}
                                    onChange={(e) =>
                                      handleOnName(
                                        e,
                                        index,
                                        mamiList,
                                        setMamiList,
                                        ""
                                      )
                                    }
                                  />
                                  {index === 0 ? (
                                    <span
                                      className="add-btn"
                                      onClick={() =>
                                        handleAdd(mamiList, setMamiList)
                                      }
                                    >
                                      <FaPlus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {index !== 0 ? (
                                    <span
                                      className="remove-btn"
                                      onClick={() =>
                                        handleRemoveList(
                                          index,
                                          mamiList,
                                          setMamiList
                                        )
                                      }
                                    >
                                      <FaMinus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div>
                        <label htmlFor="nana">Nana Name: </label>
                        <input
                          type="text"
                          name="nana"
                          value={nana}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="nani">Nani Name: </label>
                        <input
                          type="text"
                          name="nani"
                          value={nani}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="added-labels">
                        <label htmlFor="cousins">Cousins Name: </label>
                        <div className="added-fields">
                          {cousinList?.map((elm, index) => {
                            return (
                              <div key={index} className={"added_inputs"}>
                                <input
                                  type="text"
                                  name="cousins"
                                  value={elm.cousins}
                                  onChange={(e) =>
                                    handleOnName(
                                      e,
                                      index,
                                      cousinList,
                                      setCousinList,
                                      "cousins"
                                    )
                                  }
                                />
                                <div>
                                  <input
                                    type="checkbox"
                                    name="check"
                                    className="tickbox"
                                    value={elm.check}
                                    checked={elm.check}
                                    onChange={(e) =>
                                      handleOnName(
                                        e,
                                        index,
                                        cousinList,
                                        setCousinList,
                                        ""
                                      )
                                    }
                                  />
                                  {index === 0 ? (
                                    <span
                                      className="add-btn"
                                      onClick={() =>
                                        handleAdd(cousinList, setCousinList)
                                      }
                                    >
                                      <FaPlus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {index !== 0 ? (
                                    <span
                                      className="remove-btn"
                                      onClick={() =>
                                        handleRemoveList(
                                          index,
                                          cousinList,
                                          setCousinList
                                        )
                                      }
                                    >
                                      <FaMinus />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="nativePlace">Native Place:</label>
                        <input
                          type="text"
                          id="nativePlace"
                          name="nativePlace"
                          value={nativePlace}
                          onChange={handleOnChange}
                        />
                      </div>

                      <div>
                        <label htmlFor="familyStatus">Family Affluence:</label>
                        <select
                          name="familyStatus"
                          id="familyStatus"
                          value={familyStatus}
                          onChange={handleOnChange}
                        >
                          <optgroup label="Family Affluence" />
                          <option value="none">{OPTION_SELECT_EMPTY}</option>
                          {user === null
                            ? masterData.withoutLogin.familStatus.map(
                                (elem) => (
                                  <option key={elem.id}>
                                    {elem.familyStatusName}
                                  </option>
                                )
                              )
                            : masterData.familyStatus.map((elem) => (
                                <option key={elem.id}>
                                  {elem.familyStatusName}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h3>Education & Career Details</h3>
                  <div className="biodata-education-details">
                    <div className="biodata-details">
                      <div>
                        <label htmlFor="qualification">
                          Highest Qualification:
                        </label>
                        <select
                          name="qualification"
                          onChange={handleOnChange}
                          value={qualification}
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <optgroup label="Higgest Qualification" />
                          <option value="None">None</option>
                          {user === null
                            ? masterData.withoutLogin.qualification.map((x) => (
                                <option key={x.degreeName} value={x.degreeName}>
                                  {" "}
                                  {x.degreeName}{" "}
                                </option>
                              ))
                            : masterData.educationDegree.map((elem) => (
                                <option
                                  key={elem.degreeName}
                                  value={elem.degreeName}
                                >
                                  {" "}
                                  {elem.degreeName}{" "}
                                </option>
                              ))}
                        </select>
                      </div>
                      <div>
                        <label htmlFor="specialization">Specialization:</label>
                        <input
                          type="text"
                          id="specialization"
                          name="specialization"
                          placeholder="Eg. Computer Science"
                          value={specialization}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="instituteName">College Attended:</label>
                        <input
                          type="text"
                          id="instituteName"
                          name="instituteName"
                          value={instituteName}
                          onChange={handleOnChange}
                        />
                      </div>

                      <div>
                        <label htmlFor="companyName">Working With:</label>
                        <input
                          type="text"
                          id="companyName"
                          name="companyName"
                          value={companyName}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="designation">Working As:</label>
                        <input
                          type="text"
                          id="designation"
                          name="designation"
                          value={designation}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="income">Annual Income (Rs.):</label>
                        <select
                          name="income"
                          id="income"
                          value={income}
                          onChange={handleOnChange}
                        >
                          <optgroup label="Choose Your Income" />
                          <option value="none">{OPTION_SELECT_EMPTY}</option>
                          {user === null
                            ? masterData.withoutLogin.income.map((elem) => (
                                <option key={elem._id} value={elem.income}>
                                  {elem.income}
                                </option>
                              ))
                            : masterData.income.map((elem) => (
                                <option key={elem._id} value={elem.income}>
                                  {elem.income}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Contact Details</h3>
                  <div className="biodata-contact-details">
                    <div className="biodata-details">
                      <div>
                        <label htmlFor="phone_number">Mobile:</label>
                        <input
                          type="number"
                          id="phone_number"
                          name="phone_number"
                          placeholder="Eg. 9638527410"
                          value={phone_number}
                          onChange={handleOnChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="email_id">Email:</label>
                        <input
                          type="email_id"
                          id="email_id"
                          name="email_id"
                          placeholder="Eg: john123@test.com"
                          value={email_id}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>

                    <div className="biodata-details">
                      <div>
                        <label htmlFor="address_1">Address:</label>
                        <input
                          type="text"
                          id="address_1"
                          name="address_1"
                          placeholder="00,Building No"
                          value={address_1}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errorMessage?.length > 0 ? (
                  <div className="showErrorMessage">
                    <span>Alert: {errorMessage}</span>
                  </div>
                ) : (
                  ""
                )}
                <div className="print_button">
                  <button
                    type="submit"
                    disabled={errorMessage?.length > 0 ? true : false}
                    onClick={handleValidation}
                  >
                    submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bio-data-grid">
          <div className="pdf-previews">
            {isFormatLoaded || userToken ? (
              <>
                {formats2.map((item, index) => {
                  return (
                    // <div key={index} className="">
                    //   {PDFPreview(item.format, item.formatImg)}
                    // </div>
                    <div>
                      <img
                      alt=""
                        src={item.formatImg}
                        className="download-image-biodata"
                      ></img>
                    </div>
                  );
                })}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BioData;