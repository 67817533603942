import React, { useEffect, useState } from "react";
import "./ProfilePreviewMobile.css";
import { toast } from "react-toastify";
import server from "../../config/server.json";
import axios from "axios";
import EditProfilePhoto from "../ProfilePhoto/EditMyProfile";
import EditMyProfile from "../MyProfilePage/EditMyProfile";
import { heightToFeet } from "../../utils/ConvertCmToFeet";
import { MdModeEditOutline } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { BsBag } from "react-icons/bs";
import { ImGlass2 } from "react-icons/im";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BsAsterisk } from "react-icons/bs";
import UserImg from "../../assets/dummy-user.png";
import Menu from "../../assets/Menu.png";
import SideMenuPanel from "./SideMenuPanel";
import MyPreferenceMobile from "../MyPreference/MyPreferenceMobile";
import MultipleImagesSlider from "../../components/MultipleImagesSlider/MultipleImagesSlider";
import DashboardImage from "../../assets/DashboardIcons/dashboard.png";
import Dashboard from "../../pages/DashboardPage/Dashboard";

function ProfilePreviewMobile({setActiveButton}) {
  const [token, setToken] = useState(null);
  const [basicInfo, setBasicInfo] = useState(false);
  const [familyBackground, setFamilyBackground] = useState(false);
  const [career, setCareer] = useState(false);
  const [lifeStyle, setLifeStyle] = useState(false);
  const [other, setOther] = useState(false);
  const [editPreference, setEditPreference] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [editMyProfilePhoto, setEditMyProfile] = useState(false);
  const [editMyProfileDetails, setEditMyDetails] = useState(false);
  const [isImageUploaded, setImageUploaded] = useState(false);
  const [multipleImages, setMultipleImages] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [sideMenuPanel, setSideMenuPanel] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [fields, setFields] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    age: "",
    height: "",
    horoscope: "",
    profileImage: "",
    religion: "",
  });

  const [myPreference, setMyPreference] = useState({
    preferredaddress: "N/A",
    preferredHeightRangeFrom: "N/A",
    preferredHeightRangeTo: "N/A",
    preferredageRangeFrom: "N/A",
    preferredageRangeTo: "N/A",
    preferredmarital: "N/A",
    preferrededucation: "",
    preferredannualIncome: "N/A",
    preferreddrinking: "N/A",
    preferredsmoking: "N/A",
    preferredfamilyType: "N/A",
    preferredfamilyValue: "N/A",
    preferredfamilyStatus: "N/A",
    preferredoccupation: "N/A",
    preferredphysicalStatus: "N/A",
    preferredopenToRelocate: "N/A",
    preferredhoroscopeMatching: "N/A",
    preferredprofilePictures: "N/A",
  });

  const {
    user_id,
    first_name,
    last_name,
    age,
    height,
    horoscope,
    profileImage,
    religion,
  } = fields;

  const {
    preferredaddress,
    preferredHeightRangeFrom,
    preferredHeightRangeTo,
    preferredageRangeFrom,
    preferredageRangeTo,
    preferredmarital,
    preferrededucation,
    preferredannualIncome,
    preferreddrinking,
    preferredsmoking,
    preferredfamilyType,
    preferredfamilyValue,
    preferredfamilyStatus,
    preferredoccupation,
    preferredphysicalStatus,
    preferredopenToRelocate,
    preferredhoroscopeMatching,
    preferredprofilePictures,
  } = myPreference;

  const getUserData = async () => {
    if (token) {
      try {
        const headers = {
          "Content-Type": "application/json",
          token: token,
        };
        const response = await axios.get(
          `${server.url.production}${server.api.GET_USER_DATA}`,
          { headers: headers }
        );
        const details = response?.data.userDetails;
        setUserDetails(details)
        if (response?.data) {
          setFields({
            user_id: details.user_id,
            first_name: response?.data.first_name
              ? response?.data.first_name
              : "Cherry",
            last_name: response?.data.last_name
              ? response?.data.last_name
              : "Chrysanthemum",
            age: details.age ? details.age : "_",
            height: details.height ? details.height : "",
            horoscope: details.horoscope ? details.horoscope : "Vigro",
            profileImage: details.profileImage ? details.profileImage : "",
            religion: details.religion ? details.religion : "",
          });
        }
      } catch (e) {
        toast.error("Something went wrong while loading the user's data", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } else {
      return;
    }
  };

  const fetchPreferences = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.FETCH_PREFERENCES}`,
        { headers }
      );
     if(response.data){
      setMyPreference({
        preferredaddress: response?.data.address ? response?.data.address : "N/A",
        preferredheight: response?.data.Height ? response?.data.Height : "N/A",
        preferredHeightRangeFrom: response?.data.heightRangeFrom
          ? response?.data.heightRangeFrom
          : "N/A",
        preferredHeightRangeTo: response?.data.heightRangeTo
          ? response?.data.heightRangeTo
          : "N/A",
        preferredageRangeFrom: response?.data.ageRangeFrom
          ? response?.data.ageRangeFrom
          : "N/A",
        preferredageRangeTo: response?.data.ageRangeTo
          ? response?.data.ageRangeTo
          : "N/A",
        preferredmarital: response?.data.marital ? response?.data.marital : "N/A",
        preferrededucation: response?.data.education
          ? response?.data.education
          : "N/A",
        preferredannualIncome: response?.data.annualIncome
          ? response?.data.annualIncome
          : "N/A",
        preferreddrinking: response?.data.drinking
          ? response?.data.drinking
          : "N/A",
        preferredsmoking: response?.data.smoking ? response?.data.smoking : "N/A",
        preferredfamilyType: response?.data.familyType
          ? response?.data.familyType
          : "N/A",
        preferredfamilyValue: response?.data.familyValue
          ? response?.data.familyValue
          : "N/A",
        preferredfamilyStatus: response?.data.familyStatus
          ? response?.data.familyStatus
          : "N/A",
        preferredoccupation: response?.data.occupation
          ? response?.data.occupation
          : "N/A",
        preferredphysicalStatus: response?.data.physicalStatus
          ? response?.data.physicalStatus
          : "N/A",
        preferredopenToRelocate: response?.data.openToRelocated
          ? response?.data.openToRelocated
          : "N/A",
        preferredhoroscopeMatching: response?.data.horoscopeMatching
          ? response?.data.horoscopeMatching
          : "N/A",
        preferredprofilePictures: response?.data.profilePictures
          ? response?.data.profilePictures
          : "N/A",
      });
     }
    } catch (error) {
      toast.error(error.response?.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const fetchMultipleImages = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_MULTIPLE_IMAGES}/${user_id}`,
        {
          headers: headers,
        }
      );
      if (response?.data) {
        setMultipleImages(response?.data.filter((singleImg) => singleImg.status === "A"));
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.response?.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    if (token) {
      getUserData();
      fetchPreferences();
    }
    //eslint-disable-next-line
  }, [token, isImageUploaded]);

  useEffect(() => {
    if (user_id) {
      fetchMultipleImages();
    }
    //eslint-disable-next-line
  }, [user_id]);

  return (
    <>
      {!editPreference ? (
        <div className="main-preview-mobile">
          <div className="preview-mobile-menu">
          <img src={Menu} alt="" onClick={() => setSideMenuPanel(true)}/>
          </div>
          <div className="preview-mobile">
            <h5>
              {first_name} {last_name}
            </h5>
            <div className="profile-mobile">
              <div className="profile-image-mobile">
                <img src={profileImage ? profileImage : UserImg} alt="" />
                <div onClick={() => setEditMyProfile(!editMyProfilePhoto)}>
                  <MdModeEditOutline />
                </div>
              </div>
              <div>
                <ul>
                  <li>
                    {age ? age : "_"} years |{" "}
                    {height ? heightToFeet(height) : "_"}
                  </li>
                  <li>
                    {horoscope ? horoscope : "Horoscope"} |{" "}
                    {religion ? religion : "Religion"}
                  </li>
                  <li>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Harum, perferendis?
                  </li>
                </ul>
              </div>
            </div>
            <div className="preview-btns">
              {/* <button>
                <span>
                  <AiFillEye />
                </span>
                Preview
              </button> */}
              <button onClick={() => setEditMyDetails(!editMyProfileDetails)}>
                <span>
                  <MdModeEditOutline />
                </span>
                Edit Your Profile
              </button>
              <button className="profile-dashboard" onClick={() => setShowDashboard(true)}>
                <img src={DashboardImage} alt="" />
                View Dashboard</button>
            </div>
            <div className="mobile-multiple-img">
              <h5>Featured</h5>
              {isLoading ? (
                <div className="view-loader-container">
                  <div className="lds-roller">
                    {[...Array(8)].map((_, index) => (
                      <div key={index}></div>
                    ))}
                  </div>
                </div>
              ) : multipleImages?.length > 0 ? (
                <div onClick={() => setShowSlider(true)}>
                  {multipleImages.map((image, index) => (
                    <img
                      key={index}
                      src={image.profileImages}
                      alt={""}
                      width={"80px"}
                    />
                  ))}
                </div>
              ) : (
                <h4>No Results Found</h4>
              )}
            </div>
            <div className="mobile-preferences">
              <div className="mobile-preferences-head">
                <h5>Partner Preferences</h5>
                <button
                  onClick={() => {
                    setEditPreference(true);
                  }}
                >
                  EDIT
                </button>
              </div>
              <div className="mobile-preferences-content">
                <div>
                  <div
                    onClick={() => {
                      setBasicInfo(!basicInfo);
                    }}
                  >
                    <span>
                      <AiOutlineUser />
                    </span>
                    <h5>Basic Information</h5>
                    <span>
                      {!basicInfo ? <FaChevronDown /> : <FaChevronUp />}
                    </span>
                  </div>
                  {basicInfo ? (
                    <div>
                      <ul>
                        <li>Location: {preferredaddress}</li>
                        <li>
                          Age Range: {preferredageRangeFrom} -{" "}
                          {preferredageRangeTo}
                        </li>
                        <li>
                          Height Range: {preferredHeightRangeFrom === "N/A" ? preferredHeightRangeFrom : heightToFeet(preferredHeightRangeFrom)} -{" "}
                          {preferredHeightRangeTo === "N/A" ? preferredHeightRangeTo : heightToFeet(preferredHeightRangeTo)}
                        </li>
                        <li>Marital Status: {preferredmarital}</li>
                        <li>Education: {preferrededucation}</li>
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div
                  onClick={() => {
                    setFamilyBackground(!familyBackground);
                  }}
                >
                  <div>
                    <span>
                      <FaUsers />
                    </span>
                    <h5>Family Background</h5>
                    <span>
                      {!familyBackground ? <FaChevronDown /> : <FaChevronUp />}
                    </span>
                  </div>
                  {familyBackground ? (
                    <div>
                      <ul>
                        <li>Family Type: {preferredfamilyType}</li>
                        <li>Family Status: {preferredfamilyStatus}</li>
                        <li>Family Values: {preferredfamilyValue}</li>
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div
                  onClick={() => {
                    setCareer(!career);
                  }}
                >
                  <div>
                    <span>
                      <BsBag />
                    </span>
                    <h5>Career</h5>
                    <span>{!career ? <FaChevronDown /> : <FaChevronUp />}</span>
                  </div>
                  {career ? (
                    <div>
                      <ul>
                        <li>Occupation: {preferredoccupation}</li>
                        <li>Annual Income: {preferredannualIncome}</li>
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div
                  onClick={() => {
                    setLifeStyle(!lifeStyle);
                  }}
                >
                  <div>
                    <span>
                      <ImGlass2 />
                    </span>
                    <h5>Lifestyle Choices</h5>
                    <span>
                      {!lifeStyle ? <FaChevronDown /> : <FaChevronUp />}
                    </span>
                  </div>
                  {lifeStyle ? (
                    <div>
                      <ul>
                        <li>Drinking Habits: {preferreddrinking}</li>
                        <li>Smoking Habits: {preferredsmoking}</li>
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div onClick={() => setOther(!other)}>
                  <div>
                    <span>
                      <BsAsterisk />
                    </span>
                    <h5>Other</h5>
                    <span>{!other ? <FaChevronDown /> : <FaChevronUp />}</span>
                  </div>
                  {other ? (
                    <div>
                      <ul>
                        <li>Open to Relocate: {preferredopenToRelocate}</li>
                        <li>
                          Horoscope Matching: {preferredhoroscopeMatching}
                        </li>
                        <li>Physical Status: {preferredphysicalStatus}</li>
                        <li>
                          Profiles with Pictures: {preferredprofilePictures}
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {
            editMyProfilePhoto &&
            <div className="editMyProfile_page">
              <EditProfilePhoto userDetails={userDetails} setEditMyProfile={setEditMyProfile} editMyProfilePhoto={editMyProfilePhoto} isImageUploaded={isImageUploaded} setImageUploaded={setImageUploaded} />
            </div>
          }
          {
            editMyProfileDetails &&
            <div className="editMyProfileDetails">
              <EditMyProfile setEditMyDetails={setEditMyDetails} editMyProfileDetails={editMyProfileDetails} />
            </div>
          }
          {
            sideMenuPanel && 
            <div className="editSideMenuPanel">
              <SideMenuPanel setSideMenuPanel={setSideMenuPanel} />
            </div>
          }
          {showSlider ? (
            <div className="profile-preview-slider">
              <MultipleImagesSlider multipleImages={multipleImages} setShowSlider={setShowSlider} />
            </div>
          ) : null}
        </div>
      ) : (
        <MyPreferenceMobile setEditPreference={setEditPreference} />
      )}

          {showDashboard && (
        <div className="dashboard-wrapper">
          <Dashboard setShowDashboard={setShowDashboard} setActiveButton={setActiveButton} setEditMyDetails={setEditMyDetails} userDetails={userDetails} userPreference={myPreference}/>
        </div>
        )}
    </>
  );
}

export default ProfilePreviewMobile;
