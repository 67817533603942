import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import server from "../../config/server.json";
import "./ShortListProfile.css";
import selectIcon from "../../assets/ShortlistedProfileIcons/select.png";
import workIcon1 from "../../assets/ShortlistedProfileIcons/workIcon1.png";
import education from "../../assets/ShortlistedProfileIcons/education.png";
import cross from "../../assets/ShortlistedProfileIcons/basil_cross-solid.png";
import defaultProfileImage from "../../assets/avatar_placeholder.png";
import ProfileViewMob from "../../pages/ProfileView/ProfileViewMob";

function ShortListProfile() {
  const [showSelected, setShowSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestId, setRequestId] = useState([]);
  const [filteredShortlistProfile, setFilteredShortlistProfile] = useState([]);
  const [sortOption, setSortOption] = useState("Recent");
  const [isLoading, setIsLoading] = useState(true);
  const [isProfileViewVisible, setIsProfileViewVisible] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [data, setData] = useState([]);

  const handleCheckboxChange = (itemId, isChecked) => {
    if (showSelected) {
      if (isChecked) {
        setSelectedItems((prevItems) => [...prevItems, itemId]);
      } else {
        setSelectedItems((prevItems) =>
          prevItems.filter((item) => item !== itemId)
        );
      }
    }
  };

  const fetchShortlistUserData = async (userId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_USER_DATA}/${userId}`,
        {
          headers: headers,
        }
      );
      return response.data; 
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
      return null;
    }
  };

  const fetchShortlistData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.FETCH_SHORTLIST_PROFILE}`,
        {
          headers: headers,
        }
      );
      if (response.data) {
        const { requestId } = response.data;
        setRequestId(requestId);
        const userDataPromises = requestId.map((userId) => fetchShortlistUserData(userId));
        const userDataArray = await Promise.all(userDataPromises);
        const filteredUserData = userDataArray.filter((userData) => userData !== null);
        setData(filteredUserData);
      }
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortOptionChange = (option) => {
    setSortOption(option);
  };

  useEffect(() => {
    fetchShortlistData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sortedData = data
      .filter((profile) => requestId.includes(profile._id))
      .map((profile, index) => ({
        ...profile,
        index,
      }));

    switch (sortOption) {
      case "Recent":
        sortedData.sort((a, b) => b.index - a.index);
        break;
      case "Older":
        sortedData.sort((a, b) => a.index - b.index);
        break;
      case "A-Z":
        sortedData.sort((a, b) => a.first_name.localeCompare(b.first_name));
        break;
      case "Z-A":
        sortedData.sort((a, b) => b.first_name.localeCompare(a.first_name));
        break;
      default:
        break;
    }

    setFilteredShortlistProfile(sortedData);
  }, [data, requestId, selectedItems, sortOption]);

  const deleteShortlistProfile = async (userId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      await axios.delete(
        `${server.url.production}${server.api.DELETE_SHORTLIST_PROFILE}${userId}`,
        {
          headers: headers,
        }
      );
      fetchShortlistData();
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const selectHandler = () => {
    setShowSelected(true);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const goBackHandler = () => {
    setShowSelected(false);
    setSelectedItems([]);
  };

  const handleSelectAll = () => {
    if (selectedItems.length === filteredShortlistProfile.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filteredShortlistProfile.map((user) => user._id));
    }
  };

  const deleteRequestHandler = async () => {
    try {
      for (const id of selectedItems) {
        await deleteShortlistProfile(id);
      }
      setSelectedItems([]);
      handleCloseModal();
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const handleProfileView = (user) => {
    setIsProfileViewVisible(true);
    setSelectedProfile(user);
  };

  const handleBackFromProfileView = () => {
    setIsProfileViewVisible(false);
    setSelectedProfile(null);
  };

  return (
    <>
      {!isProfileViewVisible && (
        <div className="container short-list-container p-0">
          <div className="shortListHeader">
            <div className="shortlist-heading">
              <p className="pageTitle">Shortlisted Profiles</p>
            </div>

            {showSelected ? (
              <div className="shortlist-selected-Delete">
                <button className="select-cancel" onClick={handleSelectAll}>
                  Select All
                </button>

                {selectedItems.length ? (
                  <p>{selectedItems.length} selected</p>
                ) : (
                  <p> Select Items</p>
                )}
                <button className="select-cancel" onClick={goBackHandler}>
                  Cancel
                </button>
              </div>
            ) : (
              <div className="shortlist-selected">
                <div className="selected">
                  <img src={selectIcon} onClick={selectHandler} alt="" />
                  <p onClick={selectHandler}>Select</p>
                </div>
                <p>Sort:</p>
                <select
                  onChange={(e) => handleSortOptionChange(e.target.value)}
                  className="sorted"
                >
                  <option value="Recent">Recent</option>
                  <option value="Older">Older</option>
                  <option value="A-Z">A-Z</option>
                  <option value="Z-A">Z-A</option>
                </select>
              </div>
            )}
          </div>

          {isLoading ? (
            <div className="loader-container">
              <div className="lds-roller">
                {[...Array(8)].map((_, index) => (
                  <div key={index}></div>
                ))}
              </div>
            </div>
          ) : filteredShortlistProfile.length === 0 ? (
            <p className="no-profile-found">No shortlisted profiles found.</p>
          ) : (
            <div className="div-content">
              {filteredShortlistProfile.map((user) => {
                const { first_name, last_name, _id } = user;
                const {
                  age,
                  country,
                  city,
                  qualification,
                  companyName,
                  profileImage,
                } = user.userDetails;
                return (
                  <div key={_id} className="shortlistContent">
                    <div className="profile-wraper">
                      <div className="profile-detail">
                        {profileImage ? (
                          <img src={profileImage} alt="profile" />
                        ) : (
                          <img src={defaultProfileImage} alt="profile" />
                        )}
                        {showSelected && (
                          <input
                            type="checkbox"
                            className="checkImage"
                            checked={selectedItems.includes(user._id)}
                            onChange={(e) =>
                              handleCheckboxChange(_id, e.target.checked)
                            }
                          />
                        )}
                      </div>

                      <div className="profileContent">
                        <div className="name">
                          {showSelected ? (
                            <h3>{first_name} {last_name}</h3>
                          ) : (
                            <div
                              className="profile-link"
                              onClick={() => handleProfileView(user)}
                            >
                              <h3>{first_name} {last_name}</h3>
                            </div>
                          )}
                        </div>
                        <div className="location">
                          {age ? age : "N/A"} | {city ? city : "N/A"},
                          {country ? country : "N/A"}
                        </div>
                        <div className="education classDisplay">
                          <img src={education} alt="" />
                          <p>{qualification ? qualification : "N/A"}</p>
                        </div>
                        <div className="designation classDisplay">
                          <img src={workIcon1} height={20} width={20} alt="" />
                          <p>{companyName ? companyName : "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {showSelected && (
            <div className="ShortlistFooter-Delete">
              <button
                onClick={handleOpenModal}
                className="delete-shortlist-btn"
                disabled={selectedItems.length === 0}
              >
                Delete
              </button>
              {isModalOpen && (
                <div className="modal-overlay">
                  <div className="dialog">
                    <div className="modal-content-shortlist">
                      <div className="modal-header">
                        <h5>Delete {selectedItems.length} profiles?</h5>
                        <div className="crossImg">
                          <img
                            src={cross}
                            height={24}
                            width={24}
                            onClick={handleCloseModal}
                            alt=""
                          />
                        </div>
                      </div>

                      <p className="modal-message">
                        These profiles will be deleted from your collection{" "}
                      </p>
                      <div className="modal-btn">
                        <button onClick={handleCloseModal}>Cancel</button>
                        <button
                          className="modal-deleteBtn"
                          onClick={deleteRequestHandler}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {isProfileViewVisible && selectedProfile && (
        <div className="profile-view-modal">
          <ProfileViewMob
            userData={selectedProfile}
            userProfilePhoto={selectedProfile.userDetails?.profileImage}
            onBack={handleBackFromProfileView}
          />
        </div>
      )}
    </>
  );
}

export default ShortListProfile;
