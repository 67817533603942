import React from "react";
import "./PrivacyPolicy.css";
import paraContent from "./paraContent.json";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className="body">
      <Helmet>
        <title>privacy-policy</title>
        <meta
          name="description"
          content="We only ask for information that is relevant to our services and maintains transparency among both parties while matchmaking."
        />
      </Helmet>
      <section className="privacy_policy">
        <div className="page-heading">
          <div className="wrapper-heading">
            <h3 className="page-heading-text">Privacy Policy</h3>
          </div>
        </div>
        <div className="policy_content">
          <div className="wrapper">
            {paraContent.map((elem, index) => (
              <p key={index}>{elem}</p>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
