import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import axios from 'axios';
import server from "../../config/server.json";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineUser } from "react-icons/ai";
import { toast } from "react-toastify";

const EducationDetails = ({ userDetails, editEducationDetails, setEducationDetails }) => {
    const masterData = useSelector(state => state.masterData)
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [token, setToken] = useState(null);
    const [degreeDetails, setDegreeDetails] = useState({
        instituteName: "",
        specialization: "",
        passingYear: new Date().getFullYear(),
        percentage: "",
        qualification: "",
    });

    const {
        instituteName,
        percentage,
        qualification,
        passingYear,
        specialization,
    } = degreeDetails; // destructuring

    const toastifyAlert = (errStatus, msg) =>
        toast[errStatus](msg, {
            autoClose: 3000,
            position: "bottom-right",
        });

    // inputfield validation
    const handleValidation = () => {
        let count = 0;

        // eslint-disable-next-line
        Object.keys(degreeDetails).map((key) => {
            if (degreeDetails[key].length === 0) {
                toastifyAlert("error", `Please enter your ${key}`);
            } else {
                count++;
            }
        });
        if (percentage < 100) {
            console.log(percentage)
        }
        else {
            toast.error("Please enter valid percentage", {
                autoClose: 3000,
                position: "bottom-right",
            });
            return false
        }
        if (count === Object.keys(degreeDetails).length) {
            return true;
        }
        return false;
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setDegreeDetails({ ...degreeDetails, [name]: value });
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const apiPath = server.api.UPDATE_EDUCATION_DETAILS;
        const postData = {
            userId: userId,
            instituteName: instituteName,
            percentage: Number(percentage),
            qualification: qualification,
            passingYear: Number(passingYear),
            specialization: specialization,
        };

        if (handleValidation()) {
            const headers = {
                "Content-Type": "application/json",
                token: token,
            };

            try {
                const response = await axios.post(
                    `${server.url.production}${apiPath}`,
                    postData,
                    { headers: headers }
                );

                if (response.status === 201) {
                    toast.success(response.data.message, {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                } else {
                    toast.error(response.data.message, {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                }
                setEducationDetails(!editEducationDetails);
            } catch (error) {
                toast.success(error.response.data.message, {
                    autoClose: 3000,
                    position: "bottom-right",
                });
            }
        }
    };

    useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("user"));
        if (loggedUser) {
            setUserId(loggedUser.id);
            setToken(localStorage.getItem("token"));
        } else {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setDegreeDetails({
            instituteName: userDetails?.instituteName ? userDetails.instituteName : "",
            specialization: userDetails?.specialization ? userDetails.specialization : "",
            passingYear: userDetails?.passingYear ? userDetails.passingYear : "",
            percentage: userDetails?.percentage ? userDetails.percentage : "",
            qualification: userDetails?.qualification ? userDetails.qualification : "",
        });
    }, [userDetails]);

    return (
        <section className='education_details-data'>
            <div className="education_details-data_info">
                <div className="education-info">
                    <span>institute name</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : instituteName ? instituteName : "NA"
                        }
                    </span>
                </div>
                <div className="education-info">
                    <span>percentage</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : percentage
                                ? percentage
                                : "NA"
                        }%
                    </span>
                </div>
                <div className="education-info">
                    <span>qualification</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : qualification
                                ? qualification
                                : "NA"
                        }
                    </span>
                </div>
                <div className="education-info">
                    <span>passing year</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : passingYear
                                ? passingYear
                                : "NA"
                        }
                    </span>
                </div>
                <div className="education-info">
                    <span>Specialization</span>
                    <span>:</span>
                    <span>
                        {userDetails === undefined
                            ? ""
                            : specialization
                                ? specialization
                                : "NA"
                        }
                    </span>
                </div>
            </div>
            {
                editEducationDetails &&
                <div className='edit_education_details'>
                    <h4>
                        <span><RxCross1 onClick={() => setEducationDetails(!editEducationDetails)} />
                        </span><span>edit Education details</span>
                    </h4>
                    <h6>
                        <span><AiOutlineUser /></span>
                        <span>Education Information</span>
                    </h6>
                    <form onSubmit={handleOnSubmit}>
                        <div className="input-block">
                            <label htmlFor="instituteName">institute name</label>
                            <input type="text" name='instituteName' id='instituteName' value={instituteName} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="percentage">percentage</label>
                            <input type="text" name='percentage' id='percentage' value={percentage} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="qualification">qualification</label>
                            <select
                                name="qualification"
                                id="qualification"
                                onChange={(e) => handleOnChange(e)}
                                value={qualification}
                            >
                                <optgroup label="Education Degree" />
                                <option value="None">{OPTION_SELECT_EMPTY}</option>
                                {masterData?.qualification.map((x) => {
                                    return (
                                        <option key={x.value} value={x.value}>
                                            {x.value}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="passingYear">passing year</label>
                            <select
                                value={passingYear}
                                onChange={(e) => handleOnChange(e)}
                                name="passingYear"
                                id='passingYear'
                            >
                                <optgroup label="Years" />
                                <option value="None">{OPTION_SELECT_EMPTY}</option>
                                {masterData?.educationCompleteYear.slice().sort((a, b) => a.value - b.value).map((elem) => {
                                    return (
                                        <option key={elem._id} value={elem.year}>
                                            {elem.year}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="specialization">specialization</label>
                            <input type="text" name='specialization' id='specialization' value={specialization} onChange={handleOnChange} />
                        </div>
                        <div className="input-block-btn">
                            <button type='submit'>save</button>
                        </div>
                    </form>
                </div>
            }
        </section>
    )
}

export default EducationDetails;