import React, { useEffect, useState } from "react";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import { useSelector } from "react-redux";
import { toFeet } from "../../utils/ConvertCmToFeet";
import searchOptionsData from "../../pages/SearchPage/SearchOptionsData.json";
import "./MyPreference.css";
import { toast } from "react-toastify";
import axios from "axios";
import server from "../../config/server.json";
import { useNavigate } from "react-router-dom";

function MyPreference({ next, setNext }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const masterData = useSelector((state) => state.masterData);
  const ageData = searchOptionsData.searchField.age;
  const [myPreference, setMyPreference] = useState({
    address: "",
    height: "",
    ageRangeFrom: "",
    ageRangeTo: "",
    marital: "",
    education: "",
    annualIncome: "",
    drinking: "",
    smoking: "",
    familyType: "",
    familyValue: "",
    familyStatus: "",
    occupation: "",
    physicalStatus: "",
    openToRelocate: "",
    horoscopeMatching: "",
    profilePictures: "",
  });

  if (myPreference === "") {
    console.log("true");
  }

  const {
    address,
    height,
    ageRangeFrom,
    ageRangeTo,
    marital,
    education,
    annualIncome,
    drinking,
    smoking,
    familyType,
    familyValue,
    familyStatus,
    occupation,
    physicalStatus,
    openToRelocate,
    horoscopeMatching,
    profilePictures,
  } = myPreference;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setMyPreference({ ...myPreference, [name]: value });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (
      !address &&
      !height &&
      !ageRangeFrom &&
      !ageRangeTo &&
      !marital &&
      !education &&
      !annualIncome &&
      !drinking &&
      !smoking &&
      !familyType &&
      !familyValue &&
      !familyStatus &&
      !occupation &&
      !physicalStatus &&
      !openToRelocate &&
      !horoscopeMatching &&
      !profilePictures
    ) {
      toast.error("Please select atleast 1 field.", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else {
      try {
        const headers = {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        };
        const body = {
          userId: user.id,
          address: address ? address : "",
          Height: height ? height : "",
          ageRangeFrom: ageRangeFrom ? ageRangeFrom : "",
          ageRangeTo: ageRangeTo ? ageRangeTo : "",
          marital: marital ? marital : "",
          education: education ? education : "",
          annualIncome: annualIncome ? annualIncome : "",
          drinking: drinking ? drinking : "",
          smoking: smoking ? smoking : "",
          familyType: familyType ? familyType : "",
          familyValue: familyValue ? familyValue : "",
          familyStatus: familyStatus ? familyStatus : "",
          occupation: occupation ? occupation : "",
          physicalStatus: physicalStatus ? physicalStatus : "",
          openToRelocated: openToRelocate ? openToRelocate : "",
          horoscopeMatching: horoscopeMatching ? horoscopeMatching : "",
          profilePictures: profilePictures ? profilePictures : "",
        };
        const result = await axios.post(
          `${server.url.production}${server.api.EDIT_PREFERENCE}`,
          body,
          { headers }
        );
        if (result.status === 201) {
          toast.success(result.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
          setNext(true);
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        };
        const response = await axios.get(
          `${server.url.production}${server.api.FETCH_PREFERENCES}`,
          { headers }
        );
        setMyPreference({
          address: response.data.address ? response.data.address : "",
          height: response.data.Height ? response.data.Height : "",
          ageRangeFrom: response.data.ageRangeFrom
            ? response.data.ageRangeFrom
            : "",
          ageRangeTo: response.data.ageRangeTo ? response.data.ageRangeTo : "",
          marital: response.data.marital ? response.data.marital : "",
          education: response.data.education ? response.data.education : "",
          annualIncome: response.data.annualIncome
            ? response.data.annualIncome
            : "",
          drinking: response.data.drinking ? response.data.drinking : "",
          smoking: response.data.smoking ? response.data.smoking : "",
          familyType: response.data.familyType ? response.data.familyType : "",
          familyValue: response.data.familyValue
            ? response.data.familyValue
            : "",
          familyStatus: response.data.familyStatus
            ? response.data.familyStatus
            : "",
          occupation: response.data.occupation ? response.data.occupation : "",
          physicalStatus: response.data.physicalStatus
            ? response.data.physicalStatus
            : "",
          openToRelocate: response.data.openToRelocated
            ? response.data.openToRelocated
            : "",
          horoscopeMatching: response.data.horoscopeMatching
            ? response.data.horoscopeMatching
            : "",
          profilePictures: response.data.profilePictures
            ? response.data.profilePictures
            : "",
        });
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    };
    fetchPreferences();
  }, []);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setUser(loggedUser);
    } else {
      navigate("/");
      toast.error("Something Went Wrong, Please Try Again Later", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  }, [navigate]);

  return (
    <form onSubmit={handleOnSubmit} className="mypreference">
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="address">Address:</label>
          <div className="mypreference-input">
            <input
              type="text"
              name="address"
              value={address}
              onChange={handleOnChange}
              placeholder="Enter address"
            />
          </div>
        </div>
        <div className="mypreference-inputs">
          <label htmlFor="height">Height:</label>
          <div>
            <select name="height" value={height} onChange={handleOnChange}>
              <option value="none">{OPTION_SELECT_EMPTY}</option>
              <optgroup label="Heights" />
              {masterData.height?.map((elem) => {
                return (
                  <option key={elem._id} value={elem.height}>
                    {toFeet(elem.height)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="ageRangeFrom">Age Range From:</label>
          <div>
            <select
              name="ageRangeFrom"
              id=""
              value={ageRangeFrom}
              onChange={handleOnChange}
            >
              <option value="none">{OPTION_SELECT_EMPTY}</option>
              <optgroup label="ageRangeFrom">
                {ageData.values.minValueArray?.map((elem, index) => {
                  return (
                    <option key={index} value={elem.value}>
                      {elem.value}
                    </option>
                  );
                })}
              </optgroup>
            </select>
          </div>
        </div>
        <div className="mypreference-inputs">
          <label htmlFor="ageRangeTo">Age Range To:</label>
          <div>
            <select
              name="ageRangeTo"
              id=""
              value={ageRangeTo}
              onChange={handleOnChange}
            >
              <option value="none">{OPTION_SELECT_EMPTY}</option>
              <optgroup label="ageRangeTo">
                {ageData.values.minValueArray?.map((elem, index) => {
                  return (
                    <option key={index} value={elem.value}>
                      {elem.value}
                    </option>
                  );
                })}
              </optgroup>
            </select>
          </div>
        </div>
      </div>
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="marital">Marital:</label>
          <div>
            <select
              name="marital"
              id="marital"
              value={marital}
              onChange={handleOnChange}
            >
              <optgroup label="Marital Status" />
              <option value="none">Choose Your Status</option>
              {masterData.marital?.map((elem) => {
                return (
                  <option key={elem._id} value={elem.maritalStatus}>
                    {" "}
                    {elem.maritalStatus}{" "}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="mypreference-inputs">
          <label htmlFor="education">Education:</label>
          <div>
            <select
              name="education"
              value={education}
              onChange={handleOnChange}
            >
              <optgroup label="Education Degree" />
              <option value="None">{OPTION_SELECT_EMPTY}</option>
              {masterData?.educationDegree?.map((x) => {
                return (
                  <option key={x._id} value={x.degreeName}>
                    {x.degreeName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="annualIncome">Annual Income:</label>
          <div>
            <select
              name="annualIncome"
              value={annualIncome}
              onChange={handleOnChange}
            >
              <optgroup label="Income" />
              <option value="Selected">{OPTION_SELECT_EMPTY}</option>
              {masterData.income?.map((elem) => {
                return (
                  <option key={elem._id} value={elem.income}>
                    {elem.income}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="mypreference-inputs">
          <label htmlFor="drinking">Drinking:</label>
          <div className="mypreference-radio">
            <input
              type="radio"
              value="Yes"
              name="drinking"
              checked={drinking === "Yes"}
              onChange={handleOnChange}
            />{" "}
            Yes
            <input
              type="radio"
              value="No"
              name="drinking"
              checked={drinking === "No"}
              onChange={handleOnChange}
            />{" "}
            No
          </div>
        </div>
      </div>
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="smoking">Smoking:</label>
          <div className="mypreference-radio">
            <input
              type="radio"
              value="Yes"
              name="smoking"
              checked={smoking === "Yes"}
              onChange={handleOnChange}
            />{" "}
            Yes
            <input
              type="radio"
              value="No"
              name="smoking"
              checked={smoking === "No"}
              onChange={handleOnChange}
            />{" "}
            No
          </div>
        </div>
        <div className="mypreference-inputs">
          <label htmlFor="familyType">Family Type:</label>
          <div>
            <select
              name="familyType"
              id="familyType"
              value={familyType}
              onChange={handleOnChange}
            >
              <optgroup label="Family Type" />
              <option>Select Family Type</option>
              {masterData.familyType?.map((elem) => {
                return <option key={elem._id}>{elem.familyTypeName}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="familyValue">Family Value:</label>
          <div>
            <select
              name="familyValue"
              id="familyValue"
              value={familyValue}
              onChange={handleOnChange}
            >
              <optgroup label="Family Value" />
              <option>{OPTION_SELECT_EMPTY}</option>
              {masterData.familyValues?.map((elem) => {
                return <option key={elem._id}>{elem.familyValueName}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="mypreference-inputs">
          <label htmlFor="familyStatus">Family Status:</label>
          <div>
            <select
              name="familyStatus"
              id="familyStatus"
              value={familyStatus}
              onChange={handleOnChange}
            >
              <optgroup label="Family Status" />
              <option>Select Family Status</option>
              {masterData.familyStatus?.map((elem) => {
                return <option key={elem._id}>{elem.familyStatusName}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="occupation">Occupation:</label>
          <div className="mypreference-input">
            <input
              type="text"
              name="occupation"
              value={occupation}
              onChange={handleOnChange}
              id=""
            />
          </div>
        </div>
        <div className="mypreference-inputs">
          <label htmlFor="physicalStatus">Physical Status:</label>
          <div className="mypreference-input">
            <input
              type="text"
              name="physicalStatus"
              value={physicalStatus}
              onChange={handleOnChange}
            />
          </div>
        </div>
      </div>
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="openToRelocate">Open to Relocate:</label>
          <div className="mypreference-radio">
            <input
              type="radio"
              name="openToRelocate"
              value="Yes"
              checked={openToRelocate === "Yes"}
              onChange={handleOnChange}
            />{" "}
            Yes
            <input
              type="radio"
              name="openToRelocate"
              value="No"
              checked={openToRelocate === "No"}
              onChange={handleOnChange}
            />{" "}
            No
          </div>
        </div>
        <div className="mypreference-inputs">
          <label htmlFor="horoscopeMatching">Horoscope Matching:</label>
          <div className="mypreference-radio">
            <input
              type="radio"
              value="Yes"
              name="horoscopeMatching"
              checked={horoscopeMatching === "Yes"}
              onChange={handleOnChange}
            />{" "}
            Yes
            <input
              type="radio"
              value="No"
              name="horoscopeMatching"
              checked={horoscopeMatching === "No"}
              onChange={handleOnChange}
            />{" "}
            No
          </div>
        </div>
      </div>
      <div className="mypreference-fields">
        <div className="mypreference-inputs">
          <label htmlFor="profilePictures">Profile Pictures:</label>
          <div className="mypreference-radio">
            <input
              type="radio"
              value="Yes"
              name="profilePictures"
              checked={profilePictures === "Yes"}
              onChange={handleOnChange}
            />{" "}
            Yes
            <input
              type="radio"
              value="No"
              name="profilePictures"
              checked={profilePictures === "No"}
              onChange={handleOnChange}
            />{" "}
            No
          </div>
        </div>
      </div>
      <div className="saveclosebtns">
        <button type={"button"}>Previous</button>
        <button type={"submit"}>Save & Close</button>
      </div>
    </form>
  );
}

export default MyPreference;
