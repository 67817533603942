import React, { useEffect, useState } from "react";
import DefaultUserProfileImage from "../../assets/avatar_placeholder.png";
import { useNavigate } from "react-router-dom";
import { BsFillCameraFill, BsFillTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import "./ProfilePhoto.css";
import axios from "axios";
import server from "../../config/server.json";

function ProfilePhoto({ steps, setSteps, userDetails, next, setNext }) {
  const navigate = useNavigate();
  const uploadSize = 5; // Image file size limit 5 mb
  const [base64, setBase64] = useState("");
  const [token, setToken] = useState(null);
  const [pic, setPic] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const multipleImageArray = [];
  const [multImg, setMultImg] = useState([]);
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [close, setClose] = useState(false);
  //file to base64
  const getbase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      return setBase64(reader.result);
    };
  };

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;
    if (
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png"
    ) {
      for (let index = 0; index < selectedFiles.length; index++) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFiles[index]);
        reader.onload = () => {
          let str = reader.result;
          str = str.replace(/^"(.*)"$/, "$1");
          multipleImageArray.push(str);
        };
      }
      setTimeout(() => {
        setMultImg(multipleImageArray);
      }, 1000);

      const selectedFilesArray = Array.from(selectedFiles);
      const imagesArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    } else {
      toast.error("Image should be of .png or .jpg or .jpeg type only", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };
  const handleDelete = async (id) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await fetch(
        `${server.url.production}${server.api.DELETE_MULTIPLE_PROFILE_IMAGES}${id}`,
        {
          method: "PUT",
          headers,
        }
      );

      if (response.status === 200) {
        toast.success("Image deleted successfully", {
          autoClose: 3000,
          position: "bottom-right",
        });
        window.location.reload();
      } else {
        toast.success("Something Went Wrong", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };
  const handleOnChange = (e) => {
    setPic(e.target.files);
    getbase64(e.target.files[0]); //file to base64
    setProfileImagePreview(URL.createObjectURL(e.target.files[0]));
  };
  const handleOnValidations = () => {
    const maxSize = 5000000; // Max size for the Image(5MB)
    if (!pic) {
      toast.error("*** Please Select The File", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (pic[0].size >= maxSize) {
      toast.error("*** Image should be less than 5MB", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else {
      return true;
    }
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (userDetails.profileImage) {
      if (close) {
        navigate('/');
      }
      if (next) {
        setSteps(3);
        setNext(false);
      }
    }
    if (pic) {
      handleOnValidations();
      if (
        pic[0].type === "image/jpeg" ||
        pic[0].type === "image/jpeg" ||
        pic[0].type === "image/png"
      ) {
        try {
          const headers = {
            "Content-Type": "application/json",
            token: token,
          };
          const body = {
            userId: userDetails.id,
            profileImage: base64,
          };
          const response = await axios.post(
            `${server.url.production}${server.api.UPDATE_PROFILE_PHOTO}`,
            body,
            { headers }
          );
          if (response.status === 201) {
            if (close) {
              navigate('/');
            }
            if (next) {
              console.log(next)
              setSteps(3);
              setNext(false);
            }
            toast.success(response.data.message, {
              autoClose: 3000,
              position: "bottom-right",
            });
          } else {
            toast.error("Something went wrong.", {
              autoClose: 3000,
              position: "bottom-right",
            });
          }
        } catch (error) {
          toast.error(error.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      } else {
        toast.error("***Image should be of jpeg, jpg or png format", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };
  const handleOnUpload = async (e) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const body = {
        profileImages: multImg,
      };
      const response = await axios.post(
        `${server.url.production}${server.api.MULTIPLE_IMG}`,
        body,
        { headers }
      );
      if (response.status === 201) {
        navigate("/home");
        toast.success("User profile images uploaded successfully", {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error("Something went wrong.", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };
  useEffect(() => {
    if (token) {
      const getFetchImage = async () => {
        try {
          const headers = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          };
          const response = await axios.get(
            `${server.url.production}${server.api.GET_MULTIPLE_PROFILE_IMAGES}`,
            {
              headers: headers,
            }
          );
          setFilteredPhotos(
            response.data.filter((singleImg) => singleImg.status === "A")
          );
        } catch (error) {
          toast.error(error.response.data.error, {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      };
      getFetchImage();
    }
  }, [token]);
  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setToken(localStorage.getItem("token"));
    } else {
      navigate("/");
    }
  }, [navigate]);
  return (
    <>
      <div className="profile__photo">
        <div className="profile__content">
          <div className="wrapper">
            <form onSubmit={handleOnSubmit}>
              <div className="profile__upload">
                <div className="profile__image">
                  <img
                    src={
                      profileImagePreview
                        ? profileImagePreview
                        : userDetails.profileImage
                          ? userDetails.profileImage
                          : DefaultUserProfileImage
                    }
                    alt="User Profile"
                  />
                </div>
                <div className="profile__browse__photo">
                  <div>
                    <label htmlFor="inputFile">
                      <span>choose your profile photo</span>
                      <BsFillCameraFill className="cameraIcon" />
                      <input
                        id="inputFile"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleOnChange}
                      />
                    </label>
                  </div>
                  <h5>upload less than {uploadSize}MB</h5>
                  <p>
                    <span>please note: </span> it may take up to 2 hours for
                    photo validation, after which it will be visible to
                    everyone.
                  </p>
                </div>
              </div>
              <div className="add-profile-images">
                <label>
                  + Add Images
                  <p>Upto 6 images</p>
                  <input
                    type="file"
                    name="images"
                    onChange={onSelectFile}
                    multiple
                    accept="image/png, image/jpeg, image/jpg"
                  />
                </label>
                <div className="uploaded-profile-images-row">
                  {selectedImages.length > 0 &&
                    (selectedImages.length > 6 ? (
                      toast.error("You can't upload more than 6 images.", {
                        autoClose: 3000,
                        position: "bottom-right",
                      })
                    ) : (
                      <>
                        <span
                          className="upload-mult-img-btn"
                          onClick={handleOnUpload}
                        >
                          Click here to upload images
                        </span>
                      </>
                    ))}
                  {filteredPhotos === null
                    ? ""
                    : filteredPhotos.length > 0
                      ? Object.entries(filteredPhotos).map((elem) => (
                        <div key={elem} className="added-images">
                          <img
                            src={elem[1].profileImages}
                            className="uploaded-profile-images"
                            alt=""
                          />
                          <span
                            onClick={() => handleDelete(elem[1]._id)}
                            className="delete-btn"
                          >
                            <BsFillTrashFill />
                          </span>
                        </div>
                      ))
                      : ""}
                </div>
              </div>
              <div className="profile__button">
                <button type={"button"} onClick={() => setSteps(1)}>Previous</button>
                <button type={"submit"} onClick={() => setClose(true)}>Save & Close</button>
                <button type="submit" onClick={() => setNext(true)}>Save & Next</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProfilePhoto;
