import React, { useEffect, useRef, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";

const ModalContainer = ({ isModalOpen, currentSelectedField, toggleCheckbox, searchData, toggleModal, modalLoader, setModalLoader }) => {
  
  // search state is used to store the value of search field of modal container.
  const [search, setSearch] = useState("");
  // clickedAlphobet state is used to store the current clicked alphabet of the modal container.
  const [clickedAlphabet, setClickedAlphabet] = useState("");
  // alphabet is the array of all the alphabet.
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  // modalContentRef is used to store the ref the modal container.
  const modalContentRef = useRef(null);

  // This function is used to toggle the checkbox of the Modal
  // It also takes the value of the clicked checkbox and send the value to togglecheckbox function.
  // After completing the request is also enable the checkbox.
  const toggleModalCheckbox = async (value) => {
    setModalLoader(true);
    await toggleCheckbox(value);
    setModalLoader(false);
  };

  // Logic for closing the modal when the user clicks outside the modal
  // It compares the DOM of the mouse event and modal ref and handle the case accordingly
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        toggleModal();
      }
    }

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]); //eslint-disable-line

  return (
    <div ref={modalContentRef} className="search-modal-container" style={!isModalOpen ? { pointerEvents: "none" } : null}
    >
      {/* Top container */}
      <div className="search-modal-container1">
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          // If there is any alreaday alphabet search going on then we are clearing the alphabet search on click of search.
          // It help to perform the search functionally smooth and gives great UX.
          onClick={() => {setClickedAlphabet("")}}
        />
        <RxCrossCircled
          className="modal-reset"
          size={"1.2rem"}
          style={{color: clickedAlphabet || search ? "red" : "black", cursor: "pointer"}}
          // This red cross is used to clear the filter inside the modal container such that user can easily switch to different filters easily.
          onClick={()=>{setSearch("");setClickedAlphabet("")}}
        />
        {/* Mapping array of alphabets */}
        <ul>
          {alphabet.map((elem, i) => {
            return (
              <li
                key={i}
                onClick={() =>{setClickedAlphabet(elem);setSearch("")}}
                style={
                  elem === clickedAlphabet
                    ? { fontWeight: "bold", color: "black" }
                    : { fontWeight: "normal" }
                }
              >
                {elem}
              </li>
            );
          })}
        </ul>
        {/* This span is used to close the modal when clicked on cross logo */}
        <span className="close-search-btn" onClick={() => toggleModal()}><AiOutlineClose size={"1.2rem"} /></span>
      </div>
      <div className="m-2">
        {/* Here current selected field will be rendered */}
        <h5 className="modal-label">{currentSelectedField}</h5>
      </div>
      {/* Bottom Container */}
      <div className="search-modal-container2">
        <ul>
          {
            searchData[currentSelectedField].values.filter((item) => {
              // Filter by selected alphabet
              if (clickedAlphabet) {
                return item.value.toLowerCase().startsWith(clickedAlphabet.toLowerCase());
              }
              // Filter by search query
              if (search) {
                return item.value.toLowerCase().includes(search.toLowerCase());
              }
              // If neither filter is applied, include the item in the results
              return true;
            })
            .map((selectedField, i) => (
              <li key={i}>
                <label htmlFor={selectedField.value}>
                  <input
                    type="checkbox"
                    value={selectedField.value}
                    checked={selectedField.checked}
                    onChange={(e) => toggleModalCheckbox(e.target.value)}
                    disabled={modalLoader}
                  />
                  {selectedField.value}
                </label>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default ModalContainer;