import React, { useEffect, useState, useRef } from "react";
import { figmaIcons } from "../../assets/FigmaIcons/figmaIcons";
import "./ShowingHalfDetails.css";

const BottomSheetHeader = ({ setIsBottomSheetOpen, userId, setShowProfileViewMob }) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={figmaIcons.basil_cross}
          alt="cross"
          className="bottomSheetCross"
          onClick={() => setIsBottomSheetOpen(false)}
        />
        <div className="bottomSheetDetails text-decoration-underline" onClick={() => setShowProfileViewMob(true)}>
          {" "}
          Full Profile &gt;
        </div>
      </div>
      <div className="bottomSheetHeader">
        <div className="aboutSideLine"></div>
        <div className="px-4 fs-4 font-weight-normal bottomSheetHeaderAbout">
          About
        </div>
        <div className="aboutSideLine"></div>
      </div>
    </div>
  );
};

// This is a BottomSheet component that takes in isOpen, onClose, and children as props
const BottomSheet = ({
  isBottomSheetOpen,
  setIsBottomSheetOpen,
  children,
  userId,
  setShowProfileViewMob,
}) => {
  // useRef is used to reference the bottom sheet DOM element
  const bottomSheetRef = useRef(null);
  // useState is used to manage the state of the Y-axis coordinates and dragging status
  const [startY, setStartY] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  // useEffect is used to add event listeners for mouse and touch events
  useEffect(() => {
    // handleMouseMove updates the height of the bottom sheet as it is being dragged
    const handleMouseMove = (e) => {
      if (!isDragging) return;

      setCurrentY(e.clientY || e.touches[0].clientY);
      const deltaY = currentY - startY;
      setStartY(currentY);

      const sheetHeight = bottomSheetRef.current.clientHeight;
      const windowHeight = window.innerHeight;

      const newHeight = Math.max(sheetHeight - deltaY, windowHeight * 0.5);

      if (newHeight >= windowHeight * 0.5) {
        bottomSheetRef.current.style.height = `${newHeight}px`;
      }
    };

    // handleMouseUp sets isDragging to false when the user releases the mouse button
    const handleMouseUp = () => {
      setIsDragging(false);

      // Select the .bottom-sheet-content element
      let bottomSheetContent = document.querySelector(".bottom-sheet-content");

      // Checking if the element exists before modifying its style
      if (bottomSheetContent) {
        // Change the overflow style
        bottomSheetContent.style.overflowY = "scroll";
      }
    };

    // Adding event listeners for mouse and touch events
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove, { passive: false });
    document.addEventListener("touchend", handleMouseUp, { passive: false });

    // Removing event listeners when the component unmounts
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [isDragging, startY, currentY]);

  // useEffect is used to prevent scrolling in the background when the bottom sheet is open
  useEffect(() => {
    if (isBottomSheetOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isBottomSheetOpen]);

  // handleMouseDown sets the initial Y-axis coordinates and sets isDragging to true
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartY(e.clientY || e.touches[0].clientY);
    setCurrentY(startY);

    // Select the .bottom-sheet-content element
    let bottomSheetContent = document.querySelector(".bottom-sheet-content");

    // Change the overflow style
    bottomSheetContent.style.overflowY = "hidden";
  };

  // If the bottom sheet is not open, render nothing
  if (!isBottomSheetOpen) {
    return null;
  }

  return (
    <div
      className="bottom-sheet-overlay"
      onClick={() => setIsBottomSheetOpen(false)}
    >
      <div
        className="bottom-sheet-content"
        ref={bottomSheetRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="drag-bar"
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
        >
          <BottomSheetHeader
            setIsBottomSheetOpen={setIsBottomSheetOpen}
            userId={userId}
            setShowProfileViewMob={setShowProfileViewMob}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default BottomSheet;
