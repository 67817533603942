import React from "react";
import pageBottom from "../../../assets/page_bottom_format_4.png";
import firstPage from "../../../assets/BiodataImages/background-8.png";
import secondPage from "../../../assets/BiodataImages/background-8-2.png";
import familyImg from "../../../assets/BiodataImages/Family_photo.jpg";

import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
  Font,
} from "@react-pdf/renderer";

Font.register({
  src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
  family: "Ubuntu",
});

const styles = StyleSheet.create({
  pageBackground: {
    position: "absolute",
    width: "100%",
    backgroundSize: "cover",
  },

  body1: {
    marginTop: 15,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },

  View1: {
    width: 350,
    height: 270,
    display: "flex",
    justifyContent: "space-between",
  },

  ViewImage: {
    position: "absolute",
    top: 40,
    left: 40,
    backgroundColor: "#C5C5C5",
    borderRadius: 5,
  },
  ViewImage1: {
    width: "80%",
    height: 300,
    top: 35,
    bottom: 10,
    alignSelf: "center",
  },
  UserImage: {
    width: 170,
    height: 170,
  },
  UserImage1: {
    width: 300,
    height: 230,
    alignSelf: "center",
  },
  nameText: {
    top: 35,
    marginBottom: 20,
    fontSize: 20,
    letterSpacing: 1,
    fontFamily: "Helvetica-Bold",
  },
  text: {
    marginBottom: 8,
    fontSize: 12,
    top: 22,
    padding: "2 0",
  },
  body2: {
    marginTop: 10,
    position: "relative",
  },
  body3: {
    marginTop: 0,
    position: "relative",
  },
  FamilyText: {
    marginLeft: 28,
    letterSpacing: 1,
    fontWeight: "700",
    fontFamily: "Helvetica-Bold",
  },
  FamilyText1: {
    marginLeft: 35,
    marginBottom: 5,
    fontSize: 12,
    padding: "3 0",
  },
  FamilyText3: {
    marginLeft: 35,
    marginBottom: 5,
    fontSize: 12,
    width: 170,
    letterSpacing: 1,
  },
  FamilyText2: {
    marginLeft: 35,
    marginBottom: 15,
    fontSize: 12,
  },
  FamilyTextContent: {
    marginTop: 20,
  },
  FamilyTextContent1: {
    marginTop: 12,
  },
  lastContent: {
    textAlign: "center",
  },
  Contact: {
    width: 297,
    position: "absolute",
    top: 0,
    right: 10,
  },
  Contact2: {
    width: 350,
    position: "absolute",
    top: 0,
    right: 10,
  },
  contactText: {
    paddingLeft: 35,
    marginBottom: 15,
    fontSize: 12,
  },
});

const FormatFour = ({
  fields,
  uncleList,
  auntyList,
  mamaList,
  mamiList,
  brotherList,
  sisterList,
  cousinList,
  base64,
}) => {
  const dob = new Date(fields.dob);
  const birthDate = isNaN(dob.getDate()) ? "" : dob.getDate();
  const birthYear = isNaN(dob.getFullYear()) ? "" : dob.getFullYear();
  const birthMonth =
    dob.toLocaleString("default", { month: "long" }) === "Invalid Date"
      ? ""
      : dob.toLocaleString("default", { month: "long" });
  const date_of_birth = `${birthDate} ${birthMonth} ${birthYear}`;

  const checkIfLastIndex = (index, length) => {
    return index === length - 1 ? "" : ", ";
  };

  return (
    <Document>
      <Page>
        <View>
          <Image
            src={firstPage}
            alt={"Scond Page"}
            style={styles.pageBackground}
          />
        </View>

        <View style={styles.body1}>
          <View style={styles.ViewImage}>
            <Image
              style={styles.UserImage}
              src={{ uri: base64 }}
              alt={"User Image"}
            />
          </View>
          <View style={styles.View1}>
            <View>
              <Text style={styles.nameText}>
                {fields.first_name} {fields.last_name}
              </Text>
              <Text style={styles.text}>Date of Birth : {date_of_birth}</Text>
              <Text style={styles.text}>Gender : {fields.gender}</Text>
              <Text style={styles.text}>
                Blood Group : {fields.blood_group}
              </Text>
              <Text style={styles.text}>Country : {fields.country}</Text>
            </View>
          </View>
        </View>
        <View style={styles.body3}>
          <View style={styles.Contact2}>
            <View>
              <Text style={styles.FamilyText}> Overview</Text>
            </View>
            <View style={styles.FamilyTextContent1}>
              <Text style={styles.contactText}>
                Birth of Place : {fields.pob}
              </Text>
              <Text style={styles.contactText}>Height : {fields.height}</Text>
              <Text style={styles.contactText}>
                {" "}
                Marital Status : {fields.marital}
              </Text>
              <Text style={styles.contactText}>
                Religion : {fields.religion}
              </Text>
              <Text style={styles.contactText}>
                Horoscope : {fields.horoscope}
              </Text>
              <Text style={styles.contactText}>
                Language Known : {fields.language}
              </Text>
            </View>

            <View>
              <Text style={styles.FamilyText}>Occupation and education </Text>
            </View>
            <View style={styles.FamilyTextContent1}>
              <Text style={styles.contactText}>
                Institute Name : {fields.instituteName}
              </Text>
              <Text style={styles.contactText}>
                Company Name : {fields.companyName}
              </Text>
              <Text style={styles.contactText}>
                Highest Qulification : {fields.qualification}
              </Text>
              <Text style={styles.contactText}>
                Specialization : {fields.specialization}
              </Text>
              <Text style={styles.contactText}>
                Occupation : {fields.designation}
              </Text>
              <Text style={styles.contactText}>Income : {fields.income}</Text>
            </View>

            <View>
              <Text style={styles.FamilyText}>Contact Details</Text>
            </View>
            <View style={styles.FamilyTextContent1}>
              <Text style={styles.contactText}>
                Contact Number : {fields.phone_number}
              </Text>
              <Text style={styles.contactText}>Email : {fields.email_id}</Text>
              <Text style={styles.contactText}>
                Residence Address : {fields.address_1}
              </Text>
            </View>
          </View>
          <View>
            <Text style={styles.FamilyText}> About Myself</Text>
          </View>
          <View style={styles.FamilyTextContent1}>
            {
              !fields.about_us ? 
              <Text style={styles.FamilyText3}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Text>
            :
            <Text style={styles.FamilyText3}>{fields.about_us}</Text>
            }
          </View>
        </View>
        <View>
          <Text style={styles.lastContent}>
            <Image
              style={styles.BottomImage}
              src={pageBottom}
              alt={"Bottom Image"}
            />
          </Text>
        </View>
      </Page>

      <Page>
        <View>
          <Image
            src={secondPage}
            alt={"Scond Page"}
            style={styles.pageBackground}
          />
        </View>

        <View style={styles.body1}>
          <View style={styles.ViewImage1}>
            <Image
              style={styles.UserImage1}
              src={familyImg}
              alt={"User Image"}
            />
          </View>
        </View>
        <View style={styles.body2}>
          <View style={styles.Contact}>
            <View>
              <Text style={styles.FamilyText}>Contact Details</Text>
            </View>
            <View style={styles.FamilyTextContent}>
              <Text style={styles.FamilyText1}>Nanaji : {fields.nana}</Text>
              <Text style={styles.FamilyText1}>Naniji : {fields.nani}</Text>
              <Text style={styles.FamilyText1}>
                Uncles :{" "}
                {
                  // eslint-disable-next-line
                  uncleList.map((elem, index) => {
                    if (elem.check) {
                      return (
                        <Text key={index} style={styles.FamilyText1}>
                          {elem.chacha}
                          {checkIfLastIndex(index, uncleList.length)}
                        </Text>
                      );
                    }
                  })
                }
              </Text>
              <Text style={styles.FamilyText1}>
                Aunty :{" "}
                {
                  // eslint-disable-next-line
                  auntyList.map((elem, index) => {
                    if (elem.check) {
                      return (
                        <Text key={index} style={styles.FamilyText1}>
                          {elem.chachi}
                          {checkIfLastIndex(index, auntyList.length)}
                        </Text>
                      );
                    }
                  })
                }
              </Text>
              <Text style={styles.FamilyText1}>
                Mama :{" "}
                {
                  // eslint-disable-next-line
                  mamaList.map((elem, index) => {
                    if (elem.check) {
                      return (
                        <Text key={index} style={styles.FamilyText1}>
                          {elem.mama}
                          {checkIfLastIndex(index, mamaList.length)}
                        </Text>
                      );
                    }
                  })
                }
              </Text>
              <Text style={styles.FamilyText1}>
                Mami :{" "}
                {
                  // eslint-disable-next-line
                  mamiList.map((elem, index) => {
                    if (elem.check) {
                      return (
                        <Text key={index} style={styles.FamilyText1}>
                          {elem.mami}
                          {checkIfLastIndex(index, mamiList.length)}
                        </Text>
                      );
                    }
                  })
                }
              </Text>
              <Text style={styles.FamilyText1}>
                Cousins Name :{" "}
                {
                  // eslint-disable-next-line
                  cousinList.map((elem, index) => {
                    if (elem.check) {
                      return (
                        <Text key={index} style={styles.FamilyText1}>
                          {elem.cousins}
                          {checkIfLastIndex(index, cousinList.length)}
                        </Text>
                      );
                    }
                  })
                }
              </Text>
              <Text style={styles.FamilyText1}>
                Native Place : {fields.nativePlace}
              </Text>
              <Text style={styles.FamilyText1}>
                Family Status : {fields.familyStatus}
              </Text>
            </View>
          </View>
          <View>
            <Text style={styles.FamilyText}>Family Details</Text>
          </View>
          <View style={styles.FamilyTextContent}>
            <Text style={styles.FamilyText1}>Father : {fields.fatherName}</Text>
            <Text style={styles.FamilyText1}>
              Father's Job : {fields.father_job}
            </Text>
            <Text style={styles.FamilyText1}>Mother : {fields.motherName}</Text>
            <Text style={styles.FamilyText1}>
              Mother's Job : {fields.mother_job}
            </Text>
            <Text style={styles.FamilyText1}>Grand Father : {fields.dada}</Text>
            <Text style={styles.FamilyText1}>Grand Mother : {fields.dadi}</Text>
            <Text style={styles.FamilyText1}>
              Number Of Siblings : {fields.siblings}
            </Text>
            <Text style={styles.FamilyText1}>
              Brother Name :{" "}
              {
                // eslint-disable-next-line
                brotherList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.FamilyText1}>
                        {elem.brother}
                        {checkIfLastIndex(index, brotherList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
            <Text style={styles.FamilyText1}>
              Sister Name :{" "}
              {
                // eslint-disable-next-line
                sisterList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.FamilyText1}>
                        {elem.sister}
                        {checkIfLastIndex(index, sisterList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FormatFour;
