import { createAsyncThunk } from "@reduxjs/toolkit";
import { token } from "../../../utils/masterToken";
import server from "../../../config/server.json";
import axios from "axios";

export const getWithoutLoginOccupationData = createAsyncThunk('masterData/getWithoutLoginOccupationData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}occupation`, { headers });
        return data.occupation;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutLoginHeightData = createAsyncThunk('masterData/getWithoutLoginHeightData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}height`, { headers });
        return data.heigth;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutMaritalStatusData = createAsyncThunk('masterData/getWithoutMaritalStatusData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}marital-status`, { headers });
        return data.marital;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutFamilyStatusData = createAsyncThunk('masterData/getWithoutFamilyStatusData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}family-status`, { headers });
        return data.familyStatus;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutQualificationData = createAsyncThunk('masterData/getWithoutQualificationData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}education-degree`, { headers });
        return data.qualification;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutIncomeData = createAsyncThunk('masterData/getWithoutIncomenData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}annual-income`, { headers });
        return data.annualIncome;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutProfileCreatedByData = createAsyncThunk('masterData/getWithoutProfileCreatedByData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}profile-created-by`, { headers });
        return data.profileCreatedBy;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutLoginCasteData = createAsyncThunk('masterData/getWithoutLoginCasteData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}caste`, { headers });
        return data.caste;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutLoginOccupationCategoryData = createAsyncThunk('masterData/getWithoutLoginOccupationCategoryData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}occupation-category`, { headers });
        return data.occupationCategory;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});

export const getWithoutLoginMartialData = createAsyncThunk('masterData/getWithoutLoginMartialData', async (arg, { rejectWithValue }) => {
    const headers = {
        token: token
    }
    try {
        const { data } = await axios.get(`${server.url.production}${server.api.GET_MASTER_DATA_WITHOUT_LOGIN}marital-status`, { headers });
        return data.marital;
    } catch (error) {
        rejectWithValue(error.response.data)
    }
});