import React, { useState } from "react";
import LoginImg from "../../assets/login-Img5.PNG";
import Group1 from "../../assets/FigmaIcons/ph_star-bold.png";
import Group2 from "../../assets/FigmaIcons/Group (2).png";
import verified from "../../assets/FigmaIcons/octicon_verified-16.png";
import Personalized from "../../assets/FigmaIcons/mdi_love-letter.png";
import Traditions from "../../assets/FigmaIcons/ph_confetti.png";
import Rectangle from "../../assets/Rectangle.png";
import "./LandingPage.css";
import LoginPageMobile from "../LoginPageMobile/LoginPageMobile";
import RegistrationPageMobile from "../Registration/RegistrationPageMobile";
import LoginPageOtp from "../LoginPageMobile/LoginPageOTP";
import PersonalDetailsCard from "../LandingPage/SignUpPage/PersonalDetailsCard";
import FooterLandingPage from "./FooterLandingPage";

const LandingPage = () => {
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [steps, setSteps] = useState(1);
  const [modal, setModal] = useState(true);
  const [data, setData] = useState({
    phone_number: "",
    first_name: "",
    last_name: "",
    email_id: "",
    gender: "",
    dob: "",
    currentLocation: "",
  });

  const next = () => setSteps(steps + 1);

  const handleOnSignIn = () => {
    setShowLoginPage(true);
  };

  const cancel = () => {
    if (!(steps === 1)) {
      setSteps(steps - 1);
    } else {
      setModal(!modal);
    }
  };
  return (
    <div className="LandingPage-main-container">
      <div className="bgImage">
        <img src={LoginImg} alt="loginImg"></img>
      </div>
      <div className="heading">
        <p>Unite hearts the Marwaadi way, Find your perfect match today!</p>
      </div>
      <div className="heading2">
        <p>Our platform is a tribute to the timeless traditions and values that define Marwadi weddings. We understand the significance of finding a partner who resonates with your heritage and aspirations.</p>
        <p>Here, you can explore profiles that reflect the essence of Marwadi life while embracing the modern dynamics of relationships.</p>
      </div>
      <div className="btn-signIn ">
        <button onClick={handleOnSignIn}>Let's get started</button>
      </div>
      <div className="ellipseDiv">
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators m-1">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={Rectangle} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5>First slide label</h5>
                <p>
                  Some representative placeholder content for the first slide.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={Rectangle} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5>Second slide label</h5>
                <p>
                  Some representative placeholder content for the second slide.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={Rectangle} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block"></div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div className="feature">
        <p>OUR FEATURES</p>
      </div>
      <div className="feature-content">
        <div className="content">
          <div className="iconImg">
            <img src={Group1} alt="Group1"></img>
          </div>
          <div className="detail-content">
            <p className="feature-heading">Exclusively Marwaadi</p>
            <span className="feature-describe">
              Dedicated to the Marwaadi community, connecting you with your
              cultural match
            </span>
          </div>
        </div>

        <div className="content">
          <div className="iconImg">
            <img src={Group2} alt="Group2"></img>
          </div>
          <div className="detail-content">
            <p className="feature-heading">Family-Oriented Matches</p>
            <span className="feature-describe">
              Emphasizing strong family values to foster lasting relationships
            </span>
          </div>
        </div>

        <div className="content">
          <div className="iconImg">
            <img src={verified} alt="verified"></img>
          </div>
          <div className="detail-content">
            <p className="feature-heading">Verified & Safe</p>
            <span className="feature-describe">
              Trustworthy profiles with rigorous verification for Your peace of
              mind
            </span>
          </div>
        </div>

        <div className="content">
          <div className="iconImg">
            <img src={Personalized} alt="Personalized"></img>
          </div>
          <div className="detail-content">
            <p className="feature-heading">Personalized Compatibility</p>
            <span className="feature-describe">
              Advanced algorithms for meaningful & compatible matches
            </span>
          </div>
        </div>

        <div className="content">
          <div className="iconImg">
            <img src={Traditions} alt="tradition"></img>
          </div>
          <div className="detail-content">
            <p className="feature-heading">Celebrating Traditions</p>
            <span className="feature-describe">
              Preserving Marwaadi heritage, love stories rooted in culture
            </span>
          </div>
        </div>
      </div>
    <FooterLandingPage/>
      <div>
        {showLoginPage && (
          <div className="loginPage-main col-sm-12 d-block d-sm-none">
            {steps === 1 ? (
              <LoginPageMobile
                handleOnSignIn={handleOnSignIn}
                next={next}
                cancel={() => setShowLoginPage(false)}
                data={data}
                setData={setData}
              />
            ) : null}
            {steps === 2 ? (
              <LoginPageOtp
                next={next}
                cancel={cancel}
                data={data}
                setModal={setModal}
                setSteps={setSteps}
              />
            ) : null}
            {steps === 3 ? (
              <RegistrationPageMobile
                cancel={cancel}
                setModal={setModal}
                setSteps={setSteps}
                data={data}
                setData={setData}
              />
            ) : null}
            {steps === 4 ? (
              <PersonalDetailsCard
                next={next}
                cancel={cancel}
                data={data}
                setModal={setModal}
                setSteps={setSteps}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
