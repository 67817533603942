import React, { useEffect, useState } from 'react';
import axios from 'axios';
import server from "../../config/server.json";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";
import { toFeet } from "../../utils/ConvertCmToFeet";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineUser } from "react-icons/ai";

const PersonalDetails = ({ userDetails, editPersonalEdit, setPersonalEdit }) => {
    const navigate = useNavigate();
    const masterData = useSelector((state) => state.masterData);
    const [userId, setUserId] = useState(null);
    const [token, setToken] = useState(null);
    const [personalData, setPersonalData] = useState({
        age: "",
        dob: "",
        city: "",
        state: "",
        address_1: "",
        landmark: "",
        pincode: "",
        weight: "",
        language: "",
        height: "",
        horoscope: "",
        country: "",
        religion: "",
        marital: "",
        about_us: ""
    });

    const { age, dob, city, state, address_1, landmark, pincode, weight, language, height, horoscope, country, religion, marital, about_us } = personalData;

    // const languageSeperate = () => {
    //     var lan = ""
    //     language.forEach((element, index) => {
    //         lan += `${element}${(language.length) - 1 === index ? "" : ","}`
    //     });
    //     return lan;
    // }

    const handleValidation = () => {
        //countryStateRegex is used to validate the state i.e. should have 49 letters or spaces
        const countryStateRegex = /^([0-9]{5}|[a-zA-Z][a-zA-Z ]{0,49})$/;

        //pincodeRegex is used to validate the pincode i.e it should consist of 6 digits
        const pincodeRegex = /^\d{6}$/;

        const today = new Date();
        const currentYear = today.getFullYear();
        const date = new Date(dob);
        const dobYear = date.getFullYear();
        const Year = currentYear - dobYear;

        if (!(Year > 18)) {
            toast.error("***you must be 18+", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else {
            // setErrorDOB("");
        }

        if (age < 18) {
            toast.error("Enter your valid Age", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (address_1.length === 0) {
            toast.error("Enter your valid address1", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (landmark.length === 0) {
            toast.error("Enter your valid landmark", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (countryStateRegex.test(city) === false) {
            toast.error("Enter your valid city", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (countryStateRegex.test(state) === false) {
            toast.error("Enter your valid state", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (pincodeRegex.test(pincode) === false) {
            toast.error("*Pin code should be 6 digits", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (country.length === 0 || country === "none") {
            toast.error("*Select your valid country", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (height.length === 0 || height === "none") {
            toast.error("*Select your valid height", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (Number(weight) < 20) {
            toast.error("*Select your valid weight", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (language.length === 0) {
            toast.error("Enter valid language", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (horoscope.length === 0 || horoscope === "none") {
            toast.error("Select your valid horoscope", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (religion.length === 0 || religion === "none") {
            toast.error("Select your valid religion", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else if (marital.length === 0 || marital === "none") {
            toast.error("Select your valid marital", {
                autoClose: 3000,
                position: "bottom-right",
            });
        } else {
            return true;
        }
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setPersonalData({ ...personalData, [name]: value });
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            if (handleValidation()) {
                const headers = {
                    "Content-Type": "application/json",
                    token: token,
                };
                const body = {
                    userId: userId,
                    age: Number(age),
                    dob: dob,
                    address_1: address_1,
                    landmark: landmark,
                    city: city,
                    state: state,
                    pincode: Number(pincode),
                    country: country,
                    height: height,
                    weight: Number(weight),
                    language: language,
                    horoscope: horoscope,
                    religion: religion,
                    marital: marital,
                    about_us: about_us
                }
                const response = await axios.post(
                    `${server.url.production}${server.api.UPDATE_PERSONAL_DETAILS}`,
                    body,
                    { headers: headers }
                );
                if (response.status === 201) {
                    toast.success(response.data.message, {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                    setPersonalEdit(!editPersonalEdit);
                } else {
                    toast.error("Something went wrong", {
                        autoClose: 3000,
                        position: "bottom-right",
                    });
                }
            }
        } catch (error) {
            toast.error(error.response.data.message, {
                autoClose: 3000,
                position: "bottom-right",
            });
        }
    }

    useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("user"));
        if (loggedUser) {
            setUserId(loggedUser.id);
            setToken(localStorage.getItem("token"));
        } else {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        setPersonalData({
            age: userDetails?.age ? userDetails.age : "",
            dob: userDetails?.dob ? userDetails.dob : "",
            city: userDetails?.city ? userDetails.city : "",
            state: userDetails?.state ? userDetails.state : "",
            address_1: userDetails?.address_1 ? userDetails.address_1 : "",
            landmark: userDetails?.landmark ? userDetails.landmark : "",
            pincode: userDetails?.pincode ? userDetails.pincode : "",
            weight: userDetails?.weight ? userDetails.weight : "",
            language: userDetails?.language ? userDetails.language : "",
            height: userDetails?.height ? userDetails.height : "",
            horoscope: userDetails?.horoscope ? userDetails.horoscope : "",
            country: userDetails?.country ? userDetails.country : "",
            religion: userDetails?.religion ? userDetails.religion : "",
            marital: userDetails?.marital ? userDetails.marital : "",
            about_us: userDetails?.about_us ? userDetails.about_us : ""
        });
    }, [userDetails]);

    return (
        <section className='personal_region-data'>
            <div className="personal_region-data-info">
                <div className='personal_data-info'>
                    <span>age</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : age
                                    ? `${age} Years`
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>date of birth</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : dob
                                    ? dob
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>address</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : address_1
                                    ? address_1
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>landmark</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : landmark
                                    ? landmark
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>city</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : city
                                    ? city
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>state</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : state
                                    ? state
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>pincode</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : pincode
                                    ? pincode
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>country</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : country
                                    ? country
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>height</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : height
                                    ? `${height} cm`
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>weight</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : weight
                                    ? `${weight} kg`
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>horoscope</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : horoscope
                                    ? horoscope
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>religion</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : religion
                                    ? religion
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>langauage</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : userDetails?.language
                                    ? language
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>marital</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : marital
                                    ? marital
                                    : "NA"
                        }
                    </span>
                </div>
                <div className='personal_data-info'>
                    <span>about us</span>
                    <span>:</span>
                    <span>
                        {
                            userDetails === undefined
                                ? ""
                                : about_us
                                    ? about_us
                                    : "NA"
                        }
                    </span>
                </div>
            </div>
            {
                editPersonalEdit &&
                <div className='edit_personal_detail_data'>
                    <h4>
                        <span><RxCross1 onClick={() => setPersonalEdit(!editPersonalEdit)} />
                        </span><span>edit Personal details</span>
                    </h4>
                    <h6>
                        <span><AiOutlineUser /></span>
                        <span>Personal Information Data</span>
                    </h6>
                    <form onSubmit={handleOnSubmit}>
                        <div className="input-block">
                            <label htmlFor="age">Age</label>
                            <select
                                name="age"
                                id="age"
                                value={age}
                                onChange={handleOnChange}
                            >
                                <option value="none">{OPTION_SELECT_EMPTY}</option>
                                {masterData.age.slice().sort((a, b) => a.value - b.value).map((elem) => {
                                    return (
                                        <option key={elem._id} value={elem.value}>
                                            {(elem.value)}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="dob">date of birth</label>
                            <input type="date" name='dob' id='dob' value={dob} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="address_1">address</label>
                            <input type="text" name='address_1' id='address_1' value={address_1} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="landmark">landmark</label>
                            <input type="text" name='landmark' id='landmark' value={landmark} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="city">city</label>
                            <input type="text" name='city' id='city' value={city} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="state">state</label>
                            <input type="text" name='state' id='state' value={state} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="pincode">pincode</label>
                            <input type="text" name='pincode' id='pincode' value={pincode} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="country">country</label>
                            <input type="text" name='country' id='country' value={country} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="height">height</label>
                            <select
                                name="height"
                                id="height"
                                value={height}
                                onChange={handleOnChange}
                            >
                                <option value="none">{OPTION_SELECT_EMPTY}</option>
                                {masterData.height.slice().sort((a, b) => a.value - b.value).map((elem) => {
                                    return (
                                        <option key={elem._id} value={elem.value}>
                                            {elem.value === "--select--" ? elem.value : toFeet(elem.value)}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="weight">weight</label>
                            <input type="text" name='weight' id='weight' value={weight} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="horoscope">Horoscope</label>
                            <select
                                name="horoscope"
                                id="horoscope"
                                value={horoscope}
                                onChange={handleOnChange}
                            >
                                <option value="none">{OPTION_SELECT_EMPTY}</option>
                                {masterData.horoscope.map((elem) => {
                                    return (
                                        <option key={elem._id} value={elem.value}>
                                            {elem.value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="religion">religion</label>
                            <input type="text" name='religion' id='religion' value={religion} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="language">language</label>
                            <input type="text" name='language' id='language' value={language} onChange={handleOnChange} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="marital">martial status</label>
                            <select
                                name="marital"
                                id="marital"
                                value={marital}
                                onChange={handleOnChange}
                            >
                                <option value="none">{OPTION_SELECT_EMPTY}</option>
                                {masterData.maritalStatus.map((elem) => {
                                    return (
                                        <option key={elem._id} value={elem.value}>
                                            {" "}
                                            {elem.value}{" "}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="input-block">
                            <label htmlFor="about_us">about us</label>
                            <input type="text" name='about_us' id='about_us' value={about_us} onChange={handleOnChange} />
                        </div>
                        <div className="input-block-btn">
                            <button type='submit'>save</button>
                        </div>
                    </form>
                </div>
            }
        </section>
    )
}

export default PersonalDetails;