import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import server from "../config/server";
import LoginPage from "../pages/LandingPage/LoginPage/LoginPage";
import Otp from "../pages/LandingPage/OtpPage/Otp";
import SignUpPage from "../pages/LandingPage/SignUpPage/SignUpPage";
import Image from "../assets/tech-sierra-logo.png";
import { ToastContainer, toast } from "react-toastify";
import { clearUserData } from "../utils/ClearUserData";
import { ImUser } from "react-icons/im";
import { AiFillDatabase } from "react-icons/ai";
import { FaBell } from "react-icons/fa";
import { FiLogOut, FiLogIn } from "react-icons/fi";
import avatar from "../assets/avatar_placeholder.png";
import images from "../assets/assets.js";
import "react-toastify/dist/ReactToastify.css";
import "./Header.css";
import PersonalDetailsCard from "../pages/LandingPage/SignUpPage/PersonalDetailsCard";
import CardImg1 from "../../src/assets/card-image1.png";
import CardImg2 from "../../src/assets/card-image2.png";
import CardImg3 from "../../src/assets/card-image3.png";
import { figmaIcons } from "../assets/FigmaIcons/figmaIcons";
import LoginPageMobile from "../pages/LoginPageMobile/LoginPageMobile";
import LoginPageOtp from "../pages/LoginPageMobile/LoginPageOTP";
import RegistrationPageMobile from "../pages/Registration/RegistrationPageMobile";


const Header = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [modal, setModal] = useState(true);
  const [time, setTime] = useState(5);
  const [count, setCount] = useState("");
  const [steps, setSteps] = useState(1);
  const [list, setList] = useState([]);
  const [data, setData] = useState({
    phone_number: "",
    first_name: "",
    last_name: "",
    email_id: "",
    gender: "",
    dob: "",
    currentLocation: "",
  });
  const [navDropDown, setNavDropDown] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  window.addEventListener("token_update", () => {
    let userObj = JSON.parse(localStorage.getItem("user"));
    setUser(userObj);
  });

  const handleApprovalRequest = async () => {
    try {
      const headers = {
        token: token,
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_PENDING_REQUESTS}`,
        { headers }
      );
      if (response.status === 200) {
        setList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationCount = async () => {
    try {
      const headers = {
        token: token,
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_PENDING_REQUESTS_COUNT}`,
        { headers }
      );
      if (response.status === 200) {
        setCount(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if(token) {

      handleNotificationCount();
      handleApprovalRequest();
    }
    // eslint-disable-next-line
  }, [user, token]);

  const [isActive, setActive] = useState(false);
  const ToggleClass = () => setActive(!isActive);

  const next = () => setSteps(steps + 1);

  const cancel = () => {
    if (!(steps === 1)) {
      setSteps(steps - 1);
    } else {
      setModal(!modal);
    }
  };

  useEffect(() => {
    let userObj = JSON.parse(localStorage.getItem("user"));
    setUser(userObj);
  }, []);

  const logoutHandler = async () => {
    try {
      const headers = {
        token: token,
      };
      const response = await axios.post(
        `${server.url.production}${server.api.LOGOUT}`,
        user,
        { headers: headers }
      );
      if (response.status === 200) {
        clearUserData();
        setUser(null);
        toast.success(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
        navigate("/");
      } else {
        toast.error("Something went wrong, Please try again", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setToken(localStorage.getItem("token"));
    } else {
      // navigate("/");
      setUser(null);
    }
  }, [navigate]);

  // arr of NavIconData for dynamic rendeing of JSX (to avoid repetation of JSX code).
  const navData = useMemo(
    () => [
      {
        path: "/home",
        activeLinkColor: "#1b74e4",
        icon: images.home,
      },
      {
        path: "/search",
        activeLinkColor: "#C70039",
        icon: images.searchHeart,
      },
      {
        path: "",
        activeLinkColor: "#C70039",
        icon: images.heart,
      },
    ],
    []
  );

  // isActiveLink: gives a style effect if the NavIcon is active (or clicked).
  const isActiveLink = (active = false, fontColor = "", path = "") => ({
    backgroundColor: active ? "#e4e6eb" : "",
    color: active ? fontColor : "",
  });

  /**
   * NavIconFunc: It renders a NavLink component for creating links with routing.
   * It takes in props such as path, fontColor, icon, className, title, htmlElem, and onClick, and returns a link with customizable styles and functionality.
   * Its been used to create navigation links with icons or other HTML elements in a React application.
   */
  const NavIconFunc = ({
    path,
    fontColor,
    icon,
    className,
    title,
    htmlElem,
    onClick,
  }) => {
    return (
      <NavLink
        to={path}
        className={className}
        style={(e) => isActiveLink(e.isActive, fontColor, path)}
        title={title ? title : null}
        onClick={onClick ? onClick : null}
      >
        {icon && icon}
        {htmlElem && htmlElem}
      </NavLink>
    );
  };

  const confirmRequest = async (id) => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const body = {
        id: id,
        approval_status: 1,
      };
      const response = await axios.patch(
        `${server.url.production}${server.api.UPDATE_CONTACT_APPROVAL_STATUS}`,
        body,
        { headers: headers }
      );
      if (response.status === 201) {
        handleApprovalRequest();
        handleNotificationCount();
        toast.success(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error("Something went wrong, Please try again later!", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const rejectRequest = async (id) => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const body = {
        id,
        approval_status: 2,
      };
      const response = await axios.patch(
        `${server.url.production}${server.api.UPDATE_CONTACT_APPROVAL_STATUS}`,
        body,
        { headers: headers }
      );
      if (response.status === 201) {
        handleApprovalRequest();
        handleNotificationCount();
        toast.success(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error("Something went wrong, Please try again later!", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const ShowNotifi = () => {
    return (
      <>
        <div
          className={
            isActive
              ? "notification-content notification-active"
              : "notification-content notification-closed"
          }
        >
          <ul>
            {count > 0 ? (
              list.map((elem, index) => {
                return (
                  <li key={index} className="notification-list">
                    <div className="notification-user-icon">
                      <ImUser className="icon-background" size={"2rem"} />
                    </div>
                    <div className="notification-user-list">
                      <span className="pending-request-list">
                        {elem.users.first_name} {elem.users.last_name}
                      </span>{" "}
                    </div>
                    <div className="d-flex">
                      <button
                        className="request-btn confirm"
                        onClick={() => confirmRequest(elem._id)}
                      >
                        Confirm
                      </button>
                      <button
                        className="request-btn reject"
                        onClick={() => rejectRequest(elem._id)}
                      >
                        Reject
                      </button>
                    </div>
                  </li>
                );
              })
            ) : (
              <li className="notification-list p-3">No records found!</li>
            )}
          </ul>
        </div>
      </>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (time !== 0) {
        setTime(time - 1);
      }
    }, [1000]);
    return () => {
      clearInterval(interval);
      setModal(true);
    };
  }, [time]);

  const bg = [CardImg1, CardImg2, CardImg3];
  const delay = 2500;
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === bg.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {};
  }, [index, bg.length]);

  useEffect(() => {
    window.location.pathname === "/search" && setSearchBarVisible(true);

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const location = useLocation();

  let pathname = location.pathname;
  let classNameToShowHideHeaders = "headerCompWrapper";

    if (pathname === "/aboutus-m" || pathname === "/privacy-policy-m" || pathname === "/" || pathname === "/home" || pathname === "/terms-condition-m") {
      classNameToShowHideHeaders = "headerCompWrapper";
    } else{
      classNameToShowHideHeaders = "headerCompWrapper d-none d-sm-block";
    }

  return (
    <div
      className={classNameToShowHideHeaders}
    >
      <div className="headerContainer">
        <div className="headerFlexColumn1">
          <Link className="navbar-brand" to="/">
            <img
              src={Image}
              alt="logo"
              height="36"
              style={{ height: "40px", width: "100px" }}
            />
          </Link>
        </div>

        <div className="headerFlexColumn2">
          {user && (
            <>
              {screenWidth >= 871
                ? navData.map((data, i) => {
                    return (
                      <div
                        key={i}
                        className={`${
                          searchBarVisible && data.path === "/search"
                            ? "headerSearchBar expanded headerHeartSearchIcon"
                            : ""
                        } headerMiddleColIcon`}
                      >
                        <NavIconFunc
                          path={data.path}
                          fontColor={data.activeLinkColor}
                          icon={data.icon}
                          className=""
                          onClick={(e) =>
                            setSearchBarVisible(data.path === "/search")
                          }
                        />
                        {data.path === "/search" && (
                          <input
                            type="text"
                            style={{
                              display: `${searchBarVisible ? "" : "none"}`,
                            }}
                            placeholder="Search..."
                          />
                        )}
                      </div>
                    );
                  })
                : navData.map((data, i) => (
                    <div key={i} className="headerMiddleColIcon">
                      <NavIconFunc
                        path={data.path}
                        fontColor={data.activeLinkColor}
                        icon={data.icon}
                        className=""
                        onClick={(e) =>
                          setSearchBarVisible(data.path === "/search")
                        }
                      />
                    </div>
                  ))}
            </>
          )}
        </div>

        <div className="headerFlexColumn3">
          <div className="headerNavRightSection headerNavMid">
            {user ? (
              <>
                <NavIconFunc
                  path={"/biodata"}
                  fontColor={"green"}
                  icon={<AiFillDatabase />}
                  className={"headerAccIconDiv"}
                  title={"Bio Data"}
                />

                <NavIconFunc
                  icon={<FaBell style={{ transform: "rotate(-15deg)" }} />}
                  className={"notification headerAccIconDiv"}
                  onClick={ToggleClass}
                  htmlElem={
                    <div>
                      <span className="notification-counter">{count}</span>
                    </div>
                  }
                />

                <ShowNotifi />

                <NavIconFunc
                  path={"/myprofile"}
                  className={"headerAccIconDiv"}
                  title={user.first_name}
                  htmlElem={
                    <img
                      src={avatar}
                      alt={user.first_name}
                      className="headerAccProfileIcon"
                    />
                  }
                />

                <NavIconFunc
                  fontColor={"black"}
                  icon={<FiLogOut />}
                  className={"headerAccIconDiv"}
                  title={"Logout"}
                  onClick={logoutHandler}
                />
              </>
            ) : (
              <NavLink
                title="Login"
                to={"/"}
                className="headerAccLogin"
                aria-current="page"
                onClick={() => {
                  setModal(!modal);
                  setTime(0);
                }}
              >
                <div>Sign in</div>
                <div className="headerAccIconDiv">
                  <FiLogIn />
                </div>
              </NavLink>
            )}
          </div>
          <div className="headerNavRightSection smallMidDevice">
            {user ? (
              <>
                <NavIconFunc
                  icon={<FaBell style={{ transform: "rotate(-15deg)" }} />}
                  className={"notification headerAccIconDiv"}
                  onClick={ToggleClass}
                  htmlElem={
                    <div style={{ color: "black" }}>
                      <span className="notification-counter">{count}</span>
                    </div>
                  }
                />
                <ShowNotifi />

                {navDropDown ? (
                  <div className="headerDropConatiner">
                    <NavIconFunc
                      className={"headerAccIconDiv"}
                      title={user.first_name}
                      htmlElem={
                        <img
                          src={avatar}
                          alt={user.first_name}
                          className="headerAccProfileIcon"
                        />
                      }
                      onClick={(e) => setNavDropDown(!navDropDown)}
                    />
                    <div className="header-dropdown">
                      <NavIconFunc
                        path={"/biodata"}
                        fontColor={"green"}
                        icon={<AiFillDatabase />}
                        className={"headerAccIconDiv"}
                        title={"Bio Data"}
                      />
                      <NavIconFunc
                        path={"/myprofile"}
                        fontColor={"black"}
                        icon={images.profileDetailsIcon}
                        className={"headerAccIconDiv"}
                        title={"Profile Details"}
                      />
                      <NavIconFunc
                        fontColor={"black"}
                        icon={<FiLogOut />}
                        className={"headerAccIconDiv"}
                        title={"Logout"}
                        onClick={logoutHandler}
                      />
                    </div>
                  </div>
                ) : (
                  <NavIconFunc
                    className={"headerAccIconDiv"}
                    title={user.first_name}
                    htmlElem={
                      <img
                        src={avatar}
                        alt={user.first_name}
                        className="headerAccProfileIcon"
                      />
                    }
                    onClick={(e) => setNavDropDown(!navDropDown)}
                  />
                )}
              </>
            ) : (
              <NavLink
                title="Login"
                to={"/"}
                className="headerAccLogin"
                aria-current="page"
                onClick={() => {
                  setModal(!modal);
                  setTime(0);
                }}
              >
                <div>Sign in</div>
                <div className="headerAccIconDiv">
                  <FiLogIn />
                </div>
              </NavLink>
            )}
          </div>
        </div>
      </div>

      <div className="header-container">
        <span className="logo" onClick={()=>{navigate("/")}}>MarwaadiShaadi</span>
        <div className="header-loginmenu">
          <span
            className="login"
            onClick={() => {
              setModal(!modal);
              setTime(0);
            }}
          >
            Login
          </span>
          <img
            src={figmaIcons.menuIcon}
            alt="menu"
            className="menubar"
            onClick={() => setMenuOpen(true)}
          />
        </div>
      </div>

      {menuOpen && (
        <div className="LandingPage-main-container">
           <div className="sideMenubar">
          <div className="mobileMenu">
            <img
              src={figmaIcons.close}
              onClick={() => setMenuOpen(false)}
              className="closeIcon"
              alt="close icon"
            />
            <div className="MenuList">
              <div className="list" onClick={()=>{setMenuOpen(false); navigate("/")}}>
                <span>Home</span>
                <img src={figmaIcons.next} alt="next Icon"></img>
              </div>
              <div className="list" onClick={()=>{setMenuOpen(false); navigate("/aboutus-m")}}>
                <span>About Us</span>
                <img src={figmaIcons.next} alt="next Icon"></img>
              </div>
             
              <div className="list">
                <span>Testimonial</span>
                <img src={figmaIcons.next} alt="next Icon"></img>
              </div>
              <div className="list">
                <span>Member Login</span>
                <img src={figmaIcons.next} alt="next Icon"></img>
              </div>
              <div className="list">
                <span>Contact Us</span>
                <img src={figmaIcons.next} alt="next Icon"></img>
              </div>
            </div>
            <div className="sideMenuFooter">
              <p onClick={()=>{setMenuOpen(false); navigate("/terms-condition-m")}}>Terms of Use</p>
              <p onClick={()=>{setMenuOpen(false); navigate("/privacy-policy-m")}}>Privacy Policy</p>
            </div>
          </div>
        </div> 
        </div>
      
      )}

      {searchBarVisible && (
        <div className="mobileViewSearchBar d-none d-sm-block">
          <input type="text" placeholder="Search..." />
          <button>{images.search1}</button>
        </div>
      )}

<ToastContainer />
      {!user && time === 0 && modal && (
        <>
        <div className="modal-container d-none d-sm-block">
          <div className="main-modal-header">
            <div className="left-side">
              <div className="slideshow">
                <div
                  className="slideshowSlider"
                  style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                  {bg.map((elem, i) => (
                    <img
                      key={i}
                      src={elem}
                      className="slide"
                      alt="bg-slider"
                    ></img>
                  ))}
                </div>
              </div>
            </div>
            <div className="right-side">
              {steps === 1 ? (
                <LoginPage
                  next={next}
                  cancel={cancel}
                  data={data}
                  setData={setData}
                />
              ) : null}
              {steps === 2 ? (
                <Otp
                  next={next}
                  cancel={cancel}
                  data={data}
                  setModal={setModal}
                  setSteps={setSteps}
                />
              ) : null}
              {steps === 3 ? (
                <SignUpPage
                  next={next}
                  cancel={cancel}
                  data={data}
                  setData={setData}
                  setModal={setModal}
                  setSteps={setSteps}
                />
              ) : null}
              {steps === 4 ? (
                <PersonalDetailsCard
                  next={next}
                  cancel={cancel}
                  data={data}
                  setModal={setModal}
                  setSteps={setSteps}
                />
              ) : null}
              <p>
                By continuing, you agree to Matrimony's{" "}
                <Link to={"/privacy-policy"} onClick={() => setModal(false)}>
                  privacy policy
                </Link>{" "}
                &{" "}
                <Link to={"/terms-conditions"} onClick={() => setModal(false)}>
                  {" "}
                  terms of use
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
        {/* LoginPageMobile */}
        <div className="loginPage-main col-sm-12 d-block d-sm-none">
        {steps === 1 ? (
                <LoginPageMobile
                 next={next}
                 cancel={cancel}
                 data={data}
                 setData={setData}
                />
                ) : null}
                {steps === 2 ? (
                <LoginPageOtp
                  next={next}
                  cancel={cancel}
                  data={data}
                  setModal={setModal}
                  setSteps={setSteps}
                />
              ) : null}
              {steps === 3 ? (
                <RegistrationPageMobile
                  cancel={cancel}
                  setModal={setModal}
                  setSteps={setSteps}
                  data={data}
                  setData={setData}
                />
              ) : null}
              {steps === 4 ? (
                <PersonalDetailsCard
                  next={next}
                  cancel={cancel}
                  data={data}
                  setModal={setModal}
                  setSteps={setSteps}
                />
              ) : null}
        </div>
        </>
      )}
    </div>
  );
};

export default Header;
