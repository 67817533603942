import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import server from "../../../config/server.json";
import { toast } from "react-toastify";
import PersonalDetailsData from "../../PersonalDetails/PersonalDetailsData.json";
import { useSelector } from "react-redux";
import { toFeet } from "../../../utils/ConvertCmToFeet";
import { OPTION_SELECT_EMPTY } from "../../../utils/GlobalVariable";
import { AiOutlineClose } from "react-icons/ai";

function PersonalDetailsCard(props) {
  const masterData = useSelector((state) => state.masterData.withoutLogin);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [details, setDetails] = useState({
    created_by: "",
    height: "",
    marital_status: "",
    caste: "",
    work_location: "",
    company_name: "",
    annual_income: "",
    occupation: "",
    education: "",
    institute_name: "",
  });

  const {
    created_by,
    height,
    marital_status,
    caste,
    work_location,
    company_name,
    annual_income,
    occupation,
    education,
    institute_name,
  } = details;

  const { userReligion } = PersonalDetailsData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleValidation = () => {
    if (created_by.length === 0 || created_by === "none") {
      setErrorMessage("*Select profile created by");
    } else if (height.length === 0 || height === "none") {
      setErrorMessage("*Select height");
    } else if (marital_status.length === 0 || marital_status === "none") {
      setErrorMessage("*Select marital status");
    } else if (caste.length === 0 || caste === "none") {
      setErrorMessage("*Select caste");
    } else if (work_location.length === 0) {
      setErrorMessage("*Select work_location");
    } else if (company_name.length === 0) {
      setErrorMessage("*Select company");
    } else if (annual_income.length === 0 || annual_income === "none") {
      setErrorMessage("*Select annual_income");
    } else if (occupation.length === 0 || occupation === "none") {
      setErrorMessage("*Select occupation category");
    } else if (education.length === 0) {
      setErrorMessage("*Select education");
    } else if (institute_name.length === 0) {
      setErrorMessage("*Select institute_name");
    } else {
      return true;
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // const match = height.toString().match(/-\s*(\d{3})/);
    // const heightValue = match ? match[1] : "";

    if (handleValidation()) {
      try {
        const body = {
          first_name: props.data.first_name,
          last_name: props.data.last_name,
          email_id: props.data.email_id,
          phone_number: props.data.phone_number,
          gender: props.data.gender,
          dob: props.data.dob,
          current_location: props.data.currentLocation,
          profileCreatedBy: created_by,
          height: height,
          marital: marital_status,
          religion: caste,
          jobLocation: work_location,
          companyName: company_name,
          income: annual_income,
          occupation: occupation,
          specialization: education,
          instituteName: institute_name,
        };
        const response = await axios.post(
          `${server.url.production}${server.api.REGISTER}`,
          body
        );
        if (response.status === 201) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          navigate("/search");
          props.setModal(false);
          props.setSteps(1);
          window.dispatchEvent(new Event("token_update"));
        } else {
          toast.error("Something Went Wrong", {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };

  return (
    <>
      <div className="login-right-side">
        <div className="login-close-btn" onClick={() => props.cancel()}>
          <span>
            <AiOutlineClose />
          </span>
        </div>
        <div className="personal-details-card">
          <h3>Personal Details</h3>
          <form onSubmit={handleOnSubmit}>
            <div>
              <label htmlFor="created_by">Profile Created by:</label>
              <select
                name="created_by"
                value={created_by}
                onChange={handleOnChange}
              >
                <option value="none">{OPTION_SELECT_EMPTY}</option>
                <optgroup label="Profile Created By" />
                {masterData.profileCreatedBy.map((elem) => (
                  <option key={elem._id} value={elem.name}>
                    {elem.name}{" "}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="height">Height:</label>
              <select name="height" value={height} onChange={handleOnChange}>
                <option value="none">{OPTION_SELECT_EMPTY}</option>
                <optgroup label="Heights" />
                {masterData.height.map((elem) => (
                  <option key={elem._id} value={elem.height}>
                    {" "}
                    {toFeet(elem.height)}{" "}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="marital_status">Marital Status:</label>
              <select
                name="marital_status"
                value={marital_status}
                onChange={handleOnChange}
              >
                <option value="none">{OPTION_SELECT_EMPTY}</option>
                <optgroup label="Marital Status" />
                {masterData.marital.map((elem) => (
                  <option key={elem._id} value={elem.maritalStatus}>
                    {" "}
                    {elem.maritalStatus}{" "}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="caste">Caste:</label>
              <select name="caste" value={caste} onChange={handleOnChange}>
                <option value="none">{OPTION_SELECT_EMPTY}</option>
                {userReligion.map((elem) => {
                  return (
                    <option key={elem} value={elem}>
                      {elem}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label htmlFor="work_location">Work Location:</label>
              <input
                type="text"
                placeholder="Eg. Mumbai"
                name="work_location"
                value={work_location}
                onChange={handleOnChange}
              />
            </div>
            <div>
              <label htmlFor="company_name">Company Name:</label>
              <input
                type="text"
                placeholder="Eg. Tech Mahindra"
                name="company_name"
                value={company_name}
                onChange={handleOnChange}
              />
            </div>
            <div>
              <label htmlFor="annual_income">Annual Income:</label>
              <select
                name="annual_income"
                value={annual_income}
                onChange={handleOnChange}
              >
                <option value="none">{OPTION_SELECT_EMPTY}</option>
                <optgroup label={"Annual Income"} />
                {masterData.income.map((elem) => (
                  <option key={elem._id} value={elem.income}>
                    {" "}
                    {elem.income}{" "}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="occupation">Occupation Category:</label>
              <select
                name="occupation"
                value={occupation}
                onChange={handleOnChange}
              >
                <option value="none">{OPTION_SELECT_EMPTY}</option>
                <optgroup label={"Occupation Name"} />
                {masterData.occupation.map((elem) => (
                  <option key={elem._id} value={elem.occupationName}>
                    {elem.occupationName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="education">Education:</label>
              <input
                type="text"
                placeholder="Eg. MBA"
                name="education"
                value={education}
                onChange={handleOnChange}
              />
            </div>
            <div>
              <label htmlFor="institute_name">Institue Name:</label>
              <input
                type="text"
                placeholder="Eg. IIT Kharagpur"
                name="institute_name"
                value={institute_name}
                onChange={handleOnChange}
              />
            </div>
            {errorMessage.length > 0 ? (
              <span className="personal-details-error">{errorMessage}</span>
            ) : (
              ""
            )}
            <div className="signup-btn">
              <button type={"submit"}>Done</button>
              {/* <button onClick={() => props.setSteps(1)}>Not now</button> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default PersonalDetailsCard;