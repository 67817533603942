import React, { useState, useEffect } from "react";
import "./NotificationPageMobile.css";
import share from "../../assets/ProfileMobileImages/Share-Inactive.png";
import messageImg from "../../assets/ProfileMobileImages/Message-Inactive.png";
import axios from "axios";
import server from "../../config/server.json";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import defaultImage from "../../assets/avatar_placeholder.png";
import { BiChevronLeft } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import { isNumber } from "lodash";

const NotificationPageMobile = ({ setActiveButton }) => {
  const [list, setList] = useState([]);
  const [token, setToken] = useState("");
  const [message, setMessage] = useState([]);
  const [notification, setNotification] = useState([]);
  const [readModal, setReadModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [readModalMsg, setReadModalMsg] = useState("");
  const navigate = useNavigate();

  // calculate the time difference between the current time and a given timestamp:
  const getTimeStampH = (inserted_at) => {
    const now = new Date();
    const notificationTime = new Date(inserted_at);
    const timeDiff = now - notificationTime;

    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    if (minutesDiff < 60) {
      if (minutesDiff < 1) {
        return `Just now`;
      }
      return `${minutesDiff}m`;
    }

    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    return `${hoursDiff}h`;
  };

  const getTimeStampD = (inserted_at) => {
    const now = new Date();
    const notificationTime = new Date(inserted_at);
    const timeDiff = now - notificationTime;

    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return `${daysDiff}d`;
  };

  const currentTimestamp = new Date(); // Current timestamp
  const twentyFourHoursAgo = new Date(
    currentTimestamp.getTime() - 24 * 60 * 60 * 1000
  ); // 24 hours ago

  const newSectionList = notification.filter((item) => {
    const notificationTime = new Date(item.inserted_at);
    return (
      notificationTime >= twentyFourHoursAgo &&
      notificationTime <= currentTimestamp
    );
  });
  
  const earlierSectionList = notification
  .filter((item) => new Date(item.inserted_at) < twentyFourHoursAgo)
  .sort((a, b) => new Date(b.inserted_at).getTime() - new Date(a.inserted_at).getTime());

  const handleMessageRequest = async () => {
    if (token) {
      try {
        const headers = {
          token: token,
        };
        const response = await axios.get(
          `${server.url.production}${server.api.GET_MESSAGE_DATA}`,
          { headers }
        );
        if (response.status === 200) {
          setMessage(response?.data);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };

  const handleApprovalRequest = async () => {
    if (token) {
      try {
        const headers = {
          token: token,
        };
        const response = await axios.get(
          `${server.url.production}${server.api.GET_PENDING_REQUESTS}`,
          { headers }
        );
        if (response.status === 200) {
          setList(response?.data);
        }
      } catch (error) {
        toast.error(error.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };

  const isJson = (json) => {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setNotification(list.concat(message));
    // eslint-disable-next-line
  }, [message, list]);

  useEffect(() => {
    // fetching user from localstorage
    let loggedUser = localStorage.getItem("user");
    // if user exist then check the json is valid or not and if user is not exist then redirect to "/"
    if (loggedUser && isJson(loggedUser)) {
      loggedUser = JSON.parse(loggedUser);
      setToken(localStorage.getItem("token"));
    } else {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    handleApprovalRequest();
    handleMessageRequest();
    // eslint-disable-next-line
}, [token])

  // for accept button
  const confirmRequest = async (id) => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const body = {
        id: id,
        approval_status: 1,
      };
      const response = await axios.patch(
        `${server.url.production}${server.api.UPDATE_CONTACT_APPROVAL_STATUS}`,
        body,
        { headers: headers }
      );
      if (response.status === 201) {
        handleApprovalRequest();
        handleMessageRequest();
        toast.success(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error("Something went wrong, Please try again later!", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  // Reject Request
  const rejectRequest = async (id) => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const body = {
        id: id,
        approval_status: 2,
      };
      const response = await axios.patch(
        `${server.url.production}${server.api.UPDATE_CONTACT_APPROVAL_STATUS}`,
        body,
        { headers: headers }
      );
      if (response.status === 201) {
        handleApprovalRequest();
        handleMessageRequest();
        toast.success(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error("Something went wrong, Please try again later!", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const readMessage = async (id, readMessage, is_read) => {
    setReadModalMsg(readMessage);
    setReadModal(true);

    if (is_read === 0) {
      try {
        const headers = {
          token: localStorage.getItem("token"),
        };
        const body = {
          id: id,
          is_read: 1,
        };
        const response = await axios.patch(
          `${server.url.production}${server.api.UPDATE_MESSAGE_STATUS}`,
          body,
          { headers: headers }
        );
        if (response.status === 201) {
          handleMessageRequest();
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const ContactContent = (item, timeStamp) => {
    return (
      <div className="contactLabel">
        <div className="labelPhoto">
          {item?.userDetails[0]?.profileImage ? (
            <img
              className="photo"
              src={item?.userDetails[0]?.profileImage}
              alt={item.name}
            />
          ) : (
            <img className="photo" src={defaultImage} alt={item.name} />
          )}
        </div>
        <div className="labelName">
          <div>
            <span>
              <Link
                className="new-notification"
                to={`/profile/view/${item?.request_from}`}
                key={item?.request_from}
              >
                {item?.users?.first_name} {item?.users?.last_name}
              </Link>
            </span>{" "}
            sent a contact share request{" "}
            <img className="share-btn" src={share} alt="share" />
            {timeStamp === "earlier"
              ? getTimeStampD(item?.inserted_at)
              : getTimeStampH(item?.inserted_at)}
          </div>
        </div>
        {item.approval_status === 0 ? (
          <>
            <div className="acceptBtn">
              <button
                className="btn btn-primary"
                onClick={() => confirmRequest(item?._id)}
              >
                Accept
              </button>
            </div>
            <div className="closeBrn">
              <RxCross1 onClick={() => rejectRequest(item?._id)} />
            </div>
          </>
        ) : (
          <>
            <div className="acceptBtn">
              <button className="btn btn-primary" style={{ padding: "0" }}>
                Accepted
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const MessageContent = (item, timeStamp) => {
    return (
      <>
        <div className="messageLabel">
          <div className="labelPhoto">
            {item?.userDetails[0]?.profileImage ? (
              <img
                className="photo"
                src={item?.userDetails[0]?.profileImage}
                alt={item.name}
              />
            ) : (
              <img className="photo" src={defaultImage} alt={item.name} />
            )}
          </div>
          <div className="labelName">
            <div>
              <span>
                <Link
                  className="new-notification"
                  to={`/profile/view/${item?.from_user_id}`}
                  key={item?.from_user_id}
                >
                  {item?.users?.first_name} {item?.users?.last_name}
                </Link>
              </span>{" "}
              sent a message{" "}
              <img className="message-icon" src={messageImg} alt="" />
              {timeStamp === "earlier"
                ? getTimeStampD(item?.inserted_at)
                : getTimeStampH(item?.inserted_at)}
            </div>
          </div>
          <div className="readBtn">
            <button
              className="btn btn-warning"
              onClick={() => readMessage(item._id, item.message, item.is_read)}
            >
              Read
            </button>
          </div>
        </div>
        <div className="messageContent">
          {item.message.length > 20
            ? `${item.message.slice(0, 20)}...`
            : `${item.message}`}
        </div>
      </>
    );
  };

  earlierSectionList.reverse();

  return (
    <div className="notification-main">
      <div className="notification">
        <div className="">
          <span onClick={() => setActiveButton("card")}>
            <BiChevronLeft />
          </span>
          <h3>Notifications</h3>
        </div>
        {!isLoading ? (
          <>
            {newSectionList?.length > 0 && (
              <div className="new-section">
                <h3>New</h3>
                {newSectionList.map((item, index) =>
                  isNumber(item.approval_status) ? (
                    item.approval_status === 2 ? null : (
                      <div key={item._id}>{ContactContent(item)}</div>
                    )
                  ) : (
                    ""
                  )
                )}
                {newSectionList.map((item, index) =>
                  item.message ? (
                    <div key={item._id}>{MessageContent(item)}</div>
                  ) : (
                    ""
                  )
                )}
              </div>
            )}

            {earlierSectionList?.length > 0 && (
              <div className="new-section">
                <h3>Earlier</h3>
                {earlierSectionList
                  .map((item, index) =>
                    isNumber(item.approval_status) ? (
                      item.approval_status === 2 ? null : (
                        <div key={item._id}>
                          {ContactContent(item, "earlier")}
                        </div>
                      )
                    ) : (
                      ""
                    )
                  )}
                {earlierSectionList
                  .map((item, index) =>
                    item.message ? (
                      <div key={item._id}>
                        {MessageContent(item, "earlier")}
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </div>
            )}

            {notification?.length === 0 ? (
              <div className="no-notification-message">
                <h5>You don't have any notifications.</h5>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <div className="view-loader-container" style={{ height: "70vh" }}>
            <div className="lds-roller">
              {[...Array(8)].map((_, index) => (
                <div key={index}></div>
              ))}
            </div>
          </div>
        )}
      </div>

      {readModal ? (
        <div className="readMessage">
          <div className="readMessageContent">
            <div>
              <h3>Message</h3>
              <span onClick={() => setReadModal(false)}>
                <RxCross1 />
              </span>
            </div>
            <div>{readModalMsg}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NotificationPageMobile;
