import React, { useState } from "react";
import { toast } from "react-toastify";
import "../Landing.css";
import { AiOutlineClose } from "react-icons/ai";

const SignUpPage = (props) => {
  const [newUserDetails, setNewUserDetails] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    gender: "",
    dob: "",
    currentLocation: "",
  });

  const { first_name, last_name, email_id, gender, dob, currentLocation } = newUserDetails;

  const handleOnChange = (e) => {
    const newVal = { ...newUserDetails };
    newVal[e.target.name] = e.target.value;
    setNewUserDetails(newVal);
  };

  const handleOnValidation = () => {
    let today = new Date();
    let date = dob.split("-");
    let birth = today.getFullYear() - Number(date[0]);
    if (!first_name.match(/^[A-Za-z ]{2,30}$/)) {
      toast.error("First name is not valid", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (!last_name.match(/^[A-Za-z ]{2,30}$/)) {
      toast.error("Last name is not nalid", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (
      !email_id.match(/^([a-zA-Z0-9\_\-])+\@([a-zA-Z])+\.([a-zA-Z]){2,5}$/) // eslint-disable-line
    ) {
      toast.error("Email ID is not valid", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (gender === "") {
      toast.error("Please select your gender", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (dob === "") {
      toast.error("Date of Birth cannot be empty", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (birth < 18) {
      toast.error("User must be 18 or above", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (!currentLocation.match(/^[A-Za-z ]{2,30}$/)) {
      toast.error("Current location is not valid", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else {
      return true;
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (handleOnValidation()) {
      props.setData({
        ...props.data,
        first_name,
        last_name,
        email_id,
        gender,
        dob,
        currentLocation,
      })
      props.next();
    }
  };

  return (
    <div className="form-box sign-up">
            <div className="login-right-side">
        <div className="login-close-btn" onClick={() => props.cancel()}>
          <span>
            <AiOutlineClose />
          </span>
        </div>
      <h3>free registration</h3>
      <form onSubmit={handleOnSubmit}>
        <div>
          <label>First :</label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            aria-describedby="emailHelp"
            placeholder="Eg: Jack"
            value={newUserDetails.first_name}
            onChange={handleOnChange}
            required
          />
        </div>
        <div>
          <label>Last :</label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            aria-describedby="emailHelp"
            placeholder="Eg: Taylor"
            value={newUserDetails.last_name}
            onChange={handleOnChange}
            required
          />
        </div>
        <div>
          <label>Email :</label>
          <input
            type="email"
            id="signupEmail_id"
            name="email_id"
            aria-describedby="emailHelp"
            placeholder="Eg: abc@gmail.com"
            value={newUserDetails.email_id}
            onChange={handleOnChange}
            required
          />
        </div>
        <div className="gender-checkbox">
          <label>Gender :</label>
          <div>
            <div>
              <input
                type="checkbox"
                name="gender"
                value="M"
                checked={newUserDetails.gender === "M"}
                onChange={handleOnChange}
              />
              <label className="" htmlFor="flexCheckDefault">
                Male
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                name="gender"
                value="F"
                checked={newUserDetails.gender === "F"}
                onChange={handleOnChange}
              />
              <label className="" htmlFor="flexCheckDefault">
                Female
              </label>
            </div>
          </div>
        </div>
        <div>
          <label>DOB:</label>
          <input
            type="date"
            id="dob"
            name="dob"
            placeholder="Eg: 26/08/2000"
            value={newUserDetails.dob}
            onChange={handleOnChange}
            required
          />
        </div>
        <div>
          <label>Current location: </label>
          <input
            type="text"
            id="currentLocation"
            name="currentLocation"
            placeholder="Eg: Mumbai"
            value={newUserDetails.currentLocation}
            onChange={handleOnChange}
            required
          />
        </div>
        <div className="signup-btn">
          <button type={"submit"}>Next</button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default SignUpPage;