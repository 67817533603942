import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import server from "../../config/server.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Career.css";
import { Helmet } from "react-helmet";
import { OPTION_SELECT_EMPTY } from "../../utils/GlobalVariable";

const Career = ({setSteps, userDetails, next, setNext}) => {
  const navigate = useNavigate();
  const masterData = useSelector(state => state.masterData)
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [selectedIncome, setSelectedIncome] = useState("");
  const [userDetail, setUserDetail] = useState({
    occupation: "",
    jobLocation: "",
    companyName: "",
    designation: "",
    income: selectedIncome,
  });
  const [radio, setRadio] = useState("range");
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleOnChange = (e) => {
    const newVal = { ...userDetail };
    newVal[e.target.name] = e.target.value;
    setUserDetail(newVal);
  };
  const { occupation, jobLocation, companyName, designation, income } =
    userDetail;

  const handleValidation = () => {
    if (occupation === "") {
      setErrorMessage("Enter Your Occupation");
    } else if (jobLocation === "") {
      setErrorMessage("Enter Your Job Location");
    } else if (companyName === "") {
      setErrorMessage("Enter Your Company Name");
    } else if (designation === "") {
      setErrorMessage("Enter Your Current Position in Company");
    } else if (income === "") {
      setErrorMessage("Enter Your a Income");
    } else if (selectedIncome === "Selected") {
      setErrorMessage("Enter Your Income");
    } else {
      setIsValid(true);
      setNext(true)
    }
  };

  const handleCareerSubmit = async (e) => {
    e.preventDefault();
    /* perform try and catch */
    if (isValid) {
      try {
        const headers = {
          "Content-Type": "application/json",
          token: token,
        };
        const body = {
          userId,
          occupation,
          jobLocation,
          companyName,
          designation,
          income: selectedIncome,
        };
        const response = await axios.post(
          `${server.url.production}${server.api.UPDATE_CAREER_DETAILS}`,
          body,
          { headers }
        );
        if (response.status === 201) {
          if (next) {
            navigate("/home");
          }
          toast.success(response.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        } else {
          toast.error("Something went wrong.", {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      } catch (error) {
        if (error.data && error.data.status !== 201) {
          toast.error(error.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        } else {
          toast.error(error.response.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      }
    } else {
      toast.error("Something Went Wrong, Please Try Again Later", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    // err msg set to empty, when user type on pending inputfield.
    setErrorMessage("");
  }, [userDetail]);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setUserId(loggedUser.id);
      setToken(localStorage.getItem("token"));
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    setUserDetail({
      occupation: userDetails.occupation ? userDetails.occupation : "",
      jobLocation: userDetails.jobLocation ? userDetails.jobLocation : "",
      companyName: userDetails.companyName ? userDetails.companyName : "",
      designation: userDetails.designation ? userDetails.designation : "",
    });
    setSelectedIncome(userDetails.income ? userDetails.income : "");
  }, [token]); //eslint-disable-line

  return (
    <>
      <div className="body">
        <Helmet>
          <title>Career-details</title>
          <meta
            name="description"
            content="A matrimony career description summarizes the person's profession."
          />
        </Helmet>
        <form onSubmit={(e) => handleCareerSubmit(e)}>
          <div className="career_form">
            <div className="wrapper">
              <div className="form_grid ">
                <span className="col-12 career_inputField">
                  <label htmlFor="occupation">Occupation Categogry:</label>
                  <input
                    type="text"
                    placeholder="Eg: Lawyer"
                    required
                    id="occupation"
                    name="occupation"
                    value={userDetail.occupation}
                    onChange={handleOnChange}
                  />
                </span>
                <span className="col-12 career_inputField">
                  <label htmlFor="jobLocation">Current City:</label>
                  <input
                    type="text"
                    placeholder="Eg: Thane"
                    required
                    id="jobLocation"
                    name="jobLocation"
                    value={userDetail.jobLocation}
                    onChange={handleOnChange}
                  />
                </span>
              </div>
              <div className="form_grid">
                <span className="col-12 career_inputField">
                  <label htmlFor="companyName">Company Name:</label>
                  <input
                    type="text"
                    placeholder="Eg: Tech Mahindra"
                    required
                    id="companyName"
                    name="companyName"
                    value={userDetail.companyName}
                    onChange={handleOnChange}
                  />
                </span>
                <span className="col-12 career_inputField">
                  <label htmlFor="designation">Designation:</label>
                  <input
                    type="text"
                    placeholder="Eg: Sr. Software Developer"
                    required
                    id="designation"
                    name="designation"
                    value={userDetail.designation}
                    onChange={handleOnChange}
                  />
                </span>
              </div>
              <div className="form_grid">
                <span className="col-12 career_inputField">
                  <label>Annual Income (Rs.):</label>
                  <span className="radio_btns">
                    <span>
                      <input
                        type="radio"
                        checked={radio === "range" ? true : false}
                        value="range"
                        onChange={(e) => setRadio(e.target.value)}
                      />
                      <label>Income in Range</label>
                    </span>
                    <br />
                    <span>
                      <input
                        type="radio"
                        checked={radio === "exact" ? true : false}
                        value="exact"
                        onChange={(e) => setRadio(e.target.value)}
                      />
                      <label>Exact Income</label>
                    </span>
                  </span>
                </span>
                <span className="col-12 career_inputField">
                  <label>
                    {radio !== "exact" ? "Income Range:" : "Exact Income:"}
                  </label>
                  {radio !== "exact" ? (
                    <select
                      value={selectedIncome}
                      onChange={(e) => setSelectedIncome(e.target.value)}
                    >
                      <optgroup label="Income" />
                      <option value="Selected" >{OPTION_SELECT_EMPTY}</option>
                      {
                        masterData?.income.map(elem => (
                          <option key={elem._id} value={elem.income}>{elem.income}</option>
                        ))
                      }
                    </select>
                  ) : (
                    <input
                      type="number"
                      placeholder="Eg: 300000"
                      value={selectedIncome}
                      id="income"
                      name="income"
                      onChange={(e) => setSelectedIncome(e.target.value)}
                    />
                  )}
                </span>
              </div>
              <div className="form_grid pt-2">
                <button className="light-button" onClick={() => setSteps(5)}>Previous</button>
                {
                  <button
                    disabled={errorMessage.length > 0 ? true : false}
                    onClick={handleValidation}
                    className="light-button"
                  >
                    Save & Close
                  </button>
                }
              </div>
              {errorMessage.length > 0 ? (
                <div id="career_form_error">
                  <span>Alert: {errorMessage}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Career;
