import React, { useState } from "react";
import "./LoginPageMobile.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../../src/config/server.json";
import axios from "axios";
import { Link } from "react-router-dom";
import BackImg1 from "../../assets/login-back-img1.jpg";
import BackImg2 from "../../assets/login-back-img2.jpg";
import BackImg4 from "../../assets/login-back-img4.jpg";
import nextImg from "../../assets/Next-OTP.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import SwiperCore, { Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import { AiOutlineClose } from "react-icons/ai";
import RegistrationPageMobile from "../Registration/RegistrationPageMobile";

SwiperCore.use([Autoplay]);


const countryCode = ["+91"];
const LoginPageMobile = (props) => {
  const [disabledBtn, setDisabled] = useState(true);
  const [phone_number, setPhoneNumber] = useState("");
  const [modal, setModal] = useState(true);

  const handleOnChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    props.setData({ ...props.data, phone_number: value });
    if (e.target.value.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleOnValidation = () => {
    if (!phone_number) {
      toast.error("Number Cannot be Empty", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else if (phone_number.length !== 10) {
      toast.error("Please Fill Valid Number", {
        autoClose: 3000,
        position: "bottom-right",
      });
    } else {
      return true;
    }
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (handleOnValidation()) {
      try {
        setDisabled(true);
        const body = { phone_number: phone_number };
        const response = await axios.post(
          `${server.url.production}${server.api.CREATE_REGISTER_OTP}`,
          body
        );
        if (response.status === 201) {
          props.next();
          toast.success("Successfull.", {
            autoClose: 3000,
            position: "bottom-right",
          });
        } else {
          setDisabled(false);
          toast.error(response.data.message, {
            autoClose: 3000,
            position: "bottom-right",
          });
        }
      } catch (e) {
        setDisabled(false);
        toast.error(e.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    }
  };

  const handleOnSignUp = () => {
    setModal(false);
  };

  const handleOnSignIn = () => {
    setModal(true);
  }

  return modal ? (
    <div className="loginPageMobile-main">
      <div className="loginPageMobile">
        <div className="swiper-container">
        <Swiper
            slidesPerView="auto"
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
            autoplay={{ delay: 3000, disableOnInteraction: false }} // Update autoplay configuration
          >
            <SwiperSlide>
              <button className="close-button" onClick={() => props.cancel()}>
                <AiOutlineClose />
              </button>
              <img src={BackImg1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <button className="close-button" onClick={() => props.cancel()}>
                <AiOutlineClose />
              </button>
              <img src={BackImg2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <button className="close-button" onClick={() => props.cancel()}>
                <AiOutlineClose />
              </button>
              <img src={BackImg4} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="main-input">
          <form onSubmit={handleOnSubmit}>
            <div className="login-signup">
              <button onClick={handleOnSignIn}>Log In</button>
              <button onClick={handleOnSignUp}>/ Sign Up</button>
            </div>
            <div className="login-field">
              <div className="LoginContact">
                <p className="input-text">Enter your mobile number to proceed</p>
                <div className="login-info-top">
                  <select name="country" className="country-code">
                    {countryCode.map((elem) => (
                      <option value={elem} key={elem}>
                        {elem}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    autoFocus
                    placeholder="Enter mobile number"
                    value={phone_number}
                    onChange={handleOnChange}
                    required
                  ></input>
                </div>
              </div>
              <div className="login-info-bottom">
                <button
                  className="btn login-get-otp"
                  onClick={handleOnValidation}
                  disabled={disabledBtn}
                >
                  Send OTP <img src={nextImg} alt="" />
                </button>
                <hr className="simple-line" />
              </div>
            </div>
          </form>
          <p className="TandC">
            By continuing, you agree to MarwaadiShaadi{" "}
            <Link to={"/terms-conditions"}> terms of use</Link> &{" "}
            <Link to={"/privacy-policy"}>privacy policy.</Link>
          </p>
        </div>
      </div>
    </div>
  ) : (
    <RegistrationPageMobile
      handleOnSignIn={handleOnSignUp}
      cancel={props.cancel}
    />    
  );
};
export default LoginPageMobile;
