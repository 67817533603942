import React from "react"; // eslint-disable-next-line react-hooks/exhaustive-deps
import pdf from "@react-pdf/renderer";
import Backgroundimage from "../../../assets/BiodataImages/bgimg.png";
import headingBackground from "../../../assets/BiodataImages/Rectangle 27.png";
import Family_photo from "../../../assets/BiodataImages/Family_photo.jpg";

const { Page, Text, Image, Document, View, StyleSheet, Font } = pdf;

Font.register({
  src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
  family: "Ubuntu",
});

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  profileName: {
    fontFamily: "Helvetica-Bold",
    fontSize: "18px",
    fontWeight: "900",
    bottom: 10,
    paddingLeft: 15,
    color: "#AC8311",
    top: 5,
  },
  heading: {
    fontSize: 20,
    padding: "4 0",
    width: "50%",
    margin: "2 auto",
    textAlign: "center",
    color: "#ffffff",
    top: 10,
    bottom: 10,
  },
  headingBackgroundImage: {
    position: "absolute",
    height: "auto",
  },
  Text: {
    fontFamily: "Ubuntu",
    padding: "8 0",
    fontSize: 13,
  },

  personalContent: {
    display: "flex",
    flexDirection: "row",
    margin: "0 40",
  },
  personalContent1: {
    flexBasis: "70%",
    paddingRight: "10",
    top: "35",
    bottom: "20",
    height: "300",
  },
  personalContent2: {
    alignSelf: "center",
    flexBasis: "30%",
    top: "50",
    bottom: "20",
    height: "300",
  },
  familyContent1: {
    flexBasis: "50%",
    paddingRight: "10",
    top: "30",
    paddingTop: "40",
  },
  familyContent2: {
    flexBasis: "50%",
    paddingRight: "10",
    top: "30",
    paddingTop: "40",
  },
  educationContent1: {
    flexBasis: "50%",
    paddingRight: "10",
    top: "30",
    bottom: "20",
    height: "150",
  },
  educationContent2: {
    flexBasis: "50%",
    paddingRight: "10",
    top: "30",
    bottom: "20",
    height: "150",
  },
  familyimgContainer: {
    alignSelf: "center",
    top: "50",
    width: "70%",
    borderRadius: 30,
    padding: 10,
  },
  profileImg2: {
    borderRadius: "15%",
  },
  personalContent3: {
    flexBasis: "35%",
    paddingRight: "10",
    top: 40,
  },
  personalContent4: {
    // alignSelf: "center",
    flexBasis: "65%",
    top: 40,
  },
  personalContentText: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: "5px 0 5px 15px",
    fontSize: 15,
    color: "#AC8311",
  },
  ContactContentText: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: "0 0 15px 22px",
    fontSize: 15,
    color: "#AC8311",
  },
  personalContentText1: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: "0 0 10px 20px",
    fontSize: 15,
    color: "#AC8311",
    flexWrap: "wrap",
  },
  familyContent: {
    margin: "0 30",
    width: "100%",
  },
  contactContent: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginLeft: "30",
    paddingBottom: "5",
  },
  familyContentLeft: {
    flexBasis: "30%",
  },
  familyContentRight: {
    flexBasis: "70%",
  },
  familyContentText: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  headingBackground: {
    width: "100%",
  },
  about_us: {
    marginTop: "10px"
  },
  aboutUsHeading: {
    margin: "0 auto",
    marginTop: "20px",
    width: "30%",
    top: 20,
    position: "relative",
    
  }
  ,
  about_text: {
    fontSize: 20,
    textAlign: "center",
    alignSelf: "center",
    color: "#ffffff",
    position: "absolute",
    padding: "2px 0",
  },

  about_content: {
    top: 20,
    width: "80%",
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    padding: "20px 0 0 0",
    fontSize: 15,
    color: "#AC8311",
    flexWrap: "wrap",
  }
});

const FormatThree = ({
  fields,
  uncleList,
  auntyList,
  mamaList,
  mamiList,
  brotherList,
  sisterList,
  cousinList,
  base64,
}) => {
  const {
    pob,
    gender,
    blood_group,
    height,
    marital,
    religion,
    language,
    country,
    horoscope,
    qualification,
    specialization,
    instituteName,
    companyName,
    designation,
    income,
    fatherName,
    father_job,
    motherName,
    mother_job,
    dada,
    dadi,
    siblings,
    nana,
    nani,
    nativePlace,
    familyStatus,
    phone_number,
    email_id,
    address_1,
    about_us
  } = fields;
  const dob = new Date(fields.dob);
  const birthDate = isNaN(dob.getDate()) ? "" : dob.getDate();
  const birthYear = isNaN(dob.getFullYear()) ? "" : dob.getFullYear();
  const birthMonth =
    dob.toLocaleString("default", { month: "long" }) === "Invalid Date"
      ? ""
      : dob.toLocaleString("default", { month: "long" });
  const date_of_birth = `${birthDate} ${birthMonth} ${birthYear}`;

  const checkIfLastIndex = (index, length) => {
    return index === length - 1 ? "" : ", ";
  };

  const profileDetailListArr1 = [
    { label: "Date of birth:", value: date_of_birth },
    { label: "Birth Place:", value: pob },
    { label: "Gender:", value: gender },
    { label: "Blood Group:", value: blood_group },
    { label: "Height:", value: height },
    { label: "Marital Status:", value: marital },
    { label: "Religion:", value: religion },
    { label: "Language Known:", value: language },
    { label: "Country:", value: country },
    { label: "Horoscope:", value: horoscope },
  ];

  const profileDetailListArr2 = [
    { label: "Highest Qualification:", value: qualification },
    { label: "Specialization:", value: specialization },
    { label: "Institute Name:", value: instituteName },
  ];

  const profileDetailListArr5 = [
    { label: "Company Name:", value: companyName },
    { label: "Occupation:", value: designation },
    { label: "Income:", value: income },
  ];

  const profileContactDetails = [
    { label: "Conatct Number:", value: phone_number },
    { label: "Email:", value: email_id },
    { label: "Residential Address:", value: address_1 },
  ];

  const profileDetailListArr3 = [
    { label: "Father:", value: fatherName },
    { label: "Father's Job:", value: father_job },
    { label: "Mother:", value: motherName },
    { label: "Mother's Job:", value: mother_job },
    { label: "Grand Father:", value: dada },
    { label: "Grand Mother:", value: dadi },
    { label: "Number Of Siblings:", value: siblings },
    { label: "Brother Name:", value: brotherList },
    { label: "Sister Name:", value: sisterList },
  ];

  const profileDetailListArr4 = [
    { label: "Nanaji:", value: nana },
    { label: "Naniji:", value: nani },
    { label: "Uncles:", value: uncleList },
    { label: "Aunty:", value: auntyList },
    { label: "Mama:", value: mamaList },
    { label: "Mami:", value: mamiList },
    { label: "Cousins Name:", value: cousinList },
    { label: "Native Place:", value: nativePlace },
    { label: "Family Status:", value: familyStatus },
  ];

  return (
    <Document>
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={Backgroundimage} />
        </View>
        {/* profile name & img */}
        {/* Personal Details Container 1 */}
        <View style={styles.personalContent}>
          <View style={styles.personalContent1}>
            <Text style={styles.profileName}>
              {fields.first_name} {fields.last_name}
            </Text>
            {profileDetailListArr1.map((detail, i) => (
              <View style={styles.personalContentText} key={i}>
                <Text>{detail.label} </Text>
                <Text>{detail.value}</Text>
              </View>
            ))}
          </View>
          <View style={styles.personalContent2}>
            <Image
              src={{ uri: base64 }}
              alt={"User Image"}
              style={{ width: "100%" }}
            />
          </View>
        </View>
        <View style={styles.heading}>
          <View style={styles.headingBackgroundImage}>
            <Image
              src={headingBackground}
              alt={"Heading Background"}
              style={styles.headingBackground}
            />
          </View>
          <Text> Education and Qualification</Text>
        </View>
        <View style={styles.personalContent}>
          <View style={styles.educationContent1}>
            {profileDetailListArr2.map((detail, i) => (
              <View style={styles.personalContentText1} key={i}>
                <Text>{detail.label} </Text>
                <Text>{detail.value}</Text>
              </View>
            ))}
          </View>
          <View style={styles.educationContent2}>
            {profileDetailListArr5.map((detail, i) => (
              <View style={styles.personalContentText1} key={i}>
                <Text>{detail.label} </Text>
                <Text>{detail.value}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.heading}>
          <View style={styles.headingBackgroundImage}>
            <Image
              src={headingBackground}
              alt={"Heading Background"}
              style={styles.headingBackground}
            />
          </View>
          <Text>Contact Details</Text>
        </View>
        <View style={styles.personalContent}>
          <View style={styles.personalContent3}>
            <Image
              src={{ uri: base64 }}
              alt={"User Image"}
              style={{ width: "90%" }}
            />
          </View>
          <View style={styles.personalContent4}>
            {profileContactDetails.map((detail, i) => (
              <View style={styles.personalContentText1} key={i}>
                <Text>{detail.label} </Text>
                <Text>{detail.value}</Text>
              </View>
            ))}
          </View>
        </View>
      </Page>

      {/* PAGE 2 */}
      <Page>
        {/* bg Img */}
        <View style={styles.container}>
          <Image style={styles.image} src={Backgroundimage} />
        </View>
        {/* Family Img */}
        <View style={styles.familyimgContainer}>
          <Image
            src={Family_photo}
            alt={"User Image"}
            style={styles.profileImg2}
          />
          <View style={styles.heading}>
            <View style={styles.headingBackgroundImage}>
              <Image
                src={headingBackground}
                alt={"Heading Background"}
                style={styles.headingBackground}
              />
            </View>
            <Text>Family Details</Text>
          </View>
        </View>
        {/* Personal Details Container 2 */}
        <View style={styles.personalContent}>
          <View style={styles.familyContent1}>
            <View style={styles.personalDetails}>
              {profileDetailListArr3.map((detail, i) => {
                return typeof detail.value === "object" ? (
                  <View style={styles.personalContentText1} key={i}>
                    <Text>{detail.label} </Text>
                    <Text>
                      {detail.value.map((elem, i) => {
                        return (
                          elem.check && (
                            <Text key={i}>
                              {Object.values(elem)[0]}
                              {checkIfLastIndex(i, Object.keys(elem)[0].length)}
                            </Text>
                          )
                        );
                      })}
                    </Text>
                  </View>
                ) : (
                  <View style={styles.personalContentText1} key={i}>
                    <Text>{detail.label} </Text>
                    <Text>{detail.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View style={styles.familyContent2}>
            <View style={styles.personalDetails}>
              {profileDetailListArr4.map((detail, i) => {
                return typeof detail.value === "object" ? (
                  <View style={styles.personalContentText1} key={i}>
                    <Text>{detail.label} </Text>
                    <Text>
                      {detail.value.map((elem, i) => {
                        return (
                          elem.check && (
                            <Text key={i}>
                              {Object.values(elem)[0]}
                              {checkIfLastIndex(i, Object.keys(elem)[0].length)}
                            </Text>
                          )
                        );
                      })}
                    </Text>
                  </View>
                ) : (
                  <View style={styles.personalContentText1} key={i}>
                    <Text>{detail.label} </Text>
                    <Text>{detail.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        {/* ABOUT US SECTION */}
          {/* <View style={styles.heading}> */}
          {
            about_us ? 
            <>
            <View style={styles.aboutUsHeading}>
              <Image
                src={headingBackground}
                alt={"Heading Background"}
                style={styles.headingBackground}
              />
            <Text style={styles.about_text}>About Myself</Text>
            </View>
            <View>
              <Text style={styles.about_content}>{about_us}</Text>
            </View>
            </>
            : null
          }
          {/* </View> */}
      </Page>
    </Document>
  );
};
export default FormatThree;
// {
//   fields.about_us ?
//   <View style={styles.personalContentText}>
//     <Text>About Us </Text>
//     <Text>{fields.about_us}</Text>
//   </View>
//   :
//   null
// }