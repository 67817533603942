import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

export const MyQuestons = ({ question, answer }) => {
    let [show, setShow] = useState(false)

    return (
        <>
            <div className="main-heading">
                <p onClick={() => setShow(!show)}> {show ? <AiOutlineMinus /> : <AiOutlinePlus />} </p>
                <h4>{question}</h4>
            </div>
            {show && <p className='answer'>{answer}</p>}
        </>
    )
}

export default MyQuestons;