import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import biodata_format_7A from "../../../assets/BiodataImages/biodata-format-7B.png";
import biodata_format_7B from "../../../assets/BiodataImages/biodata-format-7A.png";
import { toFeet } from "../../../utils/ConvertCmToFeet";

const styles = StyleSheet.create({
  Text: {
    fontSize: "12",
    padding: "5 0",
  },

  Page1: {
    position: "relative",
  },

  headerContainer: {
    position: "absolute",
    top: "41%",
    left: "40%",
  },

  headerText: {
    color: "#FFF",
    letterSpacing: "1.2px",
  },

  nameText: {
    marginBottom: "2%",
    fontSize: "18px",
  },

  container_2: {
    width: "225px",
    position: "absolute",
    top: "50%",
    left: "7%",
  },

  container_3: {
    width: "225px",
    position: "absolute",
    top: "50%",
    right: "8%",
  },

  container_4: {
    width: "280px",
    position: "absolute",
    top: "8%",
    right: "10%",
  },

  container_5: {
    width: "225px",
    position: "absolute",
    top: "46%",
    left: "6%",
  },

  container_6: {
    width: "250px",
    position: "absolute",
    top: "45%",
    right: "7%",
  },

  container_7: {
    width: "225px",
    position: "absolute",
    top: "75%",
    left: "6%",
  },

  container_8: {
    width: "250px",
    position: "absolute",
    top: "75%",
    right: "7%",
  },

  profileImage: {
    width: "300px",
    height: "250px",
    position: "absolute",
    top: "5%",
    left: "25%",
  },

  userImage: {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  },

  profileImage1: {
    width: "178px",
    height: "198px",
    position: "absolute",
    top: "7%",
    left: "4%",
  },

  userImage1: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
  },

  contactHeader: {
    position: "absolute",
    top: "40.8%",
    left: "13%",
  },

  overViewHeader: {
    position: "absolute",
    top: "40.8%",
    right: "24%",
  },

  educationHeader: {
    position: "absolute",
    top: "69%",
    left: "28%",
  },

  TextContainer_4: {
    fontSize: "12",
    padding: "2 0",
  }
});

function FormatSix({
  fields,
  uncleList,
  auntyList,
  mamaList,
  mamiList,
  brotherList,
  sisterList,
  cousinList,
  base64,
}) {
  const dob = new Date(fields.dob);
  const birthDate = isNaN(dob.getDate()) ? "" : dob.getDate();
  const birthYear = isNaN(dob.getFullYear()) ? "" : dob.getFullYear();
  const birthMonth =
    dob.toLocaleString("default", { month: "long" }) === "Invalid Date"
      ? ""
      : dob.toLocaleString("default", { month: "long" });
  const date_of_birth = `${birthDate} ${birthMonth} ${birthYear}`;

  const checkIfLastIndex = (index, length) => {
    return index === length - 1 ? "" : ", ";
  };

  return (
    <Document>
      <Page size={"A4"}>
        <View>
          <Image src={biodata_format_7B} alt="Background" />
          <View style={styles.contactHeader}>
            <Text style={styles.headerText}>Contact Details</Text>
          </View>
          <View style={styles.overViewHeader}>
            <Text style={styles.headerText}>Overview</Text>
          </View>
          <View style={styles.educationHeader}>
            <Text style={styles.headerText}>Education and Occupation</Text>
          </View>
          <View style={styles.profileImage1}>
            <Image src={{ uri: base64 }} style={styles.userImage1}></Image>
          </View>
          <View style={styles.container_4}>
            <Text style={styles.nameText}>
              Name :{fields.first_name} {fields.last_name}
            </Text>
            <Text style={styles.TextContainer_4}>
              Location : {fields.current_location}
            </Text>
            <Text style={styles.TextContainer_4}>Country : {fields.country}</Text>
            <Text style={styles.TextContainer_4}>
              Gender : {fields.gender === "M" ? "Male" : "Female"}
            </Text>
            <Text style={styles.TextContainer_4}>Height : {toFeet(fields.height)}</Text>
            <Text style={styles.TextContainer_4}>Marital Status : {fields.marital}</Text>
            <Text style={styles.TextContainer_4}>About Myself : {fields.about_us}</Text>
          </View>
          <View style={styles.container_5}>
            <Text style={styles.Text}>Email : {fields.email_id}</Text>
            <Text style={styles.Text}>Contact : {fields.phone_number}</Text>
            <Text style={styles.Text}>
              Residence Address : {fields.address_1}
            </Text>
          </View>
          <View style={styles.container_6}>
            <Text style={styles.Text}>Date of Birth : {date_of_birth}</Text>
            <Text style={styles.Text}>Horoscope : {fields.horoscope}</Text>
            <Text style={styles.Text}>Religion : {fields.religion}</Text>
            <Text style={styles.Text}>Blood Group : {fields.blood_group}</Text>
            <Text style={styles.Text}>Language Known : {fields.language}</Text>
          </View>
          <View style={styles.container_7}>
            <Text style={styles.Text}>
              Highest qualification : {fields.qualification}
            </Text>
            <Text style={styles.Text}>
              Specalization : {fields.specialization}
            </Text>
            <Text style={styles.Text}>
              Institute Name : {fields.instituteName}
            </Text>
          </View>
          <View style={styles.container_8}>
            <Text style={styles.Text}>Company Name : {fields.companyName}</Text>
            <Text style={styles.Text}>Occupation : {fields.designation}</Text>
            <Text style={styles.Text}>Income : {fields.income}</Text>
          </View>
        </View>

        <View style={styles.Page1}>
          <Image src={biodata_format_7A} alt="Background" />
          <View style={styles.profileImage}>
            <Image src={{ uri: base64 }} style={styles.userImage}></Image>
          </View>
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Family Details</Text>
          </View>
          <View style={styles.container_2}>
            <Text style={styles.Text}>Grand Father: {fields.dada}</Text>
            <Text style={styles.Text}>Grand Mother: {fields.dadi}</Text>
            <Text style={styles.Text}>Father Name: {fields.fatherName}</Text>
            <Text style={styles.Text}>Father Job: {fields.father_job}</Text>
            <Text style={styles.Text}>Mother Name: {fields.motherName}</Text>
            <Text style={styles.Text}>Mother Job: {fields.mother_job}</Text>
            <Text style={styles.Text}>
              Brother Name:
              {
                // eslint-disable-next-line
                brotherList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.familyRightText}>
                        {elem.brother}
                        {checkIfLastIndex(index, brotherList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
            <Text style={styles.Text}>
              Sister Name:
              {
                // eslint-disable-next-line
                sisterList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.familyRightText}>
                        {elem.sister}
                        {checkIfLastIndex(index, sisterList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
            <Text style={styles.Text}>
              Number of Sibilings: {fields.siblings}
            </Text>
          </View>
          <View style={styles.container_3}>
            <Text style={styles.Text}>Nana Name: {fields.nana}</Text>
            <Text style={styles.Text}>Nani Name: {fields.nani}</Text>
            <Text style={styles.Text}>
              Uncles Name:
              {
                // eslint-disable-next-line
                uncleList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.familyRightText}>
                        {elem.chacha}
                        {checkIfLastIndex(index, uncleList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
            <Text style={styles.Text}>
              Aunty Name:
              {
                // eslint-disable-next-line
                auntyList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.familyRightText}>
                        {elem.chachi}
                        {checkIfLastIndex(index, auntyList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
            <Text style={styles.Text}>
              Mama :
              {
                // eslint-disable-next-line
                mamaList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.familyRightText}>
                        {elem.mama}
                        {checkIfLastIndex(index, mamaList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
            <Text style={styles.Text}>
              Mami Name:
              {
                // eslint-disable-next-line
                mamiList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.familyRightText}>
                        {elem.mami}
                        {checkIfLastIndex(index, mamiList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
            <Text style={styles.Text}>
              Cousins Name:
              {
                // eslint-disable-next-line
                cousinList.map((elem, index) => {
                  if (elem.check) {
                    return (
                      <Text key={index} style={styles.familyRightText}>
                        {elem.cousins}
                        {checkIfLastIndex(index, cousinList.length)}
                      </Text>
                    );
                  }
                })
              }
            </Text>
            <Text style={styles.Text}>Native Place: {fields.nativePlace}</Text>
            <Text style={styles.Text}>
              Family Status: {fields.familyStatus}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default FormatSix;
