import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlineApple } from "react-icons/ai";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import FreeService from "../../assets/FreeSite.png";
import BiodataPreview from "../../assets/biodata_preview.png";
import Privacy from "../../assets/Privacy.png";
import BiodataBg from "../../assets/BiodataPreview.png";
import Banner from "../../assets/banner-img4.jpeg";
import Aboutus from "../../assets/about_us.png";
import MobileImage from "../../assets/Mobile_image_preview.png";
import { useDispatch } from "react-redux";
import { getWithoutIncomeData, getWithoutLoginHeightData, getWithoutLoginOccupationData, getWithoutMaritalStatusData, getWithoutProfileCreatedByData } from "../../Redux/Master/MasteData/MasterDataWithoutLogin";
import "./Landing.css";
import LandingPage from "../LandingPagesMobile/LandingPage";

const Landing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (loggedUser) {
      navigate("/search"); // if user logged in navigate to home Page
    } else {
      navigate("/"); // if user is not logged in navigate to login/Register Page
    }
  }, [loggedUser, navigate]);

  useEffect(() => {
    dispatch(getWithoutLoginOccupationData());
    dispatch(getWithoutIncomeData());
    dispatch(getWithoutMaritalStatusData());
    dispatch(getWithoutLoginHeightData());
    dispatch(getWithoutProfileCreatedByData());
  }, [dispatch]);

  // useEffect(() => { 
  //   setShow(true);
  //   // eslint-disable-next-line
  // }, [isShow]);

  return (
    <>
    <div className="landingContainer d-none d-sm-block" >
      <Helmet>
        <title>matrimony</title>
        <meta
          name="description"
          content="Find your Special One on India's Leading Matrimonial Site. Register today for free. 100% Secure and Trusted site." />
      </Helmet>
      <div className="landing-banner">
        <img src={Banner} alt="Banner" />
        <p>&ldquo;find your perfect match: join our matrimonial community today&#33;&rdquo;</p>
      </div>
      <div className="landing-services">
        <h3>&ldquo;connect with your perfectmatch:sign up free&rdquo;</h3>
        <ul>
          <li>
            <div>
              <img src={FreeService} alt="Free Services" />
              <h4>free services</h4>
              <p>
                There are no fees or charges associated
                with creating a profile, searching for
                compatible matches, or connecting with
                other users. We offer a completely free
                matrimonial website to you!
              </p>
            </div>
          </li>
          <li>
            <div>
              <img src={BiodataPreview} alt="Biodata Preview" />
              <h4>biodata</h4>
              <p>
                Choose from different
                PDF formats to create
                your Marriage Biodata
                for FREE.
              </p>
            </div>
          </li>
          <li>
            <div>
              <img src={Privacy} alt="Protection Privacy" />
              <h4>protection of privacy</h4>
              <p>
                The decision to share your contact
                information lies solely in your hands.
                Contact sharing is approved on an
                individual basis.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="landing-biodata">
        <div>
          <h4>create your marriage biodata</h4>
          <Link to={"/biodata"}>create free biodata</Link>
        </div>
        <div>
          <img src={BiodataBg} alt="Biodata" />
        </div>
      </div>
      <div className="landing-about">
        <div>
          <h3>welcome to matrimonydemo.com!</h3>
          <p>
            Aenean nonummy hendrerit mauris. Phasellus porta. Fusce suscipit varius
            mi. Cum sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Nulla dui. Fusce feugiat malesuada odio. Morbi
            nunc odio, gravida at, cursus nec, luctus a, lorem. Maecenas tristique orci
            ac sem. Duis ultricies pharetra Aenean nonummy hendrerit mauris.
            Phasellus porta. Fusce suscipit varius mi. Cum sociis natoque penatibus et
            magnis dis parturient montes, nascetur ridiculus mus. magna.
          </p>
          <button onClick={() => navigate("/about")}>read more...</button>
        </div>
        <div>
          <img src={Aboutus} alt="About us" />
        </div>
      </div>
      <div className="landing-download">
        <div>
          <h3>download matrimony app on your smartphone</h3>
          <p>
            Our app is available for free on the App store and Google Play.
            Download the App now!
          </p>
          <div>
            <button> <IconContext.Provider value={{ size: "45px" }}><AiOutlineApple /></IconContext.Provider> <span><span>download free</span> <span>apple store</span></span></button>
            <button> <IconContext.Provider value={{ size: "45px" }}><IoLogoGooglePlaystore /></IconContext.Provider> <span><span>download free</span> <span>play store</span></span></button>
          </div>
        </div>
        <div>
          <img src={MobileImage} alt="Mobile Preview" />
        </div>
      </div>
    </div>
    <div className="MobileLandingContainer col-sm-12 d-block d-sm-none">  
    < LandingPage/>
    </div>
     </>
  );
};

export default Landing;
