import home from "./customHomeIcon.js";
import searchHeart from "./customSearchHeartIcon.js";
import heart from "./customHeartIcon.js";
import notification from "./customNotifiIcon.js"
import profileDetailsIcon from "./customProfileDetailsIcon.js";
import search1 from "./HeaderIcons/customSearchIcon1.js";
import crossSvg from "./svgIcons/crossIconSvg.js";
import arrowLeftSvg from "./svgIcons/arrowLeftIconSvg.js";
import arrowRightSvg from "./svgIcons/arrowRightIconSvg.js";
import avata_placeholder from "./avatar_placeholder.png";
import magnifySvgAnimation from "./svgIcons/magnifySvgAnimation.js";

const images = {
  home, 
  searchHeart,
  heart,
  notification,
  profileDetailsIcon,
  search1,
  crossSvg,
  arrowLeftSvg,
  arrowRightSvg,
  avata_placeholder,
  magnifySvgAnimation,
}

export default images;