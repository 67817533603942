import "./App.css";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CareerPage from "./pages/CareerPage/Career";
import PersonalPage from "./pages/PersonalDetails/PersonalDetails";
import EducationPage from "./pages/EducationDetails";
import UserProfile from "./pages/UserProfile/UserProfile";
import Home from "./pages/Home";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import FAQs from "./pages/FAQs/FAQs";
import Landing from "./pages/LandingPage/Landing";
import About from "./pages/About/About";
import Contact from "./pages/ContactUs/ContactUs";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import SearchPage from "./pages/SearchPage/SearchPage";
import AbuseReport from "./pages/AbuseReport/AbuseReport";
import DisplayAllUsers from "./pages/admin/DisplayAllUsers/DisplayAllUsers";
import InterestedProfile from "./pages/InterestedProfile/InterestedProfile";
import Shortlisted from "./pages/Shortlisted/Shortlisted";
import CustomerCarePage from "./pages/CustomerCarePage/CustomerCarePage";
import FamilyPage from "./pages/FamilyDetails/FamilyPage";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ProfileList from "./pages/ProfileListingPage/ProfileList";
import BioData from "./pages/Biodata/Biodata";
import ProfilePhoto from "./pages/ProfilePhoto/ProfilePhoto";
import ProfileView from "./pages/ProfileView/ProfileView";
import Testimonial from "./pages/Testimonial/Testimonial";
import BiodataMobile from "./components/BiodataMobile/BiodataMobile";
import { ProfileViewMobile } from "./components/ProfileViewMobile/ProfileViewMobile";
import MyProfile from "./pages/MyProfilePage/MyProfile";
import NotificationPageMobile from "./pages/Notification/NotificationPageMobile";
import AboutPage from "./pages/AboutPage/AboutPage"
import PrivacyPolicyMobile from "./pages/PrivacyPolicyMobile/PrivacyPolicyMobile";
import TermAndCondition from "./pages/TermsAndCondition/TermAndCondition";

function App() {
  const [isShow, setShow] = useState(true);

  return (
    <Router>
      {isShow && <Header />}
      <div>
        <Routes>
          {/* Open Route */}
          <Route path="/landing" element={<Landing />} exact />
          <Route path="/contact" element={<Contact />} exact />
          <Route path="/terms-conditions" element={<TermsConditions />} exact />
          <Route path="/abuse-report/create" element={<AbuseReport />} />
          <Route
            path="/interested-profiles"
            element={<InterestedProfile />}
            exact
          />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/shortlisted-profiles" element={<Shortlisted />} />
          <Route path="/" element={<Landing />} exact />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
          {/* <Route path="/home" element={<Home />} exact /> */}
          <Route path="/contact" element={<Contact />} exact />
          <Route path="/terms-conditions" element={<TermsConditions />} exact />
          <Route path="/aboutus-m" element={<AboutPage/>} exact/>
          <Route path="/privacy-policy-m" element={<PrivacyPolicyMobile/>} exact/>
          <Route path="/terms-condition-m" element={<TermAndCondition/>} exact/>
          <Route path="/customer-care" element={<CustomerCarePage />} />
          <Route path="/about" element={<About />} exact />
          <Route path="/faqs" element={<FAQs />} exact />
          <Route path="/biodata" element={<BioData />} />
          <Route path="/mobile/biodata" element={<BiodataMobile setShow={setShow} />} />
          <Route path="/mobile/view/profile/:id" element={<ProfileViewMobile setShow={setShow} />} />
          <Route path="/mobile/notification" element={<NotificationPageMobile />} />
 
          {/* Protected Route */}
          <Route element={<PrivateRoutes />}>
            {
              <Route path="/home" element={<Home />} exact />
              /* <Route path="/contact" element={<Contact />} exact />
              <Route path="/terms-conditions" element={<TermsConditions />} exact />
              <Route path="/customer-care" element={<CustomerCarePage />} />
              <Route path="/about" element={<About />} exact /> */
            }
            <Route path="/admin/users" element={<DisplayAllUsers />} exact />
         
            <Route path="/profile/edit" element={<UserProfile />} exact />
            <Route path="/profile/photo" element={<ProfilePhoto />} exact />
            <Route
              path="/details/personal/edit"
              element={<PersonalPage />}
              exact
            />
            <Route
              path="/details/education/edit"
              element={<EducationPage />}
              exact
            />
            <Route path="/details/career/edit" element={<CareerPage />} exact />
            <Route path="/details/family/edit" element={<FamilyPage />} />
            <Route path="/search" element={<SearchPage />} exact />
            <Route path="/abuse" element={<AbuseReport />} />
            <Route path="/profile-list" element={<ProfileList />} exact />
            <Route path="/profile/view/:id" element={<ProfileView />} />
            <Route path="/myprofile" element={<MyProfile />} />
            {/* <Route path="/faqs" element={<FAQs />} exact /> */}
          </Route>
        </Routes>
      </div>
      {isShow && < Footer />}
    </Router>
  );
}

export default App;