import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../config/server.json";
import { useNavigate } from "react-router-dom";
import "./EducationDetails.css";
import { Helmet } from "react-helmet";
import {OPTION_SELECT_EMPTY} from "../../utils/GlobalVariable";

const EducationDetails = ({setSteps, userDetails,next, setNext}) => {
  const [degreeDetails, setDegreeDetails] = useState({
    instituteName: "",
    specialization: "",
    passingYear: new Date().getFullYear(),
    percentage: "",
    qualification: "",
  });
  const masterData = useSelector(state => state.masterData)
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [close, setClose] = useState(false);
  const navigate = useNavigate();
  const {
    instituteName,
    percentage,
    qualification,
    passingYear,
    specialization,
  } = degreeDetails; // destructuring

  const degreeChangeHandler = (e) => {
    const newVal = { ...degreeDetails };
    newVal[e.target.name] = e.target.value;
    setDegreeDetails(newVal);
  };

  // global toastify alert function
  const toastifyAlert = (errStatus, msg) =>
    toast[errStatus](msg, {
      autoClose: 3000,
      position: "bottom-right",
    });

  // Too populated field
  const fetchData = async () => {
    setDegreeDetails({
      instituteName: userDetails.instituteName ? userDetails.instituteName : instituteName,
      specialization: userDetails.specialization ? userDetails.specialization : specialization,
      passingYear: userDetails.passingYear ? userDetails.passingYear : passingYear,
      percentage: userDetails.percentage ? userDetails.percentage : percentage,
      qualification: userDetails.qualification ? userDetails.qualification : qualification,
    });
  }

  // inputfield validation
  const handleValidation = () => {
    let count = 0;

    // eslint-disable-next-line
    Object.keys(degreeDetails).map((key) => {
      if (degreeDetails[key].length === 0) {
        toastifyAlert("error", `Please enter your ${key}`);
      } else {
        count++;
      }
    });
    if (percentage < 100) {
      console.log(percentage)
    }
    else {
      toast.error("Please enter valid percentage", {
        autoClose: 3000,
        position: "bottom-right",
      });
      return false
    }
    if (count === Object.keys(degreeDetails).length) {
      return true;
    }
    return false;
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const apiPath = server.api.UPDATE_EDUCATION_DETAILS;
    const postData = {
      userId: userId,
      instituteName: instituteName,
      percentage: Number(percentage),
      qualification: qualification,
      passingYear: Number(passingYear),
      specialization: specialization,
    };

    if (handleValidation()) {
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };

      try {
        const response = await axios.post(
          `${server.url.production}${apiPath}`,
          postData,
          { headers: headers }
        );

        if (response.status === 201) {
          if (close) {
            navigate('/');
          } 
          if (next) {
            setSteps(6);
            console.log("aa");
            setNext(false)
          }
          toastifyAlert("success", "Your data has been updated");
        } else {
          toastifyAlert("error", "Something went wrong, Please try again");
        }
      } catch (error) {
        toastifyAlert("error", error.message);
      }
    }
  };

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setUserId(loggedUser.id);
      setToken(localStorage.getItem("token"));
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    // if token, then populate the field 
    if (token) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [token]);

  return (
    <>
      <div className="body">
        <Helmet>
          <title>EducationDetails</title>
          <meta
            name="description"
            content="The education field is a summary of a person's educational background."
          />
        </Helmet>
      </div>
      <div className="education-content">
        <div className="wrapper">
          <form onSubmit={handleOnSubmit} className="m-auto col-lg-8 col-sm-10  col-11 bg-white min-vh-50 min-vh-sm-50 p-2 p-md-4 rounded">
            <div className="d-flex flex-sm-row flex-column">
              <div className="col-sm-6 p-2  p-md-3 education-form-group">
                <div className="my-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Institute Name:
                  </label>
                  <input
                    value={degreeDetails.instituteName}
                    onChange={(e) => degreeChangeHandler(e)}
                    name="instituteName"
                    type="text"
                    className="form-control"
                    placeholder="Eg: IIT Bombay"
                  />
                </div>
                <div className="my-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Qualification:
                  </label>
                  <select
                    name="qualification"
                    onChange={(e) => degreeChangeHandler(e)}
                    value={degreeDetails.qualification}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <optgroup label="Education Degree" />
                    <option value="None">{OPTION_SELECT_EMPTY}</option>
                    {masterData?.educationDegree.map((x) => {
                      return(
                        <option key={x.degreeName} value={x.degreeName}>
                        {x.degreeName}
                      </option>
                      )
                    })}
                  </select>
                </div>
                <div className="my-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Specialization:
                  </label>
                  <input
                    type="text"
                    value={degreeDetails.specialization}
                    onChange={(e) => degreeChangeHandler(e)}
                    name="specialization"
                    className="form-control form-control-md"
                    placeholder="Eg: Computer Science"
                  />
                </div>
              </div>
              <div className="col-sm-6 p-2  p-md-3 education-form-group">
                <div className="my-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Percentage:
                  </label>
                  <input
                    value={degreeDetails.percentage}
                    onChange={(e) => degreeChangeHandler(e)}
                    name="percentage"
                    type="number"
                    className="form-control form-control"
                    placeholder="Eg: 80.20"
                  />
                </div>
                <div className="my-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Passing Year:
                  </label>
                  <select
                    value={degreeDetails.passingYear}
                    onChange={(e) => degreeChangeHandler(e)}
                    name="passingYear"
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <optgroup label="Years" />
                    {masterData?.educationCompleteYear.map((elem) => {
                      return(
                        <option key={elem._id} value={elem.year}>
                        {elem.year}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="saveclosebtns">
              <button type="button"  onClick={() => setSteps(4)}>
                Previous
              </button>
              <button  
              type="submit"
              onClick={() => setClose(true)}
              >
                Save & Close
              </button>
              <button type="submit"  onClick={() => setNext(true)}>
                Save & Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EducationDetails;
