import React, { useState } from 'react';
import "./MultipleImageSlider.css";
import { MdArrowBackIosNew } from "react-icons/md";

function MultipleImagesSlider({multipleImages, setShowSlider}) {
    const [imageIndex, setImageIndex] = useState(0);
  return (
    <div className='multiple-slider'>
        <div>
              <span
                className="cancel-slider"
                onClick={() => setShowSlider(false)}
              >
                <MdArrowBackIosNew /> Back
              </span>
            <span>Featured</span></div>
        <div>
            <img src={multipleImages[imageIndex].profileImages} alt="" />
        </div>
        <div>
            {
                multipleImages.map((elem, index) => {
                    return(
                        <img className={imageIndex === index ? "selectedImage" : ""} key={index} src={elem.profileImages} width={"90px"} height={"100px"} alt="" onClick={() => setImageIndex(index)} />
                    )
                })
            }
        </div>
    </div>
  )
}

export default MultipleImagesSlider
