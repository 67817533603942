import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import server from "../../config/server.json";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay } from "swiper";

import BackImg4 from "../../assets/login-back-img4.jpg";
import BackImg1 from "../../assets/login-back-img1.jpg";
import BackImg2 from "../../assets/login-back-img2.jpg";
import "./LoginPageMobile.css";
import back from "../../assets/blue-back.png";
SwiperCore.use([Autoplay])


const LoginPageOtp = (props) => {
  const navigate = useNavigate();
  const [time, setTime] = useState(30);
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [finalPin, setFinalPin] = useState([]);
  const inputRef = useRef(null);

  const resetPin = () => {
    setPin1("");
    setPin2("");
    setPin3("");
    setPin4("");

    if (inputRef.current) {
      inputRef.current.focus(); // Focus on the first input element
    }
  };

  const handleFinalPin = (index, value) => {
    let demoPin = finalPin;
    if (index === 0) {
      demoPin[0] = value;
      setPin1(value);
    }
    if (index === 1) {
      demoPin[1] = value;
      setPin2(value);
    }
    if (index === 2) {
      demoPin[2] = value;
      setPin3(value);
    }
    if (index === 3) {
      demoPin[3] = value;
      setPin4(value);
    }
    let payload = demoPin.join("");
    if (payload.length === 4) {
      handleAPI(payload);
    }
  };

  const handleAPI = async (userOtp) => {
    try {
      const body = {
        phone_number: props.data.phone_number,
        otp: userOtp,
      };
      const response = await axios.post(
        `${server.url.production}${server.api.LOGIN}`,
        body
      );
      if (response.status === 200) {
        persistLogin(response.data.token, JSON.stringify(response.data.user));
        navigate("/search");
        props.setModal(false);
        props.setSteps(1);
        toast.success("Sucessess OTP", {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        setFinalPin([]);
        toast.error(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      setFinalPin([]);
      if (error.response.data.message === "User does not exist.") {
        props.next();
      } else {
        toast.error(error.response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
      resetPin();
    }
  };

  const handleKeyUp = (e) => {
    const maxLength = 1;
    const value = e.target.value;

    if (e.key === "Backspace") {
      if (value !== "") {
        e.target.value = "";
      }
    } else if (e.key === "ArrowLeft") {
      const prevInput = e.target.previousElementSibling;
      if (prevInput) {
        prevInput.focus();
      }
    } else if (e.key === "ArrowRight") {
      const nextInput = e.target.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    } else {
      const inputLength = e.target.value.length;
      if (inputLength >= maxLength) {
        const nextInput = e.target.nextElementSibling;
        if (nextInput) {
          nextInput.focus();
        }
      } else {
        const prevInput = e.target.previousElementSibling;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const timeInMinutes = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes === 0 ? "" : minutes} ${minutes === 0 ? "" : "min :"} ${
      seconds === 0 ? "" : seconds
    } ${seconds === 0 ? "" : "sec"}`;
  };

  const persistLogin = (token, user) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", user);
    window.dispatchEvent(new Event("token_update"));
  };

  const handleOnResend = async () => {
    try {
      const body = { phone_number: props.data.phone_number };
      const response = await axios.post(
        `${server.url.production}${server.api.CREATE_REGISTER_OTP}`,
        body
      );
      if (response.status === 201) {
        setTime(30);
        toast.success("OTP Sent.", {
          autoClose: 3000,
          position: "bottom-right",
        });
      } else {
        toast.error(response.data.message, {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (true) {
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (time !== 0) {
        setTime(time - 1);
      }
    }, [1000]);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  return (
    <div className="loginPageMobile-main">
      <div className="loginPageMobile">
        <div className="form-box otp-form">
          <div className="login-right-side">
            <div className="swiper-container">
              <Swiper
                slidesPerView="auto"
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                autoplay={{ delay: 3000, disableOnInteraction: false }} // Update autoplay configuration
              >
                <SwiperSlide>
                  <button
                    className="close-button"
                    onClick={() => props.cancel()}
                  >
                    <AiOutlineClose />
                  </button>
                  <img src={BackImg1} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <button className="close-button"></button>
                  <img src={BackImg2} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <button className="close-button"></button>
                  <img src={BackImg4} alt="" />
                </SwiperSlide>
              </Swiper>
            </div>

            <span
              onClick={() => props.cancel()}
              className="change-number LoginMobile-otpText"
            >
              <img src={back} alt="" /> Change number
            </span>
            <form className="LoginMobile-otp" onSubmit={handleOnSubmit}>
              <label>
                Enter OTP sent to{" "}
                <span className="OTP-phone-number">
                  +91 {props.data.phone_number}
                </span>
              </label>
              <br />
              <input
                className="otp-input"
                autoFocus
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={1}
                name="pin1"
                id="pin1"
                value={pin1}
                onChange={(e) => handleFinalPin(0, e.target.value)}
                onKeyUp={handleKeyUp}
                autoComplete="one-time-code" // Set autoComplete attribute to "one-time-code"
              />
              <input
                className="otp-input"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={1}
                name="pin2"
                id="pin2"
                value={pin2}
                onChange={(e) => handleFinalPin(1, e.target.value)}
                onKeyUp={handleKeyUp}
                autoComplete="one-time-code" // Set autoComplete attribute to "one-time-code"
              />
              <input
                className="otp-input"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={1}
                name="pin3"
                id="pin3"
                value={pin3}
                onChange={(e) => handleFinalPin(2, e.target.value)}
                onKeyUp={handleKeyUp}
                autoComplete="one-time-code" // Set autoComplete attribute to "one-time-code"
              />
              <input
                className="otp-input"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={1}
                name="pin4"
                id="pin4"
                value={pin4}
                onChange={(e) => handleFinalPin(3, e.target.value)}
                onKeyUp={handleKeyUp}
                autoComplete="one-time-code" // Set autoComplete attribute to "one-time-code"
              />

              <p className="d-flex align-items-center resend-otp-text resendText">
                Haven't received OTP? &nbsp;
                <button
                  className="resend-link"
                  onClick={handleOnResend}
                  disabled={time === 0 ? false : true}
                >
                  Resend {timeInMinutes(time)}
                </button>
              </p>
            </form>
            <hr className="line" />
            <p className="TandC TC-otp">
              By continuing, you agree to MarwaadiShaadi{" "}
              <Link to={"/terms-conditions"}> terms of use</Link> &{" "}
              <Link to={"/privacy-policy"}>privacy policy.</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPageOtp;
