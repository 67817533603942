import "./MyProfile.css";
import axios from "axios";
import { toast } from "react-toastify";
import {
  getHeightData,
  getMartialData,
  getFamilyIncomeData,
  getFamilyStatusData,
  getFamilyTypeData,
  getFamilyValuesData,
  getProfileCreatedByData,
  getEducationDegreeData,
  geteducationCompletionYearData,
  getIncomeData,
} from "../../Redux/Master/ApiMaster";
import Career from "../CareerPage/Career";
import { useDispatch } from "react-redux";
import server from "../../config/server.json";
import { useNavigate } from "react-router-dom";
import EducationDetails from "../EducationDetails";
import React, { useEffect, useState } from "react";
import UserProfile from "../UserProfile/UserProfile";
import FamilyPage from "../FamilyDetails/FamilyPage";
// import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
import MyPreference from "../MyPreference/MyPreference";
import PersonalPage from "../PersonalDetails/PersonalDetails";
import EditMyProfile from "../ProfilePhoto/EditMyProfile";
import { FiChevronLeft } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";

function MyProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [steps, setSteps] = useState(1);
  const [next, setNext] = useState(false);
  const [token, setToken] = useState(null);
  const [userDetails, setUserDetails] = useState();
  const [userPreferences, setUserPreferences] = useState();
  const [preferenceStatus, setPreferenceStatus] = useState("");
  const [userDetailsStatus, setUserDetailsStatus] = useState("");
  const [photoDetailsStatus, setPhotoDetailsStatus] = useState("");
  const [familyDetailsStatus, setFamilyDetailsStatus] = useState("");
  const [careerDetailsStatus, setCareerDetailsStatus] = useState("");
  const [personalDetailsStatus, setPersonalDetailsStatus] = useState("");
  const [educationDetailsStatus, setEducationDetailsStatus] = useState("");
  const [openProfileList, setOpenProfileList] = useState(false);

  const getUserDetails = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.GET_USER_DATA}`,
        {
          headers: headers,
        }
      );
      if (response.status === 200) {
        const user = response.data;
        const userDetails = response && response.data.userDetails;
        setUser(user);
        setUserDetails(userDetails);
      } else {
        toast.error("Something Went Wrong, Please Try Again Later", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again Later", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
    return true;
  };

  const getPartnerPreferences = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${server.url.production}${server.api.FETCH_PREFERENCES}`,
        {
          headers: headers,
        }
      );
      if (response.status === 200) {
        const userPreferences = response.data;
        setUserPreferences(userPreferences);
      } else {
        toast.error("Something Went Wrong, Please Try Again Later", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again Later", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
    return true;
  };

  const checkUserProfileStatus = () => {
    if (
      user?.first_name &&
      user?.last_name &&
      user?.email_id &&
      user?.phone_number
    ) {
      setUserDetailsStatus(1);
    } else {
      setUserDetailsStatus(0);
    }
  };

  const checkProfilePhoto = () => {
    if (userDetails?.profileImage) {
      setPhotoDetailsStatus(1);
    } else {
      setPhotoDetailsStatus(0);
    }
  };

  const checkPersonalDetails = () => {
    if (
      userDetails?.age &&
      userDetails?.dob &&
      userDetails?.address_1 &&
      userDetails?.landmark &&
      userDetails?.city &&
      userDetails?.state &&
      userDetails?.pincode &&
      userDetails?.country &&
      userDetails?.height &&
      userDetails?.weight &&
      userDetails?.language &&
      userDetails?.horoscope &&
      userDetails?.religion &&
      userDetails?.marital
    ) {
      setPersonalDetailsStatus(1);
    } else if (
      userDetails?.age ||
      userDetails?.dob ||
      userDetails?.address_1 ||
      userDetails?.landmark ||
      userDetails?.city ||
      userDetails?.state ||
      userDetails?.pincode ||
      userDetails?.country ||
      userDetails?.height ||
      userDetails?.weight ||
      userDetails?.language ||
      userDetails?.horoscope ||
      userDetails?.religion ||
      userDetails?.marital
    ) {
      setPersonalDetailsStatus(2);
    } else {
      setPersonalDetailsStatus(0);
    }
  };

  const checkFamilyDetails = () => {
    if (
      userDetails?.profileCreatedBy &&
      userDetails?.fatherName &&
      userDetails?.motherName &&
      userDetails?.brother &&
      userDetails?.sister &&
      userDetails?.gotra &&
      userDetails?.mamaSurname &&
      userDetails?.familyType &&
      userDetails?.familyValue &&
      userDetails?.familyStatus &&
      userDetails?.familyIncome &&
      userDetails?.nativePlace
    ) {
      setFamilyDetailsStatus(1);
    } else if (
      !userDetails?.profileCreatedBy ||
      !userDetails?.fatherName ||
      !userDetails?.motherName ||
      !userDetails?.brother ||
      !userDetails?.sister ||
      !userDetails?.gotra ||
      !userDetails?.mamaSurname ||
      !userDetails?.familyType ||
      !userDetails?.familyValue ||
      !userDetails?.familyStatus ||
      !userDetails?.familyIncome ||
      !userDetails?.nativePlace
    ) {
      setFamilyDetailsStatus(2);
    } else {
      setFamilyDetailsStatus(0);
    }
  };

  const checkEducationDetails = () => {
    if (
      userDetails?.instituteName &&
      userDetails?.percentage &&
      userDetails?.qualification &&
      userDetails?.passingYear &&
      userDetails.specialization
    ) {
      setEducationDetailsStatus(1);
    } else if (
      !userDetails?.instituteName ||
      !userDetails?.percentage ||
      !userDetails?.qualification ||
      !userDetails?.passingYear ||
      !userDetails.specialization
    ) {
      setEducationDetailsStatus(2);
    } else {
      setEducationDetailsStatus(0);
    }
  };

  const checkCareerDetails = () => {
    if (
      userDetails?.occupation &&
      userDetails?.jobLocation &&
      userDetails?.companyName &&
      userDetails?.designation &&
      userDetails?.income
    ) {
      setCareerDetailsStatus(1);
    } else if (
      !userDetails?.occupation ||
      !userDetails?.jobLocation ||
      !userDetails?.companyName ||
      !userDetails?.designation ||
      !userDetails?.income
    ) {
      setCareerDetailsStatus(2);
    } else {
      setCareerDetailsStatus(0);
    }
  };

  const checkPartnerPreferences = () => {
    if (
      userPreferences?.address &&
      userPreferences?.Height &&
      userPreferences?.ageRangeFrom &&
      userPreferences?.ageRangeTo &&
      userPreferences?.marital &&
      userPreferences?.education &&
      userPreferences?.annualIncome &&
      userPreferences?.drinking &&
      userPreferences?.smoking &&
      userPreferences?.familyType &&
      userPreferences?.familyValue &&
      userPreferences?.familyStatus &&
      userPreferences?.occupation &&
      userPreferences?.physicalStatus &&
      userPreferences?.openToRelocated &&
      userPreferences?.horoscopeMatching &&
      userPreferences?.profilePictures
    ) {
      setPreferenceStatus(1);
    } else if (
      userPreferences?.address !== undefined ||
      userPreferences?.Height !== undefined ||
      userPreferences?.ageRangeFrom !== undefined ||
      userPreferences?.ageRangeTo !== undefined ||
      userPreferences?.marital !== undefined ||
      userPreferences?.education !== undefined ||
      userPreferences?.annualIncome !== undefined ||
      userPreferences?.drinking !== undefined ||
      userPreferences?.smoking !== undefined ||
      userPreferences?.familyType !== undefined ||
      userPreferences?.familyValue !== undefined ||
      userPreferences?.familyStatus !== undefined ||
      userPreferences?.occupation !== undefined ||
      userPreferences?.physicalStatus !== undefined ||
      userPreferences?.openToRelocated !== undefined ||
      userPreferences?.horoscopeMatching !== undefined ||
      userPreferences?.profilePictures !== undefined
    ) {
      setPreferenceStatus(2);
    } else {
      setPreferenceStatus(0);
    }
  };

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setToken(localStorage.getItem("token"));
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    getUserDetails(); // eslint-disable-next-line
    getPartnerPreferences(); // eslint-disable-next-line
  }, [next]);

  useEffect(() => {
    checkUserProfileStatus(); // eslint-disable-next-line
    checkProfilePhoto(); // eslint-disable-next-line
    checkPersonalDetails(); // eslint-disable-next-line
    checkFamilyDetails(); // eslint-disable-next-line
    checkEducationDetails(); // eslint-disable-next-line
    checkCareerDetails(); // eslint-disable-next-line
    checkPartnerPreferences(); // eslint-disable-next-line
  }, [userDetails, userPreferences]);

  useEffect(() => {
    dispatch(getHeightData());
    dispatch(getMartialData());
    dispatch(getEducationDegreeData());
    dispatch(geteducationCompletionYearData());
    dispatch(getIncomeData());

    if (token) {
      dispatch(getFamilyTypeData());
      dispatch(getProfileCreatedByData());
      dispatch(getFamilyValuesData());
      dispatch(getFamilyStatusData());
      dispatch(getFamilyIncomeData());
    }
  }, [dispatch, token]);

  const checkStatus = (status) => {
    if (status === 1) {
      return "bg-green";
    } else if (status === 2) {
      return "bg-orange";
    } else {
      return "";
    }
  };

  const checkSteps = (stepNumber) => {
    if (steps === stepNumber) {
      return "tab-border";
    } else {
      return "";
    }
  };

  return (
    <div style={{ minHeight: "63vh" }}>
      <div className="myprofile-heading">
        <div className="myprofile-wrapper">
          <h2><span><FiChevronLeft /></span> <span>Edit Profile</span> <span><BsThreeDotsVertical onClick={() => setOpenProfileList(!openProfileList)} /></span></h2>
        </div>
      </div>
      <div className="myprofile-wrapper">
        {
          openProfileList && <div>
            <ul className="navtabs">
              <li
                className={`${checkStatus(userDetailsStatus)} ${checkSteps(1)}`}
                onClick={() => setSteps(1)}
              >
                User Details
              </li>
              <li
                className={`${checkStatus(photoDetailsStatus)} ${checkSteps(2)}`}
                onClick={() => setSteps(2)}
              >
                Profile Photo
              </li>
              <li
                className={`${checkStatus(personalDetailsStatus)} ${checkSteps(
                  3
                )}`}
                onClick={() => setSteps(3)}
              >
                Personal Details
              </li>
              <li
                className={`${checkStatus(familyDetailsStatus)} ${checkSteps(4)}`}
                onClick={() => setSteps(4)}
              >
                Family Details
              </li>
              <li
                className={`${checkStatus(educationDetailsStatus)} ${checkSteps(
                  5
                )}`}
                onClick={() => setSteps(5)}
              >
                Educations Details
              </li>
              <li
                className={`${checkStatus(careerDetailsStatus)} ${checkSteps(6)}`}
                onClick={() => setSteps(6)}
              >
                Career Details
              </li>
              <li
                className={`${checkStatus(preferenceStatus)} ${checkSteps(7)}`}
                onClick={() => setSteps(7)}
              >
                My Preference
              </li>
            </ul>
          </div>
        }
        <div>
          {steps === 1 ? (
            <UserProfile
              // steps={steps}
              setSteps={setSteps}
              userInfo={user}
              next={next}
              setNext={setNext}
            />
          ) : (
            ""
          )}
          {steps === 2 ? (
            <EditMyProfile
              // steps={steps}
              setSteps={setSteps}
              userDetails={userDetails}
              next={next}
              setNext={setNext}
            />
          ) : (
            ""
          )}
          {steps === 3 ? (
            <PersonalPage
              // steps={steps}
              setSteps={setSteps}
              Details={userDetails}
              next={next}
              setNext={setNext}
            />
          ) : (
            ""
          )}
          {steps === 4 ? (
            <FamilyPage
              // steps={steps}
              setSteps={setSteps}
              userDetails={userDetails}
              next={next}
              setNext={setNext}
            />
          ) : (
            ""
          )}
          {steps === 5 ? (
            <EducationDetails
              // steps={steps}
              setSteps={setSteps}
              userDetails={userDetails}
              next={next}
              setNext={setNext}
            />
          ) : (
            ""
          )}
          {steps === 6 ? (
            <Career
              // steps={steps}
              setSteps={setSteps}
              userDetails={userDetails}
              next={next}
              setNext={setNext}
            />
          ) : (
            ""
          )}
          {steps === 7 ? (
            <MyPreference
              // steps={steps}
              setSteps={setSteps}
              next={next}
              setNext={setNext}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default MyProfile;