import React, { useEffect, useState } from "react";
import DefaultUserProfileImage from "../../assets/avatar_placeholder.png";
import { useNavigate } from "react-router-dom";
import { BsFillTrashFill } from "react-icons/bs";
import { MdEdit, MdArrowBackIosNew } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { BiCamera } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";
import { toast } from "react-toastify";
import "./ProfilePhoto.css";
import axios from "axios";
import server from "../../config/server.json";
import Avatar from "react-avatar-edit";

function EditProfilePhoto({ userDetails, editMyProfilePhoto, setEditMyProfile, isImageUploaded, setImageUploaded }) {
    const navigate = useNavigate();
    // const [base64, setBase64] = useState(""); // eslint-disable-line
    const [token, setToken] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const multipleImageArray = [];
    const [multImg, setMultImg] = useState([]);
    const [profileImagePreview, setProfileImagePreview] = useState("");
    const [filteredPhotos, setFilteredPhotos] = useState([]);
    const [profileFeatured, setProfileFeatured] = useState(false);
    const [updateBtn, setUpdateBtn] = useState(true);
    const [edits, setEdit] = useState(false);
    const [saveBtn, setSaveBtn] = useState(false);
    const [toggle, setToggle] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [isUploaded, setUploaded] = useState(false);
    const [isDeleted, setDeleted] = useState(false);
    const [imagesCount, setImagesCount] = useState(false);
    const [modal, setModal] = useState(false);
    const [croppedProfileImage, setCroppedProfileImage] = useState("");
    //file to base64
    // const getbase64 = (file) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => {
    //         return setBase64(reader.result);
    //     };
    // };

    const onSelectFile = (e) => {
        const selectedFiles = e.target.files;
        if (
            e.target.files[0].type === "image/jpg" ||
            e.target.files[0].type === "image/jpeg" ||
            e.target.files[0].type === "image/png"
        ) {
            for (let index = 0; index < selectedFiles.length; index++) {
                const reader = new FileReader();
                reader.readAsDataURL(selectedFiles[index]);
                reader.onload = () => {
                    let str = reader.result;
                    str = str.replace(/^"(.*)"$/, "$1");
                    multipleImageArray.push(str);
                    // return reader.result;
                };
            }
            setTimeout(() => {
                setMultImg(multipleImageArray);
            }, 1000);

            const selectedFilesArray = Array.from(selectedFiles);
            const imagesArray = selectedFilesArray.map((file) => {
                return URL.createObjectURL(file);
            });
            if ((filteredPhotos.length + selectedFiles.length) < 7) {
                setImagesCount(true)
                setSelectedImages(imagesArray);
            } else {
                setImagesCount(false);
                setSelectedImages([]);
                toast.error("You can't upload more than 6 images.", {
                    autoClose: 3000,
                    position: "top-left",
                })
            }
            // setSelectedImages((previousImages) => previousImages.concat(imagesArray));
        } else {
            toast.error("Image should be of .png or .jpg or .jpeg type only", {
                autoClose: 3000,
                position: "top-left",
            });
        }
    };
    const handleDelete = async (id) => {
        try {
            const headers = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
            };
            const response = await fetch(
                `${server.url.production}${server.api.DELETE_MULTIPLE_PROFILE_IMAGES}${id}`,
                {
                    method: "PUT",
                    headers,
                }
            );

            if (response.status === 200) {
                toast.success("Successfull.", {
                    autoClose: 3000,
                    position: "top-left",
                });
                setDeleted(!isDeleted);
            } else {
                toast.success("Failed.", {
                    autoClose: 3000,
                    position: "top-left",
                });
            }
        } catch (error) {
            toast.error(error.response.data.message, {
                autoClose: 3000,
                position: "top-left",
            });
        }
    };

    const handleOnCrop = () => {
        setModal(true);
    }

    const handleCroppedImage = (item) => {
        // setPic(e.target.files);
        // getbase64(e.target.files[0]); //file to base64
        setCroppedProfileImage(item);
        setProfileImagePreview(item);
        setSaveBtn(true);
    }

    // const handleOnChange = (e) => {

    //     setPic(e.target.files);
    //     getbase64(e.target.files[0]); //file to base64
    //     setProfileImagePreview(URL.createObjectURL(e.target.files[0]));
    // };
    // const handleOnValidations = () => {
    //     const maxSize = 5000000; // Max size for the Image(5MB)
    //     if (!pic) {
    //         toast.error("*** Please Select The File", {
    //             autoClose: 3000,
    //             position: "top-left",
    //         });
    //     } else if (pic[0].size >= maxSize) {
    //         toast.error("*** Image should be less than 5MB", {
    //             autoClose: 3000,
    //             position: "top-left",
    //         });
    //     } else {
    //         return true;
    //     }
    // };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            const headers = {
                "Content-Type": "application/json",
                token: token,
            };
            const body = {
                userId: userDetails.id,
                profileImage: croppedProfileImage,
            };
            const response = await axios.post(
                `${server.url.production}${server.api.UPDATE_PROFILE_PHOTO}`,
                body,
                { headers }
            );
            if (response.status === 201) {
                toast.success(response.data.message, {
                    autoClose: 3000,
                    position: "top-left",
                });
                setModal(false);
                setEdit(false);
                setImageUploaded(!isImageUploaded);
            } else {
                toast.error("Something went wrong.", {
                    autoClose: 3000,
                    position: "top-left",
                });
            }
        } catch (error) {
            toast.error(error.message, {
                autoClose: 3000,
                position: "top-left",
            });
        }
    };
    const handleOnUpload = async () => {
        try {
            const headers = {
                "Content-Type": "application/json",
                token: token,
            };
            const body = {
                profileImages: multImg,
            };
            const response = await axios.post(
                `${server.url.production}${server.api.MULTIPLE_IMG}`,
                body,
                { headers }
            );
            if (response.status === 201) {
                setUploaded(!isUploaded);
                setUpdateBtn(false);
                toast.success("Successfull.", {
                    autoClose: 3000,
                    position: "top-left",
                });
            } else {
                toast.error("Failed.", {
                    autoClose: 3000,
                    position: "top-left",
                });
            }
        } catch (error) {
            toast.error(error.message, {
                autoClose: 3000,
                position: "top-left",
            });
        }
    };

    const updateToggle = (id) => setToggle(id);

    useEffect(() => {
        if (token) {
            const getFetchImage = async () => {
                try {
                    const headers = {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("token"),
                    };
                    const response = await axios.get(
                        `${server.url.production}${server.api.GET_MULTIPLE_PROFILE_IMAGES}`,
                        {
                            headers: headers,
                        }
                    );
                    if (response.status === 201) {
                        setIsLoading(false);
                        setFilteredPhotos(
                            response.data.filter((singleImg) => singleImg.status === "A")
                        );
                    }
                } catch (error) {
                    toast.error(error.response.data.error, {
                        autoClose: 3000,
                        position: "top-left",
                    });
                }
            };
            getFetchImage();
        }
    }, [token, isUploaded, isDeleted]);
    useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("user"));
        if (loggedUser) {
            setToken(localStorage.getItem("token"));
        } else {
            navigate("/");
        }
    }, [navigate]);

    return (
        <div className="profile_photo">
            <h3 className="back-editMyProfile">
                <span><BsChevronLeft onClick={() => setEditMyProfile(!editMyProfilePhoto)} /></span>
                <span>edit profile</span>
            </h3>
            <div className="profile_heading">
                <form onSubmit={handleOnSubmit}>
                    <h3>
                        <span>profile pic</span> </h3>
                    <div className="profile-image">

                        <img
                            src={
                                profileImagePreview
                                    ? profileImagePreview
                                    : userDetails.profileImage
                                        ? userDetails.profileImage
                                        : DefaultUserProfileImage
                            }
                            alt="User Profile"
                        />
                        <div className="user_edits_icon" onClick={() => setEdit(true)}>
                            <MdEdit />
                        </div>
                        {edits && (
                            <div className="user_images_edits">
                                {
                                    modal && <div className="profile_cropper" style={{ overflow: "hidden" }}>
                                        <Avatar
                                            width={"100%"}
                                            height={400}
                                            onCrop={handleCroppedImage}
                                            onClose={() => setModal(false)}
                                            exportAsSquare={true}
                                            exportSize={500}
                                        />
                                    </div>
                                }
                                <div className="label_div">
                                    <h6><span><GrClose onClick={() => {setEdit(false); setProfileImagePreview(userDetails.profileImage);}} /></span> <span>Edits Profile Picture</span></h6>
                                    <ul>
                                        <li>
                                            <span onClick={handleOnCrop}>Choose from Gallery</span>
                                        </li>
                                        <li><span><BiCamera /></span> <span>Take Photo</span></li>
                                        {/* <li> <span><AiFillDelete /></span> <span onClick={handledeleteProfilePic()}>Remove Current Picture</span></li> */}
                                        {saveBtn && <button type={"submit"} className="saveProfile">Save</button>}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <div className="profile_featured">
                <h3>
                    <span>featured</span>
                    <span><MdEdit onClick={() => setProfileFeatured(true)} /></span>
                </h3>
                <div className="uploaded_profile">
                    {filteredPhotos === null
                        ? ""
                        : filteredPhotos.length > 0
                            ? Object.entries(filteredPhotos).map((elem, index) => (
                                <div key={index} className="uploaded_photo">
                                    <img
                                        src={elem[1].profileImages}
                                        className="uploaded-profile-images"
                                        alt="profile"
                                    />
                                </div>
                            ))
                            :
                            isLoading ?
                                <div className="view-loader-container">
                                    <div className="lds-roller">
                                        {[...Array(8)].map((_, index) => (
                                            <div key={index}></div>
                                        ))}
                                    </div>
                                </div>
                                :
                                <div className="no_photo">
                                    <h5>no photos</h5>
                                    <div>
                                        <label>
                                            + Add Images
                                            <p>Upto 6 images</p>
                                            <input
                                                type="file"
                                                name="images"
                                                onChange={onSelectFile}
                                                multiple
                                                accept="image/png, image/jpeg, image/jpg"
                                            />
                                        </label>
                                        <div className="uploaded-profile-images-row">
                                            {selectedImages.length > 0 &&
                                                (selectedImages.length > 6 ? (
                                                    toast.error("You can't upload more than 6 images.", {
                                                        autoClose: 3000,
                                                        position: "top-left",
                                                    })
                                                ) : (
                                                    <>
                                                        <span
                                                            className="upload-mult-img-btn"
                                                            onClick={handleOnUpload}
                                                        >
                                                            Click here to upload images
                                                        </span>
                                                    </>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                    }
                </div>
                {
                    profileFeatured && (
                        <div className="add_profile-photo">
                            <h3>
                                <span onClick={() => setProfileFeatured(false)}><MdArrowBackIosNew /></span>
                                <span>featured</span>
                            </h3>
                            <h4>
                                <span className={toggle === 1 ? "activeToggle" : ""} onClick={() => updateToggle(1)}>upload</span>
                                <span className={toggle === 2 ? "activeToggle" : ""} onClick={() => updateToggle(2)}>delete</span>
                            </h4>
                            <div className={toggle === 1 ? "show_content" : "hide_content"}>
                                <div>
                                    <div className="uploaded_profile_featured">
                                        <div className={updateBtn === true && imagesCount === true ? "upload_popup_open" : "upload_popup_close"}>
                                            <div className="popup_btn">
                                                <h5>Click OK to upload and CANCEL to stop</h5>
                                                <div className="load_btn">
                                                    <button className="saveProfile" onClick={() => handleOnUpload()}>ok</button>
                                                    <button className="saveProfile" onClick={() => setUpdateBtn(false)}>cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                        {filteredPhotos === null
                                            ? ""
                                            : filteredPhotos.length > 0
                                                ? Object.entries(filteredPhotos).map((elem) => (
                                                    <div key={elem} className="uploaded_photo_featured">
                                                        <div className="image_conatiner">
                                                            <img
                                                                src={elem[1].profileImages}
                                                                alt="updated profile"
                                                            />
                                                        </div>
                                                    </div>
                                                ))
                                                : ""}
                                    </div>
                                </div>
                                {filteredPhotos.length < 6 ?
                                    <div className="label_container">
                                        <label>
                                            +
                                            <input
                                                type="file"
                                                name="images"
                                                onChange={onSelectFile}
                                                onClick={() => setUpdateBtn(true)}
                                                multiple
                                                accept="image/png, image/jpeg, image/jpg"
                                            />
                                        </label>
                                    </div> : null}
                            </div>
                            <div className={toggle === 2 ? "show_content" : "hide_content"} >
                                <div className="uploaded_profile_featured">
                                    <div className={updateBtn === true && imagesCount === true ? "upload_popup_open" : "upload_popup_close"}>
                                        <div className="popup_btn">
                                            <h5>Click OK to upload and CANCEL to stop</h5>
                                            <div className="load_btn">
                                                <button className="saveProfile" onClick={() => handleOnUpload()}>ok</button>
                                                <button className="saveProfile" onClick={() => setUpdateBtn(false)}>cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredPhotos === null
                                        ? ""
                                        : filteredPhotos.length > 0
                                            ? Object.entries(filteredPhotos).map((elem) => (
                                                <div key={elem} className="uploaded_photo_featured">
                                                    <div className="image_conatiner">
                                                        <img
                                                            src={elem[1].profileImages}
                                                            alt="updated profile"
                                                        />
                                                    </div>
                                                    <span
                                                        onClick={() => handleDelete(elem[1]._id)}
                                                        className="delete-btn"
                                                    >
                                                        <BsFillTrashFill />
                                                    </span>
                                                </div>
                                            ))
                                            : ""}
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
export default EditProfilePhoto;