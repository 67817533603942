import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import server from "../../config/server.json";
import "../../pages/Home.css";
import avatar from "../../assets/avatar_placeholder.png";
import { GoLocation } from "react-icons/go";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineReload,
  AiTwotoneThunderbolt,
} from "react-icons/ai";
import { ImCross } from "react-icons/im";

function ProfileSlider() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [allUser, setAllUser] = useState(null);
  const [token, setToken] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("user"));
    if (loggedUser) {
      setUser(loggedUser);
      setToken(localStorage.getItem("token"));
    } else {
      navigate("/");
      toast.error("Something Went Wrong, Please Try Again Later", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const userGender = user?.gender === "F" ? "M" : "F";
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const response = await axios.get(
        `${server.url.production}${server.api.SEARCH_FILTER}?gender=${userGender}`,
        { headers }
      );
      if (response.status === 200) {
        setAllUser(response?.data.users);
      } else {
        toast.error("Something Went Wrong, Please Try Again Later", {
          autoClose: 3000,
          position: "bottom-right",
        });
      }
    };
    fetchData();
  }, [token]); //eslint-disable-line

  return (
    <Slider {...settings}>
      {allUser === null
        ? ""
        : allUser?.slice(-30).map((data, index) => (
            <div key={index} className="slider1">
              <Link
                to={`/profile/view/${data._id}`}
                target="_blank"
                rel="noreferrer"
              >
                {/* <div className="single-profile">
                  <img
                    src={
                      data.userDetails.profileImage
                        ? data.userDetails.profileImage
                        : avatar
                    }
                    alt="Profile"
                  />
                  <div className="shadow"></div>
                  <div className="single-profile-data">
                    <h3>{data.first_name},</h3>
                    <h4>
                      <div className="profile_card_row">
                        {data.userDetails.age ? (
                          <span className="">
                            {data.userDetails.age}yrs, &nbsp;
                          </span>
                        ) : (
                          ""
                        )}
                        {data.userDetails.height ? (
                          <span>{data.userDetails.height}cms, &nbsp;</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="profile_card_row">
                        {data.userDetails.horoscope ? (
                          <span>{data.userDetails.horoscope}, &nbsp;</span>
                        ) : (
                          ""
                        )}
                        {data.userDetails.religion ? (
                          <span>{data.userDetails.religion}, &nbsp;</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </h4>
                    <h5>
                      <span>
                        <GoLocation />{" "}
                        {data.userDetails.city
                          ? data.userDetails.city
                          : "Unknown"}
                      </span>
                    </h5>
                    <div className="icon-button">
                      <span>
                        <AiOutlineReload />
                      </span>
                      <span>
                        <ImCross />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillHeart />
                      </span>
                      <span>
                        <AiTwotoneThunderbolt />
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="sliderCard">
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={
                        data.userDetails?.profileImage
                          ? data.userDetails?.profileImage
                          : avatar
                      }
                      alt={data.first_name}
                      className="slider-image"
                    />
                    <div className="sliderCardText">
                      <h3>{data.first_name},</h3>
                      <h4>
                        <div className="profile_card_row">
                          {data.userDetails.age ? (
                            <span className="">
                              {data.userDetails.age}yrs, &nbsp;
                            </span>
                          ) : (
                            ""
                          )}
                          {data.userDetails.height ? (
                            <span>{data.userDetails.height}cms, &nbsp;</span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="profile_card_row">
                          {data.userDetails.horoscope ? (
                            <span>{data.userDetails.horoscope}, &nbsp;</span>
                          ) : (
                            ""
                          )}
                          {data.userDetails.religion ? (
                            <span>{data.userDetails.religion}, &nbsp;</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </h4>
                      <h5>
                        <span>
                          <GoLocation />{" "}
                          {data.userDetails.city
                            ? data.userDetails.city
                            : "Unknown"}
                        </span>
                      </h5>
                    </div>
                    <div className="icon-button">
                      <span>
                        <AiOutlineReload />
                      </span>
                      <span>
                        <ImCross />
                      </span>
                      <span>
                        <AiFillStar />
                      </span>
                      <span>
                        <AiFillHeart />
                      </span>
                      <span>
                        <AiTwotoneThunderbolt />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
    </Slider>
  );
}

export default ProfileSlider;
